<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group col-8" *ngIf="mode !== 'mass'">
          <label
            [class.font-weight-bold]="form.get('configConsequence')?.dirty"
            for="configConsequence"
          >
            {{ "headers.fastcr.impact.consequence" | translate }}
            <span class="text-danger">*</span>
          </label>
          <ngx-select
            [items]="configConsequencesReference"
            [placeholder]="'(Select a Consequence)'"
            formControlName="configConsequence"
            id="configConsequence"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
          <div
            class="text-danger"
            *ngIf="
              showValidationError && form.get('configConsequence')?.invalid
            "
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
      </div>
      <div formArrayName="products">
        <div class="text-muted">
          {{ "headers.fastcr.impact.probability" | translate }} (%)
        </div>
        <div
          class="ml-2 mb-2"
          *ngFor="let product of products.controls; let i = index"
        >
          <div class="row mb-1 align-items-center" [formGroupName]="i">
            <div class="col-6 d-flex align-items-center">
              <label
                for="inputPassword6"
                class="col-form-label mr-2"
                [class.font-weight-bold]="
                  product.get('probabilityConsequence')?.dirty
                "
              >
                {{ product.get("productName")?.value }}
              </label>
              <app-field-clear-button
                (setEmptyParent)="setEmpty(i)"
              ></app-field-clear-button>
            </div>
            <div class="col-3">
              <input
                type="number"
                formControlName="probabilityConsequence"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    showValidationError &&
                    product.get('probabilityConsequence')?.invalid
                }"
                (keypress)="validateNumberInput($event)"
                placeholder="N/A"
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                showValidationError &&
                product.get('probabilityConsequence')?.errors?.min
              "
            >
              {{ "messages.greater_than" | translate }}
              0.
            </div>
            <div
              class="text-danger pl-3"
              *ngIf="
                showValidationError &&
                product.get('probabilityConsequence')?.errors?.max
              "
            >
              {{ "messages.less_than" | translate }}
              100.
            </div>
          </div>
        </div>
      </div>
      <div
        class="text-danger"
        *ngIf="showValidationError && productValidationError"
      >
        {{ "messages.criticality.zero_product" | translate }}
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
