<div class="app-container">
  <div class="app-main">
    <div class="container">
      <div class="jumbotron mt-4">
        <div class="d-flex flex-row justify-content-between">
          <h1 class="display-4"><i class="la la-lock la-1x"></i> {{ 'jumbotron.title' | translate }}</h1>
          <div class="language">
            <div (click)="switchLanguage('en')"><img alt="English" class="m-1 flag" src="assets/img/en.png"/></div>
            <div (click)="switchLanguage('fr')"><img alt="Français" class="m-1 flag" src="assets/img/fr.png"/></div>
          </div>
        </div>
        <p class="lead">{{ 'jumbotron.content' | translate }}</p>
      </div>
      <div class="d-flex justify-content-center">
        <div class="w-50 h-100">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer class="mt-auto"></app-footer>
