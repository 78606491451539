import {
  Component,
  OnInit,
  ViewChild,
  Input,
  SimpleChanges,
  Output,
  OnChanges,
  EventEmitter,
} from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConditionsModel } from '../../../../shared/models';
import { ConfirmModalComponent } from '../../../../shared/modals';
import { GridComponent } from '../../../../shared';
import { ConditionsEditComponent } from './edit/edit.component';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../../../shared/services/breadcrumb.service';

@Component({
  selector: 'app-conditions',
  templateUrl: './conditions.component.html',
})
export class ConditionsListComponent implements OnInit, OnChanges {
  @ViewChild(GridComponent, { static: true }) conditionsGrid?: GridComponent;

  @Input() selectedLifecycleModelNodes: any[] = [];
  @Input() sizeColumnsToFit = true;
  @Output() resetModelsGrid = new EventEmitter<any>();
  @Output() changeSelectLifecycleModelNodes = new EventEmitter<any>();
  @Output() changeChildGrid = new EventEmitter<any>();
  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  isReferenceLoaded = false;
  conditionsGridAutoGroupColumnDef?: any;
  modelData: any = {};
  valuesReference?: any;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    const tmpGridOptions: GridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1, // expand all groups by default
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.fastlc.lifecycle_model.model'
          ),
          field: 'model_name',
          valueGetter: (params: any) => {
            if (params.data) {
              return params.data.model_id;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.model_name;
            }
          },
          cellRenderer: (params: any) => {
            return this.modelData?.[params.value];
          },
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.model_conditions.comment'
          ),
          field: 'comment',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.model_conditions.values'
          ),
          valueGetter: (params: any) => {
            if (params.data !== undefined) {
              return params.data?.condition_values?.length
                ? params.data.condition_values.includes('___')
                  ? params.data.condition_values.split('___')
                  : params.data.condition_values
                : null;
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.lifecycle_model.id'
          ),
          field: 'model_id',
          hide: true,
        },
      ],
    };
    this.conditionsGridAutoGroupColumnDef = {
      headerName: this.translate.instant(
        'headers.fastlc.model_conditions.description'
      ),
      field: 'description',
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    this.gridOptions = tmpGridOptions;
    await this.loadItems();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedLifecycleModelNodes) {
      this.filterList(this.selectedLifecycleModelNodes);
    }
  }

  clearFilterAndResetModelsGrid(): void {
    this.conditionsGrid?.clearAllFilters();
    this.resetModelsGrid.emit();
  }

  resetModelConditionGrid(): void {
    this.conditionsGrid?.uncheckAll();
  }

  changeGrid(): void {
    this.changeChildGrid.emit();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }

    this.items = await this.data
      .get('api/fastlc/model-conditions/site-all', {})
      .toPromise();
    if (this.sizeColumnsToFit) {
      this.conditionsGrid?.gridApi?.sizeColumnsToFit();
      this.sizeColumnsToFit = false;
    }

    this.conditionsGrid?.uncheckAll();
    this.filterList(this.selectedLifecycleModelNodes);

    // set data array to use ID as value in valueGetter and Description as display value.
    // This is to allow grouping by ID instead of display value ("name" for eg.) thereby avoid grouping on duplicate display values
    this.items.map((val) => {
      this.modelData[val.model_id] = val.model_name;
    });

    if (!silent) {
      this.isLoading = false;
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onRowClicked(event: any): void {
    if (!event) {
      return;
    }
    this.showEditModal([event]);
  }

  filterList(modelConditionModels: any): void {
    const instance =
      this.conditionsGrid?.gridApi?.getFilterInstance('model_id');
    if (modelConditionModels.length > 0) {
      setTimeout(() => {
        instance?.setModel({
          values: _.map(modelConditionModels, (el) => el.id + ''),
        });
        this.conditionsGrid?.gridApi?.onFilterChanged();
      });
      return;
    }
    instance?.setModel(null);
    this.conditionsGrid?.gridApi?.onFilterChanged();
  }

  // Load values used in modal edit form
  async loadValues(id: number): Promise<void> {
    const result = await this.data
      .by('api/fastlc/condition-values', {
        criteria: {
          condition: id,
        },
      })
      .toPromise();
    this.valuesReference = _.map(result, (el: any) => {
      return {
        description: el.description,
        comment: el.comment,
      };
    });
  }

  /**
   * @param items
   */
  async showEditModal(items?: ConditionsModel[]): Promise<void> {
    this.valuesReference = [];
    if (items?.length === 1) {
      await this.loadValues(items[0].id);
    }

    this.bsModalRef = this.bsModalService.show(ConditionsEditComponent, {
      initialState: {
        items,
        selectedLifecycleModelNodes: this.selectedLifecycleModelNodes,
        valuesReference: this.valuesReference,
      },
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = (res: ConditionsModel) => {
      this.loadItems();
      this.toastr.showSuccess();
    };
  }

  showMassEditModal(): void {
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      const items = _.map(
        this.selectedNodes,
        (el) => new ConditionsModel(el.data)
      );
      this.showEditModal(items);
    }
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.isLoading = true;
      this.data
        .post('api/fastlc/model-conditions/delete-all-singly', ids)
        .subscribe(
          (res) => {
            this.items = this.items.filter(
              (item: any) => !ids.includes(`${item.id}`)
            );
            this.isLoading = false;
            this.toastr.showSuccess('messages.delete');
          },
          (err) => {
            this.isLoading = false;
            this.toastr.showError();
          }
        );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  myCellCallback = (params: any) => {
    return params.value;
  };

  exportProcessCallBack = (cellParams: any) => {
    if (!cellParams.value) {
      return '';
    }

    // for group row
    if (cellParams.node.field === 'model_name') {
      return this.modelData[cellParams.node.key];
    }

    // for normal row
    if (cellParams.column.colId === 'model_name') {
      return cellParams.node.data.model_name;
    }

    return cellParams.value;
  };
}
