export class UserModel {
  id: number;
  username: string;
  roles: string[];
  firstname: string[];
  lastname: string[];
  email: string[];
  lastConnectionDate: string;
  isEnabled: boolean;
  isRemoved: boolean;
  locale: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.username = data.username ? data.username : '';
    this.roles = data.roles ? data.roles : [];
    this.firstname = data.firstname ? data.firstname : '';
    this.lastname = data.lastname ? data.lastname : '';
    this.email = data.email ? data.email : '';
    this.lastConnectionDate = data.lastConnectionDate ? data.lastConnectionDate : '';
    this.isEnabled = data.isEnabled && [true, false].indexOf(data.isEnabled) > -1 ? data.isEnabled : null;
    this.isRemoved = data.isRemoved && [true, false].indexOf(data.isRemoved) > -1 ? data.isRemoved : null;
    this.locale = data.locale ? data.locale : '';
  }
}
