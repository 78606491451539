<div class="container-fluid h-100">
  <div class="row h-100">
    <div class="col-6 flex-column h-100">
      <div class="d-flex flex-column h-100">
        <fieldset>
          <div class="d-flex align-items-center mr-2" style="float: left">
            <div>
              <button
                (click)="refreshGrid()"
                [ngClass]="{
                  'btn-success': assetsIsLoading,
                  'btn-light': !assetsIsLoading
                }"
                class="btn btn-sm"
              >
                <i
                  [ngClass]="{ 'la-spin': assetsIsLoading }"
                  class="la la-refresh"
                ></i>
              </button>
            </div>
            <i
              *ngIf="isAssetModelsUpdated"
              tooltip="{{ 'messages.asset.update_warning' | translate }}"
              class="la la-warning text-warning am-asset-font-size"
            ></i>
            <div class="mr-2">
              <button
                (click)="assetsGrid?.expandAll()"
                [disabled]="assetsIsLoading || auth.isViewer('fastpm')"
                class="btn btn-light btn-sm"
              >
                <i class="la la-expand-arrows-alt"></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                (click)="assetsGrid?.collapseAll()"
                [disabled]="assetsIsLoading || auth.isViewer('fastpm')"
                class="btn btn-light btn-sm"
              >
                <i class="la la-compress-arrows-alt"></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                [disabled]="assetsIsLoading || auth.isViewer('fastpm')"
                (click)="setDisplayTree(true)"
                class="btn btn-light btn-sm"
              >
                <i class="las la-stream"></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                [disabled]="assetsIsLoading || auth.isViewer('fastpm')"
                (click)="setDisplayTree(false)"
                class="btn btn-light btn-sm"
              >
                <i class="las la-th-list"></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                (click)="assetsGrid?.clearAllFilters()"
                [disabled]="
                  !assetsGrid?.isFilterApplied || auth.isViewer('fastpm')
                "
                [ngClass]="{
                  'clear-filter position-relative': assetsGrid?.isFilterApplied
                }"
                class="btn btn-light btn-sm"
              >
                <i class="las la-filter"></i>
              </button>
            </div>
            <div class="mr-2">
              <div class="btn-group" dropdown>
                <button
                  id="button-custom-html"
                  dropdownToggle
                  type="button"
                  class="btn btn-sm btn-light dropdown-toggle"
                  aria-controls="dropdown-custom-html"
                  [routerLink]="[]"
                >
                  <ng-container *ngIf="assetFilterFlag === 0">{{
                    "navigations.show_all" | translate
                  }}</ng-container>
                  <ng-container *ngIf="assetFilterFlag === 1">{{
                    "navigations.model.without" | translate
                  }}</ng-container>
                  <ng-container *ngIf="assetFilterFlag === 2">{{
                    "navigations.model.with" | translate
                  }}</ng-container>
                  <span class="caret"></span>
                </button>
                <ul
                  id="dropdown-custom-html"
                  *dropdownMenu
                  class="dropdown-menu"
                  role="menu"
                  aria-labelledby="button-custom-html"
                >
                  <li role="menuitem">
                    <a
                      class="dropdown-item"
                      (click)="filterAssetsByModelCount(0)"
                      >{{ "navigations.show_all" | translate }}</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="filterAssetsByModelCount(1)"
                      >{{ "navigations.model.blank" | translate }}</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="filterAssetsByModelCount(2)"
                      >{{ "navigations.model.not_blank" | translate }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div
              [isDisabled]="assetsSelectedNodes.length === 0"
              class="btn-group mr-1"
              dropdown
            >
              <button
                class="dropdown-toggle btn btn-sm btn-primary"
                dropdownToggle
                type="button"
              >
                <ng-container *ngIf="assetsSelectedNodes.length > 0"
                  >{{ "navigations.with" | translate }}
                  <strong>{{ assetsSelectedNodes.length }}</strong>
                  {{ "navigations.selected" | translate }}
                </ng-container>
                <ng-container *ngIf="assetsSelectedNodes.length === 0">{{
                  "navigations.no_selection" | translate
                }}</ng-container>
                <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu">
                <li
                  (click)="assetsGrid?.export('xlsx')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-excel"></i> XLSX
                </li>
                <li
                  (click)="assetsGrid?.export('csv')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-csv"></i> CSV
                </li>
                <li
                  (click)="copyToClipboard()"
                  class="btn btn-sm dropdown-item copy-icon"
                >
                  <i class="las la-clipboard"></i>
                  {{ "navigations.copy_to_clipboard" | translate }}
                </li>
              </ul>
            </div>
          </div>
          <div class="mr-2" style="float: left">
            <button
              [disabled]="assetsIsLoading || auth.isViewer('fastpm')"
              (click)="showMatchAssetModal()"
              class="btn btn-sm btn-primary"
            >
              <i class="la la-fw la-file-csv"></i>
              {{ "navigations.match_select" | translate }}
            </button>
          </div>
        </fieldset>
        <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
          <app-grid
            [gridUid]="'fpm_strategy_assets'"
            (filterChanged)="assetsOnFilterChanged($event)"
            (selectionChanged)="assetsOnSelectionChanged($event)"
            [gridOptions]="assetsGridOptions"
            [autoGroupColumnDef]="assetGridAutoGroupColumnDef"
            [rowData]="displayTree ? assets : filteredItems"
            [groupSelectsChildren]="false"
            class="ag-theme-alpine"
            style="width: 100%; height: 100%"
          >
          </app-grid>
        </div>
      </div>
    </div>
    <div class="col-4 flex-column h-100">
      <div class="d-flex flex-column h-100">
        <fieldset [disabled]="auth.isViewer('fastpm')">
          <div class="d-flex align-items-center px-2">
            <div class="mr-2">
              <button
                (click)="strategiesLoadItems()"
                [ngClass]="{
                  'btn-success': strategiesIsLoading,
                  'btn-light': !strategiesIsLoading
                }"
                class="btn btn-sm"
              >
                <i
                  [ngClass]="{ 'la-spin': strategiesIsLoading }"
                  class="la la-refresh"
                ></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                (click)="addStrategies()"
                [disabled]="
                  assetsSelectedNodes.length === 0 ||
                  modelsSelectedNodes.length === 0
                "
                class="btn btn-success btn-sm"
              >
                <i class="la la-link"></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                (click)="removeStrategies()"
                [disabled]="strategiesSelectedNodes.length === 0"
                class="btn btn-danger btn-sm"
              >
                <i class="la la-unlink"></i>
              </button>
            </div>
          </div>
        </fieldset>
        <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
          <app-grid
            [gridUid]="'fpm_strategy_list'"
            (filterChanged)="strategiesOnFilterChanged($event)"
            (selectionChanged)="strategiesOnSelectionChanged($event)"
            [gridOptions]="strategiesGridOptions"
            [rowChangedCallBack]="strategiesRowChangedCallBack"
            [rowData]="strategies"
            class="ag-theme-alpine"
            style="width: 100%; height: 100%"
          >
          </app-grid>
        </div>
      </div>
    </div>
    <div class="col-2 flex-column h-100">
      <div class="d-flex flex-column h-100">
        <fieldset>
          <div class="d-flex align-items-center px-2">
            <div class="mr-2">
              <button
                (click)="modelsLoadItems()"
                [ngClass]="{
                  'btn-success': modelsIsLoading,
                  'btn-light': !modelsIsLoading
                }"
                class="btn btn-sm"
              >
                <i
                  [ngClass]="{ 'la-spin': modelsIsLoading }"
                  class="la la-refresh"
                ></i>
              </button>
            </div>
            <div
              [isDisabled]="modelsSelectedNodes.length === 0"
              class="btn-group mr-1"
              dropdown
            >
              <button
                class="dropdown-toggle btn btn-sm btn-primary"
                dropdownToggle
                type="button"
              >
                <ng-container *ngIf="modelsSelectedNodes.length > 0"
                  >{{ "navigations.with" | translate }}
                  <strong>{{ modelsSelectedNodes.length }}</strong>
                  {{ "navigations.selected" | translate }}
                </ng-container>
                <ng-container *ngIf="modelsSelectedNodes.length === 0">{{
                  "navigations.no_selection" | translate
                }}</ng-container>
                <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu">
                <li
                  (click)="modelsGrid?.export('xlsx')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-excel"></i> XLSX
                </li>
                <li
                  (click)="modelsGrid?.export('csv')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-csv"></i> CSV
                </li>
              </ul>
            </div>
          </div>
        </fieldset>
        <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
          <app-grid
            [gridUid]="'fpm_strategy_models'"
            (filterChanged)="modelsOnFilterChanged($event)"
            (selectionChanged)="modelsOnSelectionChanged($event)"
            [gridOptions]="modelsGridOptions"
            [rowData]="models"
            class="ag-theme-alpine"
            style="width: 100%; height: 100%"
          >
          </app-grid>
        </div>
      </div>
    </div>
  </div>
</div>
