import { Component, Input } from '@angular/core';

@Component({
  template: `
    <div class="modal-header">
      <h3 *ngIf="title" class="mb-0 font-weight-bold alert-heading">
        {{ title | translate }}
      </h3>
    </div>
    <div class="modal-body text-center">
      <p *ngIf="content">{{ content | translate }}</p>
      <button
        type="button"
        class="btn btn-info btn-sm mr-2"
        (click)="cancelFn()"
      >
        {{ 'navigations.close' | translate }}
      </button>
    </div>
  `,
})
export class ErrorModalComponent {
  // Modal title
  @Input()
  title: string | undefined;
  // Modal content
  @Input()
  content: string | undefined;
  // Cancel class
  @Input()
  cancelClass: string | undefined;
  // Cancel text
  @Input()
  cancelText: string | undefined;
  // Confirm class
  @Input()
  confirmClass: string | undefined;
  // Confirm text
  @Input()
  confirmText: string | undefined;

  /**
   * Action on cancel.
   */
  cancelFn(): void {}

  /**
   * Action on confirm.
   */
  confirmFn(): void {}
}
