export class AssetSpareModel {
  id: number;
  asset_id: number;
  asset_name: string;
  asset_description: string;
  asset_spare_comment: string;
  asset_spare_cost: number;
  date: string;
  efficiency: number;
  model_id: number;
  model_name: string;
  potred: number;
  spare_id: number;
  spare_name: string;
  status_id: number;
  status_name: string;
  totalcost: number;
  partref: string;
  critical: boolean | null;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.asset_id = data.asset_id ? data.asset_id : 0;
    this.asset_name = data.asset_name ? data.asset_name : '';
    this.asset_description = data.asset_description ? data.asset_description : '';
    this.asset_spare_comment = data.asset_spare_comment ? data.asset_spare_comment : '';
    this.asset_spare_cost = data.asset_spare_cost || data.asset_spare_cost === 0 ? data.asset_spare_cost : null;
    this.date = data.date ? data.date : '';
    this.efficiency = data.efficiency || data.efficiency === 0 ? data.efficiency : null;
    this.model_id = data.model_id ? data.model_id : 0;
    this.model_name = data.model_name ? data.model_name : '';
    this.potred = data.potred || data.potred === 0 ? data.potred : null;
    this.spare_id = data.spare_id ? data.spare_id : 0;
    this.spare_name = data.spare_name ? data.spare_name : '';
    this.status_id = data.status_id ? data.status_id : null;
    this.status_name = data.status_name ? data.status_name : '';
    this.totalcost = data.totalcost || data.totalcost === 0 ? data.totalcost : null;
    this.partref = data.partref ? data.partref : null;
    this.critical = data.critical ? data.critical === '1' ? true : false : null;
  }
}
