export class AccountModel {

  id = 0;
  name = '';
  color = '';
  logo = '';

  constructor(data: any) {
    Object.assign(this, data);
  }
}
