<div class="d-flex flex-column h-100">
  <div class="d-flex align-items-center px-2 pt-2">
    <div class="grid title mr-2">
      {{ "headers.routing.fsl.tasks" | translate }}
    </div>
    <div class="mr-2">
      <button
        (click)="loadItems()"
        [ngClass]="{ 'btn-success': isLoading, 'btn-light': !isLoading }"
        class="btn btn-sm"
      >
        <i [ngClass]="{ 'la-spin': isLoading }" class="la la-refresh"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        alt="clear filter"
        (click)="grid?.clearAllFilters()"
        [disabled]="!grid?.isFilterApplied"
        [ngClass]="{
          'clear-filter position-relative': grid?.isFilterApplied
        }"
        class="btn btn-light btn-sm"
      >
        <i class="las la-filter"></i>
      </button>
    </div>
    <fieldset [disabled]="auth.isViewer('routing')">
      <div class="mr-2" style="float: left">
        <button
          (click)="groupTasks()"
          [disabled]="!haveSelection()"
          class="btn btn-primary btn-sm"
        >
          <i class="la la-link"></i>
        </button>
      </div>
      <div class="mr-2" style="float: left">
        <button
          (click)="freeTasks()"
          [disabled]="!haveSelection()"
          class="btn btn-danger btn-sm"
        >
          <i class="la la-unlink"></i>
        </button>
      </div>

      <div
        *ngIf="selectedNodes.length > 0"
        class="d-flex align-items-center flex-grow-1 mr-2 font-weight-bold"
      >
        {{ selectedNodes.length }} selected over {{ selectionDuration }} hours
      </div>
    </fieldset>
    <div class="form-group col-2 mt-3">
      <ngx-select
        [items]="taskFilterOptions"
        [placeholder]="taskFilterOptions[0].name"
        (selectionChanges)="onChangeFilterType($event)"
        id="filter"
        optionTextField="name"
        optionValueField="value"
        size="small"
      ></ngx-select>
    </div>
  </div>
  <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
    <app-grid
      [gridUid]="'routing_fsl_tasks'"
      (filterChanged)="
        auth.isViewer('routing') ? null : onFilterChanged($event)
      "
      (selectionChanged)="onSelectionChanged($event)"
      [gridOptions]="gridOptions"
      [rowData]="items"
      class="ag-theme-alpine"
      style="width: 100%; height: 100%"
      [autoGroupColumnDef]="assetsGridAutoGroupColumnDef"
    >
    </app-grid>
  </div>
</div>
