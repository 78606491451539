<div class="container-fluid h-100">
  <div class="row h-100">
    <div class="col-12 flex-column h-100">
      <div class="d-flex flex-column h-100">
        <div class="d-flex align-items-center px-2">
          <div class="mr-2">
            <button
              (click)="loadItems()"
              [ngClass]="{ 'btn-success': isLoading, 'btn-light': !isLoading }"
              class="btn btn-sm"
            >
              <i [ngClass]="{ 'la-spin': isLoading }" class="la la-refresh"></i>
            </button>
          </div>
          <div class="mr-2">
            <button
              alt="clear filter"
              (click)="customCriteriaGrid?.clearAllFilters()"
              [disabled]="
                !customCriteriaGrid?.isFilterApplied || auth.isViewer('fastpm')
              "
              [ngClass]="{
                'clear-filter position-relative':
                  customCriteriaGrid?.isFilterApplied
              }"
              class="btn btn-light btn-sm"
            >
              <i class="las la-filter"></i>
            </button>
          </div>
          <fieldset>
            <div
              [isDisabled]="selectedNodes.length === 0 || isLoading"
              class="btn-group mr-1"
              dropdown
            >
              <button
                class="dropdown-toggle btn btn-sm btn-primary"
                dropdownToggle
                type="button"
              >
                <ng-container *ngIf="selectedNodes.length > 0"
                  >{{ "navigations.with" | translate }}
                  <strong>{{ selectedNodes.length }}</strong>
                  {{ "navigations.selected" | translate }}
                </ng-container>
                <ng-container *ngIf="selectedNodes.length === 0">{{
                  "navigations.no_selection" | translate
                }}</ng-container>
                <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu">
                <li
                  (click)="showMassEditModal()"
                  class="btn btn-sm dropdown-item"
                  *ngIf="!auth.isViewer('fastpm')"
                >
                  <i class="la la-fw la-pencil-alt"></i>
                  {{ "navigations.edit" | translate }}
                </li>
                <li
                  (click)="customCriteriaGrid?.export('xlsx')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-excel"></i> XLSX
                </li>
                <li
                  (click)="customCriteriaGrid?.export('csv')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-csv"></i> CSV
                </li>
                <li
                  class="divider dropdown-divider"
                  *ngIf="!auth.isViewer('fastpm')"
                ></li>
                <li
                  role="menuitem"
                  (click)="showMassClearConfirm()"
                  *ngIf="!auth.isViewer('fastpm')"
                >
                  <a class="dropdown-item btn btn-sm btn-danger">
                    <i class="la la-fw la-trash"></i>
                    {{ "navigations.clear" | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </fieldset>
        </div>
        <div class="d-flex h-100 w-100 flex-grow-1 p-2 ag-grid-container">
          <app-grid
            (filterChanged)="onFilterChanged($event)"
            (rowDoubleClicked)="
              auth.isViewer('fastpm') ? null : onRowClicked($event)
            "
            (selectionChanged)="onSelectionChanged($event)"
            [gridOptions]="gridOptions"
            [rowData]="items"
            class="ag-theme-alpine"
            style="width: 100%; height: 100%"
            [autoGroupColumnDef]="tasksGridAutoGroupColumnDef"
          >
          </app-grid>
        </div>
      </div>
    </div>
  </div>
</div>
