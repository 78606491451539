import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DataService, AuthService } from 'src/app/shared/services';
import { ConfCustomFieldsModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ComponentModel } from '../../../../../../shared/models';

@Component({
  selector: 'app-conf-asset-custom-header-edit',
  templateUrl: './edit.component.html'
})
export class ConfAssetCustomHeaderEditComponent implements OnInit {
  // Modal parameters
  headers: any; // array of headers to edit

  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: ('mass' | 'single' | 'new') = 'single';

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;
  showValidationError = false;
  label = '';
  customReference = [
    { key: 'custom1', label: 'Custom 1' },
    { key: 'custom2', label: 'Custom 2' },
    { key: 'custom3', label: 'Custom 3' },
    { key: 'custom4', label: 'Custom 4' },
    { key: 'custom5', label: 'Custom 5' },
    { key: 'custom6', label: 'Custom 6' },
  ];

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService
  ) {
    this.isLoading = true;
  }

  async ngOnInit(): Promise<void> {

    // Sniff mode
    this.mode = this.sniffEditMode(this.headers);
    this.label = this.translate.instant(`headers.asset.custom-header`);

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.headers = [new ConfCustomFieldsModel({
        account: this.auth.getAccountId(),
      })];
    }
    // Build for
    this.form = this.getForm(this.headers);
    // Loading done !
    this.isLoading = false;
  }

  get custom_fields(): UntypedFormArray {
    return this.form.get('custom_fields') as UntypedFormArray;
  }

  /**
   * Sniff usage mode from headers configuration
   */
  sniffEditMode(headers: [ComponentModel?]): ('mass' | 'single' | 'new') {
    // Empty array => New Item
    if (headers === undefined || headers.length === 0) {
      return 'new';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  getForm(items: any): UntypedFormGroup {
    return this.formBuilder.group({
      custom_fields: this.formBuilder.array(
        this.customReference.map((customRef: any) => this.formBuilder.group({
          account: new UntypedFormControl(this.auth.getAccountId()),
          customField: new UntypedFormControl(customRef.key),
          label: new UntypedFormControl({ value: customRef.label, disabled: true }),
          description: new UntypedFormControl(_.find(items, (item) => item.customField === customRef.key)?.description || ''),
        }))),
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => {
  };

  /**
   * Empty a field and mark it as dirty
   * @param key
   */
  setEmpty(key: number): void {
    this.custom_fields.at(key).get('description')?.setValue('');
    this.custom_fields.at(key).get('description')?.markAsDirty();
  }

  save(): boolean | void {
    this.showValidationError = true;
    if (!this.form || (this.form && !this.form.valid)) {
      return false;
    }

    // Query pointer
    let query;
    const postData = _.pickBy(this.form.value.custom_fields, el => el.description.trim() !== '');

    query = this.service.post('api/config/asset/custom-headers/save-all', postData);
    this.isSubmitted = true;
    query?.subscribe(res => {
      this.isSubmitted = false;
      this.confirmFn(res);
      this.bsModalRef.hide();
    }, err => {
      console.log('err: ', err);
      this.isSubmitted = false;
    });
  }
}
