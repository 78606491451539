import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService, DataService } from '../../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GridComponent } from '../../../../shared';
import * as _ from 'lodash';

@Component({
  selector: 'app-cost-calculator',
  templateUrl: './cost-calculator.component.html',
})
export class CostCalculatorComponent implements OnInit {
  @ViewChild(GridComponent, { static: true }) assetActionGrid?: GridComponent;
  @Input() assetModelId = 0;

  // Available items
  items: any[] = [];
  chartItems: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  isChartLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  isReferenceLoaded = false;
  assetActionGridAutoGroupColumnDef?: any;
  assetNames: any = {};
  modelNames: any = {};
  firstLoad = false;
  assetActionIds: any[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private translate: TranslateService,
    private data: DataService,
    public auth: AuthService,
    public router: Router
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    this.firstLoad = true;
    const tmpGridOptions: GridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      isRowSelectable: (params: any) =>
        params.data?.status?.systStatus === 'committed',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1, // expand all groups by default
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.asset.asset'),
          field: 'asset_name',
          valueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_id;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_name;
            }
          },
          cellRenderer: (params: any) => {
            return this.assetNames?.[params.value];
          },
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.name'
          ),
          field: 'asset_model_name',
          valueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_model_name;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_model_name;
            }
          },
          cellRenderer: (params: any) => {
            return this.modelNames?.[params.value];
          },
          rowGroup: true,
          hide: true,
        },

        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.type'
          ),
          field: 'type_name',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.budget'
          ),
          field: 'budget_name',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.cost'
          ),
          field: 'cost',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.plan'
          ),
          field: 'plan',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.savings'
          ),
          field: 'savings',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.status'
          ),
          field: 'status_name',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.last_status_update_date'
          ),
          field: 'lastStatusUpdateDate',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.cec_reduction'
          ),
          field: 'cec_reduction',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.ogc_reduction'
          ),
          field: 'ogc_reduction',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.comment'
          ),
          field: 'comment',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.lifecycle_strategy.id'
          ),
          field: 'asset_model_id',
          hide: true,
        },
      ],
    };
    this.assetActionGridAutoGroupColumnDef = {
      headerName: this.translate.instant(
        'headers.fastlc.asset_action.description'
      ),
      field: 'description',
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    this.gridOptions = tmpGridOptions;
    await this.loadItems();
  }

  async onSelectionChanged(event: any): Promise<void> {
    if (this.firstLoad) {
      return;
    }

    this.assetActionIds = [];

    _.filter(event, (el) => !el?.data?.system).forEach((element) => {
      this.assetActionIds.push(element.data.id);
    });

    await this.loadChartItems();
  }

  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }

    this.items = await this.data
      .by('api/fastlc/asset-actions', {
        criteria: {
          site: this.auth.getSiteId(),
          assetModel: this.assetModelId,
        },
      })
      .toPromise();

    this.assetActionGrid?.uncheckAll();

    this.items = _.filter(
      this.items,
      (el) => el.status.systStatus !== 'completed'
    );

    setTimeout(async () => {
      this.assetActionIds = [];

      this.gridOptions?.api?.forEachNode((node) => {
        if (node.data?.status?.systStatus === 'committed') {
          node.setSelected(true);
          this.assetActionIds.push(node.data.id);
        }
      });

      await this.loadChartItems();
      this.firstLoad = false;
    }, 0);

    this.items.map((val) => {
      this.assetNames[val.asset_id] = val.asset_name;
      this.modelNames[val.asset_model_name] = val.asset_model_name;
    });

    if (!silent) {
      this.isLoading = false;
    }
  }

  async loadChartItems(silent = false): Promise<void> {
    if (!silent) {
      this.isChartLoading = true;
    }

    this.chartItems = await this.data
      .post('api/fastlc/cost-calculator', {
        assetModelId: this.assetModelId,
        ids: this.assetActionIds,
      })
      .toPromise();

    if (!silent) {
      this.isChartLoading = false;
    }
  }

  myCellCallback = (params: any) => {
    return params.value;
  };

  downloadCsv(): void {
    const fields = [
      { year: '' },
      { cost: 'Cost' },
      { cec_downtime: 'CEC Downtime' },
      { cec_risk: 'CEC Risk' },
      { cec_cost: 'CEC Cost' },
      { ogc_downtime: 'OGC Downtime' },
      { ogc_add_risk: 'OGC Add Risk' },
      { ogc_add_op_cost: 'OGC Add Op Cost' },
      { benefits: 'Benefits' },
      { proj: 'PROJ' },
      { base: 'BASE' },
      { differential: 'Differential' },
      { npv: 'NPV' },
      { baseline: 'Baseline (Run To Failure)' },
      { projected_actions: 'PROJECTED (Proposed Actions)' },
      { projected_actions_costs: 'PROJECTED (Proposed Actions Costs)' },
      { projected_residual_risks: 'PROJECTED (Proposed Residual Risks)' },
      { projected_benefits: 'PROJECTED (Benefits)' },
    ];

    const fieldData = fields.map((field) => {
      const value = _.values(field)[0];
      const key = _.keys(field)[0];

      return [value, ...this.chartItems.map((row) => row[key])].join(',');
    });

    const csvData = fieldData.join('\n');

    this.download(csvData);
  }

  download(data: any): void {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'PROJECTED (Proposed Actions).csv');
    a.click();
  }
}
