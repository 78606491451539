<div class="modal-body">
  <tabset>
    <tab
      (selectTab)="tabChange('select')"
      class="pb-2"
      heading="{{ 'navigations.plan.choose' | translate }}"
    >
      <div class="py-2" style="height: 450px">
        <app-grid
          (selectionChanged)="plansSelectionChanged($event)"
          [gridOptions]="gridOptions"
          [rowData]="plans"
          [rowChangedCallBack]="rowChangedCallBack"
          class="ag-theme-alpine"
          style="width: 100%; height: 100%"
        >
        </app-grid>
      </div>
    </tab>
    <tab
      (selectTab)="tabChange('new')"
      heading="{{ 'navigations.plan.create' | translate }}"
    >
      <div class="py-2">
        <form [formGroup]="form">
          <div class="form-row">
            <div class="form-group col-12">
              <input
                [formControlName]="'name'"
                class="form-control"
                type="text"
              />
            </div>
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('cmmsReference')?.dirty"
                for="cmmsReference"
              >
                {{ "headers.routing.plan.cmms_reference" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                [formControlName]="'cmmsReference'"
                id="cmmsReference"
                type="text"
              />
            </div>
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('comments')?.dirty"
                for="comments"
              >
                {{ "headers.routing.plan.comments" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                [formControlName]="'comments'"
                id="comments"
                type="text"
              />
            </div>
          </div>
        </form>
      </div>
    </tab>
  </tabset>
</div>

<div class="modal-footer">
  <div class="form-group">
    <div class="col-12 d-flex justify-content-between">
      <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
        <i class="la la-ban"></i>
        {{ "navigations.cancel" | translate }}
      </button>

      <button
        (click)="save()"
        [disabled]="!form.valid"
        class="btn btn-sm btn-success mr-2"
        type="button"
      >
        <i class="la la-save"></i> {{ "navigations.save" | translate }}
      </button>
    </div>
  </div>
</div>
