import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { DataService, AuthService, ToasterService } from 'src/app/shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ComponentModel } from '../../../../shared/models';
import { validateNumberInput } from 'src/utils';

@Component({
  selector: 'app-impact-loss-edit',
  templateUrl: './edit.component.html'
})
export class ImpactEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  // form instance
  form!: UntypedFormGroup;
  productsReference: [] = [];
  configConsequencesReference: [] = [];
  selectedFunctionLossNodes: any[] = [];

  // Usage mode
  mode: ('mass' | 'single' | 'new') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;
  showValidationError = false;
  productValidationError = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   * @param toastr
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private toastr: ToasterService,
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.targetIds = _.map(this.items, el => el.id);

    let items = this.items;

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      items = [];
    }

    // Build for
    this.form = this.getForm(items[0] || {});

    // Loading done !
    this.isLoading = false;
  }

  get products(): UntypedFormArray {
    return this.form.get('products') as UntypedFormArray;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [ComponentModel?]): ('mass' | 'single' | 'new') {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // More than one item => Mass Edit
    } else if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  /**
   *
   */
  getForm(item: any): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      products: this.formBuilder.array(this.productsReference.map((product: any) => this.formBuilder.group({
        productId: new UntypedFormControl(product.value),
        productName: new UntypedFormControl({ value: product.name, disabled: true }),
        probabilityConsequence: new UntypedFormControl(item[`probabilityConsequence_${product.value}`]
          || null, [Validators.min(0), Validators.max(100)])
      }))),
      configConsequence: new UntypedFormControl({ value: item.consequence_id, disabled: this.mode !== 'new' }, Validators.required),
    });
  }

  validateNumberInput(event: KeyboardEvent): void {
    validateNumberInput(event);
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => { };

  /**
   * Empty a field and mark it as dirty
   * @param key
   */
  setEmpty(key: number): void {
    this.products.at(key).get('probabilityConsequence')?.setValue(0);
    this.products.at(key).get('probabilityConsequence')?.markAsDirty();
  }

  /**
   *
   */
  save(): boolean | void {
    this.showValidationError = true;
    this.productValidationError = false;
    if (!this.form || (this.form && !this.form.valid)) {
      return false;
    }

    const products = this.products.value.filter((product: any) => product.probabilityConsequence !== null);
    if (products.length === 0) {
      this.productValidationError = true;
      return;
    }

    // Query pointer
    let query;

    if (this.mode === 'new') {
      const tmpData = _.pickBy(this.form.value, el => el !== null);
      tmpData.products = tmpData.products?.filter((product: any) => product.probabilityConsequence !== null);
      tmpData.function_loss = this.selectedFunctionLossNodes.map((node) => node.data.id);
      query = this.service.post('api/fastcrit/impacts', tmpData);

    } else if (this.mode === 'single') {
      const formData = this.form.value;
      const item = this.items[0];
      formData.products = formData.products?.filter((product: any) => product.probabilityConsequence !== null);
      formData.function_loss = item.function_loss_id;
      formData.configConsequence = item.consequence_id;

      query = this.service.update('api/fastcrit/impacts', this.targetIds[0], formData);
    } else {
      const tmpData: any = _.pickBy(this.form.value, el => el !== null);
      tmpData.products = tmpData.products?.filter((product: any) => product.probabilityConsequence !== null);
      const data = this.items.map((item: any) => ({
        ...tmpData,
        function_loss: item.function_loss_id, configConsequence: item.consequence_id
      }));

      query = this.service.saveAll('api/fastcrit/impacts', data);
    }

    this.isSubmitted = true;
    query?.subscribe(res => {
      this.isSubmitted = false;
      this.confirmFn(res);
      this.bsModalRef.hide();
    }, err => {
      this.toastr.showError(`${err.error.message} </br> ${this.translate.instant('navigations.already_exist')}`);
      this.isSubmitted = false;
    });
  }
}
