import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  Broadcaster,
  DataService,
  GridComponent,
  SharedDataService,
  ToasterService,
} from '../../shared';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GridOptions } from 'ag-grid-community';
import { AssetSummaryComponent } from '../../shared/components/asset-summary/asset-summary.component';
import { ActivatedRoute } from '@angular/router';
import { ConfirmModalComponent } from '../../shared/modals';
import { AssetAccessEditComponent } from './asset-access.edit/edit.component';
import * as _ from 'lodash';
import { LOCATION_PIN_MODE } from 'src/app/shared/events';

@Component({
  selector: 'app-locations-items',
  templateUrl: './locations-items.component.html',
  styleUrls: ['./locations-items.component.scss'],
})
export class LocationsItemsComponent implements OnInit, AfterContentChecked {
  @Input() items?: any = [];
  @Input() location?: any;
  @Output() itemsUpdated = new EventEmitter();
  @Output() itemOnHover = new EventEmitter();

  bsModalRef?: BsModalRef;
  isDefaultAssetSelected = false;

  @Output() itemsSelectionChanged = new EventEmitter();

  @ViewChildren(GridComponent) grids?: GridComponent;

  // Local grid options
  itemsGridOptions?: GridOptions;
  itemsGridAutoGroupColumnDef?: any;

  itemsGrid?: any;
  selectedItems: any[] = [];
  accessReference: [] = [];
  customHeaders: any;

  isLoading = false;
  isPinMode = false;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private sharedData: SharedDataService,
    private toastr: ToasterService,
    private bsModalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    public auth: AuthService,
    public broadcaster: Broadcaster
  ) {}

  ngAfterContentChecked(): void {
    // @ts-ignore
    this.itemsGrid = this.grids?._results[0];
  }

  /**
   */
  async ngOnInit(): Promise<void> {
    this.broadcaster.on(LOCATION_PIN_MODE).subscribe((ev: any) => {
      if (ev?.isMap) {
        this.selectedItems.forEach((node) => {
          node.setSelected(false); // Deselect the node
        });
      }
      this.isPinMode = ev.isPinMode;
    });

    this.customHeaders = await this.sharedData.getAssetCustomHeaders(
      this.auth.getAccountId()
    );
    this.itemsGridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      rowDragManaged: true,
      suppressMoveWhenRowDragging: true,
      onRowDataChanged: (event) => this.selectDefaultAsset(event),
      onCellMouseOver: (event) => this.cellMouseOver(event),
      onCellMouseOut: (event) => this.cellMouseOut(event),
      defaultColDef: {
        filter: true,
        sortable: false,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.location.item.asset'),
          field: 'name',
          rowDrag: this.auth.isViewer('location') ? false : true,
          filter: 'agTextColumnFilter',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          cellRenderer: (params: any): HTMLAnchorElement | string => {
            if (params.data.type !== 'asset') {
              return params.data.name;
            }
            const aTag = document.createElement('a');
            aTag.href = 'javascript:void(0)';
            const id = this.getActualId(params.data.id, params.data.type);
            aTag.onclick = () => {
              if (!this.isPinMode) {
                this.showSummaryModal(id);
              }
            };
            aTag.innerHTML = params.data.name;
            return aTag;
          },
        },
        {
          headerName: this.translate.instant(
            'headers.location.item.is_localized'
          ),
          field: 'is_localized',
          cellRenderer: (params: any): string => {
            if (params.value === true) {
              return '<i class="la la-check-square la-lg text-success"></i>';
            }
            return '';
          },
          width: 50,
        },
        {
          headerName: this.translate.instant('headers.location.order'),
          field: 'location_order',
          cellRenderer: (params: any): string => {
            if (params.data.type === 'asset') {
              return params.value;
            }
            return '';
          },
        },

        {
          headerName: this.translate.instant(
            'modules.admin.asset_parameters.children.access_requirements.name'
          ),
          field: 'accesses',
          valueGetter: (params: any): any[] => {
            if (
              params.data !== undefined &&
              params.data.accesses &&
              params.data.accesses.length > 0
            ) {
              return _.map(params.data.accesses, (el) => el.description);
            }
            // for (Blanks) option
            return [''];
          },
        },
        {
          headerName: this.translate.instant('headers.location.item.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.category'),
          field: 'type',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqtype'),
          field: 'eqtype',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.specifications'),
          field: 'specifications',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom1,
          field: 'custom1',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom2,
          field: 'custom2',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom3,
          field: 'custom3',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom4,
          field: 'custom4',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom5,
          field: 'custom5',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom6,
          field: 'custom6',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmake'),
          field: 'eqmake',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmodel'),
          field: 'eqmodel',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqserial'),
          field: 'eqserial',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqsupplier'),
          field: 'eqsupplier',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.drawing'),
          field: 'drawing',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.criticality.criticality'),
          field: 'criticality_name',
          hide: true,
        },
      ],
    };
  }

  /**
   * @param ev
   */
  onSelectionChanged(ev: any): void {
    this.selectedItems = ev;
    this.itemsSelectionChanged.emit(ev);

    if (
      (this.selectedItems.length > 1 && this.isPinMode) ||
      (this.selectedItems.length === 0 && this.isPinMode)
    ) {
      this.isPinMode = false;
      this.broadcaster.broadcast(LOCATION_PIN_MODE, false);
    }
  }

  async loadAccessRef(): Promise<void> {
    this.isLoading = true;
    const orderByDesc = { orderBy: { description: 'asc' } };
    // Criticality
    this.accessReference = await this.data
      .by('api/config/asset/access-requirements', {
        criteria: {
          account: [this.auth.getAccountId()],
        },
        ...orderByDesc,
      })
      .toPromise();
    this.isLoading = false;
  }

  /**
   */
  showMassEditModal(): void {
    if (this.selectedItems && this.selectedItems.length > 0) {
      const items = _.map(this.selectedItems, (el) => el.data);
      this.showEditModal(items);
    }
  }

  onRowClicked(event: any): void {
    this.showEditModal([event]);
  }

  /**
   * @param items
   */
  async showEditModal(selectedItemsData?: any): Promise<void> {
    await this.loadAccessRef();
    const items = selectedItemsData
      .filter((el: any) => _.startsWith(el.id, 'asset_'))
      .map((item: any) => ({
        id: this.getActualId(item.id, 'asset'),
        accesses: item.accesses,
      }));
    this.bsModalRef = this.bsModalService.show(AssetAccessEditComponent, {
      initialState: {
        items,
        accessReference: this.accessReference,
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      this.itemsUpdated.emit();
      this.toastr.showSuccess();
    };
  }

  /**
   * @param ev
   */
  onRowDragEnd(ev: any): void {
    // Get all Asset's IDs and location_order
    const payload = this.itemsGrid
      .getAllRows()
      .filter((el: any) => el.type === 'asset')
      .map((el: any, key: number) => {
        const id = this.getActualId(el.id, el.type);
        return { id, location_order: key };
      });
    this.data.saveAll('api/assets', payload).subscribe(
      (res) => {
        this.itemsUpdated.emit();
        this.toastr.showSuccess();
      },
      (err) => {
        this.toastr.showError();
      }
    );
  }

  getActualId(id: string, type: string): number {
    return _.toInteger(_.replace(id, type + '_', ''));
  }

  clearPosition(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedItems, (el) => el.id);

      const assetsData: any[] = [];
      const tagsData: any[] = [];

      _.each(ids, (id) => {
        if (_.startsWith(id, 'asset_')) {
          const itemId = this.getActualId(id, 'asset');
          const item = {
            id: itemId,
            location: this.location.id,
            position_x: 0,
            position_y: 0,
          };
          assetsData.push(item);
        } else if (_.startsWith(id, 'tag_')) {
          const itemId = this.getActualId(id, 'tag');
          tagsData.push(itemId);
        }
      });

      if (assetsData.length > 0) {
        this.data.saveAll('api/assets', assetsData).subscribe(
          (res) => {
            this.itemsUpdated.emit();
            this.toastr.showSuccess();
          },
          (err) => {
            this.toastr.showError();
          }
        );
      }

      if (tagsData.length > 0) {
        this.data.post('api/map/tags/delete-all', tagsData).subscribe(
          (res) => {
            this.itemsUpdated.emit();
            this.toastr.showSuccess('messages.delete');
          },
          (err) => {
            this.toastr.showError();
          }
        );
      }

      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  removeFromLocation(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedItems, (el) => el.id);

      const assetsData: any[] = [];
      const tagsData: any[] = [];

      _.each(ids, (id) => {
        if (_.startsWith(id, 'asset_')) {
          const itemId = this.getActualId(id, 'asset');
          const item = {
            id: itemId,
            location: null,
            position_x: 0,
            position_y: 0,
          };
          assetsData.push(item);
        } else if (_.startsWith(id, 'tag_')) {
          const itemId = this.getActualId(id, 'tag');
          tagsData.push(itemId);
        }
      });

      if (assetsData.length > 0) {
        this.data.saveAll('api/assets', assetsData).subscribe(
          (res) => {
            this.itemsUpdated.emit();
            this.toastr.showSuccess('messages.delete');
          },
          (err) => {
            this.toastr.showError();
          }
        );
      }

      if (tagsData.length > 0) {
        this.data.post('api/map/tags/delete-all', tagsData).subscribe(
          (res) => {
            this.itemsUpdated.emit();
            this.toastr.showSuccess('messages.delete');
          },
          (err) => {
            this.toastr.showError();
          }
        );
      }

      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  exportHtml(): void {
    const selectedIds = this.selectedItems.map((el) => el.data.id);

    this.data
      .download('api/map/locations/generate-html', {
        ids: selectedIds,
        location: this.location.id,
        site: this.auth.getSiteId(),
      })
      .subscribe(
        (res) => {
          const contentDisposition = res.headers.get('content-disposition');
          let fileName = contentDisposition.split(';')[1].trim().split('=')[1];
          fileName = fileName.replace(/"/g, '');
          const options = { type: res.body.type };
          this.data.createAndDownloadBlobFile(res.body, options, fileName);
        },
        (err) => {
          this.toastr.showError();
        }
      );
  }

  async showSummaryModal(assetId: number): Promise<void> {
    this.bsModalRef = this.bsModalService.show(AssetSummaryComponent, {
      initialState: {
        assetId,
      },
      class: 'modal-lg',
    });
  }

  selectDefaultAsset(event: any): void {
    const assetId = this.activatedRoute.snapshot.queryParamMap.get('asset_id');

    if (!assetId || this.isDefaultAssetSelected) {
      return;
    }

    setTimeout(() => {
      event.api.forEachNode((node: any) => {
        if (node.data.type === 'asset') {
          const itemId = this.getActualId(node.data.id, node.data.type);
          node.setSelected(itemId === +assetId);
        }
      });
    }, 500);

    if (!this.isDefaultAssetSelected) {
      this.isDefaultAssetSelected = true;
    }
  }

  exportShouldRowBeSkipped(params: any): boolean {
    if (params.node && _.startsWith(params.node.id, 'asset_')) {
      return false;
    }
    return true;
  }

  cellMouseOver(event: any): void {
    if (event.column.colId === 'name' && !this.isPinMode) {
      this.itemOnHover.emit(event.data.id);
    }
  }

  cellMouseOut(event: any): void {
    if (event.column.colId === 'name' && !this.isPinMode) {
      this.itemOnHover.emit(null);
    }
  }

  onPinModeClick(): void {
    this.isPinMode = this.isPinMode ? false : true;
    this.broadcaster.broadcast(LOCATION_PIN_MODE, {
      isPinMode: this.isPinMode,
    });
  }
}
