<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('description')?.dirty"
            for="description"
          >
            {{ "headers.fastlc.model_actions.description" | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('description')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="description"
            id="description"
            type="text"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('description')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label [class.font-weight-bold]="form.get('cost')?.dirty" for="cost">
            {{ "headers.fastlc.model_actions.cost" | translate }}
          </label>
          <input
            [ngClass]="{
              'is-invalid': showValidationError && form.get('cost')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="cost"
            id="cost"
            type="number"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('cost')?.errors?.min"
          >
            {{ "messages.positive" | translate }}
          </div>
        </div>
        <div class="form-group col-12" *ngIf="mode !== 'mass'">
          <label [class.font-weight-bold]="form.get('type')?.dirty" for="type">
            {{ "headers.fastlc.model_actions.type" | translate }}
          </label>
          <ngx-select
            [items]="typesReference"
            [placeholder]="'(Select a Type)'"
            formControlName="type"
            id="type"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('type')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12" *ngIf="mode !== 'mass'">
          <label
            [class.font-weight-bold]="form.get('budget')?.dirty"
            for="budget"
          >
            {{ "headers.fastlc.model_actions.budget" | translate }}
          </label>
          <ngx-select
            [items]="budgetsReference"
            [placeholder]="'(Select a Budget)'"
            formControlName="budget"
            id="budget"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('budget')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('comment')?.dirty"
            for="comment"
          >
            {{ "headers.fastlc.model_actions.comment" | translate }}
          </label>
          <input
            [ngClass]="{
              'is-invalid': showValidationError && form.get('comment')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="comment"
            id="comment"
            type="text"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('comment')?.errors?.min"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
