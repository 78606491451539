<div class="d-flex flex-column h-100">
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center justify-content-between w-100">
      <app-grid-displayer
        [isExpanded]="!showLeftGrid"
        (arrowClicked)="leftArrowClicked.emit(true)"
      ></app-grid-displayer>
      <div class="mr-2 ml-2">
        <span class="font-weight-bold mr-1">{{ location.name }}</span>
      </div>
      <div *ngIf="!auth.isViewer('location')">
        <button
          *ngIf="location.image !== ''"
          (click)="toggleShowImageUploader()"
          [class.btn-primary]="showImageUploader"
          [class.btn-light]="!showImageUploader"
          class="btn btn-sm"
        >
          <i
            *ngIf="!showImageUploader"
            class="la la-image map-header-fontsize"
          ></i>
          <i *ngIf="showImageUploader" class="la la-close"></i>
        </button>
      </div>
      <div *ngIf="!auth.isViewer('location')" class="ml-auto reorder-btn-box">
        <div *ngIf="!inReorderMode">
          <button (click)="startReorder()" class="btn btn-sm">
            <i class="la la-walking map-header-fontsize"></i>
          </button>
        </div>
        <div *ngIf="inReorderMode" class="d-flex">
          <div *ngIf="isSavingReorder" class="loader-box">
            <i class="la la-refresh la-spin"></i>
          </div>
          <div *ngIf="!isSavingReorder" class="d-flex">
            <div>
              <button (click)="onReorderSave()" class="btn btn-sm">
                <i class="la la-save map-header-fontsize"></i>
              </button>
            </div>
            <div>
              <button (click)="onReorderCancel()" class="btn btn-sm">
                <i class="la la-times-circle map-header-fontsize"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-grid-displayer
      [isExpanded]="showRightGrid"
      (arrowClicked)="rightArrowClicked.emit(true)"
    ></app-grid-displayer>
  </div>
  <div class="d-flex h-100 w-100 flex-grow-1 pt-2">
    <div
      class="d-flex justify-content-center h-100 w-100 p-2"
      style="border: solid 1px #babfc7"
    >
      <div #map id="map"></div>
      <ng-container *ngIf="!auth.isViewer('location')">
        <div *ngIf="showImageUploader" class="app-file-upload">
          <app-file-upload (filesUploaded)="saveLocationImages($event)">
          </app-file-upload>
        </div>
      </ng-container>
    </div>
  </div>
</div>
