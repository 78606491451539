import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
  SharedDataService,
} from '../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../../shared/modals';
import { GridComponent } from '../../shared';
import * as _ from 'lodash';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import { FastspStrategyEditComponent } from './edit/edit.component';
import { AssetSummaryComponent } from '../../shared/components/asset-summary/asset-summary.component';
import { ActivatedRoute } from '@angular/router';
import { AssetMatchComponent } from '../assets/match/match.component';
import { copyToClipboard, syncItems } from 'src/utils';

@Component({
  selector: 'app-fastsp-strategy',
  templateUrl: './strategy.component.html',
})
export class FastspStrategyListComponent implements OnInit {
  @ViewChild(GridComponent, { static: true }) strategyGrid?: GridComponent;

  // Available items
  items: any[] = [];
  filteredItems: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  refreshSparePartsGrid = false;
  isRecalculatingFields = false;
  // Local grid options
  gridOptions?: GridOptions;
  displayTree = true;
  //
  strategyGridAutoGroupColumnDef: any;
  isDefaultAssetSelected = false;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  // Form reference Data
  isReferenceLoaded = false;
  spareModelsReference?: any;
  assetFactorsReference?: any;
  customHeaders?: any;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    protected sharedData: SharedDataService,
    public breadCrumbService: BreadCrumbService,
    private activatedRoute: ActivatedRoute
  ) {}

  setDisplayTree(flag: boolean): void {
    if (flag !== this.displayTree) {
      this.displayTree = flag;
      this.strategyGrid?.gridApi?.refreshClientSideRowModel();
    }
    this.setFilteredItemsForFlatList();
  }

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant(
            'navigations.breadcrumbs.spares_strategy'
          ),
          link: '/fastsp/strategy',
        },
      ],
      2
    );

    this.customHeaders = await this.sharedData.getAssetCustomHeaders(
      this.auth.getAccountId()
    );

    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      treeData: true,
      groupDefaultExpanded: -1, // expand all groups by default
      getDataPath: (data) => {
        if (this.displayTree) {
          return data.hierarchy;
        } else {
          return data.hierarchy.slice(-1);
        }
      },
      excludeChildrenWhenTreeDataFiltering: true,
      onRowDataChanged: (event) => this.filterAndSelectDefaultAsset(event),
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.asset.description'),
          field: 'description',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.actual_risk_amount'
          ),
          field: 'actual_risk_amount',
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.actual_criticality'
          ),
          field: 'actual_crit_name',
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.target_risk_factors'
          ),
          field: 'assetFactors',
          valueGetter: (params: any): any[] => {
            if (
              params.data !== undefined &&
              params.data.assetFactors.length > 0
            ) {
              return _.map(params.data.assetFactors, (el) => el.description);
            }
            // for (Blanks) option
            return [''];
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.current_risk_factors'
          ),
          field: 'assetCurrentRiskFactors',
          valueGetter: (params: any): any[] => {
            if (
              params.data !== undefined &&
              params.data.assetCurrentRiskFactors.length > 0
            ) {
              return _.map(
                params.data.assetCurrentRiskFactors,
                (el) => el.description
              );
            }
            // for (Blanks) option
            return [''];
          },
        },
        {
          headerName: this.translate.instant(
            'modules.admin.asset_parameters.children.access_requirements.name'
          ),
          field: 'accesses',
          valueGetter: (params: any): any[] => {
            if (params.data !== undefined && params.data.accesses.length > 0) {
              return _.map(params.data.accesses, (el) => el.description);
            }
            // for (Blanks) option
            return [''];
          },
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.potential_reduction'
          ),
          field: 'potred',
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.efficiency'
          ),
          field: 'efficiency',
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.targetredrisk'
          ),
          field: 'targetred',
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.target_criticality'
          ),
          field: 'target_crit_name',
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.spares_models'
          ),
          field: 'assetModels',
          valueGetter: (params: any): any[] => {
            if (
              params.data !== undefined &&
              params.data.assetModels.length > 0
            ) {
              return _.map(params.data.assetModels, (el) => el.name);
            }
            // for (Blanks) option
            return [''];
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.costs'
          ),
          field: 'totalcost',
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.return_period'
          ),
          field: 'returnperiod',
        },
        {
          headerName: this.translate.instant('headers.asset.active'),
          field: 'active',
          hide: true,
          cellRenderer: (params: any): string => {
            if (params.value === true) {
              return (
                '<span class="badge badge-lg badge-success">' +
                this.translate.instant('navigations.yes') +
                '</span>'
              );
            } else {
              return (
                '<span class="badge badge-lg badge-danger">' +
                this.translate.instant('navigations.no') +
                '</span>'
              );
            }
          },
        },
        {
          headerName: this.translate.instant('headers.asset.parent'),
          field: 'parent',
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.type'
          ),
          field: 'type',
        },
        {
          headerName: this.translate.instant('headers.location.location'),
          field: 'location',
        },
        {
          headerName: this.translate.instant('headers.asset.parentId'),
          field: 'parentId',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.specifications'),
          field: 'specifications',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.drawing'),
          field: 'drawing',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom1,
          field: 'custom1',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom2,
          field: 'custom2',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom3,
          field: 'custom3',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom4,
          field: 'custom4',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom5,
          field: 'custom5',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom6,
          field: 'custom6',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmake'),
          field: 'eqmake',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmodel'),
          field: 'eqmodel',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqserial'),
          field: 'eqserial',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqsupplier'),
          field: 'eqsupplier',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_1'
          ),
          field: 'unit_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_2'
          ),
          field: 'planningGroup_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_3'
          ),
          field: 'costCenter_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_4'
          ),
          field: 'workCenter_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.base_risk_amount'
          ),
          field: 'base_risk_amount',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.base_criticality'
          ),
          field: 'base_crit_name',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.asset_criticality'
          ),
          field: 'criticality',
        },
      ],
    };
    this.strategyGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.asset.asset'),
      filter: 'agTextColumnFilter',
      valueGetter: (params: any) => {
        if (params.data !== undefined) {
          return params.data.name;
        }
      },
      cellRendererParams: {
        checkbox: (param: any) => !param?.data?.system,
        suppressDoubleClickExpand: true,
        innerRenderer: (params: any): any => {
          if (params.data) {
            const aTag = document.createElement('a');
            aTag.href = 'javascript:void(0)';
            aTag.onclick = () => {
              this.showSummaryModal(params.data.id);
            };
            aTag.innerHTML = params.data.name;
            return aTag;
          }
        },
      },
      sortable: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    await this.loadItems();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data
      .post('api/fastsp/strategy/assets', [])
      .toPromise();
    this.setFilteredItemsForFlatList();
    this.strategyGrid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  recalculateFields(): void {
    this.isRecalculatingFields = true;
    const selectedAssets = _.map(this.selectedNodes, (el) => el.id);
    this.data
      .post('api/fastsp/strategy/recalculate-fields', {
        assets: selectedAssets,
      })
      .subscribe(
        (res) => {
          this.strategyGrid?.uncheckAll();
          this.items = syncItems(res, this.items);
          this.toastr.showSuccess();
          this.isRecalculatingFields = false;
        },
        (err) => {
          this.toastr.showError(err.error.message);
          this.isRecalculatingFields = false;
        }
      );
  }

  private setFilteredItemsForFlatList(): void {
    if (!this.displayTree) {
      this.filteredItems = this.items.filter((item) => !item?.system);
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = _.filter(event, (el) => !el?.data?.system);
  }

  onRowClicked(event: any): void {
    if (event?.system) {
      return;
    }
    this.showEditModal([event]);
  }

  // Load SpareModels used in modal edit form
  async loadSpareModels(): Promise<void> {
    // SpareModels
    const orderByDesc = { orderBy: { name: 'asc' } };
    const tmp = await this.data
      .by('api/fastsp/models', {
        criteria: {
          site: this.auth.getSiteId(),
        },
        ...orderByDesc,
      })
      .toPromise();
    this.spareModelsReference = _.map(tmp, (el: any) => {
      return {
        name: el.name,
        value: el.id,
      };
    });
  }

  // Load Asset Factors used in modal edit form
  async loadAssetFactors(items?: any[]): Promise<void> {
    const selectedAssets = _.map(items, (el) => +el.id);
    if (selectedAssets.length !== 1) {
      return;
    }
    const tmp = await this.data
      .by('api/fastcrit/factors', {
        criteria: { asset: selectedAssets[0] },
      })
      .toPromise();
    this.assetFactorsReference = _.map(tmp, (el: any) => {
      return {
        name: el.confCrFactor.description,
        value: el.confCrFactor.id,
      };
    });
  }

  /**
   * @param items
   */
  async showEditModal(items?: any[]): Promise<void> {
    this.isLoading = true;
    this.refreshSparePartsGrid = false;
    await Promise.all([this.loadSpareModels(), this.loadAssetFactors(items)]);
    this.isLoading = false;
    this.bsModalRef = this.bsModalService.show(FastspStrategyEditComponent, {
      initialState: {
        items,
        spareModelsReference: this.spareModelsReference,
        assetFactorsReference: this.assetFactorsReference,
        assets: _.map(items, (el) => el.id),
      },
    });
    this.bsModalRef.content.confirmFn = (res: any) => {
      this.items = syncItems(res, this.items);
      this.refreshSparePartsGrid = true;
      this.setFilteredItemsForFlatList();
      this.strategyGrid?.uncheckAll();
      this.toastr.showSuccess();
    };
  }

  async showSummaryModal(assetId: number): Promise<void> {
    this.bsModalRef = this.bsModalService.show(AssetSummaryComponent, {
      initialState: {
        assetId,
      },
      class: 'modal-lg',
    });
  }

  async refreshGrid(): Promise<void> {
    await this.loadItems();
    setTimeout(() => {
      this.gridOptions?.api?.redrawRows();
    });
  }

  copyToClipboard(): void {
    copyToClipboard(this.selectedNodes);
  }

  /**
   */
  showMassEditModal(): void {
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      const items = _.map(this.selectedNodes, (el) => el.data);
      this.showEditModal(items);
    }
  }

  async reloadAssets(assetIds: []): Promise<void> {
    this.isLoading = true;
    const updatedAssets = await this.data
      .post('api/fastsp/strategy/assets', assetIds)
      .toPromise();
    this.isLoading = false;
    this.items = syncItems(updatedAssets, this.items);
  }

  /**
   */
  showClearConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.isLoading = true;
      this.data.post('api/fastsp/strategy/clear', ids).subscribe(
        (res) => {
          this.items = syncItems(res, this.items);
          this.strategyGrid?.uncheckAll();
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        },
        () => {
          this.isLoading = false;
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  getAllRowsField(field: string): [] {
    const rowData: any = [];
    this.gridOptions?.api?.forEachNode((node) =>
      rowData.push(node.data[field])
    );
    return rowData;
  }

  async showMatchAssetModal(): Promise<void> {
    this.bsModalRef = this.bsModalService.show(AssetMatchComponent, {
      initialState: {
        assetNames: this.getAllRowsField('name'),
      },
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = (res: any) => {
      // select assets in grid
      this.strategyGrid?.uncheckAll();
      this.gridOptions?.api?.forEachNode((node) => {
        if (res.includes(node.data.name.toLowerCase())) {
          node.setSelected(true);
        }
      });
    };
  }

  filterAndSelectDefaultAsset = (event: any): void => {
    const assetId = this.activatedRoute.snapshot.queryParamMap.get('asset_id');

    if (
      !assetId ||
      this.isDefaultAssetSelected ||
      event.api.getDisplayedRowCount() === 0
    ) {
      return;
    }
    let isAssetAvailable = false;

    event.api.forEachNode((node: any) => {
      if (node.data.id === +assetId) {
        isAssetAvailable = true;

        const hardcodedFilter = {
          'ag-Grid-AutoColumn': {
            type: 'set',
            filter: node.data.name,
          },
        };
        event.api.setFilterModel(hardcodedFilter);
        node.setSelected(true);

        if (!this.isDefaultAssetSelected) {
          this.isDefaultAssetSelected = true;
        }
      }
    });

    if (!isAssetAvailable) {
      this.toastr.showError(this.translate.instant('messages.asset.not_found'));
    }
  };

  myCellCallback = (params: any) => {
    return params.value;
  };

  get recalculateFieldsBtnTitle(): string {
    return this.isRecalculatingFields
      ? 'Recalculating...'
      : this.translate.instant('navigations.recalculate_fields');
  }
}
