<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('description')?.dirty"
            for="description"
          >
            {{ "headers.spare_parameters.spare_status.status" | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            [ngClass]="{
              'is-invalid': isSubmitted && form.get('description')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="description"
            id="description"
            type="text"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('description')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('costImpact')?.dirty"
            for="costImpact"
          >
            {{
              "headers.spare_parameters.spare_status.cost_impact" | translate
            }}
            <span class="text-danger">*</span>
          </label>
          <ngx-select
            [items]="boolReference"
            [placeholder]="'(Select a Value)'"
            formControlName="costImpact"
            id="costImpact"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
          <div
            class="text-danger"
            *ngIf="
              showValidationError && form.get('costImpact')?.errors?.required
            "
          >
            {{ "messages.required" | translate }}
          </div>
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('costImpact')?.errors?.min"
          >
            {{ "messages.positive" | translate }}
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
