import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DataService, AuthService } from 'src/app/shared/services';
import { ConfCustomFieldsModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ComponentModel } from '../../../../../shared/models';

@Component({
  selector: 'app-conf-asset-custom-field-edit',
  templateUrl: './edit.component.html'
})
export class ConfAssetCustomFieldEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  type: any;

  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: ('mass' | 'single' | 'new') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;
  showValidationError = false;
  label = '';

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);
    this.label = this.translate.instant(`headers.asset.custom${this.type}`);
    // get target Ids
    this.targetIds = _.map(this.items, el => el.id);

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.items = [new ConfCustomFieldsModel({
        account: this.auth.getAccountId(),
      })];
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [ComponentModel?]): ('mass' | 'single' | 'new') {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  /**
   *
   */
  getForm(item: ConfCustomFieldsModel): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      description: new UntypedFormControl(item.description, [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
      account: new UntypedFormControl(this.auth.getAccountId())
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => { };

  /**
   *
   */
  save(): boolean | void {
    this.showValidationError = true;
    if (!this.form || (this.form && !this.form.valid)) {
      return false;
    }

    // Query pointer
    let query;

    if (this.mode === 'new') {
      const tmpData = _.pickBy(this.form.value, el => el !== null);
      query = this.service.post('api/config/asset/custom-field' + this.type, tmpData);
    } else if (this.mode === 'single') {
      query = this.service.update('api/config/asset/custom-field' + this.type, this.targetIds[0], this.form.value);
    }

    this.isSubmitted = true;
    query?.subscribe(res => {
      this.isSubmitted = false;
      this.confirmFn(res);
      this.bsModalRef.hide();
    }, err => {
      console.log('err: ', err);
      this.isSubmitted = false;
    });
  }
}
