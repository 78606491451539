<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group col-12">
          <label [class.font-weight-bold]="form.get('description')?.dirty" for="description">
            {{ "headers.fastsp.spare-list.description" | translate }}
          </label>
          <input [ngClass]="{
              'is-invalid':
                showValidationError && form.get('description')?.invalid
            }" class="form-control form-control-sm" formControlName="description" id="description" type="text" />
          <div *ngIf="showValidationError && form.get('description')?.invalid" class="text-danger">
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label [class.font-weight-bold]="form.get('cost')?.dirty" for="cost">
            {{
            "headers.fastsp.spare-list.cost" | translate
            }}
          </label>
          <input (keypress)="validateNumberInput($event)" [ngClass]="{
              'is-invalid': showValidationError && form.get('cost')?.invalid
            }" class="form-control form-control-sm" formControlName="cost" id="cost" type="number" />
          <div *ngIf="showValidationError && form.get('cost')?.invalid" class="text-danger">
            {{ "messages.required" | translate }}
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button (click)="save()" [disabled]="isSubmitted" class="btn btn-sm btn-success">
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>