import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { AuthService, DataService, Broadcaster } from '../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '../../shared';
import { SELECTED_PLANNING_GROUPS } from '../../shared/events';

@Component({
  selector: 'app-fsl-locations',
  templateUrl: './fsl-locations.component.html',
})
export class RoutingFslLocationsComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() frequency?: number;
  @Input() state?: number;
  @Input() labour?: number;

  @Output() selectItems = new EventEmitter();
  @Output() uiGridToggle = new EventEmitter();

  @ViewChildren(GridComponent) grids?: GridComponent;
  grid?: any;

  // Available items
  items: any[] = [];
  allItems: any[] = [];

  // Items (assets & tags associated to a specific location)
  selectedNodes: any[] = [];

  // UI flag
  isLoading = false;

  // Local grid options
  gridOptions?: GridOptions;

  taskFilterOptions = [
    {
      name: this.translate.instant('headers.routing.task.task_filter_all'),
      value: 'all',
    },
    {
      name: this.translate.instant('headers.routing.task.task_filter_complete'),
      value: 'complete',
    },
    {
      name: this.translate.instant(
        'headers.routing.task.task_filter_incomplete'
      ),
      value: 'incomplete',
    },
  ];

  selectedFilterType = '';
  selectedPlanningGroupIds: any[] = [];

  constructor(
    private translate: TranslateService,
    private data: DataService,
    public auth: AuthService,
    public router: Router,
    private broadcaster: Broadcaster
  ) {}

  ngAfterViewInit(): void {
    // @ts-ignore
    this.grid = this.grids?._results[0];
  }

  /**
   */
  async ngOnInit(): Promise<void> {
    this.broadcaster.on(SELECTED_PLANNING_GROUPS).subscribe((ev: any) => {
      this.selectedPlanningGroupIds = ev;
      this.items = [];
    });

    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.location.location'),
          field: 'name',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          hide: false,
        },
        {
          headerName: this.translate.instant('headers.routing.fsl.tasks'),
          field: 'tasks',
          hide: false,
        },
        {
          headerName: this.translate.instant(
            'headers.routing.fsl.unplanned_tasks'
          ),
          field: 'unplanned_tasks',
          hide: false,
        },
        {
          headerName: this.translate.instant(
            'headers.routing.fsl.planned_tasks%'
          ),
          field: 'planned_tasks_percentage',
          hide: false,
          cellRenderer: (params: any) => {
            return params.data.planned_tasks_percentage + '%';
          },
        },
        {
          headerName: this.translate.instant('headers.routing.fsl.duration'),
          field: 'duration',
          hide: false,
        },
        {
          headerName: this.translate.instant(
            'headers.routing.fsl.unplanned_tasks_duration'
          ),
          field: 'unplanned_tasks_duration',
          hide: false,
        },
        {
          headerName: this.translate.instant('headers.location.id'),
          field: 'id',
          hide: true,
        },
      ],
    };
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.frequency && this.state && this.labour) {
      await this.loadItems();
    } else {
      this.items = [];
    }
  }

  onUiGridToggle(): void {
    this.uiGridToggle.emit();
  }

  /**
   * LOADERS
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.allItems = await this.data
      .post('api/fastpm/modelTasks/fsl-locations', {
        site: this.auth.getSiteId(),
        frequency: this.frequency,
        state: this.state,
        labour: this.labour,
        planningGroupIds: this.selectedPlanningGroupIds,
      })
      .toPromise();

    this.onChangeFilterType(this.selectedFilterType, true);

    this.grid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
    this.selectItems.emit(event);
  }

  myCellCallback = (params: any) => {
    return params.value;
  };

  onChangeFilterType(event: any, filterAlreadySelected: boolean = false): void {
    this.selectedFilterType = filterAlreadySelected
      ? event
      : event[0].data.value;

    let filteredData = this.allItems;

    if (this.selectedFilterType) {
      if (this.selectedFilterType === 'complete') {
        filteredData = filteredData.filter(
          (row, index) => row.tasks === row.planned_tasks
        );
      } else if (this.selectedFilterType === 'incomplete') {
        filteredData = filteredData.filter(
          (row, index) => row.tasks !== row.planned_tasks
        );
      }
    }

    this.items = filteredData;
  }
}
