import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GridOptions } from 'ag-grid-community';
import { AuthService, DataService } from '../../services';
import * as _ from 'lodash';

@Component({
  selector: 'app-model-tasks-preview',
  templateUrl: './model-tasks-preview.component.html',
})
export class ModelTasksPreviewComponent implements OnInit {
  modelId = 0;
  gridOptions?: GridOptions;
  tasksGridAutoGroupColumnDef?: any;
  toolingReference?: [];
  tasks: any[] = [];
  tasksIsLoading = false;
  criticalityReference?: [];

  constructor(
    private translate: TranslateService,
    private data: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef
  ) {}

  async ngOnInit(): Promise<void> {
    await this.tasksLoadItems();

    const tmpGridOptions: GridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.component.component'
          ),
          field: 'component',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.state'
          ),
          field: 'state',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.frequency'
          ),
          field: 'frequency',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.labor'
          ),
          field: 'trade',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.activity.activity'
          ),
          field: 'activity',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.tool.tool'
          ),
          field: 'tools',
          filterParams: {
            values: _(this.toolingReference)
              .map((el: any) => el.name)
              .unshift(null)
              .value(),
          },
          valueGetter: (params: any) => {
            if (params.data !== undefined) {
              return params.data.atools;
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.duration'
          ),
          field: 'duration',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.criteria'
          ),
          field: 'criteria',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.keyword'),
          field: 'keyword',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.proc'),
          field: 'procedure',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.comment'),
          field: 'comment',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.justification'
          ),
          field: 'justification',
        },
        {
          headerName: this.translate.instant('headers.routing.task.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.id'
          ),
          field: 'stateId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.id'
          ),
          field: 'frequencyId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.id'
          ),
          field: 'tradeId',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.id'),
          field: 'modelId',
          hide: true,
        },
      ],
    };
    this.tasksGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.fastpm.pm_model.description'),
      field: 'description',
      filter: 'agTextColumnFilter',
      cellRendererParams: {
        checkbox: false,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: true,
    };

    _.each(this.criticalityReference, (el: any) => {
      tmpGridOptions.columnDefs?.push({
        headerName: el.name,
        field: 'crit_' + el.id,
        cellRenderer: (params: any): string => {
          if (params.value === 1) {
            return '<div class="d-flex justify-content-center"><i class="la la-check-square la-2x text-success"></i></div>';
          } else {
            return '<div class="d-flex justify-content-center"><i class="la la-check-square la-2x text-light"></i></div>';
          }
        },
        width: 100,
      });
    });

    this.gridOptions = tmpGridOptions;
  }

  async tasksLoadItems(): Promise<void> {
    this.tasksIsLoading = true;
    const orderByThreshold = { orderBy: { threshold: 'asc' } };
    const orderByName = { orderBy: { name: 'asc' } };
    this.tasks = await this.data
      .get('api/fastpm/modelTasks/' + this.modelId)
      .toPromise();

    this.toolingReference = await this.data
      .by('api/config/tools', {
        criteria: {
          account: [this.auth.getAccountId(), null],
        },
        ...orderByName,
      })
      .toPromise();

    this.criticalityReference = await this.data
      .by('api/config/criticalities', {
        criteria: {
          account: [this.auth.getAccountId(), null],
        },
        ...orderByThreshold,
      })
      .toPromise();
    this.tasksIsLoading = false;
  }
}
