import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../../shared/modals';
import { LocationModel } from '../../shared/models';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import { environment } from '../../../environments/environment';

import * as L from 'leaflet';
import { Point } from 'leaflet';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';

import {
  AuthService,
  DataService,
  Broadcaster,
  ToasterService,
} from '../../shared/services';

import { LocationItemAddComponent } from './item.add/add.item.component';
import { AssetSummaryComponent } from 'src/app/shared/components/asset-summary/asset-summary.component';
import { DeletePositionAssetComponent } from './item.delete/delete-position.asset.component';
import { DeletePositionTagComponent } from './item.delete/delete-position.tag.component';
import { LOCATION_PIN_MODE } from 'src/app/shared/events';

class CustomMarker extends L.Marker {
  id = 0;
  type = 'asset';
}

@Component({
  selector: 'app-locations-map',
  templateUrl: './locations-map.component.html',
  styleUrls: ['./locations-map.component.scss'],
})
export class LocationsMapComponent implements OnChanges, OnInit {
  @Input() location?: any;
  @Input() items?: any = [];
  @Input() showRightGrid = true;
  @Input() showLeftGrid = true;
  @Input() selectedItems?: any = [];
  @Input() hoveredItemId?: any = null;
  @Input() isPinMode = false;

  @Output() itemsUpdated = new EventEmitter<any>();
  @Output() leftArrowClicked = new EventEmitter(false);
  @Output() rightArrowClicked = new EventEmitter(false);
  @Output() itemFocused = new EventEmitter();

  // Modal ref pointer
  bsModalRef?: BsModalRef;
  bsDeleteModalRef?: BsModalRef;
  bsConfirmModalRef?: BsModalRef;

  // Location image
  showImageUploader = false;
  map: any;
  imageLayer: any;
  imageUrl = '';
  imageSize: { width: number; height: number } = { width: 0, height: 0 };
  canAddFlag = true;
  inReorderMode = false;
  isSavingReorder = false;
  assetOrderNumber = 1;
  assetsOrder: any[] = [];

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService,
    private broadcaster: Broadcaster,
    private toastr: ToasterService
  ) {}

  ngOnInit(): void {
    this.broadcaster.on(LOCATION_PIN_MODE).subscribe((ev: any) => {
      this.isPinMode = ev.isPinMode;
      this.removeAllMarker();
      this.placeItemsOnMap();
    });
  }

  /**
   *
   */
  initMap(): void {
    this.map = L.map('map', {
      crs: L.CRS.Simple,
      zoom: 1,
      minZoom: -3,
      zoomDelta: 0.25,
      zoomSnap: 0,
    });
  }

  /**
   *
   */
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.location || changes.items) {
      this.resetReordering();
    }

    if (
      changes.location &&
      changes.location.currentValue &&
      (changes.location.previousValue === undefined ||
        changes.location.currentValue.image !==
          changes.location.previousValue.image)
    ) {
      this.removeImageLayer();
      if (changes.location.currentValue.image !== '') {
        this.showImageUploader = false;
        this.loadLocationImage(changes.location.currentValue);
      } else {
        this.showImageUploader = true;
      }
    }

    if (changes.items && changes.items.currentValue) {
      this.removeAllMarker();
      if (changes.items.currentValue.length > 0) {
        this.placeItemsOnMap();
      }
    }

    if (changes.selectedItems && changes.selectedItems.currentValue) {
      this.removeAllMarker();
      this.placeItemsOnMap();
    }

    if (changes.hoveredItemId && !this.inReorderMode) {
      this.removeAllMarker();
      this.placeItemsOnMap();
    }
  }

  /**
   * @param location
   */
  async loadLocationImage(location: LocationModel): Promise<void> {
    if (!this.map) {
      this.initMap();
    }

    // Get image info
    this.imageUrl = this.getImageUrl(location);
    this.imageSize = await this.getImageSize(location);

    // Define map properties
    const bounds = [
      [0, 0],
      [this.imageSize.height, this.imageSize.width],
    ];
    this.imageLayer = L.imageOverlay(
      this.imageUrl,
      // @ts-ignore
      bounds,
      { interactive: true }
    ).addTo(this.map);
    this.map.fitBounds(bounds);

    const mapContext = this;

    this.imageLayer.on('click', (el: any): void => {
      if (this.inReorderMode) {
        return;
      }
      // add little delay to avoid race with dot dragging / map moving
      setTimeout(() => {
        if (mapContext.canAddFlag && !mapContext.auth.isViewer('location')) {
          mapContext.addItem(el);
        }
      }, 500);
    });
  }

  /**
   *
   */
  saveLocationImages(evt: any): void {
    this.data
      .update('api/map/locations', this.location.id, {
        image: evt[0].name,
      })
      .subscribe((ret: any) => {
        this.location.image = evt[0].name;
        this.showImageUploader = false;
        this.removeImageLayer();
        this.loadLocationImage(this.location);
        this.broadcaster.broadcast('set-location-image', this.location);
      });
  }

  /**
   *
   */
  removeImageLayer(): void {
    if (this.map && this.map.hasLayer(this.imageLayer)) {
      this.map.removeLayer(this.imageLayer);
    }
  }

  /**
   *
   */
  removeAllMarker(): void {
    if (this.map) {
      this.map.eachLayer((el: any) => {
        if (el.type) {
          this.map.removeLayer(el);
        }
      });
    }
  }

  /**
   * @param item
   */
  getImageUrl(item: LocationModel): string {
    // FOR LOCAL TESTING:
    // return "https://fastly.picsum.photos/id/1045/536/354.jpg?hmac=tYGrnSarkqEFrV8Xhe__wg3gQainNqYXisMPSpA01q8";

    if (item.image !== '') {
      return (
        environment.url +
        'api/map/locations/' +
        item.id +
        '/img?token=' +
        this.auth.getToken() +
        '&r=' +
        Math.random()
      );
    } else {
      return '';
    }
  }

  startReorder(): void {
    this.inReorderMode = true;
    this.assetsOrder = [];
    this.assetOrderNumber = 1;
    this.disableDraggingAllMarkers();
  }

  onReorderCancel(): void {
    this.resetReordering();
    this.removeAllMarker();
    this.placeItemsOnMap();
  }

  resetReordering(): void {
    this.inReorderMode = false;
    this.assetsOrder = [];
    this.enableDraggingAllMarkers();
  }

  disableDraggingAllMarkers(): void {
    this.getAllMarkers().forEach((marker: L.Marker) => {
      marker.dragging?.disable();
    });
  }

  enableDraggingAllMarkers(): void {
    this.getAllMarkers().forEach((marker: L.Marker) => {
      marker.dragging?.enable();
    });
  }

  getAllMarkers(): L.Marker[] {
    const allMarkers: L.Marker[] = [];
    if (this.map) {
      this.map.eachLayer((layer: L.Layer) => {
        if (layer instanceof L.Marker) {
          allMarkers.push(layer);
        }
      });
    }

    return allMarkers;
  }

  onReorderSave(): void {
    // prepare list of assets that were not set in ordering
    const remainingAssets = this.items
      .filter((item: any) => {
        return (
          item.type === 'asset' &&
          !this.assetsOrder.some(
            (asset) => asset.id === this.getActualId(item.id, 'asset')
          )
        );
      })
      .map((item: any, index: number) => ({
        id: this.getActualId(item.id, 'asset'),
        location_order: index + this.assetOrderNumber,
      }));

    const assets = [...remainingAssets, ...this.assetsOrder];
    this.isSavingReorder = true;
    this.data.saveAll('api/assets', assets).subscribe(
      (res) => {
        this.itemsUpdated.emit(res);
        this.isSavingReorder = false;
        this.inReorderMode = false;
        this.toastr.showSuccess();
      },
      (err) => {
        this.isSavingReorder = false;
        this.toastr.showError();
      }
    );
  }

  /**
   *
   * @param item
   */
  async getImageSize(item: LocationModel): Promise<any> {
    let size = { width: 0, height: 0 };
    if (item.image !== '') {
      size = await this.data
        .get('api/map/locations/' + item.id + '/imginfo')
        .toPromise();
    }

    // FOR LOCAL TESTING:
    // size = { width: 1024, height: 567 };

    return size;
  }

  /**
   * @param el Leaflet element (Click generated)
   */
  addItem(el: any, replaceTagWithAsset = false): void {
    if (this.isPinMode) {
      this.onPinModeUpdateLocation(el);
      return;
    }

    const itemData = this.items.find((item: any) => item.id === el.id);
    this.bsModalRef = this.bsModalService.show(LocationItemAddComponent, {
      initialState: {
        location: this.location,
        tagItem: replaceTagWithAsset ? itemData : null,
      },
      class: 'modal-md',
    });

    this.bsModalRef.content.confirmFn = (type: string, items: any) => {
      const point = replaceTagWithAsset
        ? new Point(itemData.position_x, itemData.position_y)
        : this.map.project(el.latlng, 0);

      if (type === 'tag') {
        this.addTagMarker(items, point);
      } else if (type === 'asset') {
        const isAnyAlreadyPositioned = _.some(
          items,
          (item) => item.is_localized
        );
        if (isAnyAlreadyPositioned) {
          this.confirmAndAddAssetMarker(el, replaceTagWithAsset, items, point);
        } else {
          this.addAssetMarker(el, replaceTagWithAsset, items, point);
        }
      }
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  addTagMarker(items: any, point: any): void {
    const item = items[0];
    item.position_x = point.x;
    item.position_y = point.y;
    this.data.create('api/map/tags', item).subscribe((ret: any) => {
      this.itemsUpdated.emit();
      this.bsModalRef?.hide();
    });
  }

  addAssetMarker(
    el: any,
    replaceTagWithAsset: boolean,
    items: any,
    point: any
  ): void {
    if (replaceTagWithAsset) {
      this.deleteTag(el);
    }
    this.updateAssetPosition(items, point);
    this.bsDeleteModalRef?.hide();
  }

  confirmAndAddAssetMarker(
    el: any,
    replaceTagWithAsset: boolean,
    items: any,
    point: any
  ): void {
    this.bsConfirmModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'messages.asset.already_positioned',
        content: '',
      },
      class: 'msg-1',
    });
    this.bsConfirmModalRef.content.confirmFn = () => {
      this.addAssetMarker(el, replaceTagWithAsset, items, point);
      this.bsConfirmModalRef?.hide();
      this.bsModalRef?.hide();
    };

    this.bsConfirmModalRef.content.cancelFn = () => {
      this.bsConfirmModalRef?.hide();
    };
  }

  updateAssetPosition(items: any, point: any): void {
    const observableBatch: Observable<any>[] = [];
    _.each(items, (item: any) => {
      observableBatch.push(this.updateItemPosition('asset', item.id, point));
    });
    forkJoin(observableBatch).subscribe(() => {
      this.itemsUpdated.emit();
    });
    this.bsModalRef?.hide();
  }

  setAssetOrder(marker: any): void {
    const itemId = this.getActualId(marker.id, marker.type);

    const isOrderAlreadySet = this.assetsOrder.find(
      (asset) => asset.id === itemId
    );
    if (isOrderAlreadySet) {
      return;
    }

    marker.setIcon(
      L.divIcon({ className: 'leafletMarker asset-order-clicked' })
    );
    const item = {
      id: Number(itemId),
      location_order: this.assetOrderNumber,
    };
    this.assetsOrder.push(item);
    this.assetOrderNumber = this.assetOrderNumber + 1;
  }

  /**
   * @param marker
   */
  markerClicked(marker: any): void {
    if (this.isPinMode) {
      return;
    }

    if (this.inReorderMode) {
      if (marker.type === 'asset') {
        this.setAssetOrder(marker);
      }
      return;
    }

    if (marker.type === 'asset') {
      this.bsDeleteModalRef = this.bsModalService.show(
        DeletePositionAssetComponent,
        {
          initialState: {
            title: marker?.options?.title,
            content: '',
          },
          class: 'custom-modal',
        }
      );
    } else {
      this.bsDeleteModalRef = this.bsModalService.show(
        DeletePositionTagComponent,
        {
          initialState: {
            title: marker?.options?.title,
            content: '',
            headerLinkText: 'navigations.asset.replace_tag_with_asset',
          },
          class: 'custom-modal',
        }
      );
    }
    this.bsDeleteModalRef.content.confirmFn = async () => {
      if (marker.type === 'tag') {
        const itemId = this.getActualId(marker.id, marker.type);
        this.data.delete('api/map/tags', itemId).subscribe((ret: any) => {
          this.itemsUpdated.emit();
        });
      } else if (marker.type === 'asset') {
        this.updateItemPosition(
          marker.type,
          marker.id,
          new Point(0, 0)
        ).subscribe((ret: any) => {
          this.itemsUpdated.emit();
        });
      }
      this.bsDeleteModalRef?.hide();
    };
    this.bsDeleteModalRef.content.cancelFn = () => {
      this.bsDeleteModalRef?.hide();
    };
    this.bsDeleteModalRef.content.updateFn = () => {
      this.addItem(marker, true);
    };
    this.bsDeleteModalRef.content.linkFn = () => {
      const itemId = this.getActualId(marker.id, marker.type);

      this.bsDeleteModalRef?.hide();

      this.showSummaryModal(itemId);
    };
  }

  deleteTag(marker: any): void {
    const itemId = this.getActualId(marker.id, marker.type);
    this.data.delete('api/map/tags', itemId).subscribe();
  }

  /**
   * @param type       asset | tag
   * @param className
   * @param item       Item definition (Asset | Tag)
   * @param latLng     latlng coordinates
   */
  addMarkerLayer(
    type: string,
    className: string,
    item: any,
    latLng: any
  ): void {
    // @ts-ignore
    const marker = new CustomMarker(latLng, {
      icon: L.divIcon({ className: 'leafletMarker ' + className }),
      draggable: this.auth.isViewer('location') ? false : true,
      riseOnHover: true,
      title: item.name,
    });
    marker.id = item.id;
    marker.type = type;

    marker.on('dragstart', async (event: any) => {
      this.canAddFlag = false;
    });

    // tslint:disable-next-line:typedef
    marker.on('dragend', async (event: any) => {
      // Get Point (x/y position) for Zoom 0 (image related)
      const xyPosition = this.map.project(event.target.getLatLng(), 0);
      if (this.isPlottedInsideImageBounds(xyPosition)) {
        await this.updateItemPosition(type, item.id, xyPosition).toPromise();
      }
      this.itemsUpdated.emit();
      setTimeout(() => {
        this.canAddFlag = true;
      }, 100);
    });
    marker.on('click', (event: any) => {
      if (!this.auth.isViewer('location')) {
        this.markerClicked(event.sourceTarget);
      }
    });
    this.map.addLayer(marker);
  }

  /**
   * @param xyPosition  any
   * @returns boolean
   */
  isPlottedInsideImageBounds(xyPosition: any): boolean {
    const xposition = parseInt(xyPosition.x, 10);
    const yposition = parseInt(xyPosition.y, 10);
    if (
      xposition > 0 &&
      yposition < 0 &&
      Math.abs(xposition) < this.imageSize.width &&
      Math.abs(yposition) < this.imageSize.height
    ) {
      return true;
    }

    return false;
  }

  /**
   *
   * @param type
   * @param id
   * @param point
   */
  updateItemPosition(type: string, id: string, point: Point): Observable<any> {
    let apiPath = '';
    const itemId: number = this.getActualId(id, type);
    if (type === 'asset') {
      apiPath = 'api/assets';
    } else if (type === 'tag') {
      apiPath = 'api/map/tags';
    }
    return this.data.update(apiPath, itemId, {
      location: this.location.id,
      position_x: point.x,
      position_y: point.y,
    });
  }

  getActualId(id: string, type: string): number {
    return _.toInteger(_.replace(id, type + '_', ''));
  }

  /**
   * Add  existing items on map based on loaded items;
   */
  placeItemsOnMap(): void {
    if (this.items.length <= 0) {
      return;
    }

    const selectedItemsIds = _.map(this.selectedItems, (item: any) => item.id);

    _.each(this.items, (el: any) => {
      // if (el.position_x !== 0 || el.position_y !== 0) {
      if (el.is_localized === true) {
        const point = new Point(el.position_x, el.position_y);
        const latLng = this.map.unproject(point, 0);
        let className = el.type;

        className += this.getItemMarkerClassName(selectedItemsIds, el);

        this.addMarkerLayer(el.type, className, el, latLng);
      }
    });
  }

  getItemMarkerClassName(selectedItemsIds: any, el: any): string {
    if (this.hoveredItemId && !this.isPinMode) {
      if (this.hoveredItemId === el.id) {
        return ' selected';
      }
      return ' not-selected';
    }

    if (selectedItemsIds.length && !this.isPinMode) {
      if (selectedItemsIds.includes(el.id)) {
        return ' selected';
      } else {
        return ' not-selected';
      }
    }

    if (selectedItemsIds.length && this.isPinMode) {
      if (selectedItemsIds.includes(el.id)) {
        return ' selected';
      } else {
        return ' hidden';
      }
    }

    return '';
  }

  /**
   *
   */
  toggleShowImageUploader(flag?: boolean): void {
    // If flag is defined
    if (flag === true || flag === false) {
      this.showImageUploader = flag;
    } else {
      this.showImageUploader = !this.showImageUploader;
    }
  }

  /**
   * Open asset summary modal
   */
  async showSummaryModal(assetId: number): Promise<void> {
    this.bsModalRef = this.bsModalService.show(AssetSummaryComponent, {
      initialState: {
        assetId,
      },
      class: 'modal-lg',
    });
  }

  async onPinModeUpdateLocation(el: any): Promise<void> {
    const id = this.selectedItems[0].data.id;
    const type = this.selectedItems[0].data.type;

    const xyPosition = this.map.project(el.latlng, 0);
    if (this.isPlottedInsideImageBounds(xyPosition)) {
      await this.updateItemPosition(type, id, xyPosition).toPromise();
    }
    this.itemsUpdated.emit();
    this.isPinMode = false;
    this.broadcaster.broadcast(LOCATION_PIN_MODE, {
      isPinMode: false,
      isMap: true,
    });
    setTimeout(() => {
      this.canAddFlag = true;
    }, 100);
  }
}
