import { AfterViewInit, Component, OnInit, ViewChildren } from '@angular/core';
import { DataService, AuthService, ToasterService } from '../../../shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '../../../shared';

@Component({
  selector: 'app-plan',
  templateUrl: './set.plan.component.html',
})
export class RoutingSetPlanComponent implements OnInit, AfterViewInit {
  @ViewChildren(GridComponent) grids?: GridComponent;
  planTasksGrid?: any;

  // Parent's Data
  tasks: any = [];
  plans: [] = [];
  selectedPlan: any;

  frequency?: number;
  labour?: number;
  state?: number;

  // Local grid options
  gridOptions?: GridOptions;

  // UI Flag
  isLoading = false;
  isFlsPlansLoading = false;
  isSaving = false;

  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: 'new' | 'select' = 'select';

  constructor(
    protected service: DataService,
    public auth: AuthService,
    private toastr: ToasterService,
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    private translate: TranslateService
  ) {}

  /**
   *
   */
  ngAfterViewInit(): void {
    // @ts-ignore
    this.planTasksGrid = this.grids?._results[0];
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      rowSelection: 'single',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.routing.plan.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.plan'),
          field: 'name',
          checkboxSelection: true,
          hide: false,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.task_count'),
          field: 'tasks',
          hide: false,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.duration'),
          field: 'duration',
          hide: false,
        },
      ],
    };

    this.form = this.getForm();
    this.tabChange('select');
  }

  rowChangedCallBack(): void {
    this.planTasksGrid?.gridApi?.sizeColumnsToFit();
  }

  /**
   *
   * @param mode
   */
  tabChange(mode: 'new' | 'select'): void {
    this.mode = mode;
    if (this.mode === 'select') {
      this.loadFSLPlans();
    }
  }

  /**
   *
   */
  getForm(): UntypedFormGroup {
    return this.formBuilder.group({
      name: new UntypedFormControl(this.getPlanNameSuggestion(), [
        Validators.required,
      ]),
      cmmsReference: new UntypedFormControl(),
      comments: new UntypedFormControl(),
    });
  }

  /**
   * Get FSL Tasks for Current Site
   */
  async loadFSLPlans(): Promise<void> {
    this.isFlsPlansLoading = true;
    this.plans = await this.service
      .post('api/fastpm/modelTasks/fsl-plans', {
        site: this.auth.getSiteId(),
        frequency: this.frequency,
        state: this.state,
        labour: this.labour,
      })
      .toPromise();
    this.isFlsPlansLoading = false;
  }

  /**
   * @param data
   */
  confirmFn = (data?: any) => {};

  /**
   *
   * @param event
   */
  plansSelectionChanged(event: any): void {
    this.selectedPlan = event[0];
  }

  /**
   * Get a plan name suggestion based on FLS & Location
   */
  getPlanNameSuggestion(): string {
    // Get suggestion
    let nameSuggestion = [
      this.tasks[0].frequency,
      this.tasks[0].labour,
      this.tasks[0].state,
    ].join(' - ');

    // If more only one location in selection, add the location, else go for the suggestion
    const locations = _.groupBy(this.tasks, (el) => el.location);

    if (Object.values(locations).length === 1) {
      nameSuggestion = this.tasks[0].location + ' - ' + nameSuggestion;
    }

    return nameSuggestion;
  }

  /**
   */
  save(): void {
    if (this.mode === 'new') {
      this.createAndSave();
    } else {
      this.selectAndSave();
    }
  }

  /**
   *
   */
  async createAndSave(): Promise<void> {
    try {
      this.isSaving = true;
      const plan = await this.createPlan().toPromise();
      this.associateTasksWithPlan(this.tasks, plan);
      this.isSaving = false;
    } catch (err: any) {
      this.toastr.showError(err.error.message);
    }
  }

  /**
   *
   */
  async selectAndSave(): Promise<void> {
    this.isSaving = true;
    this.associateTasksWithPlan(this.tasks, this.selectedPlan);
    this.isSaving = false;
  }

  /**
   *
   */
  createPlan(): Observable<any> {
    const name = this.form?.get('name')?.value;
    const cmmsReference = this.form?.get('cmmsReference')?.value;
    const comments = this.form?.get('comments')?.value;

    return this.service.create('api/plans', {
      id: 0,
      name,
      account: this.auth.getAccountId(),
      site: this.auth.getSiteId(),
      frequency: this.frequency,
      trade: this.labour,
      state: this.state,
      status: 1,
      cmmsReference,
      comments,
    });
  }

  /**
   *
   * @param tasks
   * @param plan
   */
  associateTasksWithPlan(tasks: any, plan: any): void {
    // Build payload for each selected tasks
    const data: object[] = [];
    this.tasks.forEach((el: any) => {
      const payload = {
        id: el.tpt_id,
        task: el.id,
        asset: el.asset_id,
        model: el.model_id,
        plan: plan.id,
        location: el.location_id,
      };
      if (el.plan_task) {
        payload[`id`] = el.plan_task;
      }
      data.push(payload);
    });

    // save
    this.service.post('api/plans/tasks/save-all', data).subscribe(
      (res) => {
        this.confirmFn();
        this.bsModalRef.hide();
      },
      (err) => {
        this.toastr.showError(err.error);
      }
    );
  }
}
