<div class="d-flex flex-column h-100">
  <div class="d-flex align-items-center px-2">
    <div class="mr-2">
      <button
        (click)="loadItems()"
        [ngClass]="{ 'btn-success': isLoading, 'btn-light': !isLoading }"
        class="btn btn-sm"
      >
        <i [ngClass]="{ 'la-spin': isLoading }" class="la la-refresh"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        (click)="sparePartsGrid?.expandAll()"
        [disabled]="isLoading || auth.isViewer('fastsp')"
        class="btn btn-light btn-sm"
      >
        <i class="la la-expand-arrows-alt"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        (click)="sparePartsGrid?.collapseAll()"
        [disabled]="isLoading || auth.isViewer('fastsp')"
        class="btn btn-light btn-sm"
      >
        <i class="la la-compress-arrows-alt"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        (click)="sparePartsGrid?.autoSizeColumn()"
        [disabled]="isLoading || auth.isViewer('fastsp')"
        class="btn btn-light btn-sm"
      >
        <i class="la la-arrows-alt-h"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        alt="clear filter"
        (click)="clearFilterAndResetGrid()"
        [disabled]="!sparePartsGrid?.isFilterApplied"
        [ngClass]="{
          'clear-filter position-relative': sparePartsGrid?.isFilterApplied
        }"
        class="btn btn-light btn-sm"
      >
        <i class="las la-filter"></i>
      </button>
    </div>
    <fieldset>
      <div
        [isDisabled]="selectedNodes.length === 0"
        class="btn-group mr-1"
        dropdown
      >
        <button
          class="dropdown-toggle btn btn-sm btn-primary"
          dropdownToggle
          type="button"
        >
          <ng-container *ngIf="selectedNodes.length > 0"
            >{{ "navigations.with" | translate }}
            <strong>{{ selectedNodes.length }}</strong>
            {{ "navigations.selected" | translate }}
          </ng-container>
          <ng-container *ngIf="selectedNodes.length === 0">{{
            "navigations.no_selection" | translate
          }}</ng-container>
          <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu">
          <li
            (click)="auth.isViewer('fastsp') ? null : showMassEditModal()"
            class="btn btn-sm dropdown-item"
            *ngIf="!auth.isViewer('fastsp')"
          >
            <i class="la la-fw la-pencil-alt"></i>
            {{ "navigations.edit" | translate }}
          </li>
          <li
            (click)="sparePartsGrid?.export('xlsx')"
            class="btn btn-sm dropdown-item"
          >
            <i class="la la-fw la-file-excel"></i> XLSX
          </li>
          <li
            (click)="sparePartsGrid?.export('csv')"
            class="btn btn-sm dropdown-item"
          >
            <i class="la la-fw la-file-csv"></i> CSV
          </li>
          <li
            class="divider dropdown-divider"
            *ngIf="!auth.isViewer('fastsp')"
          ></li>
          <li *ngIf="!auth.isViewer('fastsp')" role="menuitem">
            <a
              class="dropdown-item btn btn-sm btn-danger"
              (click)="auth.isViewer('fastsp') ? null : showMassDeleteConfirm()"
            >
              <i class="la la-fw la-trash"></i>
              {{ "navigations.clear" | translate }}
            </a>
          </li>
        </ul>
      </div>
    </fieldset>
  </div>
  <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
    <app-grid
      (rowDoubleClicked)="auth.isViewer('fastsp') ? null : onRowClicked($event)"
      (selectionChanged)="onSelectionChanged($event)"
      [gridOptions]="gridOptions"
      [rowData]="items"
      class="ag-theme-alpine"
      style="width: 100%; height: 100%"
      [autoGroupColumnDef]="sparePartsGridAutoGroupColumnDef"
      [exportProcessCallBack]="exportProcessCallBack"
    >
    </app-grid>
  </div>
</div>
