<div class="container-fluid d-flex flex-column h-100 w-100">
  <div class="row d-flex justify-content-between mr-2 mb-3">
    <fieldset>
      <div class="d-flex align-items-center px-2">
        <div class="mr-2">
          <button
            (click)="loadItems()"
            [ngClass]="{ 'btn-success': isLoading, 'btn-light': !isLoading }"
            class="btn btn-sm"
          >
            <i [ngClass]="{ 'la-spin': isLoading }" class="la la-refresh"></i>
          </button>
        </div>
        <div class="mr-2">
          <button
            alt="clear filter"
            (click)="grid?.clearAllFilters()"
            [disabled]="!grid?.isFilterApplied"
            [ngClass]="{
              'clear-filter position-relative': grid?.isFilterApplied
            }"
            class="btn btn-light btn-sm"
          >
            <i class="las la-filter"></i>
          </button>
        </div>
        <div class="mr-2">
          <button
            (click)="generatePlanPdf()"
            [disabled]="
              isLoading ||
              selectedNodes.length === 0 ||
              auth.isViewer('routing')
            "
            class="btn btn-sm btn-primary"
          >
            <i class="la la-fw la-file-pdf"></i>
            {{ "navigations.plan.generate_document" | translate }}
          </button>
        </div>
        <div class="mr-2">
          <button
            (click)="generatePlanCsv()"
            [disabled]="
              isLoading ||
              selectedNodes.length === 0 ||
              auth.isViewer('routing')
            "
            class="btn btn-sm btn-primary"
          >
            <i class="la la-fw la-file-csv"></i>
            {{ "navigations.task.export" | translate }}
          </button>
        </div>
        <div
          [isDisabled]="selectedNodes.length === 0"
          class="btn-group mr-1"
          dropdown
        >
          <button
            class="dropdown-toggle btn btn-sm btn-primary"
            dropdownToggle
            type="button"
          >
            <ng-container *ngIf="selectedNodes.length > 0"
              >{{ "navigations.with" | translate }}
              <strong>{{ selectedNodes.length }}</strong>
              {{ "navigations.selected" | translate }}
            </ng-container>
            <ng-container *ngIf="selectedNodes.length === 0">{{
              "navigations.no_selection" | translate
            }}</ng-container>
            <span class="caret"></span>
          </button>
          <ul *dropdownMenu class="dropdown-menu">
            <li
              (click)="showMassEditModal()"
              class="btn btn-sm dropdown-item"
              *ngIf="!auth.isViewer('routing')"
            >
              <i class="la la-fw la-pencil-alt"></i>
              {{ "navigations.edit" | translate }}
            </li>
            <li (click)="grid?.export('xlsx')" class="btn btn-sm dropdown-item">
              <i class="la la-fw la-file-excel"></i> XLSX
            </li>
            <li (click)="grid?.export('csv')" class="btn btn-sm dropdown-item">
              <i class="la la-fw la-file-csv"></i> CSV
            </li>
            <li
              class="divider dropdown-divider"
              *ngIf="!auth.isViewer('routing')"
            ></li>
            <li role="menuitem" *ngIf="!auth.isViewer('routing')">
              <a
                class="dropdown-item btn btn-sm btn-danger"
                (click)="showMassDeleteConfirm()"
              >
                <i class="la la-fw la-trash"></i>
                {{ "navigations.delete" | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </fieldset>
    <div *ngIf="selectedPlan.length > 0">
      <app-grid-displayer
        [isExpanded]="showTasksGrid"
        (arrowClicked)="toggleTasksGrid()"
      >
      </app-grid-displayer>
    </div>
  </div>
  <div class="row h-100">
    <div class="flex-column h-100" [class]="showTasksGrid ? 'col-7' : 'col-12'">
      <app-grid
        [gridUid]="'routing_plans'"
        (rowDoubleClicked)="
          auth.isViewer('routing') ? null : onRowClicked($event)
        "
        (selectionChanged)="onSelectionChanged($event)"
        [gridOptions]="gridOptions"
        [rowData]="items"
        class="ag-theme-alpine"
      >
      </app-grid>
    </div>
    <div class="flex-column h-100" [class]="showTasksGrid ? 'col-5' : 'd-none'">
      <app-plans-tasks
        *ngIf="selectedPlan.length !== 0"
        [selectedPlan]="selectedPlan"
      ></app-plans-tasks>
      <div *ngIf="selectedPlan.length === 0" class="row h-100">
        <div
          class="col-12 h-100 d-flex align-items-center justify-content-center"
        >
          Please select a Plan
        </div>
      </div>
    </div>
  </div>
</div>
