import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, DataService } from '../../../shared';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GridComponent } from '../../../shared';
import { LocationModel } from '../../../shared/models';
import * as _ from 'lodash';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-add-item',
  templateUrl: './add.item.component.html',
})
export class LocationItemAddComponent implements OnInit {
  @ViewChild(GridComponent, { static: true }) assetGrid?: GridComponent;

  assetControl = new UntypedFormControl(null);
  // Modal param
  location?: LocationModel;
  tagItem?: any = [];

  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  searchedValue = '';

  // UI flag
  isLoading = false;
  isSubmitted = false;

  type: 'asset' | 'tag' = 'asset';

  debouncedSearch: ((term: string) => void) | undefined;

  constructor(
    private data: DataService,
    public auth: AuthService,
    public router: Router,
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    // Adjusting for a searchAssets function that requires a string argument
    this.debouncedSearch = _.debounce(
      (term: string) => this.searchAssets(term),
      500
    );
  }

  async searchAssets(event: any): Promise<void> {
    this.isLoading = true;

    this.items = await this.data
      .post('api/assets/find/by/full-name', {
        fulltext: event,
      })
      .toPromise();

    this.isLoading = false;

    // Push a ghost value to allow it to be created as a Tag
    this.items.unshift({ id: 0, fullName: this.searchedValue });
    this.assetControl.setValue(0);
  }

  createTag(): void {
    const data = {
      location: this.location?.id,
      position_x: null,
      position_y: null,
      title: this.searchedValue,
    };

    this.confirmFn('tag', [data]);
  }

  confirmFn = (type: string, item: any) => {};

  /**
   *
   * @param event
   */
  async onValueSearch(event: any): Promise<void> {
    this.assetControl.reset();

    this.searchedValue = event;

    if (event.length === 0) {
      this.items = [];
    }

    if (event.length > 3) {
      // await this.searchAssets(event);

      if (this.debouncedSearch) {
        this.debouncedSearch(event);
      }
    }
  }

  save(): void {
    this.isSubmitted = true;

    const selectedAsset = _.filter(
      this.items,
      (item) => item.id === this.assetControl.value
    );

    if (selectedAsset) {
      this.confirmFn('asset', selectedAsset);
    }
    this.isSubmitted = false;
  }
}
