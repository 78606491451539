import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
  SharedDataService,
} from '../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssetModel } from '../../shared/models';
import { ConfirmModalComponent } from '../../shared/modals';
import { GridComponent } from '../../shared/';
import * as _ from 'lodash';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import { AssetEditComponent } from './edit/edit.component';
import { AssetImportComponent } from './import/import.component';
import { AssetMatchComponent } from './match/match.component';
import { AssetSummaryComponent } from '../../shared/components/asset-summary/asset-summary.component';
import { ActivatedRoute } from '@angular/router';
import { copyToClipboard } from 'src/utils';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
})
export class AssetListComponent implements OnInit {
  @ViewChild(GridComponent, { static: true }) assetGrid?: GridComponent;

  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  isDefaultAssetSelected = false;
  // UI flag
  isLoading = false;
  models: any[] = [];
  // Local grid options
  gridOptions?: GridOptions;
  displayTree = true;
  //
  assetsGridAutoGroupColumnDef: any;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  // Form reference Data
  boolReference?: { value: boolean; name: string }[];
  criticalityReference: [] = [];
  locationReference: [] = [];
  accessReference: [] = [];
  eqtypeReference: [] = [];
  customField1: [] = [];
  customField2: [] = [];
  customField3: [] = [];
  customField4: [] = [];
  customField5: [] = [];
  customField6: [] = [];
  respGroups: [] = [];

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private sharedData: SharedDataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService,
    private activatedRoute: ActivatedRoute
  ) {}

  setDisplayTree(flag: boolean): void {
    if (flag !== this.displayTree) {
      this.displayTree = flag;
      this.assetGrid?.gridApi?.refreshClientSideRowModel();
    }
  }

  /**
   */
  async ngOnInit(): Promise<void> {
    const customHeaders = await this.sharedData.getAssetCustomHeaders(
      this.auth.getAccountId()
    );
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.asset'),
          link: '/assets',
        },
      ],
      2
    );
    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      treeData: true,
      groupDefaultExpanded: -1, // expand all groups by default
      getDataPath: (data) => {
        if (this.displayTree) {
          return data.hierarchy;
        } else {
          return data.hierarchy.slice(-1);
        }
      },
      excludeChildrenWhenTreeDataFiltering: true,
      onRowDataChanged: (event) => this.filterAndSelectDefaultAsset(event),
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.asset.description'),
          field: 'description',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant('headers.asset.eqtype'),
          field: 'type',
        },
        {
          headerName: this.translate.instant('headers.asset.active'),
          field: 'active',
          cellRenderer: (params: any): string => {
            if (params.value === true) {
              return (
                '<span class="badge badge-lg badge-success">' +
                this.translate.instant('navigations.yes') +
                '</span>'
              );
            } else {
              return (
                '<span class="badge badge-lg badge-danger">' +
                this.translate.instant('navigations.no') +
                '</span>'
              );
            }
          },
        },
        {
          headerName: this.translate.instant('headers.asset.ranking'),
          field: 'ranking',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.system'),
          field: 'system',
          cellRenderer: (params: any): string => {
            if (params.value === true) {
              return (
                '<span class="badge badge-lg badge-success">' +
                this.translate.instant('navigations.yes') +
                '</span>'
              );
            } else {
              return (
                '<span class="badge badge-lg badge-danger">' +
                this.translate.instant('navigations.no') +
                '</span>'
              );
            }
          },
        },
        {
          headerName: this.translate.instant('headers.asset.parent'),
          field: 'parent',
        },
        {
          headerName: this.translate.instant('headers.location.location'),
          field: 'location',
        },
        {
          headerName: this.translate.instant('headers.asset.parentId'),
          field: 'parentId',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.model'),
          field: 'model_ids',
          valueGetter: (params: any): any => {
            if (params.data) {
              return this.filterAssetsModelList(params.data.model_ids);
            }
          },
          filterValueGetter: (params: any): any => {
            if (params.data) {
              return this.filterAssetsModelList(params.data.model_ids);
            }
          },
          cellRenderer: (params: any) => {
            return this.filterAssetsModelList(params.data.model_ids).join(', ');
          },
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.specifications'),
          field: 'specifications',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'modules.admin.asset_parameters.children.access_requirements.name'
          ),
          field: 'accesses',
          valueGetter: (params: any): any[] => {
            if (params.data !== undefined && params.data.accesses.length > 0) {
              return _.map(params.data.accesses, (el) => el.description);
            }
            // for (Blanks) option
            return [''];
          },
        },
        {
          headerName: this.translate.instant('headers.asset.drawing'),
          field: 'drawing',
          hide: true,
        },
        {
          headerName: customHeaders.custom1,
          field: 'custom1',
          hide: true,
        },
        {
          headerName: customHeaders.custom2,
          field: 'custom2',
          hide: true,
        },
        {
          headerName: customHeaders.custom3,
          field: 'custom3',
          hide: true,
        },
        {
          headerName: customHeaders.custom4,
          field: 'custom4',
          hide: true,
        },
        {
          headerName: customHeaders.custom5,
          field: 'custom5',
          hide: true,
        },
        {
          headerName: customHeaders.custom6,
          field: 'custom6',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmake'),
          field: 'eqmake',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmodel'),
          field: 'eqmodel',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqserial'),
          field: 'eqserial',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqsupplier'),
          field: 'eqsupplier',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_1'
          ),
          field: 'unit_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_2'
          ),
          field: 'planningGroup_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_3'
          ),
          field: 'costCenter_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_4'
          ),
          field: 'workCenter_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.base_risk_amount'
          ),
          field: 'base_risk_amount',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.base_criticality'
          ),
          field: 'base_crit_name',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.actual_risk_amount'
          ),
          field: 'actual_risk_amount',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.actual_criticality'
          ),
          field: 'actual_crit_name',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.asset_criticality'
          ),
          field: 'criticality',
          hide: true,
        },
      ],
    };
    this.assetsGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.asset.asset'),
      filter: 'agTextColumnFilter',
      valueGetter: (params: any) => {
        if (params.data !== undefined) {
          return params.data.name;
        }
      },
      cellRendererParams: {
        checkbox: true,
        suppressDoubleClickExpand: true,
        innerRenderer: (params: any): any => {
          const aTag = document.createElement('a');
          aTag.href = 'javascript:void(0)';
          aTag.onclick = () => {
            this.showSummaryModal(params.data.id);
          };
          aTag.innerHTML = params.data.name;
          return aTag;
        },
      },
      sortable: true,
      width: 700,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    await Promise.all([this.loadItems(), this.loadModels()]);
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data
      .post('api/assets/from_site_all', {})
      .toPromise();
    this.assetGrid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  filterAssetsModelList(modelIds: string): any[] {
    if (modelIds) {
      const modelIdsArr = modelIds.split(',').map(Number);
      const models = this.models.filter((item: any) =>
        modelIdsArr.includes(item.id)
      );
      return models.map((x) => x.name);
    }
    return [];
  }

  async loadModels(): Promise<void> {
    this.models = await this.data
      .post('api/fastpm/models/by', {
        criteria: {
          site: [this.auth.getSiteId(), null],
        },
      })
      .toPromise();
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onRowClicked(event: any): void {
    this.showEditModal([new AssetModel(event)]);
  }

  prepareItem(item: any): any {
    return {
      ...item,
      siteId: this.auth.getSiteId(),
      criticality: item?.criticality?.name,
      criticalityId: item?.criticality?.id,
      location: item?.location?.name,
      locationId: item?.location?.id,
      parent: item?.parent?.name,
      parentId: item?.parent?.id,
      unit_title: item?.unitDesc,
      unit_id: item?.unit,
      unit: item?.unit,
      planningGroup_title: item?.planningGroupDesc,
      planning_group_id: item?.planningGroup,
      planningGroup: item?.planningGroup,
      costCenter_title: item?.costCenterDesc,
      cost_center_id: item?.costCenter,
      costCenter: item?.costCenter,
      workCenter_title: item?.workCenterDesc,
      work_center_id: item?.workCenter,
      workCenter: item?.workCenter,
    };
  }

  getHierarchy(item: any): any[] {
    let hierarchy: any[] = [];
    const parent = this.assetGrid?.gridApi?.getRowNode(item?.parent?.id);
    if (parent) {
      hierarchy = [...parent.data.hierarchy];
    }
    hierarchy.push(item.id);
    return hierarchy;
  }

  /**
   * @param items
   */
  async showEditModal(items?: AssetModel[]): Promise<void> {
    await this.loadReferences();

    let autoSelectParentAsset = null;
    // check if only single parent OR only single child node is selected, use that.. otherwise no auto selection
    if (
      this.selectedNodes.filter((node) => node.__hasChildren).length === 1 ||
      this.selectedNodes.length === 1
    ) {
      autoSelectParentAsset = this.selectedNodes[0];
    }

    this.bsModalRef = this.bsModalService.show(AssetEditComponent, {
      initialState: {
        items,
        selectedAsset: autoSelectParentAsset,
        respGroups: this.respGroups,
        boolReference: this.boolReference,
        criticalityReference: this.criticalityReference,
        locationReference: this.locationReference,
        accessReference: this.accessReference,
        eqtypeReference: this.eqtypeReference,
        customField1: this.customField1,
        customField2: this.customField2,
        customField3: this.customField3,
        customField4: this.customField4,
        customField5: this.customField5,
        customField6: this.customField6,
      },
      class: 'modal-lg',
    });
    this.bsModalRef.content.confirmFn = (res: any, mode: string) => {
      if (mode === 'single') {
        this.items = this.items.map((item: any) => {
          if (item.id === res.id) {
            return {
              ...item,
              ...this.prepareItem(res),
              hierarchy: [...this.getHierarchy(res)],
            };
          }

          return item;
        });
      } else if (mode === 'mass') {
        this.items = this.items.map((item: any) => {
          const newItem = res.find((resItem: any) => resItem.id === item.id);

          if (newItem) {
            return {
              ...item,
              ...this.prepareItem(newItem),
              hierarchy: [...this.getHierarchy(newItem)],
            };
          }
          return item;
        });
      } else if (mode === 'new') {
        this.items = [
          ...this.items,
          { ...this.prepareItem(res), hierarchy: [...this.getHierarchy(res)] },
        ];
      } else {
        this.loadItems();
      }

      this.gridOptions?.api?.redrawRows();
      this.assetGrid?.uncheckAll();
      this.toastr.showSuccess();
    };
  }

  /**
   * @param items
   */
  async showImportModal(): Promise<void> {
    this.bsModalRef = this.bsModalService.show(AssetImportComponent, {
      initialState: {},
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      this.toastr.showSuccess();
    };
  }

  getAllRowsField(field: string): [] {
    const rowData: any = [];
    this.gridOptions?.api?.forEachNode((node) =>
      rowData.push(node.data[field])
    );
    return rowData;
  }

  async showMatchAssetModal(): Promise<void> {
    this.bsModalRef = this.bsModalService.show(AssetMatchComponent, {
      initialState: {
        assetNames: this.getAllRowsField('name'),
      },
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = (res: any) => {
      // select assets in grid
      this.assetGrid?.uncheckAll();
      this.gridOptions?.api?.forEachNode((node) => {
        if (res.includes(node.data.name.toLowerCase())) {
          node.setSelected(true);
        }
      });
    };
  }

  async showSummaryModal(assetId: number): Promise<void> {
    await this.loadReferences();

    this.bsModalRef = this.bsModalService.show(AssetSummaryComponent, {
      initialState: {
        assetId,
      },
      class: 'modal-lg',
    });
  }

  /**
   */
  showMassEditModal(): void {
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      const items = _.map(this.selectedNodes, (el) => new AssetModel(el.data));
      this.showEditModal(items);
    }
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.data.post('api/assets/delete-all', ids).subscribe(
        (res) => {
          this.items = this.items.filter(
            (item: any) => !ids.includes(`${item.id}`)
          );
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  /**
   */
  assetClone(): void {
    const ids = _.map(this.selectedNodes, (el) => el.id);
    this.data.post('api/assets/duplicate-all', ids).subscribe(
      (res) => {
        const newItems = res.map((item: any) => {
          return {
            ...this.prepareItem(item),
            hierarchy: [...this.getHierarchy(item)],
          };
        });

        this.items = [...this.items, ...newItems];

        this.assetGrid?.uncheckAll();

        this.toastr.showSuccess();
      },
      (err) => {
        this.toastr.showError();
      }
    );
  }

  // Load Rerefences used in modal edit form
  async loadReferences(): Promise<void> {
    // Bool
    this.boolReference = [
      { value: true, name: this.translate.instant('navigations.yes') },
      { value: false, name: this.translate.instant('navigations.no') },
    ];

    this.isLoading = true;

    await Promise.all([
      this.loadCriticalityRef(),
      this.loadLocationRef(),
      this.loadAccessRef(),
      this.loadEqtypeRef(),
      this.loadCustomField1Ref(),
      this.loadCustomField2Ref(),
      this.loadCustomField3Ref(),
      this.loadCustomField4Ref(),
      this.loadCustomField5Ref(),
      this.loadCustomField6Ref(),
      this.loadRespGroupsRef(),
    ]);

    this.isLoading = false;
  }

  async loadCriticalityRef(): Promise<void> {
    const orderByThreshold = { orderBy: { threshold: 'asc' } };
    // Criticality
    this.criticalityReference = await this.data
      .by('api/config/criticalities', {
        criteria: {
          account: [this.auth.getAccountId()],
        },
        ...orderByThreshold,
      })
      .toPromise();
  }

  async loadAccessRef(): Promise<void> {
    const orderByDesc = { orderBy: { description: 'asc' } };
    // Criticality
    this.accessReference = await this.data
      .by('api/config/asset/access-requirements', {
        criteria: {
          account: [this.auth.getAccountId()],
        },
        ...orderByDesc,
      })
      .toPromise();
  }

  async loadLocationRef(): Promise<void> {
    const orderByName = { orderBy: { name: 'asc' } };
    // Location
    this.locationReference = await this.data
      .by('api/map/locations', {
        criteria: {
          site: [this.auth.getSiteId()],
        },
        ...orderByName,
      })
      .toPromise();
  }

  async loadEqtypeRef(): Promise<void> {
    const orderByDesc = { orderBy: { description: 'asc' } };

    // Equipment Types
    this.eqtypeReference = await this.data
      .by('api/config/equipment-type', {
        criteria: {
          account: [this.auth.getAccountId()],
        },
        ...orderByDesc,
      })
      .toPromise();
  }

  async loadCustomField1Ref(): Promise<void> {
    const orderByDesc = { orderBy: { description: 'asc' } };
    // Custom Fields
    this.customField1 = await this.data
      .by('api/config/asset/custom-field1', {
        criteria: {
          account: [this.auth.getAccountId()],
        },
        ...orderByDesc,
      })
      .toPromise();
  }

  async loadCustomField2Ref(): Promise<void> {
    const orderByDesc = { orderBy: { description: 'asc' } };
    // Custom Fields
    this.customField2 = await this.data
      .by('api/config/asset/custom-field2', {
        criteria: {
          account: [this.auth.getAccountId()],
        },
        ...orderByDesc,
      })
      .toPromise();
  }

  async loadCustomField3Ref(): Promise<void> {
    const orderByDesc = { orderBy: { description: 'asc' } };
    // Custom Fields
    this.customField3 = await this.data
      .by('api/config/asset/custom-field3', {
        criteria: {
          account: [this.auth.getAccountId()],
        },
        ...orderByDesc,
      })
      .toPromise();
  }

  async loadCustomField4Ref(): Promise<void> {
    const orderByDesc = { orderBy: { description: 'asc' } };
    // Custom Fields
    this.customField4 = await this.data
      .by('api/config/asset/custom-field4', {
        criteria: {
          account: [this.auth.getAccountId()],
        },
        ...orderByDesc,
      })
      .toPromise();
  }

  async loadCustomField5Ref(): Promise<void> {
    const orderByDesc = { orderBy: { description: 'asc' } };
    // Custom Fields
    this.customField5 = await this.data
      .by('api/config/asset/custom-field5', {
        criteria: {
          account: [this.auth.getAccountId()],
        },
        ...orderByDesc,
      })
      .toPromise();
  }

  copyToClipboard(): void {
    copyToClipboard(this.selectedNodes);
  }

  async loadCustomField6Ref(): Promise<void> {
    const orderByDesc = { orderBy: { description: 'asc' } };
    // Custom Fields
    this.customField6 = await this.data
      .by('api/config/asset/custom-field6', {
        criteria: {
          account: [this.auth.getAccountId()],
        },
        ...orderByDesc,
      })
      .toPromise();
  }

  async loadRespGroupsRef(): Promise<void> {
    const orderByDesc = { orderBy: { description: 'asc' } };
    this.respGroups = await this.data
      .by('api/config/responsible_groups', {
        criteria: {
          site: [this.auth.getSiteId()],
        },
        ...orderByDesc,
      })
      .toPromise();
  }

  myCellCallback = (params: any) => {
    return params.value;
  };

  filterAndSelectDefaultAsset = (event: any): void => {
    const assetId = this.activatedRoute.snapshot.queryParamMap.get('asset_id');

    if (
      !assetId ||
      this.isDefaultAssetSelected ||
      event.api.getDisplayedRowCount() === 0
    ) {
      return;
    }
    let isAssetAvailable = false;

    event.api.forEachNode((node: any) => {
      if (node.data.id === +assetId) {
        isAssetAvailable = true;

        const hardcodedFilter = {
          'ag-Grid-AutoColumn': {
            type: 'set',
            filter: node.data.name,
          },
        };
        event.api.setFilterModel(hardcodedFilter);
        node.setSelected(true);

        if (!this.isDefaultAssetSelected) {
          this.isDefaultAssetSelected = true;
        }
      }
    });

    if (!isAssetAvailable) {
      this.toastr.showError(this.translate.instant('messages.asset.not_found'));
    }
  };
}
