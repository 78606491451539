<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <tabset>
      <tab
        (selectTab)="tabChange('new')"
        heading="{{ 'navigations.model.create' | translate }}"
      >
        <form [formGroup]="form">
          <div class="form-row">
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('description')?.dirty"
                for="description"
              >
                {{ "headers.fastcr.risk_model.model" | translate }}
                <span class="text-danger">*</span>
              </label>
              <input
                [ngClass]="{
                  'is-invalid':
                    showValidationError && form.get('description')?.invalid
                }"
                class="form-control form-control-sm"
                formControlName="description"
                id="description"
                type="text"
              />
              <div
                class="text-danger"
                *ngIf="showValidationError && form.get('description')?.invalid"
              >
                {{ "messages.required" | translate }}
              </div>
            </div>
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('comment')?.dirty"
                for="comment"
              >
                {{ "headers.fastcr.risk_model.comment" | translate }}
              </label>
              <app-field-clear-button
                [formGroupRef]="form"
                [fieldName]="'comment'"
              ></app-field-clear-button>
              <input
                [ngClass]="{
                  'is-invalid':
                    showValidationError && form.get('comment')?.invalid
                }"
                class="form-control form-control-sm"
                formControlName="comment"
                id="comment"
                type="text"
              />
            </div>
          </div>
        </form>
      </tab>
      <tab
        (selectTab)="tabChange('model')"
        heading="{{ 'navigations.model.from' | translate }}"
        style="height: 450px"
      >
        <div class="d-flex flex-column h-100 w-100">
          <div class="d-flex align-items-center my-2">
            <div class="mr-2">
              <button
                (click)="riskModelLoadItems(this.currentSite)"
                [ngClass]="{
                  'btn-success': riskModelIsLoading,
                  'btn-light': !riskModelIsLoading
                }"
                class="btn btn-sm"
              >
                <i
                  [ngClass]="{ 'la-spin': riskModelIsLoading }"
                  class="la la-refresh"
                ></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                (click)="riskModelGrid?.expandAll()"
                [disabled]="riskModelIsLoading"
                class="btn btn-light btn-sm"
              >
                <i class="la la-expand-arrows-alt"></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                (click)="riskModelGrid?.collapseAll()"
                [disabled]="riskModelIsLoading"
                class="btn btn-light btn-sm"
              >
                <i class="la la-compress-arrows-alt"></i>
              </button>
            </div>
            <div class="mr-2">
              <div class="btn-group mr-1" dropdown>
                <button
                  class="dropdown-toggle btn btn-sm btn-primary"
                  dropdownToggle
                  type="button"
                >
                  {{ currentSite.name }}
                </button>
                <ul *dropdownMenu class="dropdown-menu">
                  <li
                    (click)="setSite(site)"
                    *ngFor="let site of sitesReferences"
                    class="btn btn-sm dropdown-item"
                  >
                    {{ site.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="d-flex h-100 w-100 flex-grow-1 ag-grid-container"
            *ngIf="mode === 'model'"
          >
            <app-grid
              [gridUid]="'risk_models_clone_from_models'"
              (selectionChanged)="riskModelOnSelectionChanged($event)"
              [gridOptions]="riskModelGridOptions"
              [rowData]="riskModel"
              class="ag-theme-alpine"
              style="width: 100%; height: 100%"
            >
            </app-grid>
          </div>
        </div>
      </tab>
    </tabset>
  </div>
  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="
            (!form.pristine &&
              !form.dirty &&
              isSubmitted &&
              mode !== 'model') ||
            (!form.valid && mode !== 'model') ||
            (riskModelSelectedNodes.length === 0 && mode === 'model')
          "
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
