import {
  Component,
  OnInit,
  ViewChild,
  Input,
  SimpleChanges,
  Output,
  OnChanges,
  EventEmitter,
} from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssetModelModel } from '../../../../shared/models';
import { ConfirmModalComponent } from '../../../../shared/modals';
import { GridComponent } from '../../../../shared';
import { AssetModelEditComponent } from './edit/edit.component';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../../../shared/services/breadcrumb.service';
import { syncItems } from 'src/utils';
import { AssetConditionValueListComponent } from '../asset-condition-value/asset-condition-value.component';
import { AssetActionListComponent } from '../asset-action/asset-action.component';
import { GradesEditComponent } from './grades-edit/grades-edit.component';
import * as moment from 'moment';

@Component({
  selector: 'app-asset-model',
  templateUrl: './asset-model.component.html',
  styleUrls: ['./asset-model.component.scss'],
})
export class AssetModelListComponent implements OnInit, OnChanges {
  @ViewChild(GridComponent, { static: true }) assetModelGrid?: GridComponent;
  @ViewChild(AssetActionListComponent)
  assetActionListComponent?: AssetActionListComponent;
  @ViewChild(AssetConditionValueListComponent)
  assetConditionValueListComponent?: AssetConditionValueListComponent;

  @Input() selectedLifecycleStrategyNodes: any[] = [];
  @Input() sizeColumnsToFit = true;
  @Output() resetModelsGrid = new EventEmitter<any>();
  @Output() changeSelectLifecycleStrategyNodes = new EventEmitter<any>();

  // Available items
  items: any[] = [];
  conditionValues: any[] = [];
  gradesReference: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  isReferenceLoaded = false;
  assetModelGridAutoGroupColumnDef?: any;
  modelData: any = {};
  childGrid = 'Action';

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    const tmpGridOptions: GridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1, // expand all groups by default
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.asset'
          ),
          field: 'asset_name',
          valueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_id;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_name;
            }
          },
          cellRenderer: (params: any) => {
            return this.modelData?.[params.value];
          },
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.lifecycle_model.business_rules'
          ),
          field: 'business_rules',
          width: 70,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.asset_install_date'
          ),
          field: 'assetInstallDate',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.asset_erv'
          ),
          field: 'assetERV',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.grade'
          ),
          field: 'grade',
          valueGetter: (params: any) => {
            if (params?.data?.grade) {
              const grades = this.gradesReference.filter((element: any) => {
                const index = params?.data?.grade.length - 1;

                return (
                  element.value === Number(params?.data?.grade[index].grade_id)
                );
              });
              return grades[0]?.name;
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.grade_date'
          ),
          field: 'grade',
          valueGetter: (params: any) => {
            if (params?.data?.grade) {
              const index = params?.data?.grade.length - 1;
              return params?.data?.grade[index]?.date;
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.risk_scenario'
          ),
          field: 'riskScenario',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.cat_exposure'
          ),
          field: 'catExposure',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.cat_confidence'
          ),
          field: 'catConfidence',
          cellRenderer: (params: any): string => {
            if (params.value === 1) {
              return 'Unsure';
            } else if (params.value === 2) {
              return 'Probable';
            } else if (params.value === 3) {
              return 'Certain';
            } else {
              return '';
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.cat_down_cost'
          ),
          field: 'catDownCost',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.cat_add_risk_cost'
          ),
          field: 'catAddRiskCost',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.cat_maint_cost'
          ),
          field: 'catMaintCost',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.on_going_down_cost'
          ),
          field: 'onGoingDownCost',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.on_going_add_risk_cost'
          ),
          field: 'onGoingAddRiskCost',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.on_going_maint_cost'
          ),
          field: 'onGoingMaintCost',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.comment'
          ),
          field: 'comment',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.lifecycle_strategy.id'
          ),
          field: 'asset_id',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_model.update'
          ),
          field: 'update',
          cellRenderer: (params: any): any => {
            if (params.value === true) {
              return (
                '<span class="badge badge-lg badge-primary">' +
                this.translate.instant('navigations.yes') +
                '</span>'
              );
            } else if (params.value === false) {
              return (
                '<span class="badge badge-lg badge-secondary">' +
                this.translate.instant('navigations.no') +
                '</span>'
              );
            }
          },
        },
      ],
    };
    this.assetModelGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.fastlc.asset_model.model'),
      field: 'model_name',
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    this.gridOptions = tmpGridOptions;
    await this.loadItems();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedLifecycleStrategyNodes) {
      this.filterList(this.selectedLifecycleStrategyNodes);
    }
  }

  clearFilterAndResetModelsGrid(): void {
    this.assetModelGrid?.clearAllFilters();
    this.resetModelsGrid.emit();
  }

  resetModelActionGrid(): void {
    this.assetModelGrid?.uncheckAll();
  }

  /**
   */
  async loadItems(silent = false, reset = true): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }

    const itemsPromise = this.data
      .by('api/fastlc/asset-models', {
        criteria: {
          site: this.auth.getSiteId(),
        },
      })
      .toPromise();

    const gradesPromise = this.data
      .by('api/fastlc/grades', {
        criteria: {
          account: this.auth.getAccountId(),
        },
      })
      .toPromise();

    const conditionPromise = this.data
      .by('api/fastlc/asset-conditions', {
        criteria: {
          site: this.auth.getSiteId(),
        },
      })
      .toPromise();

    try {
      const [grades, items, condition] = await Promise.all([
        gradesPromise,
        itemsPromise,
        conditionPromise,
      ]);

      this.gradesReference = _.map(grades, (el) => {
        return {
          name: el.description,
          value: el.id,
        };
      });

      this.items = items;

      this.conditionValues = condition;
    } catch (error) {
      // Handle error if any of the promises reject
      console.error('Error fetching data:', error);
    }

    if (this.sizeColumnsToFit) {
      this.assetModelGrid?.gridApi?.sizeColumnsToFit();
      this.sizeColumnsToFit = false;
    }

    if (reset) {
      this.filterList(this.selectedLifecycleStrategyNodes);
    }

    // set data array to use ID as value in valueGetter and Description as display value.
    // This is to allow grouping by ID instead of display value ("name" for eg.) thereby avoid grouping on duplicate display values
    this.items.map((val) => {
      this.modelData[val.asset_id] = val.asset_name;
    });

    if (!silent) {
      this.isLoading = false;
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onRowClicked(event: any): void {
    if (!event) {
      return;
    }
    this.showEditModal([event]);
  }

  filterList(assetModels: any): void {
    const instance =
      this.assetModelGrid?.gridApi?.getFilterInstance('asset_id');
    if (assetModels.length > 0) {
      setTimeout(() => {
        instance?.setModel({
          values: _.map(assetModels, (el) => el.id + ''),
        });
        this.assetModelGrid?.gridApi?.onFilterChanged();
      });
      return;
    }
    instance?.setModel(null);
    this.assetModelGrid?.gridApi?.onFilterChanged();
  }

  /**
   * @param items
   */
  showEditModal(items?: AssetModelModel[]): void {
    this.bsModalRef = this.bsModalService.show(AssetModelEditComponent, {
      initialState: {
        items,
        selectedLifecycleStrategyNodes: this.selectedLifecycleStrategyNodes,
      },
      class: 'modal-lg',
    });
    this.bsModalRef.content.confirmFn = (res: AssetModelModel) => {
      this.items = syncItems(res, this.items);

      this.filterList(this.selectedLifecycleStrategyNodes);

      this.items.map((val) => {
        this.modelData[val.asset_id] = val.asset_name;
      });

      this.toastr.showSuccess();
    };
  }

  showMassEditModal(): void {
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      const items = _.map(
        this.selectedNodes,
        (el) => new AssetModelModel(el.data)
      );
      this.showEditModal(items);
    }
  }

  updateSelectedAssetModelNodes(event: any): void {
    this.selectedNodes = event;
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.isLoading = true;
      this.data
        .post('api/fastlc/asset-models/delete-all-singly', ids)
        .subscribe(
          (res) => {
            this.items = this.items.filter(
              (item: any) => !ids.includes(`${item.id}`)
            );
            this.isLoading = false;
            this.toastr.showSuccess('messages.delete');
            this.childGrid === 'Action'
              ? this.assetActionListComponent?.loadItems()
              : this.assetConditionValueListComponent?.loadItems();
          },
          (err) => {
            this.isLoading = false;
            this.toastr.showError();
          }
        );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  changeChildGrid = (): void => {
    this.childGrid = this.childGrid === 'Action' ? 'Condition' : 'Action';
  };

  myCellCallback = (params: any) => {
    return params.value;
  };

  showMassGradesModal(): void {
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      const items = _.map(
        this.selectedNodes,
        (el) => new AssetModelModel(el.data)
      );
      this.showGradesModal(items);
    }
  }

  showGradesModal = (items?: AssetModelModel[]): void => {
    let conditionValues: any[] = [];
    if (this.selectedNodes.length === 1) {
      conditionValues = this.conditionValues.filter((ele: any) => {
        return ele.asset_model_id === this.selectedNodes[0].data.id;
      });
    }

    conditionValues = _.sortBy(conditionValues, (item) => new Date(item.date));

    conditionValues = conditionValues?.slice(-5).reverse();

    conditionValues = _.map(conditionValues, (ele) => {
      const date = moment(ele.date).format('DD/MM/YYYY');
      return _.set(_.clone(ele), 'date', date);
    });

    this.bsModalRef = this.bsModalService.show(GradesEditComponent, {
      initialState: {
        items,
        selectedNodes: this.selectedNodes,
        conditionValues,
      },
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = (res: AssetModelModel) => {
      this.items = syncItems(res, this.items);

      this.filterList(this.selectedLifecycleStrategyNodes);

      this.items.map((val) => {
        this.modelData[val.asset_id] = val.asset_name;
      });

      this.toastr.showSuccess();
    };
  };

  exportProcessCallBack = (cellParams: any) => {
    if (!cellParams.value) {
      return '';
    }

    if (cellParams.column.colId === 'catConfidence') {
      if (cellParams.value === 1) {
        return 'Unsure';
      } else if (cellParams.value === 2) {
        return 'Probable';
      } else if (cellParams.value === 3) {
        return 'Certain';
      } else {
        return '';
      }
    }

    if (cellParams.node.field === 'asset_name') {
      return this.modelData[cellParams.node.key];
    }

    // for normal row
    if (cellParams.column.colId === 'asset_name') {
      return cellParams.node.data.asset_name;
    }

    return cellParams.value;
  };
}
