import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DataService, AuthService, ToasterService } from 'src/app/shared/services';
import { AssetModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { UserAccessModel } from '../../../../shared/models';

@Component({
  selector: 'app-user-edit',
  templateUrl: './edit.component.html'
})
export class UserEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  siteUsers: any; // array of items to edit
  boolReference: { value: boolean, name: string }[] = [];
  rolesReference: [] = [];
  usersReference: any = [];

  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: ('mass' | 'single' | 'new') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;
  isRoleDisabled = false;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   * @param toastr
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private toastr: ToasterService,
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.targetIds = _.map(this.items, el => el.id);

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.items = [new UserAccessModel({
        accountId: this.auth.getAccountId(),
        siteId: this.auth.getSiteId()
      })];
    }

    // Push current Parent to init parentReference and have default selection
    if (['single'].indexOf(this.mode) > -1) {
      this.usersReference = _.map(this.items, el => {
        return {
          value: el.userId,
          name: el.userFirstname + ' ' + el.userLastname + ' (' + el.userEmail + ')'
        };
      }).filter(el => el !== null);
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [AssetModel?]): ('mass' | 'single' | 'new') {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // More than one item => Mass Edit
    } else if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  /**
   *
   */
  getForm(item: UserAccessModel): UntypedFormGroup {
    const form = this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      user: new UntypedFormControl(item.userId, [Validators.required]),
      site: new UntypedFormControl(item.siteId),
      account: new UntypedFormControl(item.accountId),
      role: new UntypedFormControl(item.roleId),
      isAdmin: new UntypedFormControl(item.isAdmin),
    });

    this.isRoleDisabled = item.isAdmin;

    return form;
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => { };


  /**
   *
   */
  save(): boolean | void {
    if (!this.form || (this.form && !this.form.valid)) {
      return false;

    } else {

      // Query pointer
      let query;

      if (this.mode === 'new') {

        // check user is already site member or admin
        const existingUser = this.siteUsers.find((user: any) => user.userId === this.form.value?.user);
        if (existingUser) {
          let errorMsg = existingUser.userFirstname + ' ' + existingUser.userLastname + ' is already';
          errorMsg += existingUser.isAdmin ? ' an administrator for this account' : ' listed for this site';
          this.toastr.showError(errorMsg);
          return false;
        }

        const tmpData = _.pickBy(this.form.value, el => el !== null);
        query = this.service.post('api/access', tmpData);

      } else if (this.mode === 'single') {
        query = this.service.update('api/access', this.targetIds[0], this.form.value);

      } else {
        const tmpData: any = {};
        _.each(this.form.controls, (control, name) => {
          if (control.dirty === true && control.value !== -1) {
            tmpData[name] = control.value;
          }
        });
        const data = _.map(this.targetIds, id => {
          const cloned = _.cloneDeep(tmpData);
          _.merge(cloned, { id });
          return cloned;
        });

        query = this.service.saveAll('api/access', data);
      }

      this.isSubmitted = true;
      query.subscribe(res => {
        this.isSubmitted = false;
        this.confirmFn(res);
        this.bsModalRef.hide();
      }, err => {
        console.log('err: ', err);
        this.isSubmitted = false;
      });
    }
  }

  /**
   *
   * @param event
   */
  onTyping(event: any): void {
    if (!event) {
      return;
    }

    this.service.by('api/users', {
      criteria: { account: [this.auth.getAccountId(), null] },
      fulltext: { firstname: event, lastname: event, },
    }).subscribe((res) => {

      this.usersReference = _.map(res, el => {
        return {
          value: el.id,
          name: el.firstname + ' ' + el.lastname + ' (' + el.email + ')'
        };
      }).filter(el => el !== null);
    });
  }

  /**
   *
   * @param event
   */
  onSelect(event: any): void {
    if (event[0]) {
      this.data = event[0].data;
    }
  }

  changeIsAdmin(event: any): void {
    if (event[0]) {
      this.isRoleDisabled = event[0].value;
      if (event[0].value === true) {
        this.form.controls.site.setValue(null);
        this.form.controls.role.setValue(null);
      } else {
        this.form.controls.site.setValue(this.auth.getSiteId());
        this.form.controls.role.setValue(this.items[0].value);
      }
    }
  }
}
