<div class="header d-flex align-items-center flex-row justify-content-between p-2 mb-3">

  <div class="d-flex align-items-center flex-row">
    <div class="brand font-weight-bold p-1 mr-3">
      Amsyst
    </div>
    <div class="d-flex align-items-center">
      <ng-container *ngFor="let item of breadCrumb; let last = last; let idx = index">
        <div class="link p-1">
          <a *ngIf="!last" [class.font-weight-bold]="last" [routerLink]="item.link">{{ item.name }}</a>
          <a *ngIf="last" [class.font-weight-bold]="last">{{ item.name }}</a>
        </div>
        <div *ngIf="!last">
          <i class="la la-angle-right"></i>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="d-flex mr-3">
    <div class="d-flex align-items-center link p-1 mr-3">
      <a href="https://amsyst.com/platform-manual/" target="_blank">
        <i class="las la-question-circle la-lg"></i> Help & Documentation
      </a>
    </div>
    <div class="d-flex align-items-center link p-1">
      <a routerLink="/my-account">
        <i class="las la-user-circle la-lg"></i> <span class="name">{{ auth.getFullName() }}</span>
      </a>
    </div>
  </div>

</div>
