<div class="container-fluid h-100">
  <div class="row h-100">
    <div class="col-3 flex-column h-100">
      <div class="d-flex flex-column h-100">
        <fieldset>
          <div class="d-flex align-items-center px-2">
            <div class="mr-2">
              <button
                (click)="modelsLoadItems()"
                [ngClass]="{
                  'btn-success': modelsIsLoading,
                  'btn-light': !modelsIsLoading
                }"
                class="btn btn-sm"
              >
                <i
                  [ngClass]="{ 'la-spin': modelsIsLoading }"
                  class="la la-refresh"
                ></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                (click)="modelShowEditModal()"
                [disabled]="modelsIsLoading || auth.isViewer('fastpm')"
                class="btn btn-success btn-sm"
              >
                <i class="la la-plus"></i>
              </button>
            </div>
            <div
              [isDisabled]="modelsSelectedNodes.length === 0"
              class="btn-group mr-1"
              dropdown
            >
              <button
                class="dropdown-toggle btn btn-sm btn-primary"
                dropdownToggle
                type="button"
              >
                <ng-container *ngIf="modelsSelectedNodes.length > 0"
                  >{{ "navigations.with" | translate }}
                  <strong>{{ modelsSelectedNodes.length }}</strong>
                  {{ "navigations.selected" | translate }}
                </ng-container>
                <ng-container *ngIf="modelsSelectedNodes.length === 0">{{
                  "navigations.no_selection" | translate
                }}</ng-container>
                <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu">
                <li
                  (click)="modelClone()"
                  class="btn btn-sm dropdown-item"
                  *ngIf="!auth.isViewer('fastpm')"
                >
                  <i class="la la-fw la-clone"></i>
                  {{ "navigations.clone" | translate }}
                </li>
                <li
                  (click)="modelsGrid?.export('xlsx')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-excel"></i> XLSX
                </li>
                <li
                  (click)="modelsGrid?.export('csv')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-csv"></i> CSV
                </li>
                <li
                  class="divider dropdown-divider"
                  *ngIf="!auth.isViewer('fastpm')"
                ></li>
                <li role="menuitem" *ngIf="!auth.isViewer('fastpm')">
                  <a
                    (click)="modelShowMassDeleteConfirm()"
                    class="dropdown-item btn btn-sm btn-danger"
                  >
                    <i class="la la-fw la-trash"></i>
                    {{ "navigations.delete" | translate }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="mr-2" style="float: left">
              <button
                [disabled]="modelsIsLoading || auth.isViewer('fastpm')"
                (click)="showImportModal()"
                class="btn btn-sm btn-primary"
              >
                <i class="la la-fw la-file-csv"></i>
                {{ "navigations.model.import_models" | translate }}
              </button>
            </div>
          </div>
        </fieldset>
        <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
          <app-grid
            [gridUid]="'fpm_models_list'"
            (rowDoubleClicked)="
              auth.isViewer('fastpm') ? null : modelOnRowClicked($event)
            "
            (selectionChanged)="modelOnSelectionChanged($event)"
            [gridOptions]="modelsGridOptions"
            [rowData]="models"
            class="ag-theme-alpine"
            style="width: 100%; height: 100%"
          >
          </app-grid>
        </div>
      </div>
    </div>
    <div class="col-9 flex-column h-100">
      <div class="d-flex flex-column h-100">
        <div class="d-flex align-items-center px-2">
          <div class="mr-2">
            <button
              (click)="tasksLoadItems(true)"
              [ngClass]="{
                'btn-success': tasksIsLoading,
                'btn-light': !tasksIsLoading
              }"
              class="btn btn-sm"
            >
              <i
                [ngClass]="{ 'la-spin': tasksIsLoading }"
                class="la la-refresh"
              ></i>
            </button>
          </div>
          <div class="mr-2">
            <button
              (click)="tasksGrid?.expandAll()"
              [disabled]="tasksIsLoading || auth.isViewer('fastpm')"
              class="btn btn-light btn-sm"
            >
              <i class="la la-expand-arrows-alt"></i>
            </button>
          </div>
          <div class="mr-2">
            <button
              (click)="tasksGrid?.collapseAll()"
              [disabled]="tasksIsLoading || auth.isViewer('fastpm')"
              class="btn btn-light btn-sm"
            >
              <i class="la la-compress-arrows-alt"></i>
            </button>
          </div>
          <div class="mr-2">
            <button
              alt="clear filter"
              (click)="tasksGrid?.clearAllFilters()"
              [disabled]="
                !tasksGrid?.isFilterApplied || auth.isViewer('fastpm')
              "
              [ngClass]="{
                'clear-filter position-relative': tasksGrid?.isFilterApplied
              }"
              class="btn btn-light btn-sm"
            >
              <i class="las la-filter"></i>
            </button>
          </div>
          <fieldset>
            <div class="mr-2" style="float: left">
              <button
                (click)="taskShowAddModal()"
                [disabled]="
                  tasksIsLoading ||
                  (tasksSelectedNodes.length === 0 &&
                    modelsSelectedNodes.length === 0) ||
                  auth.isViewer('fastpm')
                "
                class="btn btn-success btn-sm"
              >
                <i class="la la-plus"></i>
              </button>
            </div>
            <div
              [isDisabled]="tasksSelectedNodes.length === 0"
              class="btn-group mr-1"
              dropdown
            >
              <button
                class="dropdown-toggle btn btn-sm btn-primary"
                dropdownToggle
                type="button"
              >
                <ng-container *ngIf="tasksSelectedNodes.length > 0"
                  >{{ "navigations.with" | translate }}
                  <strong>{{ tasksSelectedNodes.length }}</strong>
                  {{ "navigations.selected" | translate }}
                </ng-container>
                <ng-container *ngIf="tasksSelectedNodes.length === 0">{{
                  "navigations.no_selection" | translate
                }}</ng-container>
                <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu">
                <li
                  (click)="taskClone()"
                  class="btn btn-sm dropdown-item"
                  *ngIf="!auth.isViewer('fastpm')"
                >
                  <i class="la la-fw la-clone"></i>
                  {{ "navigations.clone" | translate }}
                </li>
                <li
                  (click)="taskShowMassEditModal()"
                  class="btn btn-sm dropdown-item"
                  *ngIf="!auth.isViewer('fastpm')"
                >
                  <i class="la la-fw la-pencil-alt"></i>
                  {{ "navigations.edit" | translate }}
                </li>
                <li
                  (click)="tasksGrid?.export('xlsx')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-excel"></i> XLSX
                </li>
                <li
                  (click)="tasksGrid?.export('csv')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-csv"></i> CSV
                </li>
                <li
                  class="divider dropdown-divider"
                  *ngIf="!auth.isViewer('fastpm')"
                ></li>
                <li role="menuitem">
                  <a
                    (click)="taskShowMassDeleteConfirm()"
                    class="dropdown-item btn btn-sm btn-danger"
                    *ngIf="!auth.isViewer('fastpm')"
                  >
                    <i class="la la-fw la-trash"></i>
                    {{ "navigations.delete" | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </fieldset>
        </div>
        <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
          <app-grid
            [gridUid]="'fpm_models_tasks'"
            (rowDoubleClicked)="
              auth.isViewer('fastpm') ? null : tasksOnRowClicked($event)
            "
            (selectionChanged)="tasksOnSelectionChanged($event)"
            [autoGroupColumnDef]="tasksGridAutoGroupColumnDef"
            [gridOptions]="tasksGridOptions"
            [rowData]="tasks"
            class="ag-theme-alpine"
            style="width: 100%; height: 100%"
          >
          </app-grid>
        </div>
      </div>
    </div>
  </div>
</div>
