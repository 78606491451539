import { Component, OnInit } from '@angular/core';
import { DataService, AuthService } from 'src/app/shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-process-details',
  templateUrl: './process-details.component.html'
})
export class ProcessDetailsComponent implements OnInit {
  // Modal parameters
  processDetails: any;
  processId = 0;

  isLoading = false;
  toolingReference?: [];

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private data: DataService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.getProcessDetails();
  }

  async getProcessDetails(): Promise<void> {
    if (!this.processId) {
      return;
    }
    this.isLoading = true;

    this.data.get(`api/fastcrit/processes/${this.processId}`)
      .subscribe(
        (res) => {
          this.processDetails = res;
          this.isLoading = false;
        },
        (err) => {
          console.log(err);
          this.isLoading = false;
        }
      );
  }
  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => { };
}
