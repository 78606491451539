
import { AssetModel } from '../AssetModel';
import { ActionTypeModel } from './ActionTypeModel';
import { ActionBudgetModel } from './ActionBudgetModel';
import { ActionStatusModel } from './ActionStatusModel';
import { AssetModelModel } from './AssetModelModel';
import { ActionsModel } from './ActionsModel';

export class AssetActionModel {
  id: number;
  model: AssetModelModel;
  type: ActionTypeModel;
  budget: ActionBudgetModel;
  status: ActionStatusModel;
  asset: AssetModel;
  action: ActionsModel;
  cost: number;
  savings: number;
  lastStatusUpdateDate: string;
  plan: number;
  cec_reduction: number;
  ogc_reduction: number;
  description: string;
  comment: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.model = data.model ? data.model : AssetModelModel;
    this.asset = data.asset ? data.asset : AssetModel;
    this.type = data.type ? data.type : ActionTypeModel;
    this.budget = data.budget ? data.budget : ActionBudgetModel;
    this.status = data.status ? data.status : ActionStatusModel;
    this.action = data.action ? data.action : ActionsModel;
    this.cost = data.cost || data.cost === 0 ? data.cost : null;
    this.savings = data.savings || data.savings === 0 ? data.savings : 0;
    this.lastStatusUpdateDate = data.lastStatusUpdateDate ? data.lastStatusUpdateDate : '';
    this.plan = data.plan || data.plan === 0 ? data.plan : null;
    this.cec_reduction = data.cec_reduction || data.cec_reduction === 0 ? data.cec_reduction : 100;
    this.ogc_reduction = data.ogc_reduction || data.ogc_reduction === 0 ? data.ogc_reduction : 100;
    this.description = data.description ? data.description : '';
    this.comment = data.comment ? data.comment : '';
  }
}
