export class ConfFunctionalFailureModel {
  id: number;
  description: string;

  account: string;
  accountId: number;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.description = data.description ? data.description : '';

    this.account = data.account ? data.account : null;
    this.accountId = data.accountId ? data.accountId : null;
  }
}
