<div class="d-flex flex-column h-100">
  <div class="d-flex align-items-center px-2" style="margin-left: 80px">
    <div class="mr-2">
      <button
        (click)="loadItems()"
        [ngClass]="{ 'btn-success': isLoading, 'btn-light': !isLoading }"
        class="btn btn-sm"
      >
        <i [ngClass]="{ 'la-spin': isLoading }" class="la la-refresh"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        (click)="assetActionGrid?.expandAll()"
        [disabled]="isLoading"
        class="btn btn-light btn-sm"
      >
        <i class="la la-expand-arrows-alt"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        (click)="assetActionGrid?.collapseAll()"
        [disabled]="isLoading"
        class="btn btn-light btn-sm"
      >
        <i class="la la-compress-arrows-alt"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        alt="clear filter"
        (click)="clearFilterAndResetModelsGrid()"
        [disabled]="!assetActionGrid?.isFilterApplied"
        [ngClass]="{
          'clear-filter position-relative': assetActionGrid?.isFilterApplied
        }"
        class="btn btn-light btn-sm"
      >
        <i class="las la-filter"></i>
      </button>
    </div>
    <fieldset>
      <div class="mr-2" style="float: left">
        <button
          (click)="showEditModal()"
          [disabled]="
            isLoading ||
            selectedAssetModelNodes.length === 0 ||
            auth.isViewer('fastlc')
          "
          class="btn btn-success btn-sm"
        >
          <i class="la la-plus"></i>
        </button>
      </div>
      <div
        [isDisabled]="selectedNodes.length === 0"
        class="btn-group mr-1"
        dropdown
      >
        <button
          class="dropdown-toggle btn btn-sm btn-primary"
          dropdownToggle
          type="button"
        >
          <ng-container *ngIf="selectedNodes.length > 0"
            >{{ "navigations.with" | translate }}
            <strong>{{ selectedNodes.length }}</strong>
            {{ "navigations.selected" | translate }}
          </ng-container>
          <ng-container *ngIf="selectedNodes.length === 0">{{
            "navigations.no_selection" | translate
          }}</ng-container>
          <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu">
          <li
            (click)="showMassEditModal()"
            class="btn btn-sm dropdown-item"
            *ngIf="!auth.isViewer('fastlc')"
          >
            <i class="la la-fw la-pencil-alt"></i>
            {{ "navigations.edit" | translate }}
          </li>
          <li
            (click)="assetActionGrid?.export('xlsx')"
            class="btn btn-sm dropdown-item"
          >
            <i class="la la-fw la-file-excel"></i> XLSX
          </li>
          <li
            (click)="assetActionGrid?.export('csv')"
            class="btn btn-sm dropdown-item"
          >
            <i class="la la-fw la-file-csv"></i> CSV
          </li>
          <li
            class="divider dropdown-divider"
            *ngIf="!auth.isViewer('fastlc')"
          ></li>
          <li role="menuitem" *ngIf="!auth.isViewer('fastlc')">
            <a
              class="dropdown-item btn btn-sm btn-danger"
              (click)="showMassDeleteConfirm()"
            >
              <i class="la la-fw la-trash"></i>
              {{ "navigations.delete" | translate }}
            </a>
          </li>
        </ul>
      </div>
    </fieldset>
  </div>
  <div class="d-flex h-50 w-100 flex-grow-1 pt-2 ag-grid-container">
    <app-grid
      (rowDoubleClicked)="auth.isViewer('fastlc') ? null : onRowClicked($event)"
      (selectionChanged)="onSelectionChanged($event)"
      [gridOptions]="gridOptions"
      [rowData]="items"
      class="ag-theme-alpine"
      style="width: 100%; height: 100%"
      [autoGroupColumnDef]="assetActionGridAutoGroupColumnDef"
      [exportProcessCallBack]="exportProcessCallBack"
    >
    </app-grid>
  </div>
</div>
