<div class="d-flex flex-column h-100 w-100">
  <div class="d-flex align-items-center px-2">
    <div class="mr-2">
      <button
        (click)="refreshGrid()"
        [ngClass]="{ 'btn-success': isLoading, 'btn-light': !isLoading }"
        class="btn btn-sm"
      >
        <i [ngClass]="{ 'la-spin': isLoading }" class="la la-refresh"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        (click)="riskAssessmentGrid?.expandAll()"
        [disabled]="isLoading"
        class="btn btn-light btn-sm"
      >
        <i class="la la-expand-arrows-alt"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        (click)="riskAssessmentGrid?.collapseAll()"
        [disabled]="isLoading"
        class="btn btn-light btn-sm"
      >
        <i class="la la-compress-arrows-alt"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        (click)="setDisplayTree(true)"
        [disabled]="isLoading"
        class="btn btn-light btn-sm"
      >
        <i class="las la-stream"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        (click)="setDisplayTree(false)"
        [disabled]="isLoading"
        class="btn btn-light btn-sm"
      >
        <i class="las la-th-list"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        alt="clear filter"
        (click)="riskAssessmentGrid?.clearAllFilters()"
        [disabled]="!riskAssessmentGrid?.isFilterApplied"
        [ngClass]="{
          'clear-filter position-relative': riskAssessmentGrid?.isFilterApplied
        }"
        class="btn btn-light btn-sm"
      >
        <i class="las la-filter"></i>
      </button>
    </div>
    <fieldset>
      <div
        [isDisabled]="selectedNodes.length === 0"
        class="btn-group mr-1"
        dropdown
      >
        <button
          class="dropdown-toggle btn btn-sm btn-primary"
          dropdownToggle
          type="button"
        >
          <ng-container *ngIf="selectedNodes.length > 0"
            >{{ "navigations.with" | translate }}
            <strong>{{ selectedNodes.length }}</strong>
            {{ "navigations.selected" | translate }}
          </ng-container>
          <ng-container *ngIf="selectedNodes.length === 0">{{
            "navigations.no_selection" | translate
          }}</ng-container>
          <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu">
          <li
            (click)="showMassEditModal()"
            class="btn btn-sm dropdown-item"
            *ngIf="!auth.isViewer('fast_criticality')"
          >
            <i class="la la-fw la-pencil-alt"></i>
            {{ "navigations.edit" | translate }}
          </li>
          <li
            (click)="riskAssessmentGrid?.export('xlsx')"
            class="btn btn-sm dropdown-item"
          >
            <i class="la la-fw la-file-excel"></i> XLSX
          </li>
          <li
            (click)="riskAssessmentGrid?.export('csv')"
            class="btn btn-sm dropdown-item"
          >
            <i class="la la-fw la-file-csv"></i> CSV
          </li>
          <li
            (click)="copyToClipboard()"
            class="btn btn-sm dropdown-item copy-icon"
            *ngIf="!auth.isViewer('fast_criticality')"
          >
            <i class="las la-clipboard"></i>
            {{ "navigations.copy_to_clipboard" | translate }}
          </li>
          <li
            class="divider dropdown-divider"
            *ngIf="!auth.isViewer('fast_criticality')"
          ></li>
          <li
            role="menuitem"
            (click)="showClearConfirm()"
            *ngIf="!auth.isViewer('fast_criticality')"
          >
            <a class="dropdown-item btn btn-sm btn-danger">
              <i class="la la-fw la-trash"></i>
              {{ "navigations.clear" | translate }}
            </a>
          </li>
        </ul>
      </div>
      <button
        class="btn btn-sm btn-primary"
        type="button"
        (click)="pushCriticalityToAssets()"
        [disabled]="
          selectedNodes.length === 0 || auth.isViewer('fast_criticality')
        "
      >
        <i class="la la-download"></i
        >{{ "navigations.asset.push_criticality" | translate }}
      </button>
      <button
        class="btn btn-sm btn-primary ml-1"
        type="button"
        (click)="exportCriticalityDataset()"
        [disabled]="
          selectedNodes.length === 0 ||
          isCSVExporting ||
          auth.isViewer('fast_criticality')
        "
      >
        <i class="la la-fw la-file-csv"></i>{{ exportBtnTitle }}
      </button>
      <button
        class="btn btn-sm btn-primary ml-1"
        type="button"
        (click)="recalculateCriticality()"
        [disabled]="
          selectedNodes.length === 0 ||
          isRecalculatingCrit ||
          auth.isViewer('fast_criticality')
        "
      >
        {{ recalculateCritBtnTitle }}
      </button>
      <button
        [disabled]="isLoading || auth.isViewer('fast_criticality')"
        (click)="showMatchAssetModal()"
        class="btn btn-sm btn-primary ml-1"
      >
        <i class="la la-fw la-file-csv"></i>
        {{ "navigations.match_select" | translate }}
      </button>
    </fieldset>
  </div>
  <div class="d-flex h-100 w-100 flex-grow-1 p-2 ag-grid-container">
    <app-grid
      [gridUid]="'risk_assessment'"
      (rowDoubleClicked)="
        auth.isViewer('fast_criticality') ? null : onRowClicked($event)
      "
      (selectionChanged)="onSelectionChanged($event)"
      [autoGroupColumnDef]="riskAssessmentGridAutoGroupColumnDef"
      [gridOptions]="gridOptions"
      [groupSelectsChildren]="false"
      [rowData]="displayTree ? items : filteredItems"
      class="ag-theme-alpine"
      style="width: 100%; height: 100%"
    >
    </app-grid>
  </div>
</div>
