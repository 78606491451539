export class ActionStatusModel {
  id: number;
  description: string;
  systStatus: string;
  closed: boolean;

  account: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.description = data.description ? data.description : '';
    this.systStatus = data.systStatus ? data.systStatus : '';
    this.closed = data.closed ? data.closed : false;

    this.account = data.account ? data.account : null;
  }
}
