import { AfterViewInit, Component, OnInit, ViewChildren } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { DataService, AuthService } from 'src/app/shared/services';
import { AssetModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { GridComponent } from '../../../shared';
import { TranslateService } from '@ngx-translate/core';
import { ComponentModel } from '../../../shared/models';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-model-edit',
  templateUrl: './edit.component.html',
})
export class ModelEditComponent implements OnInit, AfterViewInit {
  @ViewChildren(GridComponent) grids?: GridComponent;

  modelsGrid?: any;
  // Modal parameters
  items: any; // array of items to edit
  sitesReferences: any[] = [];
  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: 'mass' | 'single' | 'new' | 'model' = 'single';

  // Target Id's
  targetIds: number[] = [];
  currentSite: any;

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;
  currentActivatedSite: any;

  models: any[] = [];
  modelsGridOptions?: GridOptions;
  modelsSelectedNodes: any[] = [];
  modelsIsLoading = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService
  ) {
    this.isLoading = true;
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    this.modelsGrid = this.grids?._results[0];
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    const siteIndex = _.findIndex(this.sitesReferences, {
      id: this.auth.getSiteId(),
    });
    this.currentActivatedSite = this.sitesReferences[siteIndex];
    this.setSite(this.currentActivatedSite);

    this.modelsGridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.model'),
          field: 'name',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.id'),
          field: 'id',
          hide: true,
        },
      ],
    };
    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.targetIds = _.map(this.items, (el) => el.id);

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.items = [
        new AssetModel({
          account: this.auth.getAccountId(),
          siteId: this.auth.getSiteId(),
        }),
      ];
    }

    // Push current Parent to init parentReference and have default selection
    if (['single'].indexOf(this.mode) > -1) {
      this.parentReference = _.map(this.items, (el) => {
        return {
          id: el.parentId,
          name: el.parent,
        };
      }).filter((el) => el !== null);
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [ComponentModel?]): 'mass' | 'single' | 'new' {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  tabChange(mode: 'new' | 'model'): void {
    this.mode = mode;
    if (this.mode === 'model' && this.models.length === 0) {
      this.setSite(this.currentActivatedSite);
    }
  }

  /**
   * @param site
   */
  setSite(site: any): void {
    this.currentSite = site;
    this.modelsLoadItems(site);
  }

  /**
   * LOADERS
   */
  async modelsLoadItems(site: any, silent = false): Promise<void> {
    if (!silent) {
      this.modelsIsLoading = true;
    }
    this.models = await this.service
      .get('api/fastpm/models/from_site/' + site.id)
      .toPromise();
    this.modelsGrid?.uncheckAll();
    if (!silent) {
      this.modelsIsLoading = false;
    }
  }

  modelOnSelectionChanged(event: any): void {
    this.modelsSelectedNodes = event;
  }

  /**
   *
   */
  getForm(item: AssetModel): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      name: new UntypedFormControl(item.name),
      account: new UntypedFormControl(this.auth.getAccountId()),
      site: new UntypedFormControl(this.auth.getSiteId()),
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => {};

  /**
   *
   */
  save(): boolean | void {
    if (!this.form || (this.form && !this.form.valid)) {
      return false;
    } else {
      // Query pointer
      let query;

      if (this.mode === 'new') {
        const tmpData = _.pickBy(this.form.value, (el) => el !== null);
        query = this.service.post('api/fastpm/models', tmpData);
      } else if (this.mode === 'single') {
        query = this.service.update(
          'api/fastpm/models',
          this.targetIds[0],
          this.form.value
        );
      } else if (this.mode === 'model') {
        const ids = _.map(this.modelsSelectedNodes, (el) => el.id);
        query = this.service.post('api/fastpm/models/duplicate-all', ids);
      }

      this.isSubmitted = true;
      query?.subscribe(
        (res) => {
          this.isSubmitted = false;
          this.confirmFn(res);
          this.bsModalRef.hide();
        },
        (err) => {
          console.log('err: ', err);
          this.isSubmitted = false;
        }
      );
    }
  }
}
