import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  SharedDataService,
  ToasterService,
} from '../../../../../shared/services';
import { GridOptions } from 'ag-grid-community';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfCustomFieldsModel } from '../../../../../shared/models';
import { ConfirmModalComponent } from '../../../../../shared/modals';
import { GridComponent } from '../../../../../shared';
import { ConfAssetCustomFieldEditComponent } from '../edit/edit.component';

import * as _ from 'lodash';

@Component({
  selector: 'app-conf-asset-custom-field4',
  templateUrl: './custom-field4.component.html',
})
export class ConfAssetCustomField4ListComponent implements OnInit {
  @ViewChild(GridComponent, { static: true })
  confCustomFieldsGrid?: GridComponent;

  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  @Input() customHeaders: any = [];

  constructor(
    private data: DataService,
    private sharedData: SharedDataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.customHeaders.custom4,
          field: 'description',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          filter: 'agTextColumnFilter',
        },
      ],
    };
    await this.loadItems();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data
      .by('api/config/asset/custom-field4', {
        criteria: {
          account: this.auth.getAccountId(),
        },
      })
      .toPromise();
    this.confCustomFieldsGrid?.gridApi?.sizeColumnsToFit();
    this.confCustomFieldsGrid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  onFilterChanged(event: any): void {}

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onRowClicked(event: any): void {
    this.showEditModal([new ConfCustomFieldsModel(event)]);
  }

  /**
   * @param items
   */
  async showEditModal(items?: ConfCustomFieldsModel[]): Promise<void> {
    this.bsModalRef = this.bsModalService.show(
      ConfAssetCustomFieldEditComponent,
      {
        initialState: {
          items,
          type: 4,
        },
      }
    );
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      this.toastr.showSuccess();
    };
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.data
        .post('api/config/asset/custom-field4/delete-all', ids)
        .subscribe(
          (res) => {
            this.loadItems();
            this.toastr.showSuccess('messages.delete');
          },
          (err) => {
            this.toastr.showError();
          }
        );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }
}
