import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  SharedDataService,
  ToasterService,
} from '../../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GridComponent } from '../../../../shared';
import * as _ from 'lodash';

import { BreadCrumbService } from '../../../../shared/services/breadcrumb.service';
import { ConfAssetCustomHeaderEditComponent } from './custom-headers/edit/edit.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-conf-asset-custom-fields',
  templateUrl: './custom-fields.component.html',
})
export class ConfAssetCustomFieldsListComponent implements OnInit {
  @ViewChild(GridComponent, { static: true })
  confCustomFieldsGrid?: GridComponent;

  // custom headers
  headers: any[] = [];
  customHeaders: any = [];
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;

  constructor(
    public auth: AuthService,
    public router: Router,
    private bsModalService: BsModalService,
    private data: DataService,
    private sharedData: SharedDataService,
    private toastr: ToasterService,
    public breadCrumbService: BreadCrumbService,
    private translate: TranslateService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.parameters'),
          link: '/admin',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.custom_fields'),
          link: '/admin/asset/custom-fields',
        },
      ],
      2
    );

    await this.loadHeaders();
  }

  async showHeaderEditModal(): Promise<void> {
    this.bsModalRef = this.bsModalService.show(
      ConfAssetCustomHeaderEditComponent,
      {
        initialState: {
          headers: this.headers,
        },
      }
    );
    this.bsModalRef.content.confirmFn = async () => {
      this.loadHeaders();
      this.toastr.showSuccess();
    };
  }

  async loadHeaders(): Promise<void> {
    this.isLoading = true;
    this.headers = await this.sharedData.setAssetCustomHeaders(
      this.auth.getAccountId()
    );
    this.customHeaders = await this.sharedData.getAssetCustomHeaders(
      this.auth.getAccountId()
    );
    this.isLoading = false;
  }
}
