import { AssetModel } from './AssetModel';
import { ConfProcessModel } from './ConfProcessModel';

export class RiskAssessmentModel extends AssetModel {

  asset: [];
  fastcrAssetProcess: number;
  configCrProduct: any;
  fastcrAssetModel: any;
  fastcrAssetFactors: any;
  fastcrAssetComment: string;

  constructor(data: any) {
    super(data);

    this.asset = data.asset ? data.asset : [];
    this.fastcrAssetProcess = data.fastcrAssetProcess ? data.fastcrAssetProcess : ConfProcessModel;
    this.configCrProduct = data.configCrProduct ? data.configCrProduct : [];
    this.fastcrAssetModel = data.fastcrAssetModel ? data.fastcrAssetModel : [];
    this.fastcrAssetFactors = data.fastcrAssetFactors ? data.fastcrAssetFactors : [];
    this.fastcrAssetComment = data.fastcrAssetComment ? data.fastcrAssetComment : null;
  }
}
