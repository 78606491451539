import { AfterViewInit, Component, OnInit, ViewChildren } from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../../shared/modals';
import { GridComponent } from '../../shared';
import * as _ from 'lodash';
import { ComponentModel, ComponentTaskModel } from '../../shared/models';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import { ComponentEditComponent } from './edit/edit.component';
import { ComponentTaskEditComponent } from './component-task.edit/edit.component';

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss'],
})
export class ComponentListComponent implements OnInit, AfterViewInit {
  @ViewChildren(GridComponent) grids?: GridComponent;

  componentsGrid?: any;
  tasksGrid?: any;

  // Available items
  components: any[] = [];
  tasks: any[] = [];
  componentsSelectedNodes: any[] = [];
  tasksSelectedNodes: any[] = [];
  // UI flag
  componentsIsLoading = false;
  tasksIsLoading = false;
  // Local grid options
  componentsGridOptions?: GridOptions;
  tasksGridOptions?: GridOptions;
  tasksGridAutoGroupColumnDef?: any;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  // Form reference Data
  isReferenceLoaded = false;
  toolingReference?: [];
  stateReference?: [];
  tradeReference?: [];
  activityReference?: [];
  frequencyReference?: [];

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  ngAfterViewInit(): void {
    // @ts-ignore
    this.componentsGrid = this.grids?._results[0];
    // @ts-ignore
    this.tasksGrid = this.grids?._results[1];
  }

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.parameters'),
          link: '/admin',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.library'),
          link: '/admin/components',
        },
      ],
      2
    );

    this.componentsGridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.component.component'
          ),
          field: 'name',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          filter: 'agTextColumnFilter',
          width: 500,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.component.id'
          ),
          field: 'id',
          hide: true,
        },
      ],
    };
    this.tasksGridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.description'
          ),
          field: 'description',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.component.component'
          ),
          field: 'component',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.frequency'
          ),
          field: 'frequency',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.labor'
          ),
          field: 'trade',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.activity.activity'
          ),
          field: 'activity',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.tool.tool'
          ),
          field: 'tools',
          filterParams: {
            values: _(this.toolingReference)
              .map((el: any) => el.name)
              .unshift(null)
              .value(),
          },
          valueGetter: (params: any) => {
            if (params.data !== undefined) {
              return params.data.atools;
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.duration'
          ),
          field: 'duration',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.state'
          ),
          field: 'state',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.criteria'
          ),
          field: 'criteria',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.keyword'),
          field: 'keyword',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.proc'),
          field: 'procedure',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.comment'),
          field: 'comment',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.justification'
          ),
          field: 'justification',
        },
        {
          headerName: this.translate.instant('headers.routing.task.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.id'
          ),
          field: 'stateId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.id'
          ),
          field: 'frequencyId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.id'
          ),
          field: 'tradeId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.component.id'
          ),
          field: 'componentId',
          hide: true,
        },
      ],
    };
    this.tasksGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.fastpm.pm_model.group'),
      field: 'component',
      filter: 'agTextColumnFilter',
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };
    await this.componentsLoadItems();
    await this.tasksLoadItems();
  }

  /**
   * LOADERS
   */
  async componentsLoadItems(silent = false): Promise<void> {
    if (!silent) {
      this.componentsIsLoading = true;
    }
    this.components = await this.data
      .post('api/fastpm/components/by', {
        criteria: {
          account: [this.auth.getAccountId(), null],
        },
      })
      .toPromise();
    this.componentsGrid?.uncheckAll();
    if (!silent) {
      this.componentsIsLoading = false;
    }
  }

  async tasksLoadItems(silent = false): Promise<void> {
    if (!silent) {
      this.tasksIsLoading = true;
    }
    this.tasks = await this.data
      .get('api/fastpm/componentTasks/account/' + this.auth.getAccountId())
      .toPromise();
    this.tasksGrid?.uncheckAll();
    if (!silent) {
      this.tasksIsLoading = false;
    }
  }

  /**
   * COMPONENTS
   */
  componentOnSelectionChanged(event: any): void {
    this.componentsSelectedNodes = event;
    this.componentSetTaskFilter(this.componentsSelectedNodes);
  }

  componentOnRowClicked(event: any): void {
    this.componentShowEditModal([new ComponentModel(event)]);
  }

  async componentShowEditModal(items?: ComponentModel[]): Promise<void> {
    this.bsModalRef = this.bsModalService.show(ComponentEditComponent, {
      initialState: {
        items,
      },
      class: 'modal-sm',
    });
    this.bsModalRef.content.confirmFn = () => {
      this.componentsLoadItems();
      this.tasksLoadItems();
      this.toastr.showSuccess();
    };
  }

  componentShowMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.componentsSelectedNodes, (el) => el.id);
      this.data.post('api/fastpm/components/delete-all', ids).subscribe(
        (res) => {
          this.componentsLoadItems();
          this.tasksLoadItems();
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  componentClone(): void {
    const ids = _.map(this.componentsSelectedNodes, (el) => el.id);
    this.data.post('api/fastpm/components/duplicate-all', ids).subscribe(
      (res) => {
        this.componentsLoadItems();
        this.tasksLoadItems();
        this.toastr.showSuccess();
      },
      (err) => {
        this.toastr.showError();
      }
    );
  }

  componentSetTaskFilter(components: any): void {
    const instance = this.tasksGrid?.gridApi?.getFilterInstance('componentId');
    if (components.length > 0) {
      instance?.setModel({
        values: _.map(components, (el) => el.id + ''),
      });
    } else {
      instance?.setModel(null);
    }
    this.tasksGrid?.gridApi?.onFilterChanged();
  }

  /**
   * TASKS
   */
  tasksOnSelectionChanged(event: any): void {
    this.tasksSelectedNodes = event;
  }

  tasksOnRowClicked(event: any): void {
    if (event) {
      this.taskShowEditModal([new ComponentTaskModel(event)]);
    }
  }

  async taskShowEditModal(items?: ComponentTaskModel[]): Promise<void> {
    await this.loadReferences();

    let components: number[] = [];
    if (this.tasksSelectedNodes.length > 0) {
      components = _(this.tasksSelectedNodes)
        .map((el) => parseInt(el.data.componentId, 10))
        .uniq()
        .value();
    } else if (this.componentsSelectedNodes.length > 0) {
      components = _(this.componentsSelectedNodes)
        .map((el) => parseInt(el.data.id, 10))
        .uniq()
        .value();
    }
    this.bsModalRef = this.bsModalService.show(ComponentTaskEditComponent, {
      initialState: {
        items,
        toolingReference: this.toolingReference,
        stateReference: this.stateReference,
        tradeReference: this.tradeReference,
        frequencyReference: this.frequencyReference,
        activityReference: this.activityReference,
        components,
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      this.tasksLoadItems();
      this.toastr.showSuccess();
    };
  }

  taskShowMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.tasksSelectedNodes, (el) => el.id);
      this.data.post('api/fastpm/componentTasks/delete-all', ids).subscribe(
        (res) => {
          this.tasksLoadItems();
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  taskShowMassEditModal(): void {
    if (this.tasksSelectedNodes && this.tasksSelectedNodes.length > 0) {
      const items = _.map(
        this.tasksSelectedNodes,
        (el) => new ComponentTaskModel(el.data)
      );
      this.taskShowEditModal(items);
    }
  }

  taskClone(): void {
    const ids = _.map(this.tasksSelectedNodes, (el) => el.id);
    this.data.post('api/fastpm/componentTasks/duplicate-all', ids).subscribe(
      (res) => {
        this.tasksLoadItems();
        this.toastr.showSuccess();
      },
      (err) => {
        this.toastr.showError();
      }
    );
  }

  taskRowChangedCallBack = () => {
    // Expand items if we have an active selection
    if (
      this.componentsSelectedNodes.length > 0 ||
      this.tasksSelectedNodes.length > 0
    ) {
      this.tasksGrid?.expandAll();
    }
  };

  // Load Rerefences used in modal edit form
  async loadReferences(): Promise<void> {
    const orderByName = { orderBy: { name: 'asc' } };
    const orderByDuration = { orderBy: { duration: 'asc' } };

    const criteria = {
      criteria: {
        account: [this.auth.getAccountId(), null],
      },
      ...orderByName,
    };
    const freqCriteria = {
      criteria: {
        account: [this.auth.getAccountId(), null],
      },
      ...orderByDuration,
    };
    const error = () => this.toastr.showError();

    this.toolingReference = await this.data
      .by('api/config/tools', criteria)
      .toPromise();
    this.stateReference = await this.data
      .by('api/config/states', criteria)
      .toPromise();
    this.tradeReference = await this.data
      .by('api/config/trades', criteria)
      .toPromise();
    this.activityReference = await this.data
      .by('api/config/activities', criteria)
      .toPromise();
    this.frequencyReference = await this.data
      .by('api/config/frequencies', freqCriteria)
      .toPromise();

    // Mark ref as loaded (prevent reload)
    this.isReferenceLoaded = true;
  }

  myCellCallback = (params: any) => {
    return params.value;
  };
}
