export class UserAccessModel {
  id: number;
  isAdmin: boolean;
  role: string;
  roleId: number;
  userId: number;
  userFirstname: string;
  userLastname: string;
  userEmail: string;
  account: string;
  accountId: number;
  site: string;
  siteId: number;

  constructor(data: any) {

    this.id = data.id ? data.id : 0;
    this.isAdmin = (data.isAdmin && data.isAdmin === true) ? data.isAdmin : false;
    this.role = data.role ? data.role : '';
    this.roleId = data.roleId ? data.roleId : null;
    this.userId = data.userId ? data.userId : null;
    this.userFirstname = data.userFirstname ? data.userFirstname : '';
    this.userLastname = data.userLastname ? data.userLastname : '';
    this.userEmail = data.userEmail ? data.userEmail : '';
    this.account = data.account ? data.account : '';
    this.accountId = data.accountId ? data.accountId : null;
    this.site = data.site ? data.site : '';
    this.siteId = data.siteId ? data.siteId : null;
  }
}
