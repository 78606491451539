import { Component, Input } from '@angular/core';

@Component({
  template: `
      <div class="modal-header">
        <h4 *ngIf="title" class="mb-0 font-weight-bold alert-heading">{{ title | translate }}
        </h4>
      </div>
      <div class="modal-body d-flex justify-content-between">
        <div> <button type="button" class="btn btn-primary" (click)="updateFn()">
          <i class="la la-recycle"></i></button>
          <span class="ml-2">{{ headerLinkText | translate }}</span>
        </div>
        <div class="justify-content-end">
          <button type="button" class="btn btn-danger btn-sm" (click)="confirmFn()">{{ 'navigations.position.delete' | translate }}</button>
          <button type="button" class="btn btn-light btn-sm ml-2" (click)="cancelFn()"><i class="la la-ban"></i>{{ 'navigations.cancel' | translate }}</button>
        </div>
      </div>
    `
})
export class DeletePositionTagComponent {
  // Modal title
  @Input()
  title: string | undefined;

  @Input()
  headerLinkText = '';

  // Modal content
  @Input()
  content: string | undefined;
  // Cancel class
  @Input()
  cancelClass: string | undefined;
  // Cancel text
  @Input()
  cancelText: string | undefined;
  // Confirm class
  @Input()
  confirmClass: string | undefined;
  // Confirm text
  @Input()
  confirmText: string | undefined;

  /**
   * Action on cancel.
   */
  cancelFn(): void {
  }

  /**
   * Action on confirm.
   */
  confirmFn(): void {
  }

  /**
   * Action on update.
   */
  updateFn(): void {
  }
}
