import { Component, OnInit } from '@angular/core';
import { AuthService, Broadcaster } from '../../shared/services';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    public breadCrumbService: BreadCrumbService,
    public auth: AuthService,
    public router: Router,
  ) {
  }

  ngOnInit(): void {
    this.breadCrumbService.set([
      {
        name: this.auth.getAccountName(),
        link: '/accounts'
      },
      {
        name: this.auth.getSiteName(),
        link: '/sites'
      }
    ]);
  }
}
