<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group col-12" *ngIf="mode !== 'mass'">
          <label
            [class.font-weight-bold]="
              form.get('configFunctionalFailure')?.dirty
            "
            for="configFunctionalFailure"
          >
            {{ "headers.fastcr.function_loss.functional_failure" | translate }}
            <span class="text-danger">*</span>
          </label>
          <ngx-select
            [items]="functionalFailuresReference"
            [placeholder]="'(Select a Functional Failure)'"
            formControlName="configFunctionalFailure"
            id="configFunctionalFailure"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
          <div
            class="text-danger"
            *ngIf="
              showValidationError &&
              form.get('configFunctionalFailure')?.invalid
            "
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label [class.font-weight-bold]="form.get('mtbf')?.dirty" for="mtbf">
            {{ "headers.fastcr.function_loss.mtbf" | translate }}
          </label>
          <input
            [ngClass]="{
              'is-invalid': showValidationError && form.get('mtbf')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="mtbf"
            id="mtbf"
            type="number"
            (keypress)="validateNumberInput($event)"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('mtbf')?.errors?.min"
          >
            {{ "messages.positive" | translate }}
          </div>
        </div>
        <div class="form-group col-6">
          <label [class.font-weight-bold]="form.get('mttr')?.dirty" for="mttr">
            {{ "headers.fastcr.function_loss.mttr" | translate }}
          </label>
          <input
            [ngClass]="{
              'is-invalid': showValidationError && form.get('mttr')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="mttr"
            id="mttr"
            type="number"
            (keypress)="validateNumberInput($event)"
            (keyup)="setProbabilityDowntime()"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('mttr')?.errors?.min"
          >
            {{ "messages.positive" | translate }}
          </div>
        </div>
        <div class="form-group col-6">
          <label
            [class.font-weight-bold]="form.get('probabilityDowntime')?.dirty"
            for="probabilityDowntime"
          >
            {{
              "headers.fastcr.function_loss.downtime_probability" | translate
            }}
            (%)
          </label>
          <input
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('probabilityDowntime')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="probabilityDowntime"
            id="probabilityDowntime"
            type="number"
            (keypress)="validateNumberInput($event)"
          />
          <div
            class="text-danger"
            *ngIf="
              showValidationError &&
              form.get('probabilityDowntime')?.errors?.min
            "
          >
            {{ "messages.positive" | translate }}
          </div>
          <div
            class="text-danger"
            *ngIf="
              showValidationError &&
              form.get('probabilityDowntime')?.errors?.max
            "
          >
            {{ "messages.less_than" | translate }}
            100.
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('comment')?.dirty"
            for="comment"
          >
            {{ "headers.fastcr.function_loss.comment" | translate }}
          </label>
          <app-field-clear-button
            [formGroupRef]="form"
            [fieldName]="'comment'"
          ></app-field-clear-button>
          <input
            [ngClass]="{
              'is-invalid': showValidationError && form.get('comment')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="comment"
            id="comment"
            type="text"
          />
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
