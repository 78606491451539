import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnApi, GridApi } from 'ag-grid-community';
import * as _ from 'lodash';
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent {
  @Input() gridOptions?: any;
  @Input() autoGroupColumnDef?: any = {};
  @Input() rowData: any[] = [];
  @Input() frameworkComponents: any = {};
  @Input() gridUid = '';
  @Input() masterDetail = false;
  @Input() groupSelectsChildren = true;
  @Input() detailCellRendererParams: any = {};

  @Output() filterChanged = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @Output() rowClicked = new EventEmitter();
  @Output() rowDoubleClicked = new EventEmitter();
  @Output() rowDragMove = new EventEmitter();
  @Output() rowDragEnd = new EventEmitter();

  gridApi?: GridApi | null;
  columnApi?: ColumnApi | null;
  selectedNodes: any = [];
  filterModel?: { [p: string]: any } | undefined = [];
  firstLoad = true;
  exportColumns?: any = [];
  isFilterApplied = false;

  constructor() {}

  @Input() exportProcessCallBack?: any = (params: any) => {
    return params.value;
  };

  @Input() exportShouldRowBeSkipped?: any = () => {};

  @Input() readyCallBack?: any = () => {};

  @Input() rowChangedCallBack?: any = () => {};

  @Input() firstDataRendered?: any = () => {
    this.autoSizeColumn();
  };

  onGridReady(params: { api: any; columnApi: any }): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    if (this.exportColumns.length > 0) {
      const displayedColumns: any = [];
      this.columnApi?.getAllDisplayedColumns()?.forEach((column: any): void => {
        displayedColumns.push(column.colId);
      });
      this.exportColumns = displayedColumns.concat(this.exportColumns);
    }
    this.readyCallBack();
  }

  onFilterChange = (): void => {
    this.filterModel = this.gridApi?.getFilterModel();
    this.uncheckAll();

    if (this.gridUid) {
      this.saveState();
    }

    if (_.isEmpty(this.filterModel)) {
      this.isFilterApplied = false;
    } else {
      this.isFilterApplied = true;
    }

    this.filterChanged.emit(this.filterModel);
  };

  clearAllFilters = (): void => {
    this.gridOptions?.api?.setFilterModel(null);
  };

  onSortChange = (): void => {
    if (this.gridUid) {
      // console.log('onSortChange');
      this.saveState();
    }
  };

  onColumnResize = (): void => {
    if (this.gridUid) {
      // console.log('onColumnResize');
      this.saveState();
    }
  };

  onColumnMoved = (): void => {
    if (this.gridUid) {
      // console.log('onColumnMoved');
      this.saveState();
    }
  };

  onSelectionChange = (event: any): void => {
    this.selectedNodes = this.gridApi?.getSelectedNodes();
    this.selectionChanged.emit(this.selectedNodes);
  };

  onRowClick = (event: any): void => {
    this.rowClicked.emit(event.data);
  };

  onRowDoubleClick = (event: any): void => {
    this.rowDoubleClicked.emit(event.data);
  };

  onRowDragMove = (event: any): void => {
    this.rowDragMove.emit(event);
  };

  onRowDragEnd = (event: any): void => {
    this.rowDragEnd.emit(event);
  };

  onFirstDataRendered = (): void => {
    this.firstDataRendered();
  };

  onColumnVisible = (event: any): void => {
    this.saveState();
  };

  onRowDataChanged = (): void => {
    if (this.gridUid) {
      const gridState = this.getState();
      if (this.firstLoad) {
        this.firstLoad = false;
        if (gridState && gridState.column) {
          this.applyColumnState(gridState.column);
        } else {
          this.saveState();
        }
      } else {
        if (gridState && gridState.filter) {
          this.applyFilterState(gridState.filter);
        }
      }
    }
    this.rowChangedCallBack();
  };

  applyFilter = (): void => {
    // console.log('applyFilter: ' + this.gridUid);
  };

  /**
   * Check whether the current grid-tasks have an active selection (more than one row)
   */
  haveSelection(): boolean {
    // @ts-ignore
    return this.selectedNodes.length > 0;
  }

  /**
   * Check all visible (non filtered) rows in a grid-tasks
   */
  checkAll(): void {
    if (this.gridApi) {
      this.gridApi?.deselectAll();
      this.gridApi?.selectAllFiltered();
    }
  }

  /**
   * Reset current grid-tasks selection
   */
  uncheckAll(): void {
    if (this.gridApi && this.haveSelection()) {
      this.gridApi.deselectAll();
      this.selectedNodes = [];
      this.selectionChanged.emit(this.selectedNodes);
    }
  }

  /**
   * @param format
   */
  export(format: string, onlySelected: boolean = true): void {
    const keys =
      this.exportColumns.length > 0
        ? {
            allColumns: true,
            columnKeys: this.exportColumns,
          }
        : {};
    switch (format) {
      case 'xlsx':
        this.gridApi?.exportDataAsExcel({
          // @ts-ignore
          processCellCallback: (params) => this.exportProcessCallBack(params),
          shouldRowBeSkipped: (params) => this.exportShouldRowBeSkipped(params),
          onlySelected,
          ...keys,
        });
        break;
      case 'csv':
        this.gridApi?.exportDataAsCsv({
          // @ts-ignore
          processCellCallback: (params) => this.exportProcessCallBack(params),
          shouldRowBeSkipped: (params) => this.exportShouldRowBeSkipped(params),
          onlySelected,
          ...keys,
        });
        break;
    }
  }

  /**
   * Expand all grid-tasks nodes
   */
  expandAll(): void {
    if (this.gridApi) {
      this.gridApi.expandAll();
    }
  }

  /**
   * Collapse all grid-tasks nodes
   */
  collapseAll(): void {
    if (this.gridApi) {
      this.gridApi.collapseAll();
    }
  }

  /**
   * Return Default Grid Configuration
   */
  getDefaultGridOptions(): any {
    return {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [],
    };
  }

  /**
   */
  getState(): any {
    const gridState = JSON.parse(
      localStorage.getItem('amsyst_grid_state') as string
    );
    if (gridState === null) {
      return {};
    }
    const index = _.findIndex(gridState, { grid: this.gridUid });
    if (index > -1) {
      return gridState[index];
    } else {
      return [];
    }
  }

  /**
   * Save Current Grid state (per grid)
   */
  saveState(): void {
    const gridConfig = {
      grid: this.gridUid,
      filter: this.gridApi?.getFilterModel(),
      column: this.gridOptions.columnApi.getColumnState(),
    };
    let gridState = JSON.parse(
      localStorage.getItem('amsyst_grid_state') as string
    );
    if (gridState === null) {
      gridState = [gridConfig];
    } else {
      const index = _.findIndex(gridState, { grid: this.gridUid });
      if (index > -1) {
        gridState[index] = gridConfig;
      } else {
        gridState.push(gridConfig);
      }
    }
    localStorage.setItem('amsyst_grid_state', JSON.stringify(gridState));
  }

  applyColumnState(state: any): void {
    this.gridOptions.columnApi.applyColumnState({ state, applyOrder: true });
  }

  applyFilterState(state: any): void {
    this.gridApi?.setFilterModel(state);
  }

  /**
   * Auto size Column
   */
  autoSizeColumn(): void {
    const allColumnIds: any[] = [];
    this.columnApi?.getAllColumns()?.forEach((column: any): void => {
      allColumnIds.push(column.colId);
    });
    this.columnApi?.autoSizeColumns(allColumnIds, false);
  }

  /**
   * Return all row for the Grid
   */
  getAllRows(): any {
    const rowData: any[] = [];
    this.gridApi?.forEachNode((node) => rowData.push(node.data));
    return rowData;
  }

  /**
   * Expand grid group and scrool to value if found
   * @param value
   */
  expandTo(value: any[]): void {
    // Auto expand
    this.gridApi?.forEachNode((node: any) => {
      if (value.indexOf(node.key) > -1) {
        node.setExpanded(true);
        this.gridApi?.ensureIndexVisible(node.rowIndex, 'top');
      }
    });
  }
}
