import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class BreadCrumbService {

  breadCrumb: { name: string; link: string }[] = [];
  breadCrumbSubject = new Subject<any>();

  /**
   *
   */
  constructor() {
  }

  set(data: { name: string; link: string }[], index: number = 0): void {
    this.breadCrumb = _.slice(this.breadCrumb, 0, index);
    _.each(data, el => this.breadCrumb.push(el));
    this.breadCrumbSubject.next(this.breadCrumb);
  }
}
