<div>
  <div
    class="border-bottom"
    style="overflow: hidden"
    *ngIf="!riskAssessmentIsLoading && riskAssessmentData.critStatus"
  >
    <div
      class="col-12 mt-3 mb-1 border-bottom"
      style="overflow: hidden; position: relative"
    >
      <h3 style="float: left">
        {{ "assets.fast_criticality" | translate }}
      </h3>
      <span
        class="m-3"
        style="position: absolute; bottom: -5px; font-weight: 600"
      >
        <a
          [queryParams]="{
            asset_id: riskAssessmentData?.id
          }"
          [routerLink]="['/fastcrit/risk-assessment']"
          target="_blank"
        >
          {{ "modules.fast_criticality.children.assessment.name" | translate }}
          <i class="las la-external-link-alt"></i>
        </a>
      </span>
    </div>

    <div class="col-6 mb-1" style="float: left">
      <span class="font-size-smaller text-muted">Models: </span>
      <span class="font-weight-bold">{{
        riskAssessmentData.fastcrAssetModelLabel.toString()
      }}</span>
    </div>

    <div class="col-6 mb-1" style="float: left">
      <span class="font-size-smaller text-muted"
        >{{ "headers.criticality.actual_criticality" | translate }}:
      </span>
      <span class="font-weight-bold">{{
        riskAssessmentData.actual_crit_name
      }}</span>
    </div>

    <div class="col-6 mb-1" style="float: left">
      <span class="font-size-smaller text-muted"
        >{{ "headers.criticality.actual_risk_amount" | translate }}:
      </span>
      <span class="font-weight-bold">{{
        riskAssessmentData.actual_risk_amount
      }}</span>
    </div>

    <div class="col-6 mb-1" style="float: left">
      <span class="font-size-smaller text-muted">Products: </span>
      <span class="font-weight-bold">{{
        riskAssessmentData.configCrProductLabel.toString()
      }}</span>
    </div>

    <div class="col-6 mb-1" style="float: left">
      <span class="font-size-smaller text-muted">Status: </span>
      <span class="font-weight-bold">{{ riskAssessmentData.critStatus }}</span>
    </div>

    <div class="col-6 mb-1" style="float: left">
      <span class="font-size-smaller text-muted">Process: </span>
      <span class="font-weight-bold">{{
        riskAssessmentData.fastcrAssetProcessLabel
      }}</span>
    </div>

    <div class="col-6 mb-1" style="float: left">
      <span class="font-size-smaller text-muted">Push date: </span>
      <span class="font-weight-bold">{{
        riskAssessmentData.critPushDate
      }}</span>
    </div>

    <div class="col-6 mb-1" style="float: left">
      <span class="font-size-smaller text-muted">Factors: </span>
      <span class="font-weight-bold">{{
        riskAssessmentData.fastcrAssetFactorsLabel.toString()
      }}</span>
    </div>
  </div>
  <app-loader *ngIf="riskAssessmentIsLoading"></app-loader>
</div>

<div
  *ngIf="
    !criticalityDatasetIsLoading &&
    (criticalityDowntimeData?.length > 0 || criticalityRiskData?.length > 0)
  "
>
  <div class="col-6 mb-1 pt-2" style="float: left; height: 200px">
    <span class="pr-2"
      >Downtime events ({{ criticalityDowntimeData.length }})</span
    >
    <app-grid
      [gridOptions]="downtimeGridOptions"
      class="ag-theme-alpine"
      [rowData]="criticalityDowntimeData"
      [autoGroupColumnDef]="criticalityDowntimeGridAutoGroupColumnDef"
      style="width: 100%; height: 200px; max-height: 300px"
    >
    </app-grid>
    <div class="row h-100">
      <div
        class="col-12 h-100 d-flex align-items-center justify-content-center"
      ></div>
    </div>
  </div>
  <div class="col-6 mb-1 pt-2" style="float: left; height: 200px">
    <span class="pr-2">Risk events ({{ criticalityRiskData.length }})</span>
    <app-grid
      [gridOptions]="riskGridOptions"
      class="ag-theme-alpine"
      [rowData]="criticalityRiskData"
      [autoGroupColumnDef]="criticalityRiskGridAutoGroupColumnDef"
      style="width: 100%; height: 200px; max-height: 300px"
    >
    </app-grid>
    <div
      class="col-12 h-100 d-flex align-items-center justify-content-center"
    ></div>
  </div>
</div>
<app-loader *ngIf="criticalityDatasetIsLoading"></app-loader>
