<app-grid
  *ngIf="!isLoading"
  [gridUid]="'routing_plans_tasks'"
  [gridOptions]="gridOptions"
  class="ag-theme-alpine"
  [rowData]="items"
  [autoGroupColumnDef]="tasksGridAutoGroupColumnDef"
>
</app-grid>
<div *ngIf="isLoading" class="row h-100">
  <div class="col-12 h-100 d-flex align-items-center justify-content-center">
    <app-loader></app-loader>
  </div>
</div>
