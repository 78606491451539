import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {
  AuthService,
  DataService,
  Broadcaster,
  SharedDataService,
} from '../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '../../shared';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { RoutingSetPlanComponent } from './task.set-plan/set.plan.component';
import { AssetSummaryComponent } from '../../shared/components/asset-summary/asset-summary.component';
import { SELECTED_PLANNING_GROUPS } from '../../shared/events';

@Component({
  selector: 'app-fsl-tasks',
  templateUrl: './fsl-tasks.component.html',
})
export class RoutingFslTasksComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() frequency?: number;
  @Input() state?: number;
  @Input() labour?: number;
  @Input() loadFrom?: 'locations' | 'plans';
  @Input() currentSelection?: number[];

  @Output() selectItems = new EventEmitter();

  @ViewChildren(GridComponent) grids?: GridComponent;
  grid?: any;
  assetsGridAutoGroupColumnDef?: any;

  // Available items
  items: any[] = [];
  allItems: any[] = [];

  // Items (assets & tags associated to a specific location)
  selectedNodes: any[] = [];
  selectionDuration = 0;

  // UI flag
  isLoading = false;

  // Local grid options
  gridOptions?: GridOptions;

  // Modal ref
  bsModalRef?: BsModalRef;

  taskFilterOptions = [
    {
      name: this.translate.instant('headers.routing.task.task_filter_all'),
      value: 'all',
    },
    {
      name: this.translate.instant('headers.routing.task.task_filter_planned'),
      value: 'planned',
    },
    {
      name: this.translate.instant(
        'headers.routing.task.task_filter_unplanned'
      ),
      value: 'unplanned',
    },
  ];

  selectedFilterType = '';
  selectedPlanningGroupIds: any[] = [];

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private sharedData: SharedDataService,
    public auth: AuthService,
    public router: Router,
    public bsModalService: BsModalService,
    public broadcaster: Broadcaster
  ) {}

  ngAfterViewInit(): void {
    // @ts-ignore
    this.grid = this.grids?._results[0];
  }

  /**
   */
  async ngOnInit(): Promise<void> {
    this.broadcaster.on(SELECTED_PLANNING_GROUPS).subscribe((ev: any) => {
      this.selectedPlanningGroupIds = ev;
      this.items = [];
    });

    const customHeaders = await this.sharedData.getAssetCustomHeaders(
      this.auth.getAccountId()
    );

    this.gridOptions = {
      getRowId: (row: any) => row.data.key,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.asset.asset'),
          field: 'asset',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.parent'),
          field: 'parent_name',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqtype'),
          field: 'eqtype',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmake'),
          field: 'eqmake',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmodel'),
          field: 'eqmodel',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.drawing'),
          field: 'drawing',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.specifications'),
          field: 'specifications',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.criticality.criticality'),
          field: 'criticality',
          hide: true,
        },
        {
          headerName: customHeaders.custom1,
          field: 'custom1',
          hide: true,
        },
        {
          headerName: customHeaders.custom2,
          field: 'custom2',
          hide: true,
        },
        {
          headerName: customHeaders.custom3,
          field: 'custom3',
          hide: true,
        },
        {
          headerName: customHeaders.custom4,
          field: 'custom4',
          hide: true,
        },
        {
          headerName: customHeaders.custom5,
          field: 'custom5',
          hide: true,
        },
        {
          headerName: customHeaders.custom6,
          field: 'custom6',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.asset.access_requirements'
          ),
          field: 'accesses',
          valueGetter: (params: any): any[] => {
            if (params.data !== undefined && params.data.accesses.length > 0) {
              return _.map(params.data.accesses, (el) => el.description);
            }
            // for (Blanks) option
            return [''];
          },
        },
        {
          headerName: this.translate.instant('headers.location.order'),
          field: 'location_order',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.component.component'
          ),
          field: 'component',
          hide: false,
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.description'
          ),
          field: 'description',
          hide: false,
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.duration'
          ),
          field: 'duration',
          hide: false,
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.keyword'),
          field: 'keyword',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.criteria'
          ),
          field: 'criteria',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.frequency'
          ),
          field: 'frequency',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.state'
          ),
          field: 'state',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.labor'
          ),
          field: 'labour',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.activity.activity'
          ),
          field: 'activity',
          hide: false,
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.proc'),
          field: 'procedure',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.comment'),
          field: 'comment',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.justification'
          ),
          field: 'justification',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.tool.tool'
          ),
          field: 'tools',
          hide: false,
        },
        {
          headerName: this.translate.instant('headers.routing.task.assignment'),
          field: 'assignment',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.location.id'),
          field: 'location_id',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.location.location'),
          field: 'location',
          hide: false,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.plan'),
          field: 'plan_name',
          hide: false,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.quality'),
          field: 'plan_quality',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.safety'),
          field: 'plan_safety',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.parts'),
          field: 'plan_parts',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.status'),
          field: 'plan_status',
          hide: true,
          cellRenderer: (params: any): string => {
            switch (params.value) {
              case 1:
                return this.translate.instant('headers.routing.plan.status_1');
              case 2:
                return this.translate.instant('headers.routing.plan.status_2');
              case 3:
                return this.translate.instant('headers.routing.plan.status_3');
              case 4:
                return this.translate.instant('headers.routing.plan.status_4');
              default:
                return '';
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.routing.plan.actualDuration'
          ),
          field: 'plan_actual_duration',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.routing.plan.cmms_reference'
          ),
          field: 'plan_cmms_reference',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.routing.task.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.routing.task.key'),
          field: 'key',
          hide: true,
        },
      ],
    };
    this.assetsGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.asset.asset'),
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      field: 'asset',
      filter: 'agTextColumnFilter',
      sortable: true,
      cellRendererParams: {
        checkbox: true,
        innerRenderer: (params: any): any => {
          if (params.data) {
            const aTag = document.createElement('a');
            aTag.href = 'javascript:void(0)';
            aTag.onclick = () => {
              this.showSummaryModal(params.data.asset_id);
            };
            aTag.innerHTML = params.data.asset;
            return aTag;
          }
          return params.value;
        },
      },
    };
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.currentSelection) {
      if (changes.currentSelection.currentValue.length === 0) {
        this.items = [];
        this.onSelectionChanged([]);
      } else {
        await this.loadItems();
      }
    }
  }

  /**
   * LOADERS
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }

    let apiUrl = '';
    const payload = {
      site: this.auth.getSiteId(),
      frequency: this.frequency,
      state: this.state,
      labour: this.labour,
      planningGroupIds: this.selectedPlanningGroupIds,
    };

    if (this.loadFrom === 'locations') {
      apiUrl = 'api/fastpm/modelTasks/fsl-tasks-from-locations';
      _.set(payload, 'locations', this.currentSelection);
    } else if (this.loadFrom === 'plans') {
      apiUrl = 'api/fastpm/modelTasks/fsl-tasks-from-plans';
      _.set(payload, 'plans', this.currentSelection);
    }

    this.allItems = await this.data.post(apiUrl, payload).toPromise();
    this.items = this.allItems;

    this.onChangeFilterType(this.selectedFilterType, true);

    // this.grid?.gridApi?.sizeColumnsToFit();
    this.grid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  /**
   * @param event
   */
  onFilterChanged(event: any): void {}

  /**
   * @param event
   */
  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
    this.selectItems.emit(event);
    if (this.selectedNodes.length === 0) {
      this.selectionDuration = 0;
    } else {
      this.selectionDuration = _.sumBy(this.selectedNodes, 'data.duration');
      this.selectionDuration = _.round(this.selectionDuration / 60, 1);
    }
  }

  myCellCallback(params: any): void {
    return params.value;
  }

  /**
   *
   */
  haveSelection(): boolean {
    return this.selectedNodes.length > 0;
  }

  /**
   * Group selected Tasks in a plan - selected in Modal
   */
  groupTasks(): void {
    this.bsModalRef = this.bsModalService.show(RoutingSetPlanComponent, {
      initialState: {
        tasks: _.map(this.selectedNodes, (el: any) => el.data),
        frequency: this.frequency,
        state: this.state,
        labour: this.labour,
      },
      class: 'modal-xl',
    });
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      // this.toastr.showSuccess();
    };
  }

  /**
   * Free current selected tasks from associated plans
   */
  freeTasks(): void {
    if (this.selectedNodes.length > 0) {
      // Get PlanTask IDs
      const payload = _.map(this.selectedNodes, (el) => el.data.tpt_id);

      // Post
      this.data.post('api/plans/tasks/delete-all', payload).subscribe(
        () => {
          this.loadItems();
          // this.toastr.showSuccess();
        },
        () => {
          // this.toastr.showError();
        }
      );
    }
  }

  async showSummaryModal(assetId: number): Promise<void> {
    this.bsModalRef = this.bsModalService.show(AssetSummaryComponent, {
      initialState: {
        assetId,
      },
      class: 'modal-lg',
    });
  }

  onChangeFilterType(event: any, filterAlreadySelected: boolean = false): void {
    this.selectedFilterType = filterAlreadySelected
      ? event
      : event[0].data.value;
    let filteredData = this.allItems;
    if (this.selectedFilterType) {
      if (this.selectedFilterType === 'planned') {
        filteredData = filteredData.filter((row, index) => row.plan_id > 0);
      } else if (this.selectedFilterType === 'unplanned') {
        filteredData = filteredData.filter((row, index) => row.plan_id === 0);
      }
    }

    this.items = filteredData;
  }
}
