<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('description')?.dirty"
            for="description"
          >
            {{ "headers.fastlc.lifecycle_model.model" | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('description')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="description"
            id="description"
            type="text"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('description')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('typeEolDesc')?.dirty"
            for="typeEolDesc"
          >
            {{ "headers.fastlc.lifecycle_model.type_eol_desc" | translate }}
          </label>
          <textarea
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('typeEolDesc')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="typeEolDesc"
            id="typeEolDesc"
          ></textarea>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('expectedLife')?.dirty"
            for="expectedLife"
          >
            {{ "headers.fastlc.lifecycle_model.expected_life" | translate }}
          </label>
          <input
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('expectedLife')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="expectedLife"
            id="expectedLife"
            type="number"
            (keypress)="validateNumberInput($event)"
          />
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('businessRules')?.dirty"
            for="businessRules"
          >
            {{ "headers.fastlc.lifecycle_model.business_rules" | translate }}
          </label>
          <app-field-clear-button
            [formGroupRef]="form"
            [fieldName]="'businessRules'"
          ></app-field-clear-button>
          <textarea
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('businessRules')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="businessRules"
            id="businessRules"
          ></textarea>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('comment')?.dirty"
            for="comment"
          >
            {{ "headers.fastlc.lifecycle_model.comment" | translate }}
          </label>
          <app-field-clear-button
            [formGroupRef]="form"
            [fieldName]="'comment'"
          ></app-field-clear-button>
          <input
            [ngClass]="{
              'is-invalid': showValidationError && form.get('comment')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="comment"
            id="comment"
            type="text"
          />
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
