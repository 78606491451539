import { AssetModel } from './AssetModel';

export class FastspStrategyModel extends AssetModel {

  asset: [];

  constructor(data: any) {
    super(data);

    this.asset = data.asset ? data.asset : [];
  }
}
