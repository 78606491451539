import { Component } from '@angular/core';
import { AuthService } from '../../services';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  breadCrumb: any;

  constructor(
    public breadCrumbService: BreadCrumbService,
    public auth: AuthService,
    public router: Router
  ) {
    this.breadCrumbService.breadCrumbSubject.subscribe((ev: any) => {
      this.breadCrumb = ev;
    });
  }
}
