import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { Broadcaster } from './broadcaster.service';
import * as _ from 'lodash';
import { NO_ACCESS, VIEWER } from '../constants';

@Injectable()
export class AuthService {
  // Error
  error: any = null;
  // Current user object
  user: string | null = null;
  // JWT Helper service
  jwtHelper: any;
  // ROLES
  isSA = false; // Is SuperAdmin
  isA = false;  // Is Admin
  isU = false;  // Is User
  // Local key (variable prefix)
  key = 'amsyst_';

  // Is ROLE_CHANGE

  /**
   * @param broadcaster
   * @param http
   * @param router
   */
  constructor(
    public broadcaster: Broadcaster,
    public http: HttpClient,
    public router: Router
  ) {
    // Create JWT Helper service
    this.jwtHelper = new JwtHelperService();
    // If user is already logged, init rights
    if (this.isLogged()) {
      this.setRights();
    }
  }

  /**
   *
   */
  public setRights(): void {
    this.isSA = this.isSuperAdmin();
    this.isA = this.isSA || this.isAdmin();
    this.isU = true;
  }

  /**
   *
   */
  public resetRights(): void {
    this.isSA = false;
    this.isA = false;
    this.isU = false;
  }

  /**
   *
   * @param credentials
   */
  loginCheck(credentials: any): Observable<any> {
    const url = environment.url + 'login_check';
    return this.http.post(
      url,
      credentials
    );
  }

  /**
   *
   */
  checkAuth(): boolean {
    if (!this.isLogged()) {
      this.clearUser();
      this.broadcaster.broadcast('userLoggedOut', 'Some data here');
      this.router.navigate(['/login']);
      return false;
    } else {
      return true;
    }
  }

  /**
   * Check if current user is logged (i.e. Token is valid)
   */
  isLogged(): boolean {
    const token = this.getToken();
    return (token != null && token !== '' && !this.jwtHelper.isTokenExpired(token));
  }

  /**
   *
   * @param data
   */
  saveUser(data: { token: string | undefined; }): void {
    if (data.token !== undefined) {
      const ret = this.jwtHelper.decodeToken(data.token);
      localStorage.setItem(this.key + 'token', data.token);
      this.setRights();
      this.broadcaster.broadcast('userSaved', ret);
    }
  }

  clearUser(): void {
    localStorage.removeItem(this.key + 'token');
    this.resetRights();
  }

  logout(): void {
    this.clearUser();
    this.broadcaster.broadcast('userLoggedOut', 'Some data here');
    this.router.navigate(['/login']);
  }

  getToken(): any {
    return localStorage.getItem(this.key + 'token');
  }

  /**
   *
   */
  decodeToken(): any {
    const ret = this.jwtHelper.decodeToken(this.getToken());
    if (ret === null) {
      this.logout();
    }
    return ret;
  }

  /**
   *
   */
  getId(): number {
    return this.decodeToken().id;
  }

  /**
   *
   */
  getUserName(): string {
    return this.decodeToken().username;
  }

  /**
   *
   */
  getAccessRole(): any {
    return this.decodeToken().accessRole;
  }

  getAccount(): any {
    return this.decodeToken().account;
  }

  getAccountId(): number {
    const account = this.decodeToken().account;
    return account ? this.decodeToken().account.id : null;
  }

  getAccountName(): string {
    const account = this.decodeToken().account;
    return account ? this.decodeToken().account.name : null;
  }

  currentUserIsAdmin(): boolean {
    return this.decodeToken()?.is_admin || false;
  }

  getAccessRights(module: string): any {
    const accessRights = this.decodeToken()?.access_rights;
    return accessRights ? +accessRights[module] : null;
  }

  isViewer(module: string): boolean {
    if (this.currentUserIsAdmin()) {
      return false;
    }

    const rights = this.getAccessRights(module);
    return rights === VIEWER;
  }

  hasAccess(module: string): boolean {
    if (this.currentUserIsAdmin()) {
      return true;
    }

    const rights = this.getAccessRights(module);
    return rights !== NO_ACCESS;
  }

  getSite(): any {
    return this.decodeToken().site;
  }

  getSiteId(): number {
    const site = this.decodeToken().site;
    return site ? this.decodeToken().site.id : null;
  }

  getSiteName(): string {
    const site = this.decodeToken().site;
    return site ? this.decodeToken().site.name : null;
  }

  setEmail(email: string): void {
    localStorage.setItem(this.key + 'email', email);
  }

  clearEmail(): void {
    localStorage.removeItem(this.key + 'email');
  }

  getEmail(): string | null {
    const email = localStorage.getItem(this.key + 'email');
    if (email && email !== '') {
      return email;
    } else {
      return null;
    }
  }

  setSection(section: string): void {
    localStorage.setItem(this.key + 'section', section);
  }

  clearSection(): void {
    localStorage.removeItem(this.key + 'section');
  }

  getSection(): string | null {
    const site = localStorage.getItem(this.key + 'section');
    if (site && site !== '') {
      return site;
    } else {
      return null;
    }
  }

  getFullName(): string | null {
    const fullName = this.decodeToken();
    return fullName ? this.decodeToken().fullname : null;
  }

  getLastConnection(): string | null {
    const lastConnection = this.decodeToken();
    return lastConnection ? this.decodeToken().lastconnection : null;
  }

  /**
   *
   */
  getRoles(): any[] {
    return this.decodeToken().roles;
  }

  /**
   *
   */
  isSuperAdmin(): boolean {
    if (this.isLogged()) {
      return this.getRoles().indexOf('ROLE_SUPER_ADMIN') > -1;
    } else {
      return false;
    }
  }

  /**
   *
   */
  isAdmin(): boolean {
    if (this.isLogged()) {
      return this.getRoles().indexOf('ROLE_ADMIN') > -1;
    } else {
      return false;
    }
  }

  /**
   * Clear grid state to avoid unkown column issues (like criticality)
   * @param keys
   */
  clearState(keys: string[]): void {
    const gridState = JSON.parse(localStorage.getItem('amsyst_grid_state') as string);
    _.each(keys, (key: string) => {
      _.remove(gridState, (el: any) => el.grid === key);
      localStorage.setItem('amsyst_grid_state', JSON.stringify(gridState));
    });
  }
}
