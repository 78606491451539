import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChildren,
} from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
  Broadcaster,
} from '../../shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../../shared/modals';
import { GridComponent } from '../../shared';
import * as _ from 'lodash';
import {
  ComponentModel,
  ComponentTaskModel,
  LocationModel,
} from '../../shared/models';

import { LocationEditComponent } from './edit/edit.component';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
})
export class LocationsListComponent implements OnInit, AfterViewInit {
  @Output() selectLocation = new EventEmitter();

  @ViewChildren(GridComponent) grids?: GridComponent;

  locationsGrid?: any;
  isDefaultLocationSelected = false;

  // Available items (Locations)
  locations: any[] = [];

  // Items (assets & tags associated to a specific location)
  locationsSelectedNodes: any[] = [];

  // UI flag
  locationsIsLoading = false;
  locationDetailsIsLoading = false;

  // Local grid options
  locationsGridOptions?: GridOptions;
  locationsGridAutoGroupColumnDef?: any;
  itemsGridOptions?: GridOptions;

  // Modal ref pointer
  bsModalRef?: BsModalRef;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    private broadcaster: Broadcaster,
    public auth: AuthService,
    public router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    // @ts-ignore
    this.locationsGrid = this.grids?._results[0];
  }

  /**
   */
  async ngOnInit(): Promise<void> {
    this.broadcaster.on('set-location-image').subscribe((ev: any) => {
      const location = this.locations.find((l) => {
        return l.id === ev.id;
      });
      location.image = ev.image;
    });

    this.locationsGridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      treeData: true,
      onRowDataChanged: (event) => this.selectDefaultLocation(event),
      groupDefaultExpanded: -1, // expand all groups by default
      getDataPath: (data) => data.orgHierarchy,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.location.location'),
          field: 'name',
          hide: true,
          width: 300,
        },
        {
          headerName: this.translate.instant('headers.location.id'),
          field: 'id',
          hide: true,
        },
      ],
    };
    this.locationsGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.location.location'),
      filter: 'agTextColumnFilter',
      cellRendererParams: {
        checkbox: true,
        innerRenderer: (params: any): string => {
          if (params.data.image === '') {
            return '<span>' + params.value + '</span>';
          } else {
            return '<span class="font-weight-bold">' + params.value + '</span>';
          }
        },
      },
      sortable: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };
    await this.locationsLoadItems();
  }

  /**
   * LOADERS
   */
  async locationsLoadItems(silent = false): Promise<void> {
    if (!silent) {
      this.locationsIsLoading = true;
    }
    this.locations = await this.data
      .get('api/map/locations/from_site')
      .toPromise();
    this.locationsGrid?.uncheckAll();
    if (!silent) {
      this.locationsIsLoading = false;
    }
  }

  locationOnSelectionChanged(event: any): void {
    this.locationsSelectedNodes = event;
    this.selectLocation.emit(event);
  }

  locationOnRowClicked(event: any): void {
    const location = new LocationModel(event);
    this.selectLocation.emit(event);
  }

  locationOnRowDoubleClicked(event: any): void {
    this.locationShowEditModal([new LocationModel(event)]);
  }

  locationShowMassEditModal(): void {
    if (this.locationsSelectedNodes && this.locationsSelectedNodes.length > 0) {
      const items = _.map(
        this.locationsSelectedNodes,
        (el) => new LocationModel(el.data)
      );
      this.locationShowEditModal(items);
    }
  }

  async locationShowEditModal(items?: LocationModel[]): Promise<void> {
    this.bsModalRef = this.bsModalService.show(LocationEditComponent, {
      initialState: {
        items,
      },
      class: 'modal-lg',
    });
    this.bsModalRef.content.confirmFn = () => {
      this.locationsLoadItems();
      this.toastr.showSuccess();
    };
  }

  locationShowMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.locationsSelectedNodes, (el) => el.id);
      this.data.post('api/map/locations/delete-all', ids).subscribe(
        (res) => {
          this.locationsLoadItems();
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  locationClone(): void {
    const ids = _.map(this.locationsSelectedNodes, (el) => el.id);
    this.data.post('api/map/locations/duplicate-all', ids).subscribe(
      (res) => {
        this.locationsLoadItems();
        this.toastr.showSuccess();
      },
      (err) => {
        this.toastr.showError();
      }
    );
  }

  myCellCallback = (params: any) => {
    return params.value;
  };

  selectDefaultLocation = (event: any): void => {
    const locationId =
      this.activatedRoute.snapshot.queryParamMap.get('location_id');

    if (
      !locationId ||
      this.isDefaultLocationSelected ||
      event.api.getDisplayedRowCount() === 0
    ) {
      return;
    }
    let isLocationAvailable = false;

    event.api.forEachNode((node: any) => {
      if (node.data.id === +locationId) {
        isLocationAvailable = true;

        const hardcodedFilter = {
          'ag-Grid-AutoColumn': {
            type: 'set',
            filter: node.data.name,
          },
        };
        event.api.setFilterModel(hardcodedFilter);
        node.setSelected(true);

        // Allow enough time to load assets before we click on location name
        setTimeout(() => {
          this.locationOnRowClicked(node.data);
        }, 0);
        if (!this.isDefaultLocationSelected) {
          this.isDefaultLocationSelected = true;
        }
      }
    });

    if (!isLocationAvailable) {
      this.toastr.showError(
        this.translate.instant('messages.location.not_found')
      );
    }
  };
}
