<div class="container-fluid h-100">
  <div class="row h-100">
    <div class="col-4 flex-column h-100">
      <div class="d-flex flex-column h-100">
        <fieldset>
          <div class="d-flex align-items-center float-left">
            <div>
              <button
                (click)="refreshGrid()"
                [ngClass]="{
                  'btn-success': isLoading,
                  'btn-light': !isLoading
                }"
                class="btn btn-sm"
              >
                <i
                  [ngClass]="{ 'la-spin': isLoading }"
                  class="la la-refresh"
                ></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                (click)="strategyGrid?.expandAll()"
                [disabled]="isLoading || auth.isViewer('fastlc')"
                class="btn btn-light btn-sm"
              >
                <i class="la la-expand-arrows-alt"></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                (click)="strategyGrid?.collapseAll()"
                [disabled]="isLoading || auth.isViewer('fastlc')"
                class="btn btn-light btn-sm"
              >
                <i class="la la-compress-arrows-alt"></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                [disabled]="isLoading || auth.isViewer('fastlc')"
                (click)="setDisplayTree(true)"
                class="btn btn-light btn-sm"
              >
                <i class="las la-stream"></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                [disabled]="isLoading || auth.isViewer('fastlc')"
                (click)="setDisplayTree(false)"
                class="btn btn-light btn-sm"
              >
                <i class="las la-th-list"></i>
              </button>
            </div>
            <div class="mr-2">
              <button
                alt="clear filter"
                (click)="strategyGrid?.clearAllFilters()"
                [disabled]="!strategyGrid?.isFilterApplied"
                [ngClass]="{
                  'clear-filter position-relative':
                    strategyGrid?.isFilterApplied
                }"
                class="btn btn-light btn-sm"
              >
                <i class="las la-filter"></i>
              </button>
            </div>
            <div
              [isDisabled]="selectedNodes.length === 0"
              class="btn-group mr-1"
              dropdown
            >
              <button
                class="dropdown-toggle btn btn-sm btn-primary"
                dropdownToggle
                type="button"
              >
                <ng-container *ngIf="selectedNodes.length > 0"
                  >{{ "navigations.with" | translate }}
                  <strong>{{ selectedNodes.length }}</strong>
                  {{ "navigations.selected" | translate }}
                </ng-container>
                <ng-container *ngIf="selectedNodes.length === 0">{{
                  "navigations.no_selection" | translate
                }}</ng-container>
                <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu">
                <li
                  (click)="strategyGrid?.export('xlsx')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-excel"></i> XLSX
                </li>
                <li
                  (click)="strategyGrid?.export('csv')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-csv"></i> CSV
                </li>
                <li
                  (click)="copyToClipboard()"
                  class="btn btn-sm dropdown-item copy-icon"
                >
                  <i class="las la-clipboard"></i>
                  {{ "navigations.copy_to_clipboard" | translate }}
                </li>
              </ul>
            </div>
          </div>
          <div class="mr-2 float-left">
            <button
              [disabled]="isLoading || auth.isViewer('fastlc')"
              (click)="showMatchAssetModal()"
              class="btn btn-sm btn-primary"
            >
              <i class="la la-fw la-file-csv"></i>
              {{ "navigations.match_select" | translate }}
            </button>
          </div>
        </fieldset>
        <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
          <app-grid
            [gridUid]="'fastlc_strategy_grid'"
            (selectionChanged)="onSelectionChanged($event)"
            [autoGroupColumnDef]="strategyGridAutoGroupColumnDef"
            [gridOptions]="gridOptions"
            [groupSelectsChildren]="false"
            [rowData]="displayTree ? items : filteredItems"
            class="ag-theme-alpine"
            style="width: 100%; height: 100%"
          >
          </app-grid>
        </div>
      </div>
    </div>
    <app-asset-model
      class="col-8 flex-column h-100"
      [sizeColumnsToFit]="sizeColumnsToFit"
      (changeSelectLifecycleStrategyNodes)="
        updateSelectedLifecycleStrategyNodes($event)
      "
      [selectedLifecycleStrategyNodes]="selectedNodes"
    ></app-asset-model>
  </div>
</div>
