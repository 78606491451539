<div class="d-flex flex-column h-100 w-100">
  <div class="d-flex align-items-center px-2" *ngIf="!isLoading">
    <div class="row ml-4 mr-4">
      <div
        class="col-sm-6 col-md-4 col-lg-4 mb-2"
        *ngFor="let engine of enginesOptionalities"
      >
        <div
          class="card col-md-4 text-center"
          (click)="updateEngine(engine.name)"
          [class.active]="selectedEngine === engine.name"
        >
          <div class="title">
            <i class="fa fa-paper-plane" aria-hidden="true"></i>
            <h2>{{ engine.name | uppercase }}</h2>
            <p>{{ engine.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-loader *ngIf="isLoading"></app-loader>
</div>
