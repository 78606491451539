import {
  AfterContentChecked,
  Component,
  OnInit,
  ViewChildren,
} from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../../shared/modals';
import { GridComponent } from '../../shared';
import * as _ from 'lodash';
import { ComponentModel, ModelTaskModel } from '../../shared/models';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';

import { ModelEditComponent } from './edit/edit.component';
import { ModelTaskAddComponent } from './model-task.add/add.component';
import { ModelTaskEditComponent } from './model-task.edit/edit.component';
import { ModelAssetListComponent } from './model-asset-list/model-asset-list.component';
import { FastpmModelImportComponent } from './import/import.component';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss'],
})
export class ModelListComponent implements OnInit, AfterContentChecked {
  @ViewChildren(GridComponent) grids?: GridComponent;

  modelsGrid?: any;
  tasksGrid?: any;

  // Available items
  models: any[] = [];
  tasks: any[] = [];
  modelsSelectedNodes: any[] = [];
  tasksSelectedNodes: any[] = [];
  // UI flag
  modelsIsLoading = false;
  tasksIsLoading = false;
  // Local grid options
  modelsGridOptions?: GridOptions;
  tasksGridOptions?: GridOptions;
  tasksGridAutoGroupColumnDef?: any;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  // Form reference Data
  isReferenceLoaded = false;
  sitesReferences?: [];
  toolingReference?: [];
  stateReference?: [];
  tradeReference?: [];
  activityReference?: [];
  frequencyReference?: [];
  criticalityReference?: [];

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  ngAfterContentChecked(): void {
    // @ts-ignore
    this.modelsGrid = this.grids?._results[0];
    // @ts-ignore
    this.tasksGrid = this.grids?._results[1];
  }

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.pm_models'),
          link: '/models',
        },
      ],
      2
    );

    const orderByThreshold = { orderBy: { threshold: 'asc' } };

    // Get Criticalities
    this.criticalityReference = await this.data
      .by('api/config/criticalities', {
        criteria: {
          account: [this.auth.getAccountId(), null],
        },
        ...orderByThreshold,
      })
      .toPromise();

    this.modelsGridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.model'),
          field: 'name',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.asset_count'
          ),
          field: 'assetCount',
          hide: false,
          cellRenderer: (params: any): any => {
            const aTag = document.createElement('a');
            aTag.href = 'javascript:void(0)';
            aTag.onclick = () => {
              this.showAssetListModal(params.data.id);
            };
            aTag.innerHTML = params.value;
            return aTag;
          },
        },
      ],
    };
    const tmpGridOptions: GridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1, // expand all groups by default
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.model'),
          field: 'model',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.component.component'
          ),
          field: 'component',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.state'
          ),
          field: 'state',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.frequency'
          ),
          field: 'frequency',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.labor'
          ),
          field: 'trade',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.activity.activity'
          ),
          field: 'activity',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.tool.tool'
          ),
          field: 'tools',
          valueGetter: (params: any) => {
            if (params.data !== undefined) {
              return params.data.atools;
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.duration'
          ),
          field: 'duration',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.criteria'
          ),
          field: 'criteria',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.keyword'),
          field: 'keyword',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.proc'),
          field: 'procedure',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.comment'),
          field: 'comment',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.justification'
          ),
          field: 'justification',
        },
        {
          headerName: this.translate.instant('headers.routing.task.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.id'
          ),
          field: 'stateId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.id'
          ),
          field: 'frequencyId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.id'
          ),
          field: 'tradeId',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.id'),
          field: 'modelId',
          hide: true,
        },
      ],
    };
    this.tasksGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.fastpm.pm_model.description'),
      field: 'description',
      filter: 'agTextColumnFilter',
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    _.each(this.criticalityReference, (el: any) => {
      tmpGridOptions.columnDefs?.push({
        headerName: el.name,
        field: 'crit_' + el.id,
        cellRenderer: (params: any): HTMLElement | null => {
          if (!params.node?.data) {
            return null;
          }

          const input = params.context.thisCompObj.createCriticalityCbInput(
            el,
            params
          );

          return input;
        },
        width: 100,
      });
    });

    this.tasksGridOptions = tmpGridOptions;
    this.tasksGridOptions.context = {
      thisCompObj: this,
    };

    await this.modelsLoadItems();
    await this.tasksLoadItems();
  }

  createCriticalityCbInput(el: any, params: any): HTMLElement {
    const input = document.createElement('input');

    input.type = 'checkbox';
    input.className = 'crit-cb';
    input.checked = params.value;

    input.addEventListener('click', (event: any): void => {
      const modelData = params.node?.data;
      if (input.checked) {
        if (modelData.criticalityIds?.indexOf(el.id) === -1) {
          modelData.criticalityIds.push(el.id);
        }
      } else {
        modelData.criticalityIds?.splice(
          modelData.criticalityIds.indexOf(el.id),
          1
        );
      }
      params.context.thisCompObj.saveCriticality(modelData);
    });

    return input;
  }

  saveCriticality(data: any): boolean | void {
    this.disableCritCheckboxes();
    this.tasksIsLoading = true;

    const criticalitiesData: any = {
      criticalities: data.criticalityIds,
      id: data.id,
    };

    const query = this.data.post(
      'api/fastpm/modelTasks/criticality',
      criticalitiesData
    );
    query.subscribe(
      (res: any) => {
        this.updateGridRow(data);
        this.toastr.showSuccess();
      },
      (err: any) => {
        this.toastr.showError();
      },
      () => {
        this.tasksIsLoading = false;
        this.enableCritCheckboxes();
      }
    );
  }

  updateGridRow = (data: any): void => {
    const rowNodeData = this.tasksGrid?.gridApi?.getRowNode(data.id).data;
    rowNodeData.criticalityIds = data.criticalityIds;

    _.each(this.criticalityReference, (el: any) => {
      if (data.criticalityIds.includes(el.id)) {
        rowNodeData['crit_' + el.id] = 1;
      } else {
        rowNodeData['crit_' + el.id] = 0;
      }
    });

    this.tasksGrid?.gridApi?.getRowNode(data.id).setData(rowNodeData);
  };

  disableCritCheckboxes = (): void => {
    const inputs = document.getElementsByClassName('crit-cb');
    for (const input of inputs) {
      input.setAttribute('disabled', '');
    }
  };

  enableCritCheckboxes = (): void => {
    const inputs = document.getElementsByClassName('crit-cb');
    for (const input of inputs) {
      input.removeAttribute('disabled');
    }
  };

  /**
   * LOADERS
   */
  async modelsLoadItems(silent = false): Promise<void> {
    if (!silent) {
      this.modelsIsLoading = true;
    }
    this.models = await this.data
      .get('api/fastpm/models/from_site/' + this.auth.getSiteId())
      .toPromise();
    this.modelsGrid?.uncheckAll();
    if (!silent) {
      this.modelsIsLoading = false;
    }
  }

  async tasksLoadItems(applyFilter = false): Promise<void> {
    this.tasksIsLoading = true;
    this.tasks = await this.data
      .get(
        'api/fastpm/modelTasks/site/' +
          this.auth.getSiteId() +
          '/' +
          this.auth.getAccountId()
      )
      .toPromise();

    // @TODO Fix ensureIndexVisible issue with Grouping
    if (this.tasksSelectedNodes[0]) {
      this.tasksGrid.gridApi.ensureIndexVisible(
        this.tasksSelectedNodes[0].rowIndex,
        'top'
      );
    }

    this.tasksGrid?.uncheckAll();
    this.tasksIsLoading = false;
  }

  /**
   * COMPONENTS
   */
  modelOnSelectionChanged(event: any): void {
    this.modelsSelectedNodes = event;
    this.modelSetTaskFilter(this.modelsSelectedNodes);
  }

  modelOnRowClicked(event: any): void {
    this.modelShowEditModal([new ComponentModel(event)]);
  }

  async modelShowEditModal(items?: ComponentModel[]): Promise<void> {
    const orderByName = { orderBy: { name: 'asc' } };
    const criteria = {
      criteria: {
        account: [this.auth.getAccountId(), null],
      },
      ...orderByName,
    };
    this.sitesReferences = await this.data
      .by('api/sites', criteria)
      .toPromise();
    this.bsModalRef = this.bsModalService.show(ModelEditComponent, {
      initialState: {
        items,
        sitesReferences: this.sitesReferences,
      },
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = () => {
      this.modelsLoadItems();
      this.tasksLoadItems(true);
      this.toastr.showSuccess();
    };
  }

  modelShowMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.modelsSelectedNodes, (el) => el.id);
      this.data.post('api/fastpm/models/delete-all', ids).subscribe(
        (res) => {
          this.modelsLoadItems();
          this.tasksLoadItems(true);
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  modelClone(): void {
    const ids = _.map(this.modelsSelectedNodes, (el) => el.id);
    this.data.post('api/fastpm/models/duplicate-all', ids).subscribe(
      (res) => {
        this.modelsLoadItems();
        this.tasksLoadItems(true);
        this.toastr.showSuccess();
      },
      (err) => {
        this.toastr.showError();
      }
    );
  }

  modelSetTaskFilter(models: any): void {
    const instance = this.tasksGrid?.gridApi?.getFilterInstance('modelId');
    if (models.length > 0) {
      instance?.setModel({
        values: _.map(models, (el) => el.id + ''),
      });
    } else {
      instance?.setModel(null);
    }
    this.tasksGrid?.gridApi?.onFilterChanged();
  }

  /**
   * TASKS
   */
  tasksOnSelectionChanged(event: any): void {
    this.tasksSelectedNodes = event;
  }

  tasksOnRowClicked(event: any): void {
    if (event) {
      this.taskShowEditModal([new ModelTaskModel(event)]);
    }
  }

  async taskShowAddModal(): Promise<void> {
    await this.loadReferences();

    let models: number[] = [];
    if (this.tasksSelectedNodes.length > 0) {
      models = _(this.tasksSelectedNodes)
        .map((el) => parseInt(el.data.modelId, 10))
        .uniq()
        .value();
    } else if (this.modelsSelectedNodes.length > 0) {
      models = _(this.modelsSelectedNodes)
        .map((el) => parseInt(el.data.id, 10))
        .uniq()
        .value();
    }
    this.bsModalRef = this.bsModalService.show(ModelTaskAddComponent, {
      initialState: {
        toolingReference: this.toolingReference,
        stateReference: this.stateReference,
        tradeReference: this.tradeReference,
        frequencyReference: this.frequencyReference,
        activityReference: this.activityReference,
        sitesReferences: this.sitesReferences,
        criticalityReference: this.criticalityReference,
        models,
      },
      class: 'modal-lg',
    });
    this.bsModalRef.content.confirmFn = async () => {
      await this.tasksLoadItems(true);
      setTimeout(() => {
        this.modelSetTaskFilter(this.modelsSelectedNodes);
      }, 500);

      this.toastr.showSuccess();
    };
  }

  async taskShowEditModal(items?: ModelTaskModel[]): Promise<void> {
    await this.loadReferences();

    let models: number[] = [];
    if (this.tasksSelectedNodes.length > 0) {
      models = _(this.tasksSelectedNodes)
        .map((el) => parseInt(el.data.modelId, 10))
        .uniq()
        .value();
    } else if (this.modelsSelectedNodes.length > 0) {
      models = _(this.modelsSelectedNodes)
        .map((el) => parseInt(el.data.id, 10))
        .uniq()
        .value();
    }
    this.bsModalRef = this.bsModalService.show(ModelTaskEditComponent, {
      initialState: {
        items,
        toolingReference: this.toolingReference,
        stateReference: this.stateReference,
        tradeReference: this.tradeReference,
        frequencyReference: this.frequencyReference,
        activityReference: this.activityReference,
        criticalityReference: this.criticalityReference,
        models,
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      this.tasksLoadItems(true);
      this.toastr.showSuccess();
    };
  }

  taskShowMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.tasksSelectedNodes, (el) => el.id);
      this.data.post('api/fastpm/modelTasks/delete-all', ids).subscribe(
        (res) => {
          this.tasksLoadItems(true);
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  taskShowMassEditModal(): void {
    if (this.tasksSelectedNodes && this.tasksSelectedNodes.length > 0) {
      const items = _.map(
        this.tasksSelectedNodes,
        (el) => new ModelTaskModel(el.data)
      );
      this.taskShowEditModal(items);
    }
  }

  taskClone(): void {
    const ids = _.map(this.tasksSelectedNodes, (el) => el.id);
    this.data.post('api/fastpm/modelTasks/duplicate-all', ids).subscribe(
      (res) => {
        this.tasksLoadItems(true);
        this.toastr.showSuccess();
      },
      (err) => {
        this.toastr.showError();
      }
    );
  }

  // Load Rerefences used in modal edit form
  async loadReferences(): Promise<void> {
    const orderByName = { orderBy: { name: 'asc' } };
    const orderByThreshold = { orderBy: { threshold: 'asc' } };
    const orderByDuration = { orderBy: { duration: 'asc' } };
    const criteria = {
      criteria: {
        account: [this.auth.getAccountId(), null],
      },
      ...orderByName,
    };

    const freqCriteria = {
      criteria: {
        account: [this.auth.getAccountId(), null],
      },
      ...orderByDuration,
    };
    const error = () => this.toastr.showError();
    // Get Criticalities
    this.criticalityReference = await this.data
      .by('api/config/criticalities', {
        criteria: {
          account: [this.auth.getAccountId(), null],
        },
        ...orderByThreshold,
      })
      .toPromise();

    this.toolingReference = await this.data
      .by('api/config/tools', {
        criteria: {
          account: [this.auth.getAccountId(), null],
        },
        ...orderByName,
      })
      .toPromise();
    this.stateReference = await this.data
      .by('api/config/states', criteria)
      .toPromise();
    this.tradeReference = await this.data
      .by('api/config/trades', criteria)
      .toPromise();
    this.activityReference = await this.data
      .by('api/config/activities', criteria)
      .toPromise();
    this.frequencyReference = await this.data
      .by('api/config/frequencies', freqCriteria)
      .toPromise();
    this.sitesReferences = await this.data
      .by('api/sites', criteria)
      .toPromise();
    // Criticality is loaded on ngOnInit()

    // Mark ref as loaded (prevent reload)
    this.isReferenceLoaded = true;
  }

  myCellCallback = (params: any) => {
    return params.value;
  };

  async showAssetListModal(modelId: number): Promise<void> {
    this.bsModalRef = this.bsModalService.show(ModelAssetListComponent, {
      initialState: {
        modelId,
      },
      class: 'modal-xl',
    });
  }

  /**
   * @param items
   */
  async showImportModal(): Promise<void> {
    this.bsModalRef = this.bsModalService.show(FastpmModelImportComponent, {
      initialState: {},
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = () => {
      this.modelsLoadItems();
      this.tasksLoadItems();
    };
  }
}
