import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DataService, AuthService } from 'src/app/shared/services';
import { LifecycleModelModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { validateNumberInput } from 'src/utils';

@Component({
  selector: 'app-lifecycle-model-edit',
  templateUrl: './edit.component.html'
})
export class LifecycleModelEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: ('mass' | 'single' | 'new') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;
  showValidationError = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.targetIds = _.map(this.items, el => el.id);

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.items = [new LifecycleModelModel({
        site: this.auth.getSiteId(),
      })];
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [LifecycleModelModel?]): ('mass' | 'single' | 'new') {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  /**
   *
   */
  getForm(item: LifecycleModelModel): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      site: new UntypedFormControl(this.auth.getSiteId()),
      description: new UntypedFormControl(item.description, [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
      businessRules: new UntypedFormControl(item.businessRules),
      comment: new UntypedFormControl(item.comment),
      expectedLife: new UntypedFormControl(item.expectedLife, [Validators.min(0)]),
      typeEolDesc: new UntypedFormControl(item.typeEolDesc),
    });
  }

  validateNumberInput(event: KeyboardEvent): void {
    validateNumberInput(event);
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => { };

  /**
   *
   */
  save(): boolean | void {
    this.showValidationError = true;
    if (!this.form || (this.form && !this.form.valid)) {
      return false;

    } else {

      // Query pointer
      let query;

      if (this.mode === 'new') {
        const tmpData = _.pickBy(this.form.value, el => el !== null);
        query = this.service.post('api/fastlc/lifecycle-models', tmpData);

      } else if (this.mode === 'single') {
        query = this.service.update('api/fastlc/lifecycle-models', this.targetIds[0], this.form.value);

      }

      this.isSubmitted = true;
      query?.subscribe(res => {
        this.isSubmitted = false;
        this.confirmFn(res);
        this.bsModalRef.hide();
      }, err => {
        console.log('err: ', err);
        this.isSubmitted = false;
      });
    }
  }
}
