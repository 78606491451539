<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="card form">
        <div class="card-body">
          <h6 class="card-title">
            {{ "headers.fastlc.asset_model.asset" | translate }}
          </h6>
          <div class="form-row">
            <div class="form-group col-6">
              <label
                [class.font-weight-bold]="form.get('grade')?.dirty"
                for="grade"
              >
                {{ "headers.fastlc.asset_model.grade" | translate }}
              </label>
              <ngx-select
                [items]="gradesReference"
                [placeholder]="'(Select a Grade)'"
                formControlName="grade"
                id="grade"
                optionTextField="name"
                optionValueField="value"
                size="small"
              ></ngx-select>
              <div
                class="text-danger"
                *ngIf="showValidationError && form.get('grade')?.invalid"
              >
                {{ "messages.required" | translate }}
              </div>
            </div>
            <div
              class="form-group col-6 pl-30"
              *ngIf="previousAssessments[0]?.date"
            >
              <label class="w-full">
                {{ "headers.fastlc.asset_model.last_updated" | translate }}
              </label>
              <strong *ngIf="mode === 'single'" class="form-control-sm pl-0">
                {{ previousAssessments[0]?.date }}
              </strong>
            </div>
          </div>
          <div
            class="form-row mt-15"
            *ngIf="mode === 'single' && previousAssessments.length > 0"
          >
            <p class="mt-0 mb-6 w-full">
              <strong>{{
                "headers.fastlc.asset_model.previous_assessments" | translate
              }}</strong>
            </p>
            <div class="table-responsive">
              <table>
                <tr *ngFor="let item of previousAssessments; index as i">
                  <td>{{ item?.date }}</td>
                  <td>{{ item?.grade }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div
            class="form-row"
            *ngIf="mode === 'single' && conditionValues.length > 0"
          >
            <p class="my w-full">
              <strong>{{
                "headers.fastlc.asset_model.conditions_values" | translate
              }}</strong>
            </p>
            <div class="table-responsive">
              <table>
                <tr *ngFor="let item of conditionValues; index as i">
                  <td>{{ item.condition_description }}</td>
                  <td>{{ item.date }}</td>
                  <td>{{ item.value }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
