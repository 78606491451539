import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LifecycleModelModel } from '../../../shared/models';
import { ConfirmModalComponent } from '../../../shared/modals';
import { GridComponent } from '../../../shared';
import { LifecycleModelEditComponent } from './models/edit/edit.component';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../../shared/services/breadcrumb.service';
import { LifecycleModelAddComponent } from './models/add/add.component';
import { LifecycleModelAssetListComponent } from './models/asset-list/asset-list.component';
import { ConditionsListComponent } from './conditions/conditions.component';
import { ActionsListComponent } from './actions/actions.component';
import { syncItems } from 'src/utils';

@Component({
  selector: 'app-lifecycle-models',
  templateUrl: './lifecycle-models.component.html',
})
export class LifecycleModelsListComponent implements OnInit {
  @ViewChild(GridComponent, { static: true })
  lifecycleModelsGrid?: GridComponent;
  @ViewChild(ConditionsListComponent)
  conditionsListComponent?: ConditionsListComponent;
  @ViewChild(ActionsListComponent) actionsListComponent?: ActionsListComponent;

  // Available items
  items: any[] = [];
  selectedLifecycleModelNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  isReferenceLoaded = false;
  sitesReferences?: [];
  sizeColumnsToFit = true;
  childGrid = 'action';

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant(
            'navigations.breadcrumbs.lifecycle_models'
          ),
          link: '/fastlc/lifecycle-models',
        },
      ],
      2
    );
    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.fastlc.lifecycle_model.model'
          ),
          field: 'description',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          filter: 'agTextColumnFilter',
          filterParams: { newRowsAction: 'keep' },
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.lifecycle_model.type_eol_desc'
          ),
          field: 'type_eol_desc',
          width: 70,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.lifecycle_model.expected_life'
          ),
          field: 'expected_life',
          width: 70,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.lifecycle_model.business_rules'
          ),
          field: 'business_rules',
          width: 70,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.lifecycle_model.comment'
          ),
          field: 'comment',
        },
        {
          headerName: this.translate.instant('headers.model.asset_count'),
          field: 'assetCount',
          hide: false,
          cellRenderer: (params: any): any => {
            const aTag = document.createElement('a');
            aTag.href = 'javascript:void(0)';
            aTag.onclick = () => {
              this.showAssetListModal(params.data.id);
            };
            aTag.innerHTML = params.value;
            return aTag;
          },
        },
      ],
    };
    await this.loadItems();
  }

  async showAssetListModal(modelId: number): Promise<void> {
    this.bsModalRef = this.bsModalService.show(
      LifecycleModelAssetListComponent,
      {
        initialState: {
          modelId,
        },
        class: 'modal-xl',
      }
    );
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data
      .get('api/fastlc/lifecycle-models/from_site')
      .toPromise();
    if (this.sizeColumnsToFit) {
      this.lifecycleModelsGrid?.gridApi?.sizeColumnsToFit();
      this.sizeColumnsToFit = false;
    }
    this.lifecycleModelsGrid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  onFilterChanged(event: any): void {}

  onSelectionChanged(event: any): void {
    this.selectedLifecycleModelNodes = event;
  }

  onRowClicked(event: any): void {
    if (!event) {
      return;
    }
    this.showEditModal([new LifecycleModelModel(event)]);
  }

  updateSelectedLifecycleModelNodes(event: any): void {
    this.selectedLifecycleModelNodes = event;
  }

  resetModelsGrid(): void {
    this.lifecycleModelsGrid?.uncheckAll();
    this.conditionsListComponent?.resetModelConditionGrid();
    this.actionsListComponent?.resetModelActionGrid();
  }

  async showAddLifecycleModelModal(): Promise<void> {
    const orderByName = { orderBy: { name: 'asc' } };
    const criteria = {
      criteria: {
        account: [this.auth.getAccountId(), null],
      },
      ...orderByName,
    };
    this.sitesReferences = await this.data
      .by('api/sites', criteria)
      .toPromise();
    this.isReferenceLoaded = true;

    this.bsModalRef = this.bsModalService.show(LifecycleModelAddComponent, {
      initialState: {
        sitesReferences: this.sitesReferences,
      },
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = (res: LifecycleModelModel) => {
      this.items = syncItems(res, this.items);
      if (this.childGrid === 'action') {
        this.actionsListComponent?.loadItems();
      } else {
        this.conditionsListComponent?.loadItems();
      }
      this.toastr.showSuccess();
    };
  }

  duplicateModels(): void {
    this.isLoading = true;
    const ids = _.map(this.selectedLifecycleModelNodes, (el) => el.id);

    this.data.post('api/fastlc/lifecycle-models/duplicate-all', ids).subscribe(
      (res: LifecycleModelModel) => {
        this.items = syncItems(res, this.items);
        this.lifecycleModelsGrid?.uncheckAll();
        if (this.childGrid === 'action') {
          this.actionsListComponent?.loadItems();
        } else {
          this.conditionsListComponent?.loadItems();
        }
        this.isLoading = false;
        this.toastr.showSuccess();
      },
      (err: any) => {
        console.log('err: ', err);
        this.isLoading = false;
      }
    );
  }

  changeChildGrid(): void {
    this.childGrid = this.childGrid === 'action' ? 'condition' : 'action';
  }

  /**
   * @param items
   */
  async showEditModal(items?: LifecycleModelModel[]): Promise<void> {
    this.bsModalRef = this.bsModalService.show(LifecycleModelEditComponent, {
      initialState: {
        items,
      },
    });
    this.bsModalRef.content.confirmFn = (res: LifecycleModelModel) => {
      this.items = syncItems([res], this.items);
      this.toastr.showSuccess();
    };
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedLifecycleModelNodes, (el) => el.id);
      this.data
        .post('api/fastlc/lifecycle-models/delete-all-singly', ids)
        .subscribe(
          (res) => {
            this.items = this.items.filter(
              (item: any) => !ids.includes(`${item.id}`)
            );
            this.toastr.showSuccess('messages.delete');
            this.conditionsListComponent?.loadItems();
            this.actionsListComponent?.loadItems();
          },
          (err) => {
            this.toastr.showError();
          },
          () => {
            this.isLoading = false;
          }
        );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  myCellCallback = (params: any) => {
    return params.value;
  };
}
