<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div formArrayName="custom_fields">
        <div class="text-muted">Add Custom Headers</div>
        <div
          class="ml-2 mb-2"
          *ngFor="let custom_field of custom_fields.controls; let i = index"
        >
          <div class="row mb-1 align-items-center" [formGroupName]="i">
            <div class="col-5 d-flex align-items-center">
              <label
                class="col-form-label mr-2"
                [class.font-weight-bold]="
                  custom_field.get('description')?.dirty
                "
              >
                {{ custom_field.get("label")?.value }}
              </label>
              <app-field-clear-button
                (setEmptyParent)="setEmpty(i)"
              ></app-field-clear-button>
            </div>
            <div class="col-7">
              <input
                type="text"
                formControlName="description"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    showValidationError &&
                    custom_field.get('description')?.invalid
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
