<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="card form">
        <div class="card-body">
          <h6 class="card-title">{{ "assets.general" | translate }}</h6>
          <div class="form-row">
            <ng-container *ngIf="mode !== 'mass'">
              <div class="form-group col-6">
                <label [class.font-weight-bold]="form.get('name')?.dirty" for="name">
                  {{ "headers.asset.asset" | translate }}
                  <span class="text-danger">*</span>
                </label>
                <input [ngClass]="{
                    'is-invalid': isSubmitted && form.get('name')?.invalid
                  }" class="form-control form-control-sm" formControlName="name" id="name" type="text" />
              </div>
              <div class="form-group col-6">
                <label [class.font-weight-bold]="form.get('description')?.dirty" for="description">
                  {{ "headers.asset.description" | translate }}
                </label>
                <input class="form-control form-control-sm" formControlName="description" id="description"
                  type="text" />
              </div>
            </ng-container>

            <div class="form-group col-2">
              <label [class.font-weight-bold]="form.get('active')?.dirty" for="active">
                {{ "headers.asset.active" | translate }}
              </label>
              <ngx-select [items]="boolReference" [placeholder]="'(Select a Value)'" formControlName="active"
                id="active" optionTextField="name" optionValueField="value" size="small"></ngx-select>
            </div>

            <div class="form-group col-2">
              <label [class.font-weight-bold]="form.get('system')?.dirty">
                {{ "headers.asset.system" | translate }}
              </label>
              <ngx-select [items]="boolReference" [placeholder]="'(Select a Value)'" formControlName="system"
                optionTextField="name" optionValueField="value" size="small"></ngx-select>
            </div>

            <div class="form-group col-2">
              <label [class.font-weight-bold]="form.get('ranking')?.dirty">
                {{ "headers.asset.ranking" | translate }}
              </label>
              <input class="form-control form-control-sm" formControlName="ranking" type="number" />
            </div>

            <div class="form-group col-6">
              <label [class.font-weight-bold]="form.get('parent')?.dirty">
                {{ "headers.asset.parent" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'parent'"></app-field-clear-button>
              <ngx-select (typed)="onTyping($event)" [allowClear]="true" [items]="parentReference"
                [placeholder]="'(Search for a parent)'" optionTextField="name" formControlName="parent"
                size="small"></ngx-select>
            </div>

            <div class="form-group col-6">
              <label [class.font-weight-bold]="form.get('location')?.dirty">
                {{ "headers.location.location" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'location'"></app-field-clear-button>
              <ngx-select [allowClear]="true" [items]="locationReference" [placeholder]="'(Select a Location)'"
                formControlName="location" optionTextField="name" size="small"></ngx-select>
            </div>
            <div class="form-group col-6">
              <label [class.font-weight-bold]="form.get('accesses')?.dirty">
                {{
                "modules.admin.asset_parameters.children.access_requirements.name"
                | translate
                }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'accesses'"></app-field-clear-button>
              <ngx-select [allowClear]="true" [items]="accessReference" [placeholder]="'(Select Access Requirements)'"
                formControlName="accesses" id="accesses" [multiple]="true" optionTextField="description"
                optionValueField="id" size="small"></ngx-select>
            </div>
          </div>
        </div>
      </div>

      <div class="card form">
        <div class="card-body">
          <h6 class="card-title">{{ "assets.process" | translate }}</h6>
          <div class="form-row">
            <div class="form-group col-3">
              <label [class.font-weight-bold]="form.get('criticality')?.dirty">
                {{ "headers.criticality.criticality" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'criticality'"></app-field-clear-button>
              <ngx-select [allowClear]="true" [items]="criticalityReference" [placeholder]="'(Select a Criticality)'"
                formControlName="criticality" optionTextField="name" size="small"></ngx-select>
            </div>

            <div class="form-group col-3 type-select-container">
              <label [class.font-weight-bold]="form.get('type')?.dirty">
                {{ "headers.asset.eqtype" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'type'"></app-field-clear-button>
              <ngx-select (typed)="onEqTypeSearch($event)" [items]="eqtypeReference"
                [placeholder]="'(Select a Equipment Type)'" optionTextField="description" optionValueField="description"
                size="small" formControlName="type" (open)="doEqtypeSelectOpen()"></ngx-select>
            </div>

            <div class="form-group col-3">
              <label [class.font-weight-bold]="form.get('specifications')?.dirty">
                {{ "headers.asset.specifications" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'specifications'"></app-field-clear-button>
              <input class="form-control form-control-sm" formControlName="specifications" />
            </div>

            <div class="form-group col-3">
              <label [class.font-weight-bold]="form.get('drawing')?.dirty">
                {{ "headers.asset.drawing" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'drawing'"></app-field-clear-button>
              <input class="form-control form-control-sm" formControlName="drawing" />
            </div>
          </div>
        </div>
      </div>

      <div class="card form">
        <div class="card-body">
          <h6 class="card-title">
            {{ "modules.core.children.responsible_groups.name" | translate }}
          </h6>
          <div class="form-row">
            <div class="form-group col-3">
              <label [class.font-weight-bold]="form.get('unit')?.dirty">
                {{ "headers.responsible_group.type_1" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'unit'"></app-field-clear-button>
              <ngx-select [items]="respGroupUnitArr" [placeholder]="'(Select a Value)'" formControlName="unit"
                optionTextField="description" optionValueField="id" size="small"></ngx-select>
            </div>

            <div class="form-group col-3">
              <label [class.font-weight-bold]="form.get('planningGroup')?.dirty">
                {{ "headers.responsible_group.type_2" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'planningGroup'"></app-field-clear-button>
              <ngx-select [items]="respGroupPlanningGroupArr" [placeholder]="'(Select a Value)'"
                formControlName="planningGroup" optionTextField="description" optionValueField="id"
                size="small"></ngx-select>
            </div>

            <div class="form-group col-3">
              <label [class.font-weight-bold]="form.get('costCenter')?.dirty">
                {{ "headers.responsible_group.type_3" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'costCenter'"></app-field-clear-button>
              <ngx-select [items]="respGroupCostCenterArr" [placeholder]="'(Select a Value)'"
                formControlName="costCenter" optionTextField="description" optionValueField="id"
                size="small"></ngx-select>
            </div>
            <div class="form-group col-3">
              <label [class.font-weight-bold]="form.get('workCenter')?.dirty">
                {{ "headers.responsible_group.type_4" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'workCenter'"></app-field-clear-button>
              <ngx-select [items]="respGroupWorkCenterArr" [placeholder]="'(Select a Value)'"
                formControlName="workCenter" optionTextField="description" optionValueField="id"
                size="small"></ngx-select>
            </div>
          </div>
        </div>
      </div>

      <div class="card form">
        <div class="card-body">
          <h6 class="card-title">{{ "assets.equipment" | translate }}</h6>
          <div class="form-row">
            <div class="form-group col-3">
              <label [class.font-weight-bold]="form.get('eqsupplier')?.dirty">
                {{ "headers.asset.eqsupplier" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'eqsupplier'"></app-field-clear-button>
              <input class="form-control form-control-sm" formControlName="eqsupplier" />
            </div>

            <div class="form-group col-3">
              <label [class.font-weight-bold]="form.get('eqmake')?.dirty">
                {{ "headers.asset.eqmake" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'eqmake'"></app-field-clear-button>
              <input class="form-control form-control-sm" formControlName="eqmake" />
            </div>

            <div class="form-group col-3">
              <label [class.font-weight-bold]="form.get('eqmodel')?.dirty">
                {{ "headers.asset.eqmodel" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'eqmodel'"></app-field-clear-button>
              <input class="form-control form-control-sm" formControlName="eqmodel" />
            </div>
            <div class="form-group col-3">
              <label [class.font-weight-bold]="form.get('eqserial')?.dirty">
                {{ "headers.asset.eqserial" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'eqserial'"></app-field-clear-button>
              <input class="form-control form-control-sm" formControlName="eqserial" />
            </div>
          </div>
        </div>
      </div>

      <div class="card form">
        <div class="card-body">
          <h6 class="card-title">{{ "assets.custom_fields" | translate }}</h6>
          <div class="form-row">
            <div class="form-group col-4 custom1-select-container">
              <label [class.font-weight-bold]="form.get('custom1')?.dirty">
                {{ customHeaders.custom1 }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'custom1'"></app-field-clear-button>
              <ngx-select (typed)="onCustom1Search($event)" [items]="customField1"
                [placeholder]="'(Select Custom Field1)'" optionTextField="description" optionValueField="description"
                size="small" formControlName="custom1" (open)="doCustom1SelectOpen()"></ngx-select>
              <!-- <input
                class="form-control form-control-sm"
                formControlName="custom1"
              /> -->
            </div>

            <div class="form-group col-4 custom2-select-container">
              <label [class.font-weight-bold]="form.get('custom2')?.dirty">
                {{ customHeaders.custom2 }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'custom2'"></app-field-clear-button>
              <ngx-select (typed)="onCustom2Search($event)" [items]="customField2"
                [placeholder]="'(Select Custom Field2)'" optionTextField="description" optionValueField="description"
                size="small" formControlName="custom2" (open)="doCustom2SelectOpen()"></ngx-select>
            </div>

            <div class="form-group custom3-select-container col-4">
              <label [class.font-weight-bold]="form.get('custom3')?.dirty">
                {{ customHeaders.custom3 }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'custom3'"></app-field-clear-button>
              <ngx-select (typed)="onCustom3Search($event)" [items]="customField3"
                [placeholder]="'(Select Custom Field3)'" optionTextField="description" optionValueField="description"
                size="small" formControlName="custom3" (open)="doCustom3SelectOpen()"></ngx-select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-4 custom4-select-container">
              <label [class.font-weight-bold]="form.get('custom4')?.dirty">
                {{ customHeaders.custom4 }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'custom4'"></app-field-clear-button>
              <ngx-select (typed)="onCustom4Search($event)" [items]="customField4"
                [placeholder]="'(Select Custom Field4)'" optionTextField="description" optionValueField="description"
                size="small" formControlName="custom4" (open)="doCustom4SelectOpen()"></ngx-select>
              <!-- <input
                class="form-control form-control-sm"
                formControlName="custom1"
              /> -->
            </div>

            <div class="form-group col-4 custom5-select-container">
              <label [class.font-weight-bold]="form.get('custom5')?.dirty">
                {{ customHeaders.custom5 }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'custom5'"></app-field-clear-button>
              <ngx-select (typed)="onCustom5Search($event)" [items]="customField5"
                [placeholder]="'(Select Custom Field5)'" optionTextField="description" optionValueField="description"
                size="small" formControlName="custom5" (open)="doCustom5SelectOpen()"></ngx-select>
            </div>

            <div class="form-group custom6-select-container col-4">
              <label [class.font-weight-bold]="form.get('custom6')?.dirty">
                {{ customHeaders.custom6 }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'custom6'"></app-field-clear-button>
              <ngx-select (typed)="onCustom6Search($event)" [items]="customField6"
                [placeholder]="'(Select Custom Field6)'" optionTextField="description" optionValueField="description"
                size="small" formControlName="custom6" (open)="doCustom6SelectOpen()"></ngx-select>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button (click)="save()" [disabled]="
            (!form.pristine && !form.dirty && isSubmitted) || !form.valid
          " class="btn btn-sm btn-success">
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>