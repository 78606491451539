export class FastspSpareModel {

  model: number;
  description: string;
  cost: number;

  constructor(data: any) {
    this.model = data.model ? data.model : 0;
    this.description = data.description ? data.description : '';
    this.cost = data.cost ? data.cost : 0;
  }
}
