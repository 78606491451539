<div class="container-fluid">
    <div class="row">
      <div *ngFor="let item of modules" class="col-sm-6 col-md-4 col-lg-3">
        <div [routerLink]="item.link" class="card core d-flex justify-content-center mb-4 module">
          <div class="module d-flex align-items-center flex-column">
            <div class="title p-2">{{ item.name }}</div>
            <div class="p-2">Text & Description here</div>
          </div>
        </div>
      </div>
    </div>
</div>



