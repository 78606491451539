<ng-container *ngIf="!isLoading">
  <div class="modal-header">
    <h3 class="mb-0 font-weight-bold alert-heading">
      {{ assetData.asset.name }}
      {{
        assetData.asset.description ? " - " + assetData.asset.description : ""
      }}
    </h3>
  </div>

  <div class="modal-body">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-1" style="overflow: hidden; position: relative">
            <h3 style="float: left">Asset Detail</h3>
            <span
              class="m-3"
              style="position: absolute; bottom: -5px; font-weight: 600"
            >
              <a
                [queryParams]="{
                  asset_id: assetData.asset?.id
                }"
                [routerLink]="['/assets']"
                target="_blank"
              >
                {{ "assets.core" | translate }}
                <i class="las la-external-link-alt"></i>
              </a>
            </span>
          </div>
          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted">
              {{ "headers.asset.active" | translate }}:
            </span>
            <span
              *ngIf="assetData.asset.active === true"
              class="badge badge-lg badge-success"
            >
              {{ "navigations.yes" | translate }}</span
            >
            <span
              *ngIf="assetData.asset.active === false"
              class="badge badge-lg badge-danger"
            >
              {{ "navigations.no" | translate }}</span
            >
            &nbsp;
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ "headers.asset.system" | translate }}:
            </span>
            <span
              *ngIf="assetData.asset.system === true"
              class="badge badge-lg badge-success"
            >
              {{ "navigations.yes" | translate }}</span
            >
            <span
              *ngIf="assetData.asset.system === false"
              class="badge badge-lg badge-danger"
            >
              {{ "navigations.no" | translate }}</span
            >
            &nbsp;
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ "headers.asset.type" | translate }}:
            </span>
            <span class="font-weight-bold">{{ assetData.asset.type }}</span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ "headers.asset.specifications" | translate }}:
            </span>
            <span class="font-weight-bold">{{
              assetData.asset.specifications
            }}</span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ "headers.asset.eqmake" | translate }}:
            </span>
            <span class="font-weight-bold">{{ assetData.asset.eqmake }}</span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ "headers.asset.eqmodel" | translate }}:
            </span>
            <span class="font-weight-bold">{{ assetData.asset.eqmodel }}</span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ "headers.asset.eqserial" | translate }}:
            </span>
            <span class="font-weight-bold">{{ assetData.asset.eqserial }}</span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ "headers.asset.eqsupplier" | translate }}:
            </span>
            <span class="font-weight-bold">{{
              assetData.asset.eqsupplier
            }}</span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ customHeaders.custom1 }}:
            </span>
            <span class="font-weight-bold">{{ assetData.asset.custom1 }}</span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ customHeaders.custom2 }}:
            </span>
            <span class="font-weight-bold">{{ assetData.asset.custom2 }}</span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ customHeaders.custom3 }}:
            </span>
            <span class="font-weight-bold">{{ assetData.asset.custom3 }}</span>
          </div>
          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ customHeaders.custom4 }}:
            </span>
            <span class="font-weight-bold">{{ assetData.asset.custom4 }}</span>
          </div>
          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ customHeaders.custom5 }}:
            </span>
            <span class="font-weight-bold">{{ assetData.asset.custom5 }}</span>
          </div>
          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ customHeaders.custom6 }}:
            </span>
            <span class="font-weight-bold">{{ assetData.asset.custom6 }}</span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ "headers.asset.drawing" | translate }}:
            </span>
            <span class="font-weight-bold">{{ assetData.asset.drawing }}</span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ "headers.location.location" | translate }}:
            </span>
            <span class="font-weight-bold">
              <a
                *ngIf="
                  auth.hasAccess('location') &&
                  assetData.asset?.location !== undefined
                "
                [queryParams]="{
                  location_id: assetData.asset?.location?.id,
                  asset_id: assetData.asset?.id
                }"
                [routerLink]="['/map/locations']"
                target="_blank"
              >
                {{ assetData.asset?.location?.name }}
                <i class="las la-external-link-alt"></i>
              </a>
              <span
                *ngIf="
                  !auth.hasAccess('location') &&
                  assetData.asset?.location !== undefined
                "
              >
                {{ assetData.asset?.location?.name }}
              </span>
              &nbsp;
            </span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted">{{
              "headers.criticality.criticality" | translate
            }}</span>
            <span class="font-weight-bold">
              {{ assetData.asset?.criticality?.name }}
            </span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted">
              {{ "headers.responsible_group.type_1" | translate }}:
            </span>
            <span class="font-weight-bold">{{
              assetData.asset?.unitDesc
            }}</span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ "headers.responsible_group.type_2" | translate }}:
            </span>
            <span class="font-weight-bold">{{
              assetData.asset?.planningGroupDesc
            }}</span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ "headers.responsible_group.type_3" | translate }}:
            </span>
            <span class="font-weight-bold">{{
              assetData.asset?.costCenterDesc
            }}</span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ "headers.responsible_group.type_4" | translate }}:
            </span>
            <span class="font-weight-bold">{{
              assetData.asset?.workCenterDesc
            }}</span>
          </div>

          <div class="col-12 mt-3 mb-1 border-bottom">
            <h3>Hierarchy</h3>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted">
              {{ "assets.children" | translate }} ({{
                assetData.children.length
              }}):
            </span>
          </div>

          <div class="col-6 mb-1">
            <span class="font-size-smaller text-muted"
              >{{ "headers.asset.parent" | translate }}:
            </span>
            <span class="font-weight-bold">
              <a
                (click)="onAssetChange(assetData.asset?.parent?.id)"
                *ngIf="
                  assetData.asset?.parent &&
                  assetData.asset?.parent !== undefined
                "
                href="javascript:void(0)"
                >{{ assetData.asset.parent.name }} -
                {{ assetData.asset.parent.description }}
                <i class="las la-external-link-alt"></i> </a
              >&nbsp;
            </span>
          </div>

          <div
            *ngIf="assetData.children.length > 0"
            class="overflow-auto border rounded col-12"
            style="max-height: 100px"
          >
            <div *ngFor="let child of assetData.children">
              <a (click)="onAssetChange(child.id)" href="javascript:void(0)">
                {{ child.name }} - {{ child.description }}
              </a>
            </div>
          </div>

          <ng-container *ngIf="auth.hasAccess('fast_criticality')">
            <div class="col-12 p-0 mb-1">
              <app-asset-criticality
                [assetId]="assetId"
              ></app-asset-criticality>
            </div>
          </ng-container>
          <ng-container *ngIf="auth.hasAccess('fastpm')">
            <div
              class="col-12 mt-3 mb-1 border-bottom pt-3"
              style="overflow: hidden; position: relative"
            >
              <h3 style="float: left">
                {{ "assets.fast_pm" | translate }}
              </h3>
              <span
                class="m-3"
                style="position: absolute; bottom: -5px; font-weight: 600"
              >
                <a
                  [queryParams]="{ asset_id: assetData?.asset?.id }"
                  [routerLink]="['/fastpm/strategy']"
                  class="ml-1 font-weight-bold"
                  target="_blank"
                >
                  {{ "modules.fast_pm.children.strategy.name" | translate }}
                  <i class="las la-external-link-alt"></i>
                </a>
              </span>
            </div>

            <div class="col-6 mb-1">
              Tasks
              <span *ngIf="!tasksIsLoading">({{ tasks.length }})</span>
            </div>

            <div
              *ngIf="!tasksIsLoading && tasks.length > 0"
              class="col-12 mb-1"
            >
              <div class="d-flex flex-grow-1 pt-2 ag-grid-container">
                <app-grid
                  [autoGroupColumnDef]="tasksGridAutoGroupColumnDef"
                  [gridOptions]="tasksGridOptions"
                  [gridUid]="'asset_task_list'"
                  [rowData]="tasks"
                  class="ag-theme-alpine"
                  style="width: 100%; height: 300px; max-height: 300px"
                >
                </app-grid>
              </div>
            </div>
            <app-loader *ngIf="tasksIsLoading"></app-loader>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<app-loader *ngIf="isLoading"></app-loader>
