import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Amsyst';
  locale = 'en';

  constructor(
    public translate: TranslateService,
  ) {
    this.translate.setDefaultLang(this.locale);
    this.translate.use(this.locale);
  }
}
