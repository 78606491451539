<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="card form">
        <div class="card-body">
          <h6 class="card-title">General</h6>

          <div class="form-row">
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('description')?.dirty"
                for="description"
              >
                {{ "headers.fastpm.pm_model.description" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="description"
                id="description"
                type="text"
              />
            </div>
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('component')?.dirty"
                for="component"
              >
                {{ "headers.tasks_parameters.component.component" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="component"
                id="component"
                type="text"
              />
            </div>
            <div class="form-group col-4">
              <label
                [class.font-weight-bold]="form.get('keyword')?.dirty"
                for="keyword"
              >
                {{ "headers.fastpm.pm_model.keyword" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="keyword"
                id="keyword"
                type="text"
              />
            </div>
            <div class="form-group col-3">
              <label
                [class.font-weight-bold]="form.get('duration')?.dirty"
                for="duration"
              >
                {{ "headers.fastpm.pm_model.duration" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="duration"
                id="duration"
                type="number"
              />
            </div>
            <div class="form-group col-5">
              <label
                [class.font-weight-bold]="form.get('criteria')?.dirty"
                for="criteria"
              >
                {{ "headers.fastpm.pm_model.criteria" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="criteria"
                id="criteria"
                type="text"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('criticalities')?.dirty"
                for="tools"
              >
                {{ "headers.criticality.criticality" | translate }}
              </label>
              <app-field-clear-button
                *ngIf="criticalityReference.length > 0"
                [formGroupRef]="form"
                [fieldName]="'criticalities'"
              ></app-field-clear-button>

              <ng-select
                [items]="criticalityReference"
                [placeholder]="
                  criticalityReference.length > 0
                    ? '(Select a Value)'
                    : 'No available options'
                "
                [multiple]="true"
                [closeOnSelect]="false"
                bindLabel="name"
                bindValue="id"
                formControlName="criticalities"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  <input
                    id="item-{{ index }}"
                    type="checkbox"
                    [checked]="item$.selected"
                    class="mr-1"
                  />
                  {{ item.name }}
                </ng-template>
              </ng-select>
            </div>

            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('tools')?.dirty"
                for="tools"
              >
                {{ "headers.tasks_parameters.tool.tool" | translate }}
              </label>
              <app-field-clear-button
                *ngIf="toolingReference.length > 0"
                [formGroupRef]="form"
                [fieldName]="'tools'"
              ></app-field-clear-button>
              <ngx-select
                [autoClearSearch]="true"
                [disabled]="toolingReference.length === 0"
                [items]="toolingReference"
                [multiple]="true"
                [placeholder]="
                  toolingReference.length > 0
                    ? '(Select a Value)'
                    : 'No available options'
                "
                formControlName="tools"
                id="tools"
                optionTextField="name"
                optionValueField="id"
                size="small"
              ></ngx-select>
            </div>

            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('state')?.dirty"
                for="state"
              >
                {{ "headers.tasks_parameters.state.state" | translate }}
              </label>
              <app-field-clear-button
                *ngIf="stateReference.length > 0"
                [formGroupRef]="form"
                [fieldName]="'state'"
              ></app-field-clear-button>
              <ngx-select
                [disabled]="stateReference.length === 0"
                [items]="stateReference"
                [placeholder]="
                  stateReference.length > 0
                    ? '(Select a Value)'
                    : 'No available options'
                "
                formControlName="state"
                id="state"
                optionTextField="name"
                optionValueField="id"
                size="small"
              ></ngx-select>
            </div>

            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('frequency')?.dirty"
                for="frequency"
              >
                {{ "headers.tasks_parameters.frequency.frequency" | translate }}
              </label>
              <app-field-clear-button
                *ngIf="frequencyReference.length > 0"
                [formGroupRef]="form"
                [fieldName]="'frequency'"
              ></app-field-clear-button>
              <ngx-select
                [disabled]="frequencyReference.length === 0"
                [items]="frequencyReference"
                [placeholder]="
                  frequencyReference.length > 0
                    ? '(Select a Value)'
                    : 'No available options'
                "
                formControlName="frequency"
                id="frequency"
                optionTextField="name"
                optionValueField="id"
                size="small"
              ></ngx-select>
            </div>

            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('trade')?.dirty"
                for="trade"
              >
                {{ "headers.tasks_parameters.labor.labor" | translate }}
              </label>
              <app-field-clear-button
                *ngIf="tradeReference.length > 0"
                [formGroupRef]="form"
                [fieldName]="'trade'"
              ></app-field-clear-button>
              <ngx-select
                [disabled]="tradeReference.length === 0"
                [items]="tradeReference"
                [placeholder]="
                  tradeReference.length > 0
                    ? '(Select a Value)'
                    : 'No available options'
                "
                formControlName="trade"
                id="trade"
                optionTextField="name"
                optionValueField="id"
                size="small"
              ></ngx-select>
            </div>

            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('activity')?.dirty"
                for="activity"
              >
                {{ "headers.tasks_parameters.activity.activity" | translate }}
              </label>
              <app-field-clear-button
                *ngIf="activityReference.length > 0"
                [formGroupRef]="form"
                [fieldName]="'activity'"
              ></app-field-clear-button>
              <ngx-select
                [disabled]="activityReference.length === 0"
                [items]="activityReference"
                [placeholder]="
                  activityReference.length > 0
                    ? '(Select a Value)'
                    : 'No available options'
                "
                formControlName="activity"
                id="activity"
                optionTextField="name"
                optionValueField="id"
                size="small"
              ></ngx-select>
            </div>
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('procedure')?.dirty"
                for="procedure"
              >
                {{ "headers.fastpm.pm_model.proc" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="procedure"
                id="procedure"
                type="text"
              />
            </div>
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('comment')?.dirty"
                for="comment"
              >
                {{ "headers.fastpm.pm_model.comment" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="comment"
                id="comment"
                type="text"
              />
            </div>
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('justification')?.dirty"
                for="justification"
              >
                {{ "headers.fastpm.pm_model.justification" | translate }}
              </label>
              <input
                class="form-control form-control-sm"
                formControlName="justification"
                id="justification"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="
            (!form.pristine && !form.dirty && isSubmitted) || !form.valid
          "
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
