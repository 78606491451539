import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DataService, AuthService, ToasterService } from 'src/app/shared/services';
import { AssetModelModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { validateNumberInput } from 'src/utils';

@Component({
  selector: 'app-asset-model-edit',
  templateUrl: './edit.component.html'
})
export class AssetModelEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  // form instance
  form!: UntypedFormGroup;
  selectedLifecycleStrategyNodes: any[] = [];
  gradesReference: any[] = [];
  modelsReference: any[] = [];
  years: any[] = [];
  expectedLife: number | null = null;
  recommendedYears: number | null = null;
  yearsLabel: string | null = null;
  levelOptions: any[] = [];

  // Usage mode
  mode: ('mass' | 'single' | 'new') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;
  showValidationError = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   * @param toastr
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private toastr: ToasterService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {

    await this.loadModels();

    this.getYears();

    this.levelOptions = [
      { value: 1, name: 'Unsure' },
      { value: 2, name: 'Probable' },
      { value: 3, name: 'Certain' }
    ];

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.targetIds = _.map(this.items, el => el.id);

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.items = [new AssetModelModel({
        site: this.auth.getSiteId(),
      })];
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    if (!this.items[0].catExposure) {
      this.form.get('catExposure')?.setValue(this.years[1]?.value);
    }

    if (this.mode === 'single') {
      this.endOfLifeYear();
    }

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [AssetModelModel?]): ('mass' | 'single' | 'new') {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // More than one item => Mass Edit
    } else if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  // Load Types and Budgets used in modal edit form
  async loadModels(): Promise<void> {
    const orderByDesc = { orderBy: { description: 'asc' } };

    const gradesPromise = this.service.by('api/fastlc/grades', {
      criteria: {
        account: this.auth.getAccountId()
      },
      ...orderByDesc
    }).toPromise();

    const modelsPromise = this.service.by('api/fastlc/lifecycle-models', {
      criteria: {
        site: this.auth.getSiteId()
      },
      ...orderByDesc
    }).toPromise();

    try {
      const [grades, models] = await Promise.all([gradesPromise, modelsPromise]);

      this.gradesReference = _.map(grades, (el) => {
        return {
          name: el.description,
          value: el.id
        };
      });

      this.modelsReference = _.map(models, (el) => {
        return {
          name: el.description,
          value: el.id,
          expectedLife: el.expected_life,
          typeEolDesc: el.type_eol_desc
        };
      });
    } catch (error) {
      // Handle error if any of the promises reject
      console.error('Error fetching data:', error);
    }
  }

  /**
   *
   */
  getForm(item: AssetModelModel): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      site: new UntypedFormControl(this.auth.getSiteId()),
      catMaintCost: new UntypedFormControl(item.catMaintCost, [Validators.min(0)]),
      catDownCost: new UntypedFormControl(item.catDownCost, [Validators.min(0)]),
      catAddRiskCost: new UntypedFormControl(item.catAddRiskCost, [Validators.min(0)]),
      onGoingMaintCost: new UntypedFormControl(item.onGoingMaintCost, [Validators.min(0)]),
      onGoingDownCost: new UntypedFormControl(item.onGoingDownCost, [Validators.min(0)]),
      onGoingAddRiskCost: new UntypedFormControl(item.onGoingAddRiskCost, [Validators.min(0)]),
      assetERV: new UntypedFormControl(item.assetERV, [Validators.min(0)]),
      assetInstallDate: new UntypedFormControl(item.assetInstallDate),
      riskScenario: new UntypedFormControl(item.riskScenario),
      catExposure: new UntypedFormControl(item.catExposure),
      catConfidence: new UntypedFormControl(item.catConfidence),
      comment: new UntypedFormControl(item.comment),
      update: new UntypedFormControl(item.update),
      model: new UntypedFormControl({
        value: item.model.id,
        disabled: this.mode !== 'new'
      }, Validators.required),
    });
  }

  validateNumberInput(event: KeyboardEvent): void {
    validateNumberInput(event);
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => { };

  getYears(): void {
    const currentYear = new Date().getFullYear();

    for (let i = currentYear; i <= currentYear + 20; i++) {
      this.years.push({ name: i, value: i });
    }
  }

  endOfLifeYear(): void {
    if (this.mode === 'new') {

      const model = this.modelsReference.filter(val => val.value === this.form.value.model);
      this.expectedLife = model[0].expectedLife || null;
      this.form.get('riskScenario')?.setValue(model[0].typeEolDesc || '');

      if (this.expectedLife) {
        this.form.get('catExposure')?.setValue(new Date().getFullYear() + this.expectedLife);
      }

    } else {
      this.expectedLife = this.items[0].model?.expected_life;
    }

    if (this.expectedLife && this.form.value.assetInstallDate) {
      this.recommendedYears = new Date(this.form.value.assetInstallDate).getFullYear() + this.expectedLife;
    } else {
      this.recommendedYears = null;
    }

    if (this.recommendedYears && this.expectedLife) {
      this.yearsLabel = '(' + this.recommendedYears + ' ' +
        this.translate.instant('headers.fastlc.asset_model.recommended')
        + ', ' + this.translate.instant('headers.fastlc.asset_model.based')
        + ' ' + this.expectedLife + ' ' +
        this.translate.instant('headers.fastlc.asset_model.expected') + ')';
    } else if (this.expectedLife && !this.recommendedYears) {
      this.yearsLabel = '(' + this.expectedLife + ' ' +
        this.translate.instant('headers.fastlc.asset_model.expected') + ')';
    } else {
      this.yearsLabel = null;
    }
  }

  /**
   *
   */
  save(): boolean | void {
    this.showValidationError = true;
    if (!this.form || (this.form && !this.form.valid)) {
      return false;

    } else {

      // Query pointer
      let query;

      if (this.mode === 'new') {
        const assetModels: any = [];
        _.each(this.selectedLifecycleStrategyNodes, modelNode => {
          const assetModelData = _.pickBy(this.form.value, el => el !== null);
          assetModelData.asset = modelNode.data.id;
          assetModels.push(assetModelData);
        });
        query = this.service.saveAll('api/fastlc/asset-models', assetModels);
      } else if (this.mode === 'single') {
        query = this.service.update('api/fastlc/asset-models', this.targetIds[0], this.form.value);

      } else {
        const tmpData: any = {};
        _.each(this.form.controls, (control, name) => {
          if (control.dirty === true && control.value !== -1) {
            tmpData[name] = control.value;
          }
        });
        const data = _.map(this.targetIds, id => {
          const cloned = _.cloneDeep(tmpData);
          _.merge(cloned, { id });
          return cloned;
        });

        query = this.service.saveAll('api/fastlc/asset-models', data);
      }

      this.isSubmitted = true;
      query?.subscribe(res => {
        this.isSubmitted = false;
        this.confirmFn(res);
        this.bsModalRef.hide();
      }, err => {
        this.toastr.showError(`${err.error.message} </br> ${this.translate.instant('navigations.already_exist')}`);
        this.isSubmitted = false;
      });
    }
  }
}
