export class ConfFastspStatusModel {
  id: number;
  description: string;
  accountId: number;
  costImpact: number;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.description = data.description ? data.description : '';
    this.costImpact = data.costImpact ? data.costImpact : false;

    this.accountId = data.accountId ? data.accountId : null;
  }
}
