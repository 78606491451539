import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../shared';
import { DataService } from '../../../shared';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GridComponent } from '../../../shared';
import { LocationModel, TagModel } from '../../../shared/models';
import * as _ from 'lodash';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { SharedDataService } from 'src/app/shared';

@Component({
  selector: 'app-edit-tag',
  templateUrl: './edit.tag.component.html',
})
export class LocationEditTagComponent implements OnInit {
  @ViewChild(GridComponent, { static: true }) assetGrid?: GridComponent;

  // Modal param
  location?: LocationModel;

  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];

  // UI flag
  isLoading = false;
  isSubmitted = false;

  // Local grid options
  gridOptions?: GridOptions;

  // Filters options
  filters = [
    { label: this.translate.instant('asset.all'), value: 1 },
    { label: this.translate.instant('locations.none'), value: 2 },
  ];

  currentFilter?: any;
  type: 'asset' | 'tag' = 'asset';

  // form instance
  form!: UntypedFormGroup;

  customHeaders: any;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private sharedData: SharedDataService,
    public auth: AuthService,
    public router: Router,
    public bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    this.customHeaders = await this.sharedData.getAssetCustomHeaders(
      this.auth.getAccountId()
    );
    this.filters.push({
      label:
        this.translate.instant('navigations.location.in_location') +
        '"' +
        this.location?.name +
        '"',
      value: 3,
    });
    this.filters.push({
      label:
        this.translate.instant('navigations.location.not_in_location') +
        ' "' +
        this.location?.name +
        '"',
      value: 4,
    });

    // Set current to all assets
    this.currentFilter = this.filters[0];

    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      rowSelection: 'single',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.asset.asset'),
          field: 'name',
          checkboxSelection: true,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant('headers.asset.description'),
          field: 'description',
        },
        {
          headerName: this.translate.instant('headers.asset.type'),
          field: 'type',
        },
        {
          headerName: this.translate.instant('headers.asset.active'),
          field: 'active',
          cellRenderer: (params: any): string => {
            if (params.value === true) {
              return (
                '<span class="badge badge-lg badge-success">' +
                this.translate.instant('navigations.yes') +
                '</span>'
              );
            } else {
              return (
                '<span class="badge badge-lg badge-danger">' +
                this.translate.instant('navigations.no') +
                '</span>'
              );
            }
          },
        },
        {
          headerName: this.translate.instant('headers.asset.ranking'),
          field: 'ranking',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.system'),
          field: 'system',
          cellRenderer: (params: any): string => {
            if (params.value === true) {
              return (
                '<span class="badge badge-lg badge-success">' +
                this.translate.instant('navigations.yes') +
                '</span>'
              );
            } else {
              return (
                '<span class="badge badge-lg badge-danger">' +
                this.translate.instant('navigations.no') +
                '</span>'
              );
            }
          },
        },
        {
          headerName: this.translate.instant('headers.asset.parent'),
          field: 'parent',
        },
        {
          headerName: this.translate.instant('headers.location.location'),
          field: 'location',
        },
        {
          headerName: this.translate.instant('headers.location.id'),
          field: 'locationId',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.criticality.criticality'),
          field: 'criticality',
        },
        {
          headerName: this.translate.instant('headers.asset.parentId'),
          field: 'parentId',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.specifications'),
          field: 'specifications',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.drawing'),
          field: 'drawing',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom1,
          field: 'custom1',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom2,
          field: 'custom2',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom3,
          field: 'custom3',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom4,
          field: 'custom4',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom5,
          field: 'custom5',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom6,
          field: 'custom6',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmake'),
          field: 'eqmake',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmodel'),
          field: 'eqmodel',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqserial'),
          field: 'eqserial',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqsupplier'),
          field: 'eqsupplier',
          hide: true,
        },
      ],
    };
    await this.loadItems();

    this.form = this.getForm(new TagModel({}));
  }

  /**
   * @param type
   */
  tabChange(type: 'asset' | 'tag'): void {
    this.type = type;
  }

  /**
   *
   */
  getForm(item: TagModel): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      title: new UntypedFormControl(item.title),
      location: new UntypedFormControl(this.location?.id),
      position_x: new UntypedFormControl(item.positionX),
      position_y: new UntypedFormControl(item.positionY),
    });
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data
      .post('api/assets/from_site_all', {
        criteria: {
          syst: 0,
          active: 1,
        },
      })
      .toPromise();
    this.assetGrid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  onFilterChanged(event: any): void {}

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onRowClicked(event: any): void {
    // this.showEditModal([new AssetModel(event)]);
  }

  gridReadyCallBack = () => {
    // this.assetGrid?.gridApi?.sizeColumnsToFit();
  };

  myCellCallback = (params: any) => {
    return params.value;
  };

  confirmFn = (type: string, item: any) => {};

  /**
   * Set Current Location filter
   * @param filter
   */
  setFilter(filter: any): void {
    this.currentFilter = filter;

    const instance = this.assetGrid?.gridApi?.getFilterInstance('locationId');

    // All Assets
    if (this.currentFilter.value === 1) {
      this.assetGrid?.gridApi?.destroyFilter('locationId');

      // Assets with no locations
    } else if (this.currentFilter.value === 2) {
      instance?.setModel({
        values: [0 + ''],
      });

      // Assets with current location
    } else if (this.currentFilter.value === 3) {
      instance?.setModel({
        values: [this.location?.id + ''],
      });

      // Assets with location different from current
    } else if (this.currentFilter.value === 4) {
      let locationsIds: string[] = [];
      this.assetGrid?.gridApi?.forEachNode((el) => {
        if (el.data.locationId !== 0) {
          locationsIds.push(el.data.locationId + '');
        }
      });
      locationsIds = _.uniq(locationsIds);
      instance?.setModel({
        values: locationsIds,
      });
    }
    this.assetGrid?.gridApi?.onFilterChanged();
  }

  save(): void {
    this.isSubmitted = true;
    let data;
    if (this.type === 'asset' && this.selectedNodes.length === 1) {
      data = this.selectedNodes[0];
    } else if (this.type === 'tag') {
      data = this.form.value;
    }
    if (data) {
      this.confirmFn(this.type, data);
    }
    this.isSubmitted = false;
  }
}
