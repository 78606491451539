import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  DataService,
  AuthService,
  ToasterService,
} from 'src/app/shared/services';
import { AssetActionModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { doSelectOpen, onSearch, validateNumberInput } from 'src/utils';

@Component({
  selector: 'app-asset-action-edit',
  templateUrl: './edit.component.html',
})
export class AssetActionEditComponent implements OnInit, OnDestroy {
  // Modal parameters
  items: any; // array of items to edit
  // form instance
  form!: UntypedFormGroup;
  selectedAssetModelNodes: any[] = [];
  typesReference: any[] = [];
  budgetsReference: any[] = [];
  statusesReference: any[] = [];
  actionsReference: any[] = [];
  years: any[] = [];

  // Usage mode
  mode: 'mass' | 'single' | 'new' = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;
  showValidationError = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   * @param toastr
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private toastr: ToasterService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    await this.loadTypesBudgetsAndStatuses();

    this.getYears();

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.targetIds = _.map(this.items, (el) => el.id);

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.items = [
        new AssetActionModel({
          site: this.auth.getSiteId(),
        }),
      ];
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    if (!this.items[0].status?.id && this.mode !== 'mass') {
      this.form.get('status')?.setValue(this.statusesReference[0].value);
    }

    if (!this.items[0].plan) {
      this.form.get('plan')?.setValue(this.years[0]?.value);
    }

    const matchedActionsReference = this.actionsReference.find(
      (value: any) => value.description === this.items[0].description
    );
    if (!matchedActionsReference && this.items[0].description) {
      this.actionsReference.unshift({
        id: 0,
        description: this.items[0].description,
      });
    }

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [AssetActionModel?]): 'mass' | 'single' | 'new' {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // More than one item => Mass Edit
    } else if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  // Load Types, Budgets, Statuses used in modal edit form
  async loadTypesBudgetsAndStatuses(): Promise<void> {
    const ids = _.map(
      this.selectedAssetModelNodes,
      (el) => el?.data?.model?.id
    );

    const orderByDesc = { orderBy: { description: 'asc' } };

    let actionPromise = null;

    if (ids.length > 0) {
      actionPromise = this.service
        .post('api/fastlc/model-actions/find-by-ids', {
          site: this.auth.getSiteId(),
          ids,
        })
        .toPromise();
    }

    const typesPromise = this.service
      .by('api/fastlc/action-types', {
        criteria: {
          account: this.auth.getAccountId(),
        },
        ...orderByDesc,
      })
      .toPromise();

    const statusesPromise = this.service
      .by('api/fastlc/action-statuses', {
        criteria: {
          account: this.auth.getAccountId(),
        },
        ...orderByDesc,
      })
      .toPromise();

    const budgetsPromise = this.service
      .by('api/fastlc/action-budgets', {
        criteria: {
          account: this.auth.getAccountId(),
        },
        ...orderByDesc,
      })
      .toPromise();

    try {
      const [types, statuses, budgets, actions] = await Promise.all([
        typesPromise,
        statusesPromise,
        budgetsPromise,
        actionPromise,
      ]);

      this.statusesReference = _.map(statuses, (el) => {
        return {
          name: el.description,
          value: el.id,
        };
      });

      this.budgetsReference = _.map(budgets, (el) => {
        return {
          name: el.description,
          value: el.id,
        };
      });

      this.typesReference = _.map(types, (el) => {
        return {
          name: el.description,
          value: el.id,
        };
      });

      this.actionsReference = _.map(actions, (el) => {
        return {
          description: el.description,
          value: el.id,
          cost: el.cost,
          type: el.type ? el.type : null,
          budget: el.budget ? el.budget : null,
        };
      });
    } catch (error) {
      // Handle error if any of the promises reject
      console.error('Error fetching data:', error);
    }
  }

  onChange(): void {
    if (this.mode !== 'new') {
      return;
    }
    const selectedAction = this.actionsReference.filter(
      (value) => value.description === this.form.value.description
    );

    this.form.get('cost')?.setValue(selectedAction[0]?.cost);
    this.form
      .get('type')
      ?.setValue(
        selectedAction[0]?.type ? Number(selectedAction[0].type) : null
      );
    this.form
      .get('budget')
      ?.setValue(
        selectedAction[0]?.budget ? Number(selectedAction[0].budget) : null
      );
  }

  getForm(item: AssetActionModel): UntypedFormGroup {
    let validators: any = [];
    let disable = false;
    if (this.mode !== 'mass') {
      validators = [Validators.required];
    }

    if (item.status && item.status.closed) {
      disable = true;
    }

    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      site: new UntypedFormControl(this.auth.getSiteId()),

      cost: new UntypedFormControl({ value: item.cost, disabled: disable }, [
        Validators.min(0),
      ]),
      description: new UntypedFormControl(
        {
          value: item.description,
          disabled: disable,
        },
        validators
      ),
      savings: new UntypedFormControl(
        {
          value: this.mode === 'mass' ? null : item.savings,
          disabled: disable,
        },
        [Validators.min(0)]
      ),
      plan: new UntypedFormControl(
        {
          value: this.mode === 'mass' ? null : item.plan,
          disabled: disable,
        },
        validators
      ),
      cec_reduction: new UntypedFormControl(
        {
          value: this.mode === 'mass' ? null : item.cec_reduction,
          disabled: disable,
        },
        [Validators.min(0), Validators.max(100)]
      ),
      ogc_reduction: new UntypedFormControl(
        {
          value: this.mode === 'mass' ? null : item.ogc_reduction,
          disabled: disable,
        },
        [Validators.min(0), Validators.max(100)]
      ),
      comment: new UntypedFormControl({
        value: item.comment,
        disabled: disable,
      }),
      type: new UntypedFormControl(
        {
          value: item.type ? item.type.id : null,
          disabled: disable,
        },
        validators
      ),
      budget: new UntypedFormControl(
        {
          value: item.budget ? item.budget.id : null,
          disabled: disable,
        },
        validators
      ),
      status: new UntypedFormControl(
        item.status ? item.status.id : null,
        validators
      ),
    });
  }

  validateNumberInput(event: KeyboardEvent): void {
    validateNumberInput(event);
  }

  doActionSelectOpen(): void {
    doSelectOpen(this.form, 'description');
  }

  /**
   *
   * @param event
   */
  onActionSearch(event: any): void {
    onSearch(event, this.actionsReference, this.form, 'description');
  }

  getYears(): void {
    const currentYear = new Date().getFullYear();

    for (let i = currentYear; i <= currentYear + 20; i++) {
      this.years.push({ name: i, value: i });
    }
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => {};

  /**
   *
   */
  save(): boolean | void {
    this.showValidationError = true;
    if (!this.form || (this.form && !this.form.valid)) {
      return false;
    } else {
      // Query pointer
      let query;

      if (this.mode === 'new') {
        const assetActions: any = [];
        _.each(this.selectedAssetModelNodes, (modelNode) => {
          const assetActionData = _.pickBy(
            this.form.value,
            (el) => el !== null
          );
          assetActionData.assetModel = modelNode.data.id;
          assetActionData.asset = modelNode.data.asset.id;
          assetActions.push(assetActionData);
        });
        query = this.service.saveAll('api/fastlc/asset-actions', assetActions);
      } else if (this.mode === 'single') {
        query = this.service.update(
          'api/fastlc/asset-actions',
          this.targetIds[0],
          this.form.value
        );
      } else {
        const tmpData: any = {};
        _.each(this.form.controls, (control, name) => {
          if (control.dirty === true && control.value !== -1) {
            tmpData[name] = control.value;
          }
        });
        const data = _.map(this.targetIds, (id) => {
          const cloned = _.cloneDeep(tmpData);
          _.merge(cloned, { id });
          return cloned;
        });

        query = this.service.saveAll('api/fastlc/asset-actions', data);
      }

      this.isSubmitted = true;
      query?.subscribe(
        (res) => {
          this.isSubmitted = false;
          this.confirmFn(res);
          this.bsModalRef.hide();
        },
        (err) => {
          this.toastr.showError(
            `${err.error.message} </br> ${this.translate.instant(
              'navigations.already_exist'
            )}`
          );
          this.isSubmitted = false;
        }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.actionsReference[0]?.id === 0) {
      this.actionsReference.shift();
    }
  }
}
