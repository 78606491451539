export class ComponentTaskModel {
  id: number;
  description: string;
  keyword: string;
  criteria: string;
  duration: number;
  group: string;

  state: string;
  stateId: number;
  frequency: string;
  frequencyId: number;
  trade: string;
  tradeId: number;
  activity: string;
  activityId: number;
  tools: string;
  toolIds: number[];
  model: string;
  modelId: number;
  component: string;
  componentId: number;
  procedure: string;
  comment: string;
  justification: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;

    this.description = data.description ? data.description : '';
    this.keyword = data.keyword ? data.keyword : '';
    this.criteria = data.criteria ? data.criteria : '';
    this.duration = data.duration ? parseFloat(data.duration) : 0;
    this.group = data.group ? data.group : '';

    this.state = data.state ? data.state : null;
    this.stateId = data.stateId ? data.stateId : null;
    this.frequency = data.frequency ? data.frequency : null;
    this.frequencyId = data.frequencyId ? data.frequencyId : null;
    this.trade = data.trade ? data.trade : null;
    this.tradeId = data.tradeId ? data.tradeId : null;
    this.activity = data.activity ? data.activity : null;
    this.activityId = data.activityId ? data.activityId : null;
    this.tools = data.tools ? data.tools : '';
    this.toolIds = data.toolIds ? data.toolIds : [];

    this.model = data.model ? data.model : '';
    this.modelId = data.modelId ? data.modelId : null;
    this.component = data.component ? data.component : '';
    this.componentId = data.componentId ? data.componentId : null;
    this.procedure = data.procedure ? data.procedure : '';
    this.comment = data.comment ? data.comment : '';
    this.justification = data.justification ? data.justification : '';
  }
}
