<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('condition')?.dirty"
            for="type"
          >
            {{ "headers.fastlc.asset_conditions.condition" | translate }}
            <span *ngIf="mode !== 'mass'" class="text-danger">*</span>
          </label>
          <ngx-select
            (select)="onChange()"
            [items]="conditionsReference"
            [placeholder]="'(Select a Condition)'"
            formControlName="condition"
            id="condition"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('condition')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group value-select-container col-12">
          <label [class.font-weight-bold]="form.get('value')?.dirty">
            {{ "headers.fastlc.asset_conditions.value" | translate }}
            <span *ngIf="mode !== 'mass'" class="text-danger">*</span>
          </label>
          <ngx-select
            (typed)="onValueSearch($event)"
            [items]="valueField"
            [placeholder]="'(Select Value)'"
            optionTextField="description"
            optionValueField="description"
            size="small"
            formControlName="value"
            (open)="doValueSelectOpen()"
          ></ngx-select>
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('value')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label [class.font-weight-bold]="form.get('date')?.dirty" for="date">
            {{ "headers.fastlc.asset_conditions.date" | translate }}
            <span *ngIf="mode !== 'mass'" class="text-danger">*</span>
          </label>
          <input
            [ngClass]="{
              'is-invalid': showValidationError && form.get('date')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="date"
            id="date"
            type="date"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('date')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('comment')?.dirty"
            for="comment"
          >
            {{ "headers.fastlc.asset_conditions.comment" | translate }}
          </label>
          <input
            [ngClass]="{
              'is-invalid': showValidationError && form.get('comment')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="comment"
            id="comment"
            type="text"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('comment')?.errors?.min"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
