import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DataService, AuthService } from 'src/app/shared/services';
import { ModelTaskModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-model-task-edit',
  templateUrl: './edit.component.html'
})
export class ModelTaskEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  toolingReference: any[] = [];
  stateReference: any[] = [];
  tradeReference: any[] = [];
  activityReference: any[] = [];
  frequencyReference: any[] = [];
  criticalityReference: any[] = [];
  models: any[] = [];

  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: ('mass' | 'single' | 'new') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.targetIds = _.map(this.items, el => el.id);

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.items = [new ModelTaskModel({
        account: this.auth.getAccountId(),
        siteId: this.auth.getSiteId()
      })];
    }

    // Push current Parent to init parentReference and have default selection
    if (['single'].indexOf(this.mode) > -1) {
      this.parentReference = _.map(this.items, el => {
        return {
          id: el.parentId, name: el.parent
        };
      }).filter(el => el !== null);
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [ModelTaskModel?]): ('mass' | 'single' | 'new') {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // More than one item => Mass Edit
    } else if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  /**
   *
   */
  getForm(item: ModelTaskModel): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      component: new UntypedFormControl(item.component),
      description: new UntypedFormControl(item.description),
      keyword: new UntypedFormControl(item.keyword),
      duration: new UntypedFormControl(item.duration),
      criteria: new UntypedFormControl(item.criteria),
      tools: new UntypedFormControl(item.toolIds),
      state: new UntypedFormControl(item.stateId),
      frequency: new UntypedFormControl(item.frequencyId),
      trade: new UntypedFormControl(item.tradeId),
      activity: new UntypedFormControl(item.activityId),
      criticalities: new UntypedFormControl(item.criticalityIds),
      procedure: new UntypedFormControl(item.procedure),
      comment: new UntypedFormControl(item.comment),
      justification: new UntypedFormControl(item.justification),
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => { };

  /**
   *
   */
  save(): boolean | void {
    if (!this.form || (this.form && !this.form.valid)) {
      return false;

    } else {

      // Query pointer
      let query;

      if (this.mode === 'new') {
        const tmpData = _.pickBy(this.form.value, el => el !== null);
        const data: any[] = [];
        _.each(this.models, (modelId) => {
          const tmp = _.cloneDeep(tmpData);
          _.set(tmp, 'model', modelId);
          data.push(tmp);
        });
        query = this.service.saveAll('api/fastpm/modelTasks', data);

      } else if (this.mode === 'single') {
        query = this.service.update('api/fastpm/modelTasks', this.targetIds[0], this.form.value);

      } else {
        const tmpData: any = {};
        _.each(this.form.controls, (control, name) => {
          if (control.dirty === true && control.value !== -1) {
            tmpData[name] = control.value;
          }
        });
        const data = _.map(this.targetIds, id => {
          const cloned = _.cloneDeep(tmpData);
          _.merge(cloned, { id });
          return cloned;
        });
        const retainFields = ['tools'];
        query = this.service.saveAllRetain('api/fastpm/modelTasks', data, retainFields);
      }

      this.isSubmitted = true;
      query.subscribe((res: any) => {
        this.isSubmitted = false;
        this.confirmFn(res);
        this.bsModalRef.hide();
      }, (err: any) => {
        console.log('err: ', err);
        this.isSubmitted = false;
      });
    }
  }
}
