export class AccountRoleModel {
  id: number;
  name: string;
  asset: number;
  fastpm: number;
  location: number;
  routing: number;
  fastCriticality: number;
  fastsp: number;
  fastlc: number;
  accountId: number;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.name = data.name ? data.name : '';

    this.asset = data.asset ? data.asset : null;
    this.fastpm = data.fastpm ? data.fastpm : null;
    this.location = data.location ? data.location : null;
    this.routing = data.routing ? data.routing : null;
    this.fastsp = data.fastsp ? data.fastsp : null;
    this.fastlc = data.fastlc ? data.fastlc : null;
    this.fastCriticality = data.fastCriticality ? data.fastCriticality : null;

    this.accountId = data.accountId ? data.accountId : null;
  }
}
