import { Component, Output, EventEmitter, Input } from '@angular/core';


@Component({
  selector: 'app-grid-displayer',
  templateUrl: './grid-displayer.component.html'
})
export class GridDisplayerComponent {

  @Input() isExpanded = false;
  @Output() arrowClicked = new EventEmitter(false);

  constructor() { }

}
