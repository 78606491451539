import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { DataService, AuthService } from 'src/app/shared/services';
import { StateModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-state-edit',
  templateUrl: './edit.component.html',
})
export class StateEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit

  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: 'mass' | 'single' | 'new' = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.targetIds = _.map(this.items, (el) => el.id);

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.items = [
        new StateModel({
          account: this.auth.getAccountId(),
          siteId: this.auth.getSiteId(),
        }),
      ];
    }

    // Push current Parent to init parentReference and have default selection
    if (['single'].indexOf(this.mode) > -1) {
      this.parentReference = _.map(this.items, (el) => {
        return {
          id: el.parentId,
          name: el.parent,
        };
      }).filter((el) => el !== null);
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [StateModel?]): 'mass' | 'single' | 'new' {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  /**
   *
   */
  getForm(item: StateModel): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      name: new UntypedFormControl(item.name),
      account: new UntypedFormControl(this.auth.getAccountId()),
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => {};

  /**
   *
   */
  save(): boolean | void {
    if (!this.form || (this.form && !this.form.valid)) {
      return false;
    } else {
      // Query pointer
      let query;

      if (this.mode === 'new') {
        const tmpData = _.pickBy(this.form.value, (el) => el !== null);
        query = this.service.post('api/config/states', tmpData);
      } else if (this.mode === 'single') {
        query = this.service.update(
          'api/config/states',
          this.targetIds[0],
          this.form.value
        );
      }

      this.isSubmitted = true;
      query?.subscribe(
        (res) => {
          this.isSubmitted = false;
          this.confirmFn(res);
          this.bsModalRef.hide();
        },
        (err) => {
          console.log('err: ', err);
          this.isSubmitted = false;
        }
      );
    }
  }
}
