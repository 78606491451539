export class GradeModel {
  id: number;
  description: string;
  isDefault: boolean;
  account: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.description = data.description ? data.description : '';
    this.isDefault = data.is_default ? data.is_default : false;
    this.account = data.account ? data.account : null;
  }
}
