import { AfterViewInit, Component, OnInit, ViewChildren } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { DataService, AuthService } from 'src/app/shared/services';
import { ModelTaskModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid-community';
import { GridComponent } from 'src/app/shared/grid/grid.component';

@Component({
  selector: 'app-model-task-add',
  templateUrl: './add.component.html',
})
export class ModelTaskAddComponent implements OnInit, AfterViewInit {
  @ViewChildren(GridComponent) grids?: GridComponent;
  modelTasksGrid?: any;
  componentTasksGrid?: any;

  // Modal parameters
  items: any; // array of items to edit
  toolingReference: any[] = [];
  stateReference: any[] = [];
  tradeReference: any[] = [];
  activityReference: any[] = [];
  frequencyReference: any[] = [];
  sitesReferences: any[] = [];
  criticalityReference: any[] = [];
  models: any[] = [];
  currentSite: any;

  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: 'new' | 'model' | 'component' = 'new';

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;

  modelTasksGridOptions?: GridOptions;
  modelTasksGridAutoGroupColumnDef?: any;
  modelTasks: any[] = [];
  modelTasksSelectedNodes: any[] = [];
  modelTasksIsLoading = false;

  componentTasksGridOptions?: GridOptions;
  componentTasksGridAutoGroupColumnDef?: any;
  componentTasks: any[] = [];
  componentTasksSelectedNodes: any[] = [];
  componentTasksIsLoading = false;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService
  ) {
    this.isLoading = true;
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    this.modelTasksGrid = this.grids?._results[0];
    // @ts-ignore
    this.componentTasksGrid = this.grids?._results[1];
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    // Set current site
    const siteIndex = _.findIndex(this.sitesReferences, {
      id: this.auth.getSiteId(),
    });
    this.setSite(this.sitesReferences[siteIndex]);

    this.modelTasksGridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.model'),
          field: 'model',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.component.component'
          ),
          field: 'component',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.description'
          ),
          field: 'description',
          filter: 'agTextColumnFilter',
          minWidth: 150,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.state'
          ),
          field: 'state',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.frequency'
          ),
          field: 'frequency',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.labor'
          ),
          field: 'trade',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.activity.activity'
          ),
          field: 'activity',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.tool.tool'
          ),
          field: 'tools',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.duration'
          ),
          field: 'duration',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.criteria'
          ),
          field: 'criteria',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.keyword'),
          field: 'keyword',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.proc'),
          field: 'procedure',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.comment'),
          field: 'comment',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.justification'
          ),
          field: 'justification',
        },
        {
          headerName: this.translate.instant('headers.routing.task.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.id'
          ),
          field: 'stateId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.id'
          ),
          field: 'frequencyId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.id'
          ),
          field: 'tradeId',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.id'),
          field: 'modelId',
          hide: true,
        },
      ],
    };

    this.modelTasksGridAutoGroupColumnDef = {
      headerName: this.translate.instant(
        'headers.tasks_parameters.component.component'
      ),
      field: 'component',
      filter: 'agTextColumnFilter',
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    // Add Criticalities Column
    _.each(this.criticalityReference, (el: any) => {
      this.modelTasksGridOptions?.columnDefs?.push({
        headerName: el.name,
        field: 'crit_' + el.id,
        cellRenderer: (params: any): string => {
          if (params.value === 1) {
            return '<div class="d-flex justify-content-center"><i class="la la-check-square la-2x text-success"></i></div>';
          } else {
            return '<div class="d-flex justify-content-center"><i class="la la-check-square la-2x text-light"></i></div>';
          }
        },
        width: 100,
      });
    });

    this.componentTasksGridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.component.component'
          ),
          field: 'component',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.description'
          ),
          field: 'description',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.state'
          ),
          field: 'state',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.frequency'
          ),
          field: 'frequency',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.labor'
          ),
          field: 'trade',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.activity.activity'
          ),
          field: 'activity',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.tool.tool'
          ),
          field: 'tools',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.duration'
          ),
          field: 'duration',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.state'
          ),
          field: 'state',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.criteria'
          ),
          field: 'criteria',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.keyword'),
          field: 'keyword',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.proc'),
          field: 'procedure',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.comment'),
          field: 'comment',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.justification'
          ),
          field: 'justification',
        },
        {
          headerName: this.translate.instant('headers.routing.task.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.id'
          ),
          field: 'stateId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.id'
          ),
          field: 'frequencyId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.id'
          ),
          field: 'tradeId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.component.id'
          ),
          field: 'componentId',
          hide: true,
        },
      ],
    };
    this.componentTasksGridAutoGroupColumnDef = {
      headerName: this.translate.instant(
        'headers.tasks_parameters.component.component'
      ),
      field: 'component',
      filter: 'agTextColumnFilter',
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    // Build for
    this.form = this.getForm(new ModelTaskModel({}));

    // Loading done !
    this.isLoading = false;
  }

  /**
   * @param site
   */
  setSite(site: any): void {
    this.currentSite = site;
    this.modelTasksLoadItems(site);
  }

  async modelTasksLoadItems(site: any, silent = false): Promise<void> {
    if (!silent) {
      this.modelTasksIsLoading = true;
    }
    this.modelTasks = await this.service
      .get(
        'api/fastpm/modelTasks/site/' + site.id + '/' + this.auth.getAccountId()
      )
      .toPromise();
    this.modelTasksGrid?.uncheckAll();
    if (!silent) {
      this.modelTasksIsLoading = false;
    }
  }

  async componentTasksLoadItems(silent = false): Promise<void> {
    if (!silent) {
      this.componentTasksIsLoading = true;
    }
    this.componentTasks = await this.service
      .get('api/fastpm/componentTasks/account/' + this.auth.getAccountId())
      .toPromise();
    this.componentTasksGrid?.uncheckAll();
    if (!silent) {
      this.componentTasksIsLoading = false;
    }
  }

  modelTasksOnSelectionChanged(event: any): void {
    this.modelTasksSelectedNodes = event;
  }

  componentTasksOnSelectionChanged(event: any): void {
    this.componentTasksSelectedNodes = event;
  }

  /**
   *
   */
  getForm(item: ModelTaskModel): UntypedFormGroup {
    return this.formBuilder.group({
      component: new UntypedFormControl(item.component),
      description: new UntypedFormControl(item.description),
      keyword: new UntypedFormControl(item.keyword),
      duration: new UntypedFormControl(item.duration),
      criteria: new UntypedFormControl(item.criteria),
      tools: new UntypedFormControl(item.toolIds),
      state: new UntypedFormControl(item.stateId),
      frequency: new UntypedFormControl(item.frequencyId),
      trade: new UntypedFormControl(item.tradeId),
      activity: new UntypedFormControl(item.activityId),
      procedure: new UntypedFormControl(item.procedure),
      comment: new UntypedFormControl(item.comment),
      justification: new UntypedFormControl(item.justification),
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => {};

  tabChange(mode: 'new' | 'model' | 'component'): void {
    this.mode = mode;
    if (this.mode === 'model' && this.modelTasks.length === 0) {
      this.setSite(this.sitesReferences[0]);
    } else if (this.mode === 'component' && this.componentTasks.length === 0) {
      this.componentTasksLoadItems();
    }
  }

  /**
   *
   */
  save(): boolean | void {
    // Query pointer
    let query;

    if (this.mode === 'new') {
      const tmpData = _.pickBy(this.form.value, (el) => el !== null);
      const data: any[] = [];
      _.each(this.models, (modelId) => {
        const tmp = _.cloneDeep(tmpData);
        _.set(tmp, 'model', modelId);
        data.push(tmp);
      });
      query = this.service.saveAll('api/fastpm/modelTasks', data);
    } else if (this.mode === 'model' || this.mode === 'component') {
      let tasks: any = [];
      const data: any[] = [];

      if (this.mode === 'model') {
        tasks = this.modelTasksSelectedNodes;
        _.each(this.models, (modelId) => {
          _.each(tasks, (task) => {
            const item = {
              // Extract from current model group
              component: task.data.component,
              description: task.data.description,
              keyword: task.data.keyword,
              duration: task.data.duration,
              criteria: task.data.criteria,
              tools: task.data.toolIds,
              state: task.data.stateId,
              frequency: task.data.frequencyId,
              trade: task.data.tradeId,
              activity: task.data.activityId,
              criticalities: task.data.criticalityIds,
              model: modelId,
              procedure: task.data.procedure ? task.data.procedure : '',
              justification: task.data.justification
                ? task.data.justification
                : '',
              comment: task.data.comment ? task.data.comment : '',
            };
            data.push(item);
          });
        });
      } else {
        tasks = this.componentTasksSelectedNodes;
        _.each(this.models, (modelId) => {
          _.each(tasks, (task) => {
            const item = {
              // Extract from component name
              component: task.data.component,
              description: task.data.description,
              keyword: task.data.keyword,
              duration: task.data.duration,
              criteria: task.data.criteria,
              tools: task.data.toolIds,
              state: task.data.stateId,
              frequency: task.data.frequencyId,
              trade: task.data.tradeId,
              activity: task.data.activityId,
              criticalities: task.data.criticalityIds,
              model: modelId,
              procedure: task.data.procedure ? task.data.procedure : '',
              justification: task.data.justification
                ? task.data.justification
                : '',
              comment: task.data.comment ? task.data.comment : '',
            };
            data.push(item);
          });
        });
      }
      query = this.service.saveAll('api/fastpm/modelTasks', data);
    }

    this.isSubmitted = true;
    query?.subscribe(
      (res: any) => {
        this.isSubmitted = false;
        this.confirmFn(res);
        this.bsModalRef.hide();
      },
      (err: any) => {
        console.log('err: ', err);
        this.isSubmitted = false;
      }
    );
  }
}
