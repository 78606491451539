export * from './locations.component';
export * from './locations-list.component';
export * from './locations-map.component';
export * from './locations-items.component';
export * from './item.delete/delete-position.tag.component';
export * from './edit/edit.component';
export * from './tag.edit/edit.tag.component';
export * from './asset-access.edit/edit.component';
export * from './item.add/add.item.component';
export * from './item.delete/delete-position.asset.component';
