import {
  Component,
  OnInit,
  ViewChild,
  Input,
  SimpleChanges,
  Output,
  OnChanges,
  EventEmitter,
} from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../shared/services';
import { Router } from '@angular/router';
import { Column, GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../../shared/modals';
import { GridComponent } from '../../shared';
import { SparePartEditComponent } from './spare-parts-edit/edit.component';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import { AssetSpareModel } from 'src/app/shared/models/AssetSpareModel';
import { syncItems } from 'src/utils';

@Component({
  selector: 'app-spare-parts',
  templateUrl: './spare-parts.component.html',
})
export class SparePartsListComponent implements OnInit, OnChanges {
  @ViewChild(GridComponent, { static: true }) sparePartsGrid?: GridComponent;

  @Input() selectedAssetNodes: any[] = [];
  @Input() sizeColumnsToFit = true;
  @Input() refreshSparePartsGrid = true;
  @Output() reloadAssets = new EventEmitter<any>();
  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  statusReference?: any;
  partrefReference: any[] = [];
  boolReference?: any;
  sparePartsGridAutoGroupColumnDef?: any;
  assetNames: any = {};
  modelNames: any = {};

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    const tmpGridOptions: GridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1, // expand all groups by default
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.asset.asset'),
          field: 'asset_name',
          valueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_id;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_name;
            }
          },
          cellRenderer: (params: any) => {
            return this.assetNames?.[params.value];
          },
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.id'),
          field: 'asset_id',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastsp.spare_model.name'),
          field: 'model_name',
          valueGetter: (params: any) => {
            if (params.data) {
              return params.data.model_id;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.model_name;
            }
          },
          cellRenderer: (params: any) => {
            return this.modelNames?.[params.value];
          },
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.critical'
          ),
          field: 'critical',
          cellRenderer: (params: any): any => {
            if (params?.data?.critical === '1') {
              return (
                '<span class="badge badge-lg badge-primary">' +
                this.translate.instant('navigations.yes') +
                '</span>'
              );
            } else if (
              params?.data?.critical === '0' ||
              params?.data?.critical === null
            ) {
              return (
                '<span class="badge badge-lg badge-secondary">' +
                this.translate.instant('navigations.no') +
                '</span>'
              );
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.status'
          ),
          field: 'status_name',
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.partref'
          ),
          field: 'partref',
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.estimatedCost'
          ),
          field: 'asset_spare_cost',
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.comment'
          ),
          field: 'asset_spare_comment',
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_strategy.date'
          ),
          field: 'date',
        },
      ],
    };
    this.sparePartsGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.fastsp.spare_parts.name'),
      field: 'spare_name',
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    this.gridOptions = tmpGridOptions;
    await this.loadItems();
  }

  async loadStatuses(): Promise<void> {
    this.isLoading = true;
    const orderByDesc = { orderBy: { description: 'asc' } };
    const tpm = await this.data
      .by('api/config/fastsp-status', {
        criteria: {
          account: this.auth.getAccountId(),
        },
        ...orderByDesc,
      })
      .toPromise();
    this.statusReference = _.map(tpm, (el: any) => {
      return {
        name: el.description,
        value: el.id,
      };
    });

    this.isLoading = false;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedAssetNodes) {
      this.filterSpareParts(this.selectedAssetNodes);
    }
    if (
      this.refreshSparePartsGrid &&
      changes.refreshSparePartsGrid &&
      changes.refreshSparePartsGrid.currentValue !==
        changes.refreshSparePartsGrid.previousValue
    ) {
      this.loadItems();
    }
  }

  clearFilterAndResetGrid(): void {
    this.sparePartsGrid?.clearAllFilters();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }

    this.boolReference = [
      { value: true, name: this.translate.instant('navigations.yes') },
      { value: false, name: this.translate.instant('navigations.no') },
    ];

    this.items = await this.data.get('api/fastsp/asset-spares').toPromise();
    if (this.sizeColumnsToFit) {
      this.sparePartsGrid?.gridApi?.sizeColumnsToFit();
      this.sizeColumnsToFit = false;
    }

    this.sparePartsGrid?.uncheckAll();

    this.filterSpareParts(this.selectedAssetNodes);

    // set data array to use ID as value in valueGetter and Name as display value.
    // This is to allow grouping by ID instead of display value ("name" for eg.) thereby avoid grouping on duplicate display values
    this.items.map((val) => {
      this.modelNames[val.model_id] = val.model_name;
    });
    this.items.map((val) => {
      this.assetNames[val.asset_id] =
        val.asset_name +
        (val.asset_description ? ' - ' + val.asset_description : '');
    });
    if (!silent) {
      this.isLoading = false;
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onRowClicked(event: any): void {
    this.showEditModal([new AssetSpareModel(event)]);
  }

  filterSpareParts(assets: any): void {
    const instance =
      this.sparePartsGrid?.gridApi?.getFilterInstance('asset_id');
    if (assets.length > 0) {
      setTimeout(() => {
        instance?.setModel({
          values: _.map(assets, (el) => el.id),
        });
        this.sparePartsGrid?.gridApi?.onFilterChanged();
      });
      return;
    }

    instance?.setModel(null);
    this.sparePartsGrid?.gridApi?.onFilterChanged();
  }

  setPartrefRefernce(): void {
    const items: any[] = [];
    const partrefReference: any[] = [];
    this.items.map((item: any) => {
      items.push(item?.partref);
    });

    const uniqueSet = Array.from(new Set(items)).filter(
      (item) => item !== null
    );

    uniqueSet.map((element: any, index: number) => {
      partrefReference.push({ id: index, description: element });
    });

    this.partrefReference = partrefReference;
  }

  /**
   * @param items
   */
  async showEditModal(items?: AssetSpareModel[]): Promise<void> {
    await this.loadStatuses();
    this.setPartrefRefernce();

    this.bsModalRef = this.bsModalService.show(SparePartEditComponent, {
      initialState: {
        items,
        statusReference: this.statusReference,
        partrefReference: this.partrefReference,
        boolReference: this.boolReference,
        selectedNodes: _.map(this.selectedNodes, (el) => el.data),
      },
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = (res: any) => {
      this.items = syncItems(res, this.items);
      this.reloadAssets.emit(
        _.chain(res)
          .map((item) => item.asset_id)
          .uniq()
          .value()
      );
      this.sparePartsGrid?.uncheckAll();
      this.toastr.showSuccess();
    };
  }

  showMassEditModal(): void {
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      const items = _.map(
        this.selectedNodes,
        (el) => new AssetSpareModel(el.data)
      );
      this.showEditModal(items);
    }
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      this.isLoading = true;
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.data.post('api/fastsp/asset-spares/delete-all', ids).subscribe(
        (res) => {
          // remove ids from items
          this.items = _.filter(this.items, (el) => {
            return !_.includes(ids, el.id);
          });
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        },
        () => {
          this.isLoading = false;
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  myCellCallback = (params: any) => {
    return params.value;
  };

  exportProcessCallBack = (cellParams: any) => {
    if (!cellParams.value) {
      return '';
    }

    // for group row
    if (cellParams.node.field === 'asset_name') {
      return this.assetNames[cellParams.node.key];
    }

    if (cellParams.node.field === 'model_name') {
      return this.modelNames[cellParams.node.key];
    }

    // for normal row
    if (cellParams.column.colId === 'asset_name') {
      return this.assetNames[cellParams.node.data.asset_id];
    }

    if (cellParams.column.colId === 'model_name') {
      return cellParams.node.data.model_name;
    }

    return cellParams.value;
  };
}
