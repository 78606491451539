import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { ToastrModule } from 'ngx-toastr';
import { AgGridModule } from 'ag-grid-angular';
import { NgxSelectModule } from 'ngx-select-ex';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey(
  'CompanyName=AMSYST Limited,LicensedApplication=AMSYST,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-025865,ExpiryDate=25_February_2023_[v2]_MTY3NzI4MzIwMDAwMA==452ba4e4443afd1968a21a4d9393b2b7'
);

/**
 * SHARED
 */
import {
  AuthGuard,
  SuperAdminGuard,
  AuthService,
  Broadcaster,
  IntlService,
  ProxyService,
  DataService,
  SharedDataService,
  ToasterService,
  GridService,
} from './shared';

import {
  HeaderComponent,
  FooterComponent,
  ConfirmModalComponent,
  LoaderComponent,
  GridComponent,
  TreeComponent,
  FileUploadComponent,
} from './shared';

/**
 * COMPONENTS
 */
import {
  MainComponent,
  HomeComponent,
  LoginComponent,
  RegisterComponent,
  AuthAccountListComponent,
  AuthAccountCreateComponent,
  AuthSiteListComponent,
  AuthSiteCreateComponent,
  ModulesComponent,
  UsersListComponent,
  UserEditComponent,
  AssetListComponent,
  AssetEditComponent,
  AssetImportComponent,
  AssetMatchComponent,
  ComponentListComponent,
  ComponentEditComponent,
  ComponentTaskEditComponent,
  ModelListComponent,
  ModelEditComponent,
  ModelTaskEditComponent,
  ModelTaskAddComponent,
  FastpmModelImportComponent,
  AdminComponent,
  ActivityListComponent,
  ActivityEditComponent,
  CriticalityListComponent,
  SpareStatusesListComponent,
  CriticalityEditComponent,
  StatusEditComponent,
  FrequencyListComponent,
  FrequencyEditComponent,
  SiteListComponent,
  AppearanceComponent,
  SiteEditComponent,
  AppearanceEditComponent,
  StateListComponent,
  StateEditComponent,
  TradeListComponent,
  TradeEditComponent,
  ToolEditComponent,
  ToolListComponent,
  RoleEditComponent,
  RoleListComponent,
  LocationsComponent,
  LocationsListComponent,
  LocationsMapComponent,
  LocationsItemsComponent,
  LocationEditComponent,
  LocationItemAddComponent,
  LocationEditTagComponent,
  AssetAccessEditComponent,
  StrategyListComponent,
  FastspStrategyListComponent,
  FastspStrategyEditComponent,
  SparePartsListComponent,
  SparePartEditComponent,
  RoutingTaskComponent,
  RoutingFslGroupsComponent,
  RoutingFslLocationsComponent,
  RoutingFslPlansComponent,
  RoutingFslTasksComponent,
  RoutingSetPlanComponent,
  RoutingPlanComponent,
  RoutingPlanEditComponent,
  ResponsibleGroupsComponent,
  ResponsibleGroupsEditComponent,
  MyAccountComponent,
  FastCritConfigurationComponent,
  ConfFunctionalFailuresListComponent,
  ConfFunctionalFailureEditComponent,
  ConfProductsListComponent,
  ConfProductEditComponent,
  ConfFinalitiesListComponent,
  ConfFinalityEditComponent,
  ConfCategoriesListComponent,
  ConfCategoryEditComponent,
  ConfAssetAccessRequirementsListComponent,
  ConfAssetAccessRequirementEditComponent,
  ConfProcessesListComponent,
  ConfProcessEditComponent,
  ConfConsequencesListComponent,
  ConfConsequenceEditComponent,
  ConfFactorsListComponent,
  ConfFactorEditComponent,
  ConfEnginesListComponent,
  RiskModelsListComponent,
  RiskModelEditComponent,
  SpareModelsListComponent,
  SpareModelEditComponent,
  SpareListComponent,
  SpareEditComponent,
  SpareModelAssetListComponent,
  FunctionLossListComponent,
  FunctionLossEditComponent,
  ImpactsListComponent,
  ImpactEditComponent,
  RiskAssessmentListComponent,
  RiskAssessmentEditComponent,
  RiskModelAddComponent,
  RiskModelAssetListComponent,
  SpareModelAddComponent,
  ConfEquipmentTypeListComponent,
  ConfAssetCustomFieldsListComponent,
  ConfAssetCustomField1ListComponent,
  ConfAssetCustomField2ListComponent,
  ConfAssetCustomField3ListComponent,
  ConfAssetCustomField4ListComponent,
  ConfAssetCustomField5ListComponent,
  ConfAssetCustomField6ListComponent,
  ConfAssetCustomFieldEditComponent,
  ConfAssetCustomHeaderEditComponent,
  ConfEquipmentTypeEditComponent,
  CustomCriteriaComponent,
  CustomCriteriaEditComponent,

  // Fastlc
  FastlcActionBudgetsListComponent,
  FastlcActionBudgetsEditComponent,
  FastlcActionStatusesListComponent,
  FastlcActionStatusesEditComponent,
  FastlcActionTypesListComponent,
  FastlcActionTypesEditComponent,
  LifecycleModelsListComponent,
  LifecycleModelAddComponent,
  LifecycleModelAssetListComponent,
  LifecycleModelEditComponent,
  ConditionsListComponent,
  ConditionsEditComponent,
  ActionsListComponent,
  ActionsEditComponent,
  LifecycleStrategyListComponent,
  FastlcGradesEditComponent,
  FastlcGradesListComponent,
  AssetModelListComponent,
  AssetModelEditComponent,
  AssetActionListComponent,
  AssetActionEditComponent,
  AssetConditionValueListComponent,
  AssetConditionValueEditComponent,
  CostCalculatorComponent,
  ChartComponent,
  GradesEditComponent,
  DeletePositionAssetComponent,
  DeletePositionTagComponent,
} from './pages';

import { BreadCrumbService } from './shared/services/breadcrumb.service';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { AssetSummaryComponent } from './shared/components/asset-summary/asset-summary.component';
import { FieldClearButtonComponent } from './shared/components/field-clear-button/field-clear-button.component';

import { ModelAssetListComponent } from './pages/fastpm-models/model-asset-list/model-asset-list.component';
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { Injector, APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { PlanTasksComponent } from './pages/routing-plans/plan-tasks/plan-tasks.component';
import { ModelTasksPreviewComponent } from './shared/components/model-tasks/model-tasks-preview.component';
import { ProcessDetailsComponent } from './shared/components/process-details/process-details.component';
import { AssetCriticalityComponent } from './shared/components/asset-summary/asset-criticality.component';
import { ModelDetailComponent } from './shared/components/model-details/model-detail.component';
import { GridDisplayerComponent } from './shared/components/grid-displayer/grid-displayer.component';
import { TagInputComponent } from './shared/tag-input/tag-input.component';
import { ErrorModalComponent } from './shared';
import { NgChartsModule } from 'ng2-charts';

// tslint:disable-next-line:typedef
export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        const langToSet = 'en';
        translate.setDefaultLang('en');
        translate.use(langToSet).subscribe(
          () => {
            // console.log(`Successfully initialized '${langToSet}' language.'`);
          },
          () => {
            // console.error(`Problem with '${langToSet}' language initialization.'`);
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}

@NgModule({
  declarations: [
    AppComponent,

    HeaderComponent,
    FooterComponent,
    ConfirmModalComponent,
    ErrorModalComponent,
    LoaderComponent,
    AssetEditComponent,
    AssetImportComponent,
    AssetMatchComponent,
    AssetSummaryComponent,
    FieldClearButtonComponent,
    ModelTasksPreviewComponent,
    ModelAssetListComponent,
    ComponentEditComponent,
    ModelEditComponent,
    ComponentTaskEditComponent,
    ModelTaskEditComponent,
    ModelTaskAddComponent,

    HomeComponent,
    LoginComponent,
    RegisterComponent,
    AuthAccountListComponent,
    AuthAccountCreateComponent,
    AuthSiteListComponent,
    AuthSiteCreateComponent,
    MainComponent,

    ModulesComponent,

    UsersListComponent,
    UserEditComponent,

    AssetListComponent,
    ComponentListComponent,
    ModelListComponent,
    FastpmModelImportComponent,
    StrategyListComponent,

    FastspStrategyListComponent,
    FastspStrategyEditComponent,
    SparePartsListComponent,
    SparePartEditComponent,

    GridComponent,
    TreeComponent,

    AdminComponent,
    ActivityListComponent,
    ActivityEditComponent,
    CriticalityListComponent,
    SpareStatusesListComponent,
    CriticalityEditComponent,
    StatusEditComponent,
    FrequencyListComponent,
    FrequencyEditComponent,
    SiteListComponent,
    AppearanceComponent,
    SiteEditComponent,
    AppearanceEditComponent,
    StateListComponent,
    StateEditComponent,
    TradeListComponent,
    TradeEditComponent,
    ToolEditComponent,
    ToolListComponent,
    RoleEditComponent,
    RoleListComponent,

    LocationsComponent,
    LocationsListComponent,
    LocationsMapComponent,
    LocationsItemsComponent,
    LocationEditComponent,
    LocationItemAddComponent,
    DeletePositionTagComponent,
    DeletePositionAssetComponent,
    LocationEditTagComponent,
    AssetAccessEditComponent,
    FileUploadComponent,
    TagInputComponent,

    RoutingTaskComponent,
    RoutingFslGroupsComponent,
    RoutingFslLocationsComponent,
    RoutingFslPlansComponent,
    RoutingFslTasksComponent,
    RoutingSetPlanComponent,

    RoutingPlanComponent,
    RoutingPlanEditComponent,

    ResponsibleGroupsComponent,
    ResponsibleGroupsEditComponent,

    MyAccountComponent,

    PlanTasksComponent,
    FastCritConfigurationComponent,

    ConfFunctionalFailuresListComponent,
    ConfFunctionalFailureEditComponent,

    ConfProductsListComponent,
    ConfProductEditComponent,

    ConfFinalitiesListComponent,
    ConfFinalityEditComponent,

    ConfCategoriesListComponent,
    ConfCategoryEditComponent,

    ConfAssetAccessRequirementsListComponent,
    ConfAssetAccessRequirementEditComponent,

    ConfProcessesListComponent,
    ConfProcessEditComponent,

    ConfConsequencesListComponent,
    ConfConsequenceEditComponent,

    ConfFactorsListComponent,
    ConfFactorEditComponent,

    ConfEnginesListComponent,

    RiskModelsListComponent,
    RiskModelEditComponent,

    RiskModelsListComponent,
    SpareModelEditComponent,

    FunctionLossListComponent,
    FunctionLossEditComponent,

    ImpactsListComponent,
    ImpactEditComponent,

    RiskAssessmentListComponent,
    RiskAssessmentEditComponent,
    ProcessDetailsComponent,

    AssetCriticalityComponent,
    ModelDetailComponent,
    GridDisplayerComponent,

    RiskModelAddComponent,
    RiskModelAssetListComponent,

    SpareModelAddComponent,

    ConfEquipmentTypeListComponent,
    ConfAssetCustomFieldsListComponent,
    ConfAssetCustomField1ListComponent,
    ConfAssetCustomField2ListComponent,
    ConfAssetCustomField3ListComponent,
    ConfAssetCustomField4ListComponent,
    ConfAssetCustomField5ListComponent,
    ConfAssetCustomField6ListComponent,
    ConfAssetCustomFieldEditComponent,
    ConfAssetCustomHeaderEditComponent,
    ConfEquipmentTypeEditComponent,
    CustomCriteriaComponent,
    CustomCriteriaEditComponent,

    SpareModelsListComponent,
    SpareListComponent,
    SpareEditComponent,
    SpareModelAssetListComponent,

    // Fastlc
    FastlcGradesListComponent,
    FastlcGradesEditComponent,
    FastlcActionBudgetsListComponent,
    FastlcActionBudgetsEditComponent,
    FastlcActionStatusesListComponent,
    FastlcActionStatusesEditComponent,
    FastlcActionTypesListComponent,
    FastlcActionTypesEditComponent,

    LifecycleModelsListComponent,
    LifecycleModelEditComponent,
    LifecycleModelAddComponent,
    LifecycleModelAssetListComponent,
    ConditionsListComponent,
    ConditionsEditComponent,
    ActionsListComponent,
    ActionsEditComponent,
    LifecycleStrategyListComponent,
    AssetModelListComponent,
    AssetModelEditComponent,
    AssetActionListComponent,
    AssetActionEditComponent,
    AssetConditionValueListComponent,
    AssetConditionValueEditComponent,
    CostCalculatorComponent,
    GradesEditComponent,
    ChartComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgSelectModule,
    BrowserAnimationsModule,
    LeafletModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2000,
      preventDuplicates: true,
    }),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AgGridModule.withComponents([]),
    NgxSelectModule,
    AccordionModule.forRoot(),
    FileUploadModule,
    NgChartsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    AuthGuard,
    SuperAdminGuard,
    AuthService,
    Broadcaster,
    IntlService,
    ProxyService,
    DataService,
    SharedDataService,
    ToasterService,
    BreadCrumbService,
    GridService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
