import { AfterViewInit, Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DataService, AuthService } from 'src/app/shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { RiskModelModel } from 'src/app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid-community';
import { GridComponent } from 'src/app/shared/grid/grid.component';

@Component({
  selector: 'app-spare-model-add',
  templateUrl: './add.component.html'
})
export class SpareModelAddComponent implements OnInit, AfterViewInit {
  @ViewChildren(GridComponent) grids?: GridComponent;
  riskModelGrid?: any;

  sitesReferences: any[] = [];

  // Modal parameters
  items: any; // array of items to edit
  currentSite: any;
  showValidationError = false;

  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: ('new' | 'model') = 'new';

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;

  riskModelGridOptions?: GridOptions;
  riskModel: any[] = [];
  riskModelSelectedNodes: any[] = [];
  riskModelIsLoading = false;
  currentActivatedSite: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService
  ) {
    this.isLoading = true;
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    this.riskModelGrid = this.grids?._results[0];
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {

    const siteIndex = _.findIndex(this.sitesReferences, { id: this.auth.getSiteId() });
    this.currentActivatedSite = this.sitesReferences[siteIndex];
    this.currentSite = this.currentActivatedSite;

    // Set current site
    this.riskModelGridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.fastsp.spare_model.model'),
          field: 'name',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          filter: 'agTextColumnFilter',
          filterParams: { newRowsAction: 'keep' }
        },
        {
          headerName: this.translate.instant('headers.fastsp.spare_model.comment'),
          field: 'comment'
        }
      ]
    };

    // Build for
    this.form = this.getForm(new RiskModelModel({}));

    // Loading done !
    this.isLoading = false;
  }

  /**
   * @param site
   */
  setSite(site: any): void {
    this.currentSite = site;
    this.riskModelLoadItems(site);
  }

  async riskModelLoadItems(site: any, silent = false): Promise<void> {
    if (!silent) {
      this.riskModelIsLoading = true;
    }
    this.riskModel = await this.service.by(
      'api/fastsp/models', {
      criteria: {
        site: site.id
      }
    }
    ).toPromise();
    this.riskModelGrid?.uncheckAll();
    if (!silent) {
      this.riskModelIsLoading = false;
    }
  }

  riskModelOnSelectionChanged(event: any): void {
    this.riskModelSelectedNodes = event;
  }

  /**
   *
   */
  getForm(item: any): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      site: new UntypedFormControl(this.auth.getSiteId()),
      name: new UntypedFormControl(item.name, [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
      comment: new UntypedFormControl(item.comment),
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => {
  }

  tabChange(mode: ('new' | 'model')): void {
    this.mode = mode;
    if (this.mode === 'model' && this.riskModel.length === 0) {
      this.setSite(this.currentActivatedSite);
    }
  }

  /**
   *
   */
  save(): boolean | void {
    this.showValidationError = true;
    // Query pointer
    let query;

    if (this.mode === 'new') {
      if (!this.form || (this.form && !this.form.valid)) {
        return false;
      }

      const tmpData = _.pickBy(this.form.value, el => el !== null);
      query = this.service.post('api/fastsp/models', tmpData);

    } else if (this.mode === 'model') {
      const ids = _.map(this.riskModelSelectedNodes, el => el.id);
      query = this.service.post('api/fastsp/models/duplicate-all', ids);
    }

    this.isSubmitted = true;
    query?.subscribe((res: any) => {
      this.isSubmitted = false;
      this.confirmFn(res);
      this.bsModalRef.hide();
    }, (err: any) => {
      console.log('err: ', err);
      this.isSubmitted = false;
    });
  }
}
