import { LifecycleModelModel } from './LifecycleModelModel';

export class ConditionsModel {
  id: number;
  description: string;
  fastlcModel: LifecycleModelModel;
  comment: string;
  values: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.fastlcModel = data.fastlcModel ? data.fastlcModel : LifecycleModelModel;
    this.description = data.description ? data.description : '';
    this.comment = data.comment ? data.comment : '';
    this.values = data.condition_values ? data.condition_values : '';
  }
}
