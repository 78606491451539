<div class="modal-body">
  <div class="d-flex justify-content-between align-items-center mb-2">
    <input
      type="text"
      [formControl]="search"
      name="search"
      placeholder="{{ 'navigations.search' | translate }}"
      class="form-control form-control-sm"
    />
  </div>
  <ng-container *ngIf="tree.length > 0">
    <ng-template #recursiveList let-list>
      <ng-container *ngFor="let item of list; let firstItem = first">
        <accordion [isAnimated]="true">
          <ng-container *ngIf="item.children.length > 0">
            <accordion-group
              [isOpen]="item.isOpen"
              (isOpenChange)="openChange($event, item)"
            >
              <div
                class="d-flex justify-content-between align-items-center"
                accordion-heading
              >
                <button
                  (click)="selectItem($event, item)"
                  class="btn btn-sm btn-link clearfix"
                  type="button"
                >
                  {{ item.name }} {{ item.description }}
                </button>
                <i
                  *ngIf="!item.isOpen"
                  class="la la-caret-right la-lg text-muted mr-2"
                ></i>
                <i
                  *ngIf="item.isOpen"
                  class="la la-caret-down la-lg text-muted mr-2"
                ></i>
              </div>
              <ng-container
                *ngTemplateOutlet="
                  recursiveList;
                  context: { $implicit: item.children }
                "
              ></ng-container>
            </accordion-group>
          </ng-container>
          <ng-container *ngIf="item.children.length === 0">
            <div style="margin-bottom: 0.5rem">
              <button
                (click)="selectItem($event, item)"
                class="btn btn-sm btn-link"
                type="button"
              >
                {{ item.name }} {{ item.description }}
              </button>
            </div>
          </ng-container>
        </accordion>
      </ng-container>
    </ng-template>

    <ng-container
      *ngTemplateOutlet="recursiveList; context: { $implicit: tree }"
    ></ng-container>
  </ng-container>
</div>
