<div class="modal-body">
  <div class="modal-body">
    <div class="form-row">
      <div class="form-group value-select-container col-12">
        <ngx-select (typed)="onValueSearch($event)" [items]="items" [placeholder]="'(Search Asset)'"
          optionTextField="fullName" optionValueField="id" size="small" [formControl]="assetControl"></ngx-select>
      </div>
    </div>
    <div class="d-flex mb-0">
      <button [ngClass]="{
          'btn-success ml-auto': isLoading,
          'd-none': !isLoading
        }" class="btn btn-sm">
        <i [ngClass]="{ 'la-spin': isLoading }" class="la la-refresh"></i>
      </button>
      <button [hidden]="
          isLoading ||
          assetControl.value !== 0
        " class="btn btn-sm btn-primary ml-auto" (click)="createTag()">
        {{ "navigations.tag.create" | translate }}
      </button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="form-group">
    <div class="col-12 d-flex justify-content-between">
      <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
        <i class="la la-ban"></i>
        {{ "navigations.cancel" | translate }}
      </button>
      <button (click)="save()" [disabled]="
          isSubmitted ||
          isLoading ||
          !assetControl.dirty ||
          assetControl.value === 0
        " class="btn btn-sm btn-success">
        <i *ngIf="!isSubmitted" class="la la-save"></i>
        <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
        {{ "navigations.save" | translate }}
      </button>
    </div>
  </div>
</div>
