<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group col-12">
          <label [class.font-weight-bold]="form.get('name')?.dirty" for="name">
            {{ "headers.tasks_parameters.activity.activity" | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            [ngClass]="{
              'is-invalid': isSubmitted && form.get('name')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="name"
            id="name"
            type="text"
          />
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('parameter')?.dirty"
            for="parameter"
          >
            {{ "headers.tasks_parameters.activity.parameter" | translate }}
          </label>
          <app-field-clear-button
            [formGroupRef]="form"
            [fieldName]="'parameter'"
          ></app-field-clear-button>
          <input
            [ngClass]="{
              'is-invalid': isSubmitted && form.get('parameter')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="parameter"
            id="parameter"
            type="text"
          />
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="
            (!form.pristine && !form.dirty && isSubmitted) || !form.valid
          "
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
