import { Component, OnInit } from '@angular/core';
import { AuthService, Broadcaster } from '../../shared/services';
import { Router } from '@angular/router';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fastcrit-configuration',
  templateUrl: './configuration.component.html',
})
export class FastCritConfigurationComponent implements OnInit {
  modules: { name: string; link: string; module: string }[] = [];

  constructor(
    public router: Router,
    public auth: AuthService,
    public breadCrumbService: BreadCrumbService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: 'Configuration',
          link: '/fastcrit/configuration',
        },
      ],
      2
    );
  }
}
