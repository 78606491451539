export class AssetModel {
  id: number;
  name: string;
  description: string;
  fullName: string;
  type: any;
  active: any;
  ranking: number;
  unit: number;
  planningGroup: number;
  costCenter: number;
  workCenter: number;
  system: boolean;
  drawing: any;
  specifications: any;
  eqmake: string;
  eqmodel: string;
  eqserial: string;
  eqsupplier: string;
  custom1: string;
  custom2: string;
  custom3: string;
  custom4: string;
  custom5: string;
  custom6: string;
  modelCount: number;
  position_x: number; // tslint:disable-line:variable-name
  position_y: number; // tslint:disable-line:variable-name

  children: [];
  models: [];

  parent: string;
  parentId: number;
  location: string;
  locationId: number;
  accesses: [];
  criticality: string;
  criticalityId: number;
  site: string;
  siteId: number;

  pick: any;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;

    this.name = data.name ? data.name : '';
    this.description = data.description !== undefined ? data.description : null;
    this.fullName = this.name + ' - ' + this.description;
    this.type = data.type ? data.type : '';
    this.active = (data.active || data.active === false) ? data.active : null;
    this.ranking = data.ranking !== undefined ? data.ranking : 0;
    this.unit = data.unit ? data.unit : 0;
    this.planningGroup = data.planningGroup ? data.planningGroup : 0;
    this.costCenter = data.costCenter ? data.costCenter : 0;
    this.workCenter = data.workCenter ? data.workCenter : 0;
    this.system = (data.system || data.system === false) ? data.system : null;
    this.drawing = data.drawing ? data.drawing : '';
    this.specifications = data.specifications ? data.specifications : '';
    this.eqmake = data.eqmake ? data.eqmake : '';
    this.eqmodel = data.eqmodel ? data.eqmodel : '';
    this.eqserial = data.eqserial ? data.eqserial : '';
    this.eqsupplier = data.eqsupplier ? data.eqsupplier : '';
    this.custom1 = data.custom1 ? data.custom1 : '';
    this.custom2 = data.custom2 ? data.custom2 : '';
    this.custom3 = data.custom3 ? data.custom3 : '';
    this.custom4 = data.custom4 ? data.custom4 : '';
    this.custom5 = data.custom5 ? data.custom5 : '';
    this.custom6 = data.custom6 ? data.custom6 : '';
    this.modelCount = data.modelCount ? data.modelCount : 0;
    this.position_x = data.position_x ? data.position_x : 0;
    this.position_y = data.position_y ? data.position_y : 0;

    this.children = data.children ? data.children : [];
    this.models = data.models ? data.models : [];

    this.parent = data.parent ? data.parent : null;
    this.parentId = data.parentId ? data.parentId : null;
    this.location = data.location ? data.location : null;
    this.locationId = data.locationId ? data.locationId : null;
    this.accesses = data.accesses ? data.accesses : [];
    this.criticality = data.criticality ? data.criticality : null;
    this.criticalityId = data.criticalityId ? data.criticalityId : null;
    this.site = data.site ? data.site : null;
    this.siteId = data.siteId ? data.siteId : null;
  }
}
