import { Component, OnInit } from '@angular/core';
import { DataService, ToasterService, AuthService } from '../../shared';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-routing',
  templateUrl: './routing.component.html',
})
export class RoutingTaskComponent implements OnInit {
  // Reference data
  selectionDuration = 0;
  groups: [] = [];
  locations: [] = [];
  tasks: [] = [];
  plans: [] = [];

  loadFrom?: 'locations' | 'plans';

  currentGroup: any;
  currentFrequency?: number;
  currentState?: number;
  currentLabour?: number;

  // locationIds or planIds
  currentSelection: number[] = [];

  // toggle between Locations (default) & Plans grids
  uiGridState = false;

  // Modal ref pointer
  bsModalRef?: BsModalRef;

  // UI Flags
  isLoading = false;

  /**
   * @param service
   * @param toastr
   * @param auth
   * @param bsModalService
   * @param breadCrumbService
   */
  constructor(
    protected service: DataService,
    private toastr: ToasterService,
    public auth: AuthService,
    protected bsModalService: BsModalService,
    public breadCrumbService: BreadCrumbService,
    private translate: TranslateService
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.tasks'),
          link: '/tasks',
        },
      ],
      2
    );
  }

  /**
   *
   */
  uiGridToggle(): void {
    this.uiGridState = !this.uiGridState;
    this.currentSelection = [];
  }

  /**
   *
   * @param event
   */
  groupsSelectionChanged(event: any): void {
    this.currentGroup = event;
    if (this.currentGroup.length === 0) {
      this.currentFrequency = undefined;
      this.currentState = undefined;
      this.currentLabour = undefined;
    } else {
      this.currentFrequency = event[0].data.frequency_id;
      this.currentState = event[0].data.state_id;
      this.currentLabour = event[0].data.labour_id;
    }
  }

  locationsSelectionChanged(event: any): void {
    this.currentSelection = _.map(event, (el: any) => {
      return el.data.id;
    });
    this.loadFrom = 'locations';
  }

  plansSelectionChanged(event: any): void {
    this.currentSelection = _.map(event, (el: any) => {
      return el.data.id;
    });
    this.loadFrom = 'plans';
  }
}
