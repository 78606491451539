<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group col-12">
          <label [class.font-weight-bold]="form.get('name')?.dirty" for="name">
            {{ "headers.account_and_user_management.role.role" | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            [ngClass]="{
              'is-invalid': isSubmitted && form.get('name')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="name"
            id="name"
            type="text"
          />
        </div>

        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('asset')?.dirty"
            for="asset"
          >
            {{ "headers.account_and_user_management.role.asset" | translate }}
            <span class="text-danger">*</span>
          </label>
          <ngx-select
            [items]="roleReference"
            [placeholder]="'(Select a Value)'"
            formControlName="asset"
            id="asset"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
        </div>

        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('fastpm')?.dirty"
            for="fastpm"
          >
            {{ "headers.account_and_user_management.role.fastpm" | translate }}
            <span class="text-danger">*</span>
          </label>
          <ngx-select
            [items]="roleReference"
            [placeholder]="'(Select a Value)'"
            formControlName="fastpm"
            id="fastpm"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
        </div>

        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('location')?.dirty"
            for="location"
          >
            {{
              "headers.account_and_user_management.role.location" | translate
            }}
            <span class="text-danger">*</span>
          </label>
          <ngx-select
            [items]="roleReference"
            [placeholder]="'(Select a Value)'"
            formControlName="location"
            id="location"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
        </div>

        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('routing')?.dirty"
            for="routing"
          >
            {{ "headers.account_and_user_management.role.routing" | translate }}
            <span class="text-danger">*</span>
          </label>
          <ngx-select
            [items]="roleReference"
            [placeholder]="'(Select a Value)'"
            formControlName="routing"
            id="routing"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
        </div>

        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('fastCriticality')?.dirty"
            for="fastCriticality"
          >
            {{
              "headers.account_and_user_management.role.fast_criticality"
                | translate
            }}
            <span class="text-danger">*</span>
          </label>
          <ngx-select
            [items]="roleReference"
            [placeholder]="'(Select a Value)'"
            formControlName="fastCriticality"
            id="fastCriticality"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
        </div>

        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('fastsp')?.dirty"
            for="fastsp"
          >
            {{ "headers.account_and_user_management.role.fastsp" | translate }}
            <span class="text-danger">*</span>
          </label>
          <ngx-select
            [items]="roleReference"
            [placeholder]="'(Select a Value)'"
            formControlName="fastsp"
            id="fastsp"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('fastlc')?.dirty"
            for="fastlc"
          >
            {{ "headers.account_and_user_management.role.fastlc" | translate }}
            <span class="text-danger">*</span>
          </label>
          <ngx-select
            [items]="roleReference"
            [placeholder]="'(Select a Value)'"
            formControlName="fastlc"
            id="fastlc"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="
            (!form.pristine && !form.dirty && isSubmitted) || !form.valid
          "
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
