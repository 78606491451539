import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DataService, AuthService, ToasterService } from 'src/app/shared/services';
import { FastspStrategyModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { validateNumberInput } from 'src/utils';

@Component({
  selector: 'app-asset-edit',
  templateUrl: './edit.component.html'
})
export class FastspStrategyEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  spareModelsReference: [] = [];
  assetFactorsReference: [] = [];
  assets: any[] = [];
  showValidationError = false;

  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: ('mass' | 'single') = 'single';

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param toastr
   * @param translate
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    private toastr: ToasterService,
    public translate: TranslateService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // Get default empty object
    if ('mass' === this.mode) {
      this.items = [new FastspStrategyModel({
        account: this.auth.getAccountId(),
        siteId: this.auth.getSiteId()
      })];
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [FastspStrategyModel?]): ('mass' | 'single') {
    // Empty array => New Item
    if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  /**
   *
   */
  getForm(item: any): UntypedFormGroup {
    const efficiency = (this.mode !== 'mass') ? (item.efficiency || 100) : null;
    let efficiencyValidators: any[];
    if (this.mode !== 'mass') {
      efficiencyValidators = [Validators.required, Validators.min(1), Validators.max(100)];
    } else {
      efficiencyValidators = [Validators.min(1), Validators.max(100)];
    }

    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      asset: new UntypedFormControl(this.assets),
      model: new UntypedFormControl(_.map(item.assetModels, el => el.id)),
      factor: new UntypedFormControl(_.map(item.assetFactors, el => el.id)),
      efficiency: new UntypedFormControl(efficiency, efficiencyValidators),
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any, mode: string) => { };

  validateNumberInput(event: KeyboardEvent): void {
    validateNumberInput(event);
  }

  /**
   *
   */
  save(): boolean | void {
    this.showValidationError = true;
    if (!this.form || (this.form && !this.form.valid)) {
      return false;
    }

    let data: any = {};
    if (this.mode === 'single') {

      if ((!this.form.value.model || this.form.value.model.length === 0)
        && (!this.form.value.factor || this.form.value.factor.length === 0)
      ) {
        this.toastr.showError(this.translate.instant('navigations.model.no_model_factor'));
        return false;
      }

      data = this.form.value;
    } else {
      _.each(this.form.controls, (control, name) => {
        if (control.dirty === true && control.value !== -1) {
          data[name] = control.value;
        }
      });

      if (Object.keys(data).length === 0) {
        this.toastr.showError(this.translate.instant('messages.no_data_to_update'));
        return false; // no data to update
      }

      _.merge(data, { asset: this.assets });
    }

    this.isSubmitted = true;

    this.service.post('api/fastsp/strategy', data).subscribe(res => {
      this.isSubmitted = false;
      this.confirmFn(res, this.mode);
      this.bsModalRef.hide();
    }, err => {
      this.isSubmitted = false;
    });
  }
}
