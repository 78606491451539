import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessRightsResolver } from './shared/custom.resolver';

import {
  HomeComponent,
  LoginComponent,
  MainComponent,
  RegisterComponent,
  ModulesComponent,
  UsersListComponent,
  AuthAccountListComponent,
  AuthAccountCreateComponent,
  AuthSiteListComponent,
  AuthSiteCreateComponent,
  AssetListComponent,
  AdminComponent,
  ModelListComponent,
  ComponentListComponent,
  SiteListComponent,
  AppearanceComponent,
  SpareStatusesListComponent,
  CriticalityListComponent,
  TradeListComponent,
  StateListComponent,
  ToolListComponent,
  FrequencyListComponent,
  ActivityListComponent,
  LocationsComponent,
  StrategyListComponent,
  FastspStrategyListComponent,
  RoleListComponent,
  ResponsibleGroupsComponent,
  RoutingTaskComponent,
  RoutingPlanComponent,
  MyAccountComponent,
  FastCritConfigurationComponent,
  ConfFunctionalFailuresListComponent,
  ConfProductsListComponent,
  ConfFinalitiesListComponent,
  ConfCategoriesListComponent,
  ConfAssetAccessRequirementsListComponent,
  ConfProcessesListComponent,
  ConfConsequencesListComponent,
  ConfFactorsListComponent,
  ConfEnginesListComponent,
  RiskModelsListComponent,
  RiskAssessmentListComponent,
  ConfEquipmentTypeListComponent,
  ConfAssetCustomFieldsListComponent,
  CustomCriteriaComponent,
  SpareModelsListComponent,

  // Fastlc
  FastlcActionBudgetsListComponent,
  FastlcActionStatusesListComponent,
  FastlcActionTypesListComponent,
  LifecycleModelsListComponent,
  LifecycleStrategyListComponent,
  FastlcGradesListComponent,
} from './pages';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'accounts',
        component: AuthAccountListComponent,
      },
      {
        path: 'accounts/create',
        component: AuthAccountCreateComponent,
      },
      {
        path: 'sites',
        component: AuthSiteListComponent,
      },
      {
        path: 'sites/create',
        component: AuthSiteCreateComponent,
      },
    ],
  },
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'modules',
        component: ModulesComponent,
      },
      {
        path: 'my-account',
        component: MyAccountComponent,
      },
      {
        path: 'assets',
        component: AssetListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'asset' },
      },
      {
        path: 'responsible-groups',
        component: ResponsibleGroupsComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'responsible-groups' },
      },
      {
        path: 'fastpm/models',
        component: ModelListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'fastpm' },
      },
      {
        path: 'fastpm/strategy',
        component: StrategyListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'fastpm' },
      },
      {
        path: 'fastpm/custom-criteria',
        component: CustomCriteriaComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'fastpm' },
      },
      {
        path: 'fastsp/strategy',
        component: FastspStrategyListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'fastsp' },
      },
      {
        path: 'map/locations',
        component: LocationsComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'location' },
      },
      {
        path: 'routing/tasks',
        component: RoutingTaskComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'routing' },
      },
      {
        path: 'routing/plans',
        component: RoutingPlanComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'routing' },
      },
      {
        path: 'admin',
        component: AdminComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'admin' },
      },
      {
        path: 'admin/sites',
        component: SiteListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'admin' },
      },
      {
        path: 'admin/appearance',
        component: AppearanceComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'admin' },
      },
      {
        path: 'admin/criticalities',
        component: CriticalityListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'admin' },
      },
      {
        path: 'admin/spare-statuses',
        component: SpareStatusesListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'admin' },
      },
      {
        path: 'admin/frequencies',
        component: FrequencyListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'admin' },
      },
      {
        path: 'admin/trades',
        component: TradeListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'admin' },
      },
      {
        path: 'admin/states',
        component: StateListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'admin' },
      },
      {
        path: 'admin/tools',
        component: ToolListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'admin' },
      },
      {
        path: 'admin/activities',
        component: ActivityListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'admin' },
      },
      {
        path: 'admin/roles',
        component: RoleListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'admin' },
      },
      {
        path: 'admin/users',
        component: UsersListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'admin' },
      },
      {
        path: 'admin/components',
        component: ComponentListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'admin' },
      },
      {
        path: 'fastcrit/configuration',
        component: FastCritConfigurationComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'criticality' },
      },
      {
        path: 'admin/functional-failures',
        component: ConfFunctionalFailuresListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'criticality' },
      },
      {
        path: 'admin/equipment-types',
        component: ConfEquipmentTypeListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'criticality' },
      },
      {
        path: 'admin/asset/custom-fields',
        component: ConfAssetCustomFieldsListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'admin' },
      },
      {
        path: 'admin/products',
        component: ConfProductsListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'criticality' },
      },
      {
        path: 'admin/finalities',
        component: ConfFinalitiesListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'criticality' },
      },
      {
        path: 'admin/categories',
        component: ConfCategoriesListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'criticality' },
      },
      {
        path: 'admin/asset/access-requirements',
        component: ConfAssetAccessRequirementsListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'criticality' },
      },
      {
        path: 'fastcrit/processes',
        component: ConfProcessesListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'fast_criticality' },
      },
      {
        path: 'fastcrit/risk-models',
        component: RiskModelsListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'fast_criticality' },
      },

      {
        path: 'fastsp/models',
        component: SpareModelsListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'fastsp' },
      },
      {
        path: 'fastcrit/risk-assessment',
        component: RiskAssessmentListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'fast_criticality' },
      },

      {
        path: 'fastlc/models',
        component: LifecycleModelsListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'fastlc' },
      },
      {
        path: 'fastlc/strategy',
        component: LifecycleStrategyListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'fastlc' },
      },

      {
        path: 'admin/consequences',
        component: ConfConsequencesListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'criticality' },
      },
      {
        path: 'admin/factors',
        component: ConfFactorsListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'criticality' },
      },
      {
        path: 'admin/engines',
        component: ConfEnginesListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'criticality' },
      },
      {
        path: 'admin/fastlc/grades',
        component: FastlcGradesListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'fastlc' },
      },
      {
        path: 'admin/fastlc/budget-categories',
        component: FastlcActionBudgetsListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'fastlc' },
      },
      {
        path: 'admin/fastlc/action-statuses',
        component: FastlcActionStatusesListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'fastlc' },
      },
      {
        path: 'admin/fastlc/action-types',
        component: FastlcActionTypesListComponent,
        resolve: { access: AccessRightsResolver },
        data: { module: 'fastlc' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
