import {Injectable} from '@angular/core';

@Injectable()
export class GridService {
  /**
   */
  constructor(
  ) {
  }

}
