<div class="d-flex flex-column h-100">
  <div class="d-flex align-items-center px-2">
    <div class="mr-2">
      <button (click)="locationsLoadItems()" [ngClass]="{
          'btn-success': locationsIsLoading,
          'btn-light': !locationsIsLoading
        }" class="btn btn-sm">
        <i [ngClass]="{ 'la-spin': locationsIsLoading }" class="la la-refresh"></i>
      </button>
    </div>
    <div class="mr-2">
      <button (click)="locationShowEditModal()" [disabled]="locationsIsLoading || auth.isViewer('location')"
        class="btn btn-success btn-sm">
        <i class="la la-plus"></i>
      </button>
    </div>
    <div class="mr-2">
      <button (click)="locationsGrid?.expandAll()" [disabled]="locationsIsLoading" class="btn btn-light btn-sm">
        <i class="la la-expand-arrows-alt"></i>
      </button>
    </div>
    <div class="mr-2">
      <button (click)="locationsGrid?.collapseAll()" [disabled]="locationsIsLoading" class="btn btn-light btn-sm">
        <i class="la la-compress-arrows-alt"></i>
      </button>
    </div>
    <fieldset>
      <div [isDisabled]="locationsSelectedNodes.length === 0" class="btn-group mr-1" dropdown>
        <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle type="button"
          [disabled]="auth.isViewer('location')">
          <ng-container *ngIf="locationsSelectedNodes.length > 0">{{
            "navigations.with" | translate }} <strong>{{ locationsSelectedNodes.length
              }}</strong> {{
            "navigations.selected" | translate }}
          </ng-container>
          <ng-container *ngIf="locationsSelectedNodes.length === 0">{{ "navigations.no_selection" | translate
            }}</ng-container>
          <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu">
          <ng-container *ngIf="!auth.isViewer('location')">
            <li (click)="locationShowMassEditModal()" class="btn btn-sm dropdown-item">
              <i class="la la-fw la-pencil-alt"></i> {{ "navigations.edit" | translate }}
            </li>
            <li (click)="locationClone()" class="btn btn-sm dropdown-item">
              <i class="la la-fw la-clone"></i> {{ "navigations.clone" | translate }}
            </li>
            <li role="menuitem">
              <a (click)="locationShowMassDeleteConfirm()" class="dropdown-item btn btn-sm btn-danger">
                <i class="la la-fw la-trash"></i> {{ "navigations.delete" | translate }}
              </a>
            </li>
            <li class="divider dropdown-divider"></li>
          </ng-container>
          <li (click)="locationsGrid?.export('xlsx')" class="btn btn-sm dropdown-item">
            <i class="la la-fw la-file-excel"></i> XLSX
          </li>
          <li (click)="locationsGrid?.export('csv')" class="btn btn-sm dropdown-item">
            <i class="la la-fw la-file-csv"></i> CSV
          </li>
        </ul>
      </div>
    </fieldset>
  </div>
  <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
    <app-grid [gridUid]="'map_location_list'" (rowClicked)="locationOnRowClicked($event)" (rowDoubleClicked)="
        auth.isViewer('location') ? null : locationOnRowDoubleClicked($event)
      " (selectionChanged)="locationOnSelectionChanged($event)" [autoGroupColumnDef]="locationsGridAutoGroupColumnDef"
      [gridOptions]="locationsGridOptions" [groupSelectsChildren]="false" [rowData]="locations" class="ag-theme-alpine"
      style="width: 100%; height: 100%">
    </app-grid>
  </div>
</div>