import { Component, OnInit } from '@angular/core';
import {AuthService, Broadcaster, DataService} from '../../../../shared/services';
import {Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class AuthSiteListComponent implements OnInit {

  // Available items
  items: any[] = [];
  // UI flag
  isLoading = false;

  constructor(
    public auth: AuthService,
    public router: Router,
    public data: DataService
  ) {
  }

  /**
   */
  ngOnInit(): void {
    this.loadItems();
  }

  /**
   */
  async loadItems(): Promise<void> {
    this.isLoading = true;
    this.items = await this.data.get(
      'api/access/sites'
    ).toPromise();
    this.isLoading = false;
  }

  /**
   * @param data
   */
  async setSite(data: any): Promise<void> {
    const toPost = {
      account: this.auth.getAccount(),
      site: {id: data.id, name: data.name},
      role: data.role
    };
    const token = await this.data.post(
      'api/users/ut', toPost
    ).toPromise();
    this.auth.clearSection();
    this.auth.clearState(['fpm_models_tasks', 'fpm_models_tasks_from_models']);
    this.auth.saveUser(token);
    this.router.navigate(['/modules']);
  }
}

