import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DataService, AuthService, ToasterService } from 'src/app/shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ComponentModel } from '../../../../shared/models';

@Component({
  selector: 'app-appearance-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class AppearanceEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  imageUrl: any = '';

  // form instance
  form!: UntypedFormGroup;

  uploadUrl = 'api/images';

  // Usage mode
  mode: ('mass' | 'single' | 'new') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;

  // Form reference Data
  parentReference: any;

  // File Object to upload
  fileToUpload: File | null = null;

  // Is file upload complete
  uploaded = false;
  storyId: any;

  //
  isUploading = false;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);
    // get target Ids
    this.targetIds = _.map(this.items, el => el.id);
    // Build for
    this.form = this.getForm(this.items[0]);

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [ComponentModel?]): ('mass' | 'single' | 'new') {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  /**
   *
   */
  getForm(item: any): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(this.auth.getAccountId()),
      name: new UntypedFormControl(item.name),
      color: new UntypedFormControl(item.color || '#ff0000'),
      logo: new UntypedFormControl(item.logo),
    });
  }

  handleFileInput(e: Event): boolean {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    this.fileToUpload = (target.files as FileList)[0];
    return true;
  }

  /**
   *
   */
  save(): boolean | void {
    if (!this.isFormValid()) {
      return false;
    }

    if (!this.fileToUpload) {
      this.saveAccount();
      return true;
    }

    this.isSubmitted = true;
    const formData: FormData = new FormData();
    formData.append('entityType', 'logo');
    formData.append('account', '' + this.auth.getAccountId());
    formData.append('images[]', this.fileToUpload as File, (this.fileToUpload as File).name);
    this.service.postFiles(this.uploadUrl, formData)
      .subscribe((res) => {
        this.form.get('logo')?.setValue(res[0].name || null);
        this.saveAccount();
      },
        (err) => {
          this.isSubmitted = false;
        }
      );
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => { };

  isFormValid(): boolean {
    return !(!this.form || (this.form && !this.form.valid));
  }

  /**
   *
   */
  saveAccount(): boolean | void {
    // Query pointer
    let query;

    if (this.mode === 'new') {
      const tmpData = _.pickBy(this.form.value, el => el !== null);
      query = this.service.post('api/access/accounts', tmpData);
    } else if (this.mode === 'single') {
      query = this.service.update('api/accounts', this.auth.getAccountId(), this.form.value);
    }

    this.isSubmitted = true;
    query?.subscribe(res => {
      this.isSubmitted = false;
      this.confirmFn(res);
      this.bsModalRef.hide();
    }, err => {
      this.isSubmitted = false;
    });
  }
}
