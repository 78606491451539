import { ConfCategoryModel } from './ConfCategoryModel';

export class ConfConsequenceModel {
  id: number;
  cost: number;
  description: string;

  account: string;
  category: ConfCategoryModel;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.cost = data.cost ? data.cost : 0;
    this.description = data.description ? data.description : '';

    this.account = data.account ? data.account : null;
    this.category = data.category ? data.category : ConfCategoryModel;
  }
}
