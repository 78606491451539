<ng-container>
  <div class="modal-body">
    <h3 *ngIf="!tasksIsLoading && tasks.length > 0">{{ tasks[0].model }}</h3>
    <div class="d-flex flex-column h-100 mt-3">
      <app-loader *ngIf="tasksIsLoading"></app-loader>
      <div
        *ngIf="!tasksIsLoading"
        class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container"
      >
        <app-grid
          [gridUid]="'fpm_models_tasks'"
          [autoGroupColumnDef]="tasksGridAutoGroupColumnDef"
          [gridOptions]="gridOptions"
          [rowData]="tasks"
          class="ag-theme-alpine"
          style="width: 100%; height: 500px; max-height: 500px"
        >
        </app-grid>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
