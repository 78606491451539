<div class="d-flex flex-column h-100 w-100">
  <div class="d-flex align-items-center px-2">
    <div class="mr-2">
      <button
        (click)="loadItems()"
        [ngClass]="{ 'btn-success': isLoading, 'btn-light': !isLoading }"
        class="btn btn-sm"
      >
        <i [ngClass]="{ 'la-spin': isLoading }" class="la la-refresh"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        (click)="assetGrid?.expandAll()"
        [disabled]="isLoading"
        class="btn btn-light btn-sm"
      >
        <i class="la la-expand-arrows-alt"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        (click)="assetGrid?.collapseAll()"
        [disabled]="isLoading"
        class="btn btn-light btn-sm"
      >
        <i class="la la-compress-arrows-alt"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        (click)="setDisplayTree(true)"
        [disabled]="isLoading"
        class="btn btn-light btn-sm"
      >
        <i class="las la-stream"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        (click)="setDisplayTree(false)"
        [disabled]="isLoading"
        class="btn btn-light btn-sm"
      >
        <i class="las la-th-list"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        alt="clear filter"
        (click)="assetGrid?.clearAllFilters()"
        [disabled]="!assetGrid?.isFilterApplied"
        [ngClass]="{
          'clear-filter position-relative': assetGrid?.isFilterApplied
        }"
        class="btn btn-light btn-sm"
      >
        <i class="las la-filter"></i>
      </button>
    </div>
    <fieldset>
      <div class="mr-2" style="float: left">
        <button
          (click)="showEditModal()"
          [disabled]="isLoading || auth.isViewer('asset')"
          class="btn btn-success btn-sm"
        >
          <i class="la la-plus"></i>
        </button>
      </div>
      <div
        [isDisabled]="selectedNodes.length === 0"
        class="btn-group mr-2"
        style="float: left"
        dropdown
      >
        <button
          class="dropdown-toggle btn btn-sm btn-primary"
          dropdownToggle
          type="button"
        >
          <ng-container *ngIf="selectedNodes.length > 0"
            >{{ "navigations.with" | translate }}
            <strong>{{ selectedNodes.length }}</strong>
            {{ "navigations.selected" | translate }}
          </ng-container>
          <ng-container *ngIf="selectedNodes.length === 0">{{
            "navigations.no_selection" | translate
          }}</ng-container>
          <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu">
          <ng-container *ngIf="!auth.isViewer('asset')">
            <li (click)="showMassEditModal()" class="btn btn-sm dropdown-item">
              <i class="la la-fw la-pencil-alt"></i>
              {{ "navigations.edit" | translate }}
            </li>
            <li role="menuitem">
              <a
                (click)="assetClone()"
                class="dropdown-item btn btn-sm btn-primary"
              >
                <i class="la la-fw la-clone"></i>
                {{ "navigations.clone" | translate }}
              </a>
            </li>
            <li role="menuitem">
              <a
                class="dropdown-item btn btn-sm btn-danger"
                (click)="showMassDeleteConfirm()"
              >
                <i class="la la-fw la-trash"></i>
                {{ "navigations.delete" | translate }}
              </a>
            </li>
            <li class="divider dropdown-divider"></li>
          </ng-container>
          <li
            (click)="assetGrid?.export('xlsx')"
            class="btn btn-sm dropdown-item"
          >
            <i class="la la-fw la-file-excel"></i> XLSX
          </li>
          <li
            (click)="assetGrid?.export('csv')"
            class="btn btn-sm dropdown-item"
          >
            <i class="la la-fw la-file-csv"></i> CSV
          </li>
          <li
            (click)="copyToClipboard()"
            class="btn btn-sm dropdown-item copy-icon"
          >
            <i class="las la-clipboard"></i>
            {{ "navigations.copy_to_clipboard" | translate }}
          </li>
        </ul>
      </div>
      <div class="mr-2" style="float: left">
        <button
          [disabled]="isLoading || auth.isViewer('asset')"
          (click)="showImportModal()"
          class="btn btn-sm btn-primary"
        >
          <i class="la la-fw la-file-csv"></i>
          {{ "navigations.asset.import_update" | translate }}
        </button>
      </div>
      <div class="mr-2" style="float: left">
        <button
          [disabled]="isLoading || auth.isViewer('asset')"
          (click)="showMatchAssetModal()"
          class="btn btn-sm btn-primary"
        >
          <i class="la la-fw la-file-csv"></i>
          {{ "navigations.match_select" | translate }}
        </button>
      </div>
    </fieldset>
  </div>
  <div class="d-flex h-100 w-100 flex-grow-1 p-2 ag-grid-container">
    <app-grid
      [gridUid]="'assets'"
      (rowDoubleClicked)="auth.isViewer('asset') ? null : onRowClicked($event)"
      (selectionChanged)="onSelectionChanged($event)"
      [autoGroupColumnDef]="assetsGridAutoGroupColumnDef"
      [gridOptions]="gridOptions"
      [rowData]="items"
      [groupSelectsChildren]="false"
      class="ag-theme-alpine"
      style="width: 100%; height: 100%"
    >
    </app-grid>
  </div>
</div>
