import {Component, OnInit} from '@angular/core';
import {AccountModel} from 'src/app/shared/models';
import {AuthService, Broadcaster, DataService} from 'src/app/shared/services';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-account-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class AuthAccountListComponent implements OnInit {

  // Available items
  items: any[] = [];
  // UI flag
  isLoading = false;

  constructor(
    public auth: AuthService,
    public router: Router,
    public data: DataService,
    public broadcast: Broadcaster
  ) {
  }

  /**
   */
  ngOnInit(): void {
    this.loadItems();
  }

  /**
   */
  async loadItems(): Promise<void> {
    this.isLoading = true;
    this.items = await this.data.get(
      'api/access/accounts'
    ).toPromise();
    this.isLoading = false;
  }

  /**
   * @param account
   */
  async setAccount(account: any): Promise<void> {
    const toPost = {
      account
    };
    const token = await this.data.post(
      'api/users/ut',
      toPost
    ).toPromise();
    this.auth.clearSection();
    this.auth.clearState(['fpm_models_tasks', 'fpm_models_tasks_from_models']);
    this.auth.saveUser(token);
    this.router.navigate(['/sites']);
  }

}
