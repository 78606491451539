import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImpactModel } from '../../../shared/models';
import { ConfirmModalComponent } from '../../../shared/modals';
import { GridComponent } from '../../../shared';
import { ImpactEditComponent } from './impact-edit/edit.component';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../../shared/services/breadcrumb.service';

@Component({
  selector: 'app-impacts',
  templateUrl: './impacts.component.html',
})
export class ImpactsListComponent implements OnInit, OnChanges {
  @ViewChild(GridComponent, { static: true }) impactGrid?: GridComponent;

  @Input() selectedFunctionLossNodes: any[] = [];
  @Output() resetModelsGrid = new EventEmitter<any>();
  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  isReferenceLoaded = false;
  productsReference?: any;
  configConsequencesReference?: any;
  impactGridAutoGroupColumnDef?: any;
  modelDescriptions: any = {};
  functionFailureDescriptions: any = {};
  productDescriptions: any = {};

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    const tmpGridOptions: GridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1, // expand all groups by default
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.fastcr.risk_model.risk_model'
          ),
          field: 'model_name',
          valueGetter: (params: any) => {
            if (params.data) {
              return params.data.model_id;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.model_name;
            }
          },
          cellRenderer: (params: any) => {
            return this.modelDescriptions?.[params.value];
          },
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.function_loss.function_loss'
          ),
          field: 'function_failure_description',
          valueGetter: (params: any) => {
            if (params.data) {
              return params.data.function_failure_id;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.function_failure_description;
            }
          },
          cellRenderer: (params: any) => {
            return this.functionFailureDescriptions?.[params.value];
          },
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastcr.function_loss.id'),
          field: 'function_loss_id',
          hide: true,
        },
      ],
    };
    this.impactGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.fastcr.impact.consequence'),
      field: 'consequence_description',
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    await this.loadItems();
    await this.loadProducts();

    Object.values(this.productsReference).forEach((obj: any) => {
      tmpGridOptions.columnDefs?.push({
        headerName: obj.name,
        field: `probabilityConsequence_${obj.value}`,
        cellRenderer: (params: any): string => {
          if (params.node.group) {
            return '';
          }
          if (params.value !== undefined) {
            return params.value + '%';
          }
          return 'N/A';
        },
      });
    });
    this.gridOptions = tmpGridOptions;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedFunctionLossNodes) {
      this.filterImpacts(this.selectedFunctionLossNodes);
    }
  }

  clearFilterAndResetModelsGrid(): void {
    this.impactGrid?.clearAllFilters();
    this.resetModelsGrid.emit();
  }

  filterImpacts(riskModels: any): void {
    const instance =
      this.impactGrid?.gridApi?.getFilterInstance('function_loss_id');

    if (riskModels.length > 0) {
      setTimeout(() => {
        instance?.setModel({
          values: _.map(riskModels, (el) => el.id + ''),
        });
        this.impactGrid?.gridApi?.onFilterChanged();
      });
      return;
    }

    instance?.setModel(null);
    this.impactGrid?.gridApi?.onFilterChanged();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    const items = await this.data
      .by('api/fastcrit/impacts', {
        criteria: {
          site: this.auth.getSiteId(),
        },
      })
      .toPromise();

    // prepare consequnce wise data
    const records: any = {};

    // this.impactGrid?.gridApi?.sizeColumnsToFit();
    this.impactGrid?.uncheckAll();
    this.filterImpacts(this.selectedFunctionLossNodes);
    // set data array to use ID as value in valueGetter and Description as display value.
    // This is to allow grouping by ID instead of display value ("name" for eg.) thereby avoid grouping on duplicate display values
    items.forEach((item: any) => {
      this.modelDescriptions[item.fastcrFunctionLoss.fastcrModel.id] =
        item.fastcrFunctionLoss.fastcrModel.description;
      this.functionFailureDescriptions[
        item.fastcrFunctionLoss.configFunctionalFailure.id
      ] = item.fastcrFunctionLoss.configFunctionalFailure.description;
      this.productDescriptions[item.configProduct.id] = {
        id: item.configProduct.id,
        value: item.configProduct.description,
      };

      const uniqueKey = `${item.fastcrFunctionLoss.fastcrModel.id}_${item.fastcrFunctionLoss.configFunctionalFailure.id}_${item.configConsequence.id}`;
      records[uniqueKey] = {
        ...records[uniqueKey],
        [`probabilityConsequence_${item.configProduct.id}`]:
          item.probabilityConsequence,
        model_name: item.fastcrFunctionLoss.fastcrModel.description,
        model_id: item.fastcrFunctionLoss.fastcrModel.id,
        function_failure_description:
          item.fastcrFunctionLoss.configFunctionalFailure.description,
        function_failure_id: item.fastcrFunctionLoss.configFunctionalFailure.id,
        consequence_description: item.configConsequence.description,
        consequence_id: item.configConsequence.id,
        function_loss_id: item.fastcrFunctionLoss.id,
        id: item.id,
      };
    });

    // assign formatted values
    this.items = Object.values(records);

    if (!silent) {
      this.isLoading = false;
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onRowClicked(event: any): void {
    if (!event) {
      return;
    }
    this.showEditModal([event]);
  }

  showMassEditModal(): void {
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      const items = _.map(this.selectedNodes, (el) => el.data);
      this.showEditModal(items);
    }
  }

  /**
   * @param items
   */
  async showEditModal(items?: any[]): Promise<void> {
    await this.loadConsequences();
    await this.loadProducts();
    this.bsModalRef = this.bsModalService.show(ImpactEditComponent, {
      initialState: {
        items,
        productsReference: this.productsReference,
        configConsequencesReference: this.configConsequencesReference,
        selectedFunctionLossNodes: this.selectedFunctionLossNodes,
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      this.toastr.showSuccess();
    };
  }

  // Load Products used in modal edit form
  async loadProducts(): Promise<void> {
    // Products
    const orderByDesc = { orderBy: { description: 'asc' } };
    const tpm = await this.data
      .by('api/config/products', {
        criteria: {
          account: this.auth.getAccountId(),
        },
        ...orderByDesc,
      })
      .toPromise();
    this.productsReference = _.map(tpm, (el: any) => {
      return {
        name: el.description,
        value: el.id,
      };
    });
  }

  // Load Consequences used in modal edit form
  async loadConsequences(): Promise<void> {
    // Consequences
    const tpm = await this.data
      .by('api/config/consequences', {
        criteria: {
          account: this.auth.getAccountId(),
        },
      })
      .toPromise();

    const data = _.orderBy(tpm, ['category_name', 'cost'], ['asc', 'asc']);
    this.configConsequencesReference = _.map(data, (el: any) => {
      return {
        name: el.description,
        value: el.id,
      };
    });

    // Mark ref as loaded (prevent reload)
    this.isReferenceLoaded = true;
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const data = _.map(this.selectedNodes, (el) => ({
        function_loss: el.data.function_loss_id,
        configConsequence: el.data.consequence_id,
      }));
      this.data.post('api/fastcrit/impacts/delete-all', data).subscribe(
        (res) => {
          this.loadItems();
          const message = res.success
            ? 'messages.delete'
            : 'messages.delete_partial';
          this.toastr.showSuccess(message);
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  myCellCallback = (params: any) => {
    return params.value;
  };

  exportProcessCallBack = (cellParams: any) => {
    if (cellParams.value && cellParams.column.colId === 'model_name') {
      return this.modelDescriptions?.[cellParams.value];
    }
    if (
      cellParams.value &&
      cellParams.column.colId === 'function_failure_description'
    ) {
      return this.functionFailureDescriptions?.[cellParams.value];
    }
    if (cellParams.value && cellParams.node.field === 'model_name') {
      return this.modelDescriptions?.[cellParams.node.key];
    }

    if (
      cellParams.value &&
      cellParams.node.field === 'function_failure_description'
    ) {
      return this.functionFailureDescriptions?.[cellParams.node.key];
    }

    if (cellParams.node.group) {
      return '';
    }

    if (`${cellParams.column.colId}`.startsWith('probabilityConsequence')) {
      if (cellParams.value) {
        return cellParams.value + '%';
      }
      return 'N/A';
    }

    return cellParams.value;
  };
}
