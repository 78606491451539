export class FrequencyModel {
  id: number;
  name: string;
  duration: number;
  accountId: number;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.name = data.name ? data.name : '';
    this.duration = data.duration ? data.duration : 0;

    this.accountId = data.accountId ? data.accountId : null;
  }
}
