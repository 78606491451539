<div class="modal-body">
  <tabset>
    <tab (selectTab)="tabChange('asset')" heading="{{ 'navigations.asset.choose' | translate }}">
      <div style="height: 450px">
        <div class="d-flex flex-column h-100 w-100">
          <div class="d-flex align-items-center px-2">
            <div class="mr-2">
              <button (click)="loadItems()" [ngClass]="{'btn-success': isLoading, 'btn-light': !isLoading}"
                class="btn btn-sm">
                <i [ngClass]="{'la-spin': isLoading}" class="la la-refresh"></i>
              </button>
            </div>
            <div class="mr-2">
              <div class="btn-group mr-1" dropdown>
                <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle type="button">
                  {{ currentFilter.label }}
                </button>
                <ul *dropdownMenu class="dropdown-menu">
                  <li (click)="setFilter(filter)" *ngFor="let filter of filters" class="btn btn-sm dropdown-item">
                    {{ filter.label }}
                  </li>
                </ul>
              </div>
            </div>

          </div>
          <div class="d-flex h-100 w-100 flex-grow-1  p-2 ag-grid-container">
            <app-grid (filterChanged)="onFilterChanged($event)" (rowClicked)="onRowClicked($event)"
              (selectionChanged)="onSelectionChanged($event)" [gridOptions]="gridOptions"
              [readyCallBack]="gridReadyCallBack" [rowData]="items" class="ag-theme-alpine"
              style="width:100%;height:100%;">
            </app-grid>
          </div>
        </div>
      </div>
    </tab>
    <tab *ngIf="form" (selectTab)="tabChange('tag')" heading="Add Tag">
      <form [formGroup]="form">
        <div class="form-row">
          <div class="form-group col-12">
            <label [class.font-weight-bold]="form.get('title')?.dirty" for="title">
              {{ 'tags.title' | translate }}
            </label>
            <textarea class="form-control form-control-sm" formControlName="title" id="title"></textarea>
          </div>
        </div>
      </form>
    </tab>
  </tabset>
</div>
<div class="modal-footer">
  <div class="form-group">
    <div class="col-12 d-flex justify-content-between">
      <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
        <i class="la la-ban"></i>
        {{ 'navigations.cancel' | translate }}
      </button>
      <button (click)="save()" [disabled]="isSubmitted" class="btn btn-sm btn-success">
        <i *ngIf="!isSubmitted" class="la la-save"></i>
        <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
        {{ 'navigations.save' | translate }}
      </button>
    </div>
  </div>
</div>