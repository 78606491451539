import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DataService, AuthService, ToasterService } from 'src/app/shared/services';
import { PlanModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-plan-edit',
  templateUrl: './edit.component.html'
})
export class RoutingPlanEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  statusReference: any;

  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: ('mass' | 'single') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   * @param toastr
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private toastr: ToasterService,
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.targetIds = _.map(this.items, el => el.id);

    // Build for
    this.form = this.getForm(this.items[0]);

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [PlanModel?]): ('mass' | 'single') {
    // More than one item => Mass Edit
    if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  /**
   *
   */
  getForm(item: PlanModel): UntypedFormGroup {

    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      name: new UntypedFormControl(item.name),
      status: new UntypedFormControl(item.status),
      site: new UntypedFormControl(this.auth.getSiteId()),
      quality: new UntypedFormControl(item.quality),
      safety: new UntypedFormControl(item.safety),
      parts: new UntypedFormControl(item.parts),
      actualDuration: new UntypedFormControl(item.actualDuration),
      cmmsReference: new UntypedFormControl(item.cmmsReference),
      comments: new UntypedFormControl(item.comments)
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => { };

  /**
   *
   */
  checkName(): void {
    let name = this.form?.get('name')?.value;

    const pattern = /^[a-zA-Z0-9^\- ()_{.}]*$/;
    if (!pattern.test(name)) {
      name = name.replace(/[^a-zA-Z0-9^\- ()_{.}]/g, '');
    }
    this.form?.get('name')?.setValue(name);
  }

  /**
   *
   */
  save(): boolean | void {
    if (!this.form || (this.form && !this.form.valid)) {
      return false;

    } else {
      // Query pointer
      let query;

      if (this.mode === 'single') {
        query = this.service.update('api/plans', this.targetIds[0], this.form.value);

      } else {
        const tmpData: any = {};
        _.each(this.form.controls, (control, name) => {
          if (control.dirty === true && control.value !== -1) {
            tmpData[name] = control.value;
          }
        });
        const data = _.map(this.targetIds, id => {
          const cloned = _.cloneDeep(tmpData);
          _.merge(cloned, { id });
          return cloned;
        });

        query = this.service.saveAll('api/plans', data);
      }

      this.isSubmitted = true;
      query.subscribe(res => {
        this.isSubmitted = false;
        this.confirmFn(res);
        this.bsModalRef.hide();
      }, err => {
        this.toastr.showError(err.error.message);
        this.isSubmitted = false;
      });
    }
  }
}
