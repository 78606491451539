import { Component, Input } from '@angular/core';

@Component({
  template: `
      <div class="modal-header">
        <h4 *ngIf="title" class="mb-0  alert-heading text-primary" (click)="linkFn()"><a class="btn font-weight-bold btn-link">{{ title | translate }}</a></h4>
      </div>
      <div class="modal-body text-right">
        <p *ngIf="content">{{ content | translate }}</p>
        <button type="button" class="btn btn-danger btn-sm" (click)="confirmFn()">{{ 'navigations.position.delete' | translate }}</button>
        <button type="button" class="btn btn-light btn-sm ml-2" (click)="cancelFn()"><i class="la la-ban"></i>
        {{ "navigations.cancel" | translate }}</button>
      </div>
    `
})
export class DeletePositionAssetComponent {
  // Modal title
  @Input()
  title: string | undefined;
  // Modal content
  @Input()
  content: string | undefined;
  // Cancel class
  @Input()
  cancelClass: string | undefined;
  // Cancel text
  @Input()
  cancelText: string | undefined;
  // Confirm class
  @Input()
  confirmClass: string | undefined;
  // Confirm text
  @Input()
  confirmText: string | undefined;

  /**
   * Action on cancel.
   */
  cancelFn(): void {
  }

  /**
   * Action on confirm.
   */
  confirmFn(): void {
  }

  /**
   * Action on confirm.
   */
  linkFn(): void {
  }
}
