<ng-container>
  <div class="modal-body">
    <ng-container *ngIf="!afterPaste">
      <form [formGroup]="form">
        <div class="form-row">
          <div class="form-group col-12">
            <label
              [class.font-weight-bold]="form.get('names')?.dirty"
              for="names"
            >
              {{ "assets.paste_asset_names" | translate }}
            </label>
            <textarea
              class="form-control form-control-lg"
              formControlName="names"
              id="names"
              style="height: 350px"
            ></textarea>
          </div>
          <div
            class="text-danger"
            *ngIf="
              showValidationError &&
              (form.get('names')?.hasError('whitespace') ||
                form.get('names')?.errors?.required)
            "
          >
            {{ "messages.asset.invalid" | translate }}
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="afterPaste">
      <ng-container *ngIf="unmatchedAssets.length > 0">
        <h6>
          {{ matchedAssetsCount }} {{ " " }}
          {{ "messages.asset.of" | translate }} {{ " " }}
          {{ pastedAssetsCount }} {{ " " }}
          {{ "messages.asset.assets_matched" | translate }}
        </h6>
        <h6>{{ "messages.asset.following_not_found" | translate }}</h6>
        <ul>
          <li *ngFor="let assetName of unmatchedAssets">{{ assetName }}</li>
        </ul>
      </ng-container>
      <ng-container *ngIf="unmatchedAssets.length === 0">
        <h6>
          {{ "messages.asset.all_matched" | translate }}
        </h6>
      </ng-container>
    </ng-container>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i
            class="la"
            [ngClass]="{
              'la-check': actionBtn === 'ok',
              'la-close': actionBtn === 'cancel'
            }"
          ></i>
          {{ "navigations." + actionBtn | translate }}
        </button>
        <ng-container *ngIf="!afterPaste">
          <button
            (click)="submit()"
            [disabled]="isSubmitted"
            class="btn btn-sm btn-success"
          >
            <i *ngIf="!isSubmitted" class="la la-save"></i>
            <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
            {{ "navigations.submit" | translate }}
          </button>
        </ng-container>

        <ng-container *ngIf="afterPaste && unmatchedAssets.length > 0">
          <button
            (click)="downloadFile()"
            [disabled]="isSubmitted"
            class="btn btn-sm btn-success"
          >
            <i *ngIf="!isSubmitted" class="la la-save"></i>
            <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
            {{ "navigations.download" | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
