import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../shared';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: UntypedFormGroup | undefined;
  isLoading: boolean;
  email: string | null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public auth: AuthService,
    public router: Router
  ) {
    this.isLoading = false;
    this.email = this.auth.getEmail();
  }

  /**
   *
   */
  ngOnInit(): void {
    this.form = this.getForm();
  }

  /**
   *
   */
  getForm(): UntypedFormGroup {
    return this.formBuilder.group({
      _username: [(this.email !== null ? this.email : ''), [Validators.required]],
      _password: ['', Validators.required]
    });
  }

  /**
   *
   * @param ev
   */
  loginCheck(ev: any): void {
    if (this.form) {
      this.isLoading = true;
      const credentials = this.form.value;
      this.auth.loginCheck(credentials).subscribe(
        // authentification Ok
        (data) => {
          this.auth.setEmail(credentials._username);
          this.auth.saveUser(data);
          this.isLoading = false;
          this.router.navigate(['/accounts']);
        },
        // authentification Ko
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
    }
  }

}
