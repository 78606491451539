import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RiskModelModel } from '../../../shared/models';
import { ConfirmModalComponent } from '../../../shared/modals';
import { GridComponent } from '../../../shared';
import { RiskModelEditComponent } from './risk-model-edit/edit.component';
import { FunctionLossListComponent } from './function-loss.component';
import { ImpactsListComponent } from './impacts.component';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../../shared/services/breadcrumb.service';
import { RiskModelAddComponent } from './risk-model-add/add.component';
import { RiskModelAssetListComponent } from './risk-model-asset-list/risk-model-asset-list.component';

@Component({
  selector: 'app-risk-models',
  templateUrl: './risk-models.component.html',
})
export class RiskModelsListComponent implements OnInit {
  @ViewChild(GridComponent, { static: true }) riskModelsGrid?: GridComponent;
  @ViewChild(FunctionLossListComponent)
  functionLossListComponent?: FunctionLossListComponent;
  @ViewChild(ImpactsListComponent) impactsListComponent?: ImpactsListComponent;

  // Available items
  items: any[] = [];
  selectedRiskModelNodes: any[] = [];
  selectedFunctionLossNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  isReferenceLoaded = false;
  sitesReferences?: [];
  sizeColumnsToFit = true;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.risk_models'),
          link: '/fastcrit/risk-models',
        },
      ],
      2
    );
    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.fastcr.risk_model.model'),
          field: 'description',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          filter: 'agTextColumnFilter',
          filterParams: { newRowsAction: 'keep' },
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.risk_model.comment'
          ),
          field: 'comment',
        },
        {
          headerName: this.translate.instant('headers.model.asset_count'),
          field: 'assetCount',
          hide: false,
          cellRenderer: (params: any): any => {
            const aTag = document.createElement('a');
            aTag.href = 'javascript:void(0)';
            aTag.onclick = () => {
              this.showAssetListModal(params.data.id);
            };
            aTag.innerHTML = params.value;
            return aTag;
          },
        },
      ],
    };
    await this.loadItems();
  }

  async showAssetListModal(modelId: number): Promise<void> {
    this.bsModalRef = this.bsModalService.show(RiskModelAssetListComponent, {
      initialState: {
        modelId,
      },
      class: 'modal-xl',
    });
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data
      .get('api/fastcrit/risk-models/from_site')
      .toPromise();
    if (this.sizeColumnsToFit) {
      this.riskModelsGrid?.gridApi?.sizeColumnsToFit();
      this.sizeColumnsToFit = false;
    }
    this.riskModelsGrid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  onFilterChanged(event: any): void {}

  onSelectionChanged(event: any): void {
    this.selectedRiskModelNodes = event;
  }

  onRowClicked(event: any): void {
    this.showEditModal([new RiskModelModel(event)]);
  }

  updateSelectedFunctionLossNodes(event: any): void {
    this.selectedFunctionLossNodes = event;
  }

  resetModelsGrid(): void {
    this.riskModelsGrid?.uncheckAll();
    this.functionLossListComponent?.resetFunctionLossGrid();
  }

  async showAddRiskModelModal(): Promise<void> {
    const orderByName = { orderBy: { name: 'asc' } };
    const criteria = {
      criteria: {
        account: [this.auth.getAccountId(), null],
      },
      ...orderByName,
    };
    this.sitesReferences = await this.data
      .by('api/sites', criteria)
      .toPromise();
    this.isReferenceLoaded = true;

    this.bsModalRef = this.bsModalService.show(RiskModelAddComponent, {
      initialState: {
        sitesReferences: this.sitesReferences,
      },
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      this.functionLossListComponent?.loadItems();
      this.impactsListComponent?.loadItems();
      this.toastr.showSuccess();
    };
  }

  duplicateModels(): void {
    this.isLoading = true;
    const ids = _.map(this.selectedRiskModelNodes, (el) => el.id);

    this.data.post('api/fastcrit/risk-models/duplicate-all', ids).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.loadItems();
        this.functionLossListComponent?.loadItems();
        this.impactsListComponent?.loadItems();
        this.toastr.showSuccess();
      },
      (err: any) => {
        console.log('err: ', err);
        this.isLoading = false;
      }
    );
  }

  /**
   * @param items
   */
  async showEditModal(items?: RiskModelModel[]): Promise<void> {
    this.bsModalRef = this.bsModalService.show(RiskModelEditComponent, {
      initialState: {
        items,
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      this.toastr.showSuccess();
    };
  }

  onFunctionLossDelete(event: boolean): any {
    this.impactsListComponent?.loadItems();
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedRiskModelNodes, (el) => el.id);
      this.data.post('api/fastcrit/risk-models/delete-all', ids).subscribe(
        (res) => {
          this.loadItems();
          this.toastr.showSuccess('messages.delete');
          this.functionLossListComponent?.loadItems();
          this.impactsListComponent?.loadItems();
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  myCellCallback = (params: any) => {
    return params.value;
  };
}
