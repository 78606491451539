<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group col-12">
          <label [class.font-weight-bold]="form.get('accesses')?.dirty">
            {{
              "modules.admin.asset_parameters.children.access_requirements.name"
                | translate
            }}
          </label>
          <app-field-clear-button
            [formGroupRef]="form"
            [fieldName]="'accesses'"
          ></app-field-clear-button>
          <ngx-select
            [allowClear]="true"
            [items]="accessReference"
            [placeholder]="'(Select Access Requirements)'"
            formControlName="accesses"
            id="accesses"
            [multiple]="true"
            optionTextField="description"
            optionValueField="id"
            size="small"
          ></ngx-select>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
