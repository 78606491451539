<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('description')?.dirty"
            for="description"
          >
            {{
              "headers.risk_parameters.consequences.consequences" | translate
            }}
            <span class="text-danger">*</span>
          </label>
          <input
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('description')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="description"
            id="description"
            type="text"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('description')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label [class.font-weight-bold]="form.get('cost')?.dirty" for="cost">
            {{ "headers.risk_parameters.consequences.cost" | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            [ngClass]="{
              'is-invalid': showValidationError && form.get('cost')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="cost"
            id="cost"
            type="number"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('cost')?.errors?.required"
          >
            {{ "messages.required" | translate }}
          </div>
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('cost')?.errors?.min"
          >
            {{ "messages.positive" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('category')?.dirty"
            for="category"
          >
            {{
              "headers.risk_parameters.consequences_categories.categories"
                | translate
            }}
          </label>
          <app-field-clear-button
            [formGroupRef]="form"
            [fieldName]="'category'"
          ></app-field-clear-button>
          <ngx-select
            [items]="categoriesReference"
            [placeholder]="'(Select a Category)'"
            [ngClass]="{
              'is-invalid': showValidationError && form.get('category')?.invalid
            }"
            formControlName="category"
            id="category"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('category')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
