import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../../../shared/services';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActionBudgetModel } from '../../../../shared/models';
import { ConfirmModalComponent } from '../../../../shared/modals';
import { GridComponent } from '../../../../shared';
import { FastlcActionBudgetsEditComponent } from './edit/edit.component';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../../../shared/services/breadcrumb.service';
import { syncItems } from 'src/utils';

@Component({
  selector: 'app-fastlc-action-budgets',
  templateUrl: './action-budgets.component.html',
})
export class FastlcActionBudgetsListComponent implements OnInit {
  @ViewChild(GridComponent, { static: true }) actionBudgetsGrid?: GridComponent;

  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.parameters'),
          link: '/admin',
        },
        {
          name: this.translate.instant(
            'navigations.breadcrumbs.budget_categories'
          ),
          link: '/admin/fastlc/budget-categories',
        },
      ],
      2
    );
    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.lifecycle_parameters.budget_category.description'
          ),
          field: 'description',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          filter: 'agTextColumnFilter',
        },
      ],
    };
    await this.loadItems();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data
      .by('api/fastlc/action-budgets', {
        criteria: {
          account: this.auth.getAccountId(),
        },
      })
      .toPromise();
    this.actionBudgetsGrid?.gridApi?.sizeColumnsToFit();
    this.actionBudgetsGrid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onFilterChanged(event: any): void {}

  onRowClicked(event: any): void {
    this.showEditModal([new ActionBudgetModel(event)]);
  }

  /**
   * @param items
   */
  async showEditModal(items?: ActionBudgetModel[]): Promise<void> {
    this.bsModalRef = this.bsModalService.show(
      FastlcActionBudgetsEditComponent,
      {
        initialState: {
          items,
        },
      }
    );
    this.bsModalRef.content.confirmFn = (res: ActionBudgetModel) => {
      this.items = syncItems([res], this.items);
      this.toastr.showSuccess();
    };
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      this.isLoading = true;
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.data.post('api/fastlc/action-budgets/delete-all', ids).subscribe(
        (res) => {
          this.items = this.items.filter(
            (item: any) => !ids.includes(`${item.id}`)
          );
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        },
        () => {
          this.isLoading = false;
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  myCellCallback = (params: any) => {
    return params.value;
  };
}
