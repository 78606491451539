import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
  SharedDataService,
} from '../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RiskAssessmentModel } from '../../../shared/models';
import { ConfirmModalComponent } from '../../../shared/modals';
import { GridComponent } from '../../../shared';
import * as _ from 'lodash';
import { BreadCrumbService } from '../../../shared/services/breadcrumb.service';
import { RiskAssessmentEditComponent } from './edit/edit.component';
import { AssetSummaryComponent } from '../../../shared/components/asset-summary/asset-summary.component';
import { ProcessDetailsComponent } from '../../../shared/components/process-details/process-details.component';
import { ActivatedRoute } from '@angular/router';
import { ModelDetailComponent } from 'src/app/shared/components/model-details/model-detail.component';
import { AssetMatchComponent } from '../../assets/match/match.component';
import { copyToClipboard, syncItems } from 'src/utils';

@Component({
  selector: 'app-risk-assessment',
  templateUrl: './risk-assessment.component.html',
})
export class RiskAssessmentListComponent implements OnInit {
  @ViewChild(GridComponent, { static: true })
  riskAssessmentGrid?: GridComponent;

  // Available items
  items: any[] = [];
  filteredItems: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  isCSVExporting = false;
  isRecalculatingCrit = false;
  // Local grid options
  gridOptions?: GridOptions;
  displayTree = true;
  //
  riskAssessmentGridAutoGroupColumnDef: any;
  isDefaultAssetSelected = false;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  // Form reference Data
  isReferenceLoaded = false;
  processReference?: any;
  productsReference?: any;
  riskModelsReference?: any;
  configFactorReference?: any;
  critStatusClass: any = {
    draft: 'badge-light',
    current: 'badge-success',
    overridden: 'badge-danger',
    modified: 'badge-warning',
  };
  customHeaders?: any;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    protected sharedData: SharedDataService,
    public breadCrumbService: BreadCrumbService,
    private activatedRoute: ActivatedRoute
  ) {}

  setDisplayTree(flag: boolean): void {
    if (flag !== this.displayTree) {
      this.displayTree = flag;
      this.riskAssessmentGrid?.gridApi?.refreshClientSideRowModel();
    }
    this.setFilteredItemsForFlatList();
  }

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant(
            'navigations.breadcrumbs.risk_assessment'
          ),
          link: '/risk-assessment',
        },
      ],
      2
    );

    await this.loadProducts();
    await this.loadRiskModels();
    await this.loadConfigFactors();
    this.customHeaders = await this.sharedData.getAssetCustomHeaders(
      this.auth.getAccountId()
    );

    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      treeData: true,
      groupDefaultExpanded: -1, // expand all groups by default
      getDataPath: (data) => {
        if (this.displayTree) {
          return data.hierarchy;
        } else {
          return data.hierarchy.slice(-1);
        }
      },
      excludeChildrenWhenTreeDataFiltering: true,
      onRowDataChanged: (event) => this.filterAndSelectDefaultAsset(event),
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.asset.description'),
          field: 'description',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.risk_assessment.factor'
          ),
          valueGetter: (params: any) => {
            if (params.data !== undefined) {
              return params.data.fastcrAssetFactorsLabel.length
                ? params.data.fastcrAssetFactorsLabel
                : null;
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.risk_assessment.model'
          ),
          cellRenderer: (params: any): any => {
            if (!params.data) {
              return;
            }
            const modelIds = params.data.fastcrAssetModel;
            const modelDiv = document.createElement('div');
            params.data.fastcrAssetModelLabel.map((value: any, key: number) => {
              const aTag = document.createElement('a');
              aTag.href = 'javascript:void(0)';
              aTag.innerHTML = key === 0 ? value : ', ' + value;
              aTag.onclick = () => {
                this.showModelDetailModal(modelIds[key], value);
              };
              modelDiv.appendChild(aTag);
            });

            return modelDiv;
          },
          valueGetter: (params: any) => {
            if (params.data !== undefined) {
              return params.data.fastcrAssetModelLabel.length
                ? params.data.fastcrAssetModelLabel
                : null;
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.risk_assessment.product'
          ),
          valueGetter: (params: any) => {
            if (params.data !== undefined) {
              return params.data.configCrProductLabel.length
                ? params.data.configCrProductLabel
                : null;
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.risk_assessment.process'
          ),
          cellRenderer: (params: any) => {
            if (!params.data) {
              return;
            }
            const aTag = document.createElement('a');
            aTag.href = 'javascript:void(0)';
            aTag.onclick = () => {
              this.showProcessDetailsModal(params.data.fastcrAssetProcess);
            };
            aTag.innerHTML = params.value;
            return aTag;
          },
          valueGetter: (params: any) => {
            if (params.data !== undefined) {
              return params.data.fastcrAssetProcessLabel || null;
            }
          },
        },
        {
          headerName: this.translate.instant('headers.asset.type'),
          field: 'type',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.active'),
          field: 'active',
          hide: true,
          cellRenderer: (params: any): string => {
            if (params.value === true) {
              return (
                '<span class="badge badge-lg badge-success">' +
                this.translate.instant('navigations.yes') +
                '</span>'
              );
            } else {
              return (
                '<span class="badge badge-lg badge-danger">' +
                this.translate.instant('navigations.no') +
                '</span>'
              );
            }
          },
        },
        {
          headerName: this.translate.instant('headers.asset.ranking'),
          field: 'ranking',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.system'),
          field: 'system',
          hide: true,
          cellRenderer: (params: any): string => {
            if (params.value === true) {
              return (
                '<span class="badge badge-lg badge-success">' +
                this.translate.instant('navigations.yes') +
                '</span>'
              );
            } else {
              return (
                '<span class="badge badge-lg badge-danger">' +
                this.translate.instant('navigations.no') +
                '</span>'
              );
            }
          },
        },
        {
          headerName: this.translate.instant('headers.asset.parent'),
          field: 'parent',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.location.location'),
          field: 'location',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.parentId'),
          field: 'parentId',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.model.model_count'),
          field: 'modelCount',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.specifications'),
          field: 'specifications',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.drawing'),
          field: 'drawing',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom1,
          field: 'custom1',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom2,
          field: 'custom2',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom3,
          field: 'custom3',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom4,
          field: 'custom4',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom5,
          field: 'custom5',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom6,
          field: 'custom6',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmake'),
          field: 'eqmake',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmodel'),
          field: 'eqmodel',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqserial'),
          field: 'eqserial',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqsupplier'),
          field: 'eqsupplier',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'modules.admin.asset_parameters.children.access_requirements.name'
          ),
          field: 'accesses',
          valueGetter: (params: any): any[] => {
            if (params.data !== undefined && params.data.accesses.length > 0) {
              return _.map(params.data.accesses, (el) => el.description);
            }
            // for (Blanks) option
            return [''];
          },
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_1'
          ),
          field: 'unit_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_2'
          ),
          field: 'planningGroup_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_3'
          ),
          field: 'costCenter_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.responsible_group.type_4'
          ),
          field: 'workCenter_title',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.base_risk_amount'
          ),
          field: 'base_risk_amount',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.base_criticality'
          ),
          field: 'base_crit_name',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.actual_risk_amount'
          ),
          field: 'actual_risk_amount',
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.actual_criticality'
          ),
          field: 'actual_crit_name',
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.risk_assessment.status'
          ),
          field: 'critStatus',
          cellRenderer: (params: any): string => {
            if (params.data?.system && !params.value) {
              return '';
            }

            let status = params.value || 'not assessed';
            let statusClass = 'badge-primary';

            if (status in this.critStatusClass) {
              statusClass = this.critStatusClass[status];
            }
            // make first letter capital and replace _ with space
            status =
              status.charAt(0).toUpperCase() +
              status.slice(1).replace(/_/g, ' ');

            return (
              '<span class="badge badge-lg ' +
              statusClass +
              '">' +
              status +
              '</span>'
            );
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.risk_assessment.push_date'
          ),
          field: 'critPushDate',
        },
        {
          headerName: this.translate.instant(
            'headers.criticality.asset_criticality'
          ),
          field: 'criticality',
        },
      ],
    };
    this.riskAssessmentGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.asset.asset'),
      filter: 'agTextColumnFilter',
      valueGetter: (params: any) => {
        if (params.data !== undefined) {
          return params.data.name;
        }
      },
      cellRendererParams: {
        checkbox: (param: any) => !param?.data?.system,
        suppressDoubleClickExpand: true,
        innerRenderer: (params: any): any => {
          if (params.data) {
            const aTag = document.createElement('a');
            aTag.href = 'javascript:void(0)';
            aTag.onclick = () => {
              this.showSummaryModal(params.data.id);
            };
            aTag.innerHTML = params.data.name;
            return aTag;
          }
        },
      },
      sortable: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    await this.loadItems();
    this.setPinned();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data
      .get('api/fastcrit/risk-assessment/from-site-asset')
      .toPromise();
    this.setFilteredItemsForFlatList();
    this.riskAssessmentGrid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  private setFilteredItemsForFlatList(): void {
    if (!this.displayTree) {
      this.filteredItems = this.items.filter((item) => !item?.system);
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = _.filter(event, (el) => !el?.data?.system);
  }

  onRowClicked(event: any): void {
    if (event?.system) {
      return;
    }
    const selectedNode = this.riskAssessmentGrid?.gridApi?.getRowNode(event.id);
    selectedNode?.setSelected(true);
    this.selectedNodes = [event];
    this.showEditModal([new RiskAssessmentModel(event)]);
  }

  exportCriticalityDataset(): void {
    this.isCSVExporting = true;
    const selectedAssets = _.map(this.selectedNodes, (el) => el.id);

    this.data
      .download('api/fastcrit/risk-assessment/export-criticality-dataset', {
        assets: selectedAssets,
      })
      .subscribe(
        (res) => {
          const contentDisposition = res.headers.get('content-disposition');
          let fileName = contentDisposition.split(';')[1].trim().split('=')[1];
          fileName = fileName.replace(/"/g, '');
          const options = { type: res.body.type };
          this.data.createAndDownloadBlobFile(res.body, options, fileName);
          this.isCSVExporting = false;
        },
        (err) => {
          this.toastr.showError(err.error.message);
          this.isCSVExporting = false;
        }
      );
  }

  recalculateCriticality(): void {
    this.isRecalculatingCrit = true;
    const selectedAssets = _.map(this.selectedNodes, (el) => el.id);
    this.data
      .post('api/fastcrit/risk-assessment/recalculate-criticality', {
        assets: selectedAssets,
      })
      .subscribe(
        (res) => {
          this.riskAssessmentGrid?.uncheckAll();
          this.items = syncItems(res, this.items);
          this.toastr.showSuccess();
          this.isRecalculatingCrit = false;
        },
        (err) => {
          this.toastr.showError(err.error.message);
          this.isRecalculatingCrit = false;
        }
      );
  }

  async pushCriticalityToAssets(): Promise<void> {
    const confirmation = confirm('Are you sure...?');
    if (!confirmation) {
      return;
    }
    const assetIds = _.map(this.selectedNodes, (el) => el.id);

    this.data
      .post('api/fastcrit/risk-assessment/push-criticality', {
        asset: assetIds,
      })
      .subscribe(
        (res) => {
          this.toastr.showSuccess(
            this.translate.instant('messages.criticality.criticality_pushed')
          );
          this.loadItems();
        },
        (err) => {
          this.toastr.showSuccess(
            this.translate.instant(
              'messages.criticality.criticality_not_calculated'
            )
          );
        }
      );
  }

  setPinned(): any {
    this.gridOptions?.columnApi?.applyColumnState({
      state: [
        { colId: 'base_risk_amount', pinned: 'right' },
        { colId: 'base_crit_name', pinned: 'right' },
        { colId: 'actual_risk_amount', pinned: 'right' },
        { colId: 'actual_crit_name', pinned: 'right' },
        { colId: 'critStatus', pinned: 'right' },
        { colId: 'critPushDate', pinned: 'right' },
        { colId: 'criticality', pinned: 'right' },
      ],
      defaultState: { pinned: null },
    });
  }

  // Load Products used in modal edit form
  async loadProducts(): Promise<void> {
    // Products
    const orderByDesc = { orderBy: { description: 'asc' } };
    const tpm = await this.data
      .by('api/config/products', {
        criteria: {
          account: this.auth.getAccountId(),
        },
        ...orderByDesc,
      })
      .toPromise();
    this.productsReference = _.map(tpm, (el: any) => {
      return {
        name: el.description,
        value: el.id,
      };
    });
  }

  // Load Process used in modal edit form
  async loadProcess(): Promise<void> {
    // Process
    const orderByDesc = { orderBy: { description: 'asc' } };
    const tpm = await this.data
      .by('api/fastcrit/processes', {
        criteria: {
          site: this.auth.getSiteId(),
        },
        ...orderByDesc,
      })
      .toPromise();
    this.processReference = _.map(tpm, (el: any) => {
      return {
        name: el.description,
        value: el.id,
      };
    });

    // Mark ref as loaded (prevent reload)
    this.isReferenceLoaded = true;
  }

  // Load RiskModels used in modal edit form
  async loadRiskModels(): Promise<void> {
    // RiskModels
    const orderByDesc = { orderBy: { description: 'asc' } };
    const tpm = await this.data
      .by('api/fastcrit/risk-models', {
        criteria: {
          site: this.auth.getSiteId(),
        },
        ...orderByDesc,
      })
      .toPromise();
    this.riskModelsReference = _.map(tpm, (el: any) => {
      return {
        name: el.description,
        value: el.id,
      };
    });
  }

  // Load Config Factors used in modal edit form
  async loadConfigFactors(): Promise<void> {
    // ConfigFactors
    const orderByDesc = { orderBy: { description: 'asc' } };
    const tpm = await this.data
      .by('api/config/factors', {
        criteria: {
          account: this.auth.getAccountId(),
        },
        ...orderByDesc,
      })
      .toPromise();
    this.configFactorReference = _.map(tpm, (el: any) => {
      return {
        name: el.description,
        value: el.id,
      };
    });
  }

  /**
   * @param items
   */
  async showEditModal(items?: RiskAssessmentModel[]): Promise<void> {
    this.isLoading = true;
    await Promise.all([
      this.loadProcess(),
      this.loadProducts(),
      this.loadRiskModels(),
      this.loadConfigFactors(),
    ]);
    this.isLoading = false;
    this.bsModalRef = this.bsModalService.show(RiskAssessmentEditComponent, {
      initialState: {
        items,
        processReference: this.processReference,
        productsReference: this.productsReference,
        riskModelsReference: this.riskModelsReference,
        configFactorReference: this.configFactorReference,
        assets: _.map(this.selectedNodes, (el) => el.id),
      },
    });
    this.bsModalRef.content.confirmFn = (res: any) => {
      this.items = syncItems(res, this.items);
      this.setFilteredItemsForFlatList();
      this.riskAssessmentGrid?.uncheckAll();
      this.toastr.showSuccess();
    };
  }

  async showSummaryModal(assetId: number): Promise<void> {
    this.bsModalRef = this.bsModalService.show(AssetSummaryComponent, {
      initialState: {
        assetId,
      },
      class: 'modal-lg',
    });
  }

  async showProcessDetailsModal(processId: number): Promise<void> {
    this.bsModalRef = this.bsModalService.show(ProcessDetailsComponent, {
      initialState: {
        processId,
      },
      class: 'modal-lg',
    });
  }

  async showModelDetailModal(
    modelId: number,
    modelName: string
  ): Promise<void> {
    this.bsModalRef = this.bsModalService.show(ModelDetailComponent, {
      initialState: {
        modelId,
        modelName,
      },
      class: 'modal-lg',
    });
  }

  async refreshGrid(): Promise<void> {
    await this.loadItems();
    setTimeout(() => {
      this.gridOptions?.api?.redrawRows();
    });
  }

  /**
   */
  showMassEditModal(): void {
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      const items = _.map(
        this.selectedNodes,
        (el) => new RiskAssessmentModel(el.data)
      );
      this.showEditModal(items);
    }
  }

  copyToClipboard(): void {
    copyToClipboard(this.selectedNodes);
  }

  /**
   */
  showClearConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.data.post('api/fastcrit/risk-assessment/clear', ids).subscribe(
        (res) => {
          this.items = syncItems(res, this.items);
          this.riskAssessmentGrid?.uncheckAll();
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  getAllRowsField(field: string): [] {
    const rowData: any = [];
    this.gridOptions?.api?.forEachNode((node) =>
      rowData.push(node.data[field])
    );
    return rowData;
  }

  async showMatchAssetModal(): Promise<void> {
    this.bsModalRef = this.bsModalService.show(AssetMatchComponent, {
      initialState: {
        assetNames: this.getAllRowsField('name'),
      },
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = (res: any) => {
      // select assets in grid
      this.riskAssessmentGrid?.uncheckAll();
      this.gridOptions?.api?.forEachNode((node) => {
        if (res.includes(node.data.name.toLowerCase())) {
          node.setSelected(true);
        }
      });
    };
  }

  filterAndSelectDefaultAsset = (event: any): void => {
    const assetId = this.activatedRoute.snapshot.queryParamMap.get('asset_id');

    if (
      !assetId ||
      this.isDefaultAssetSelected ||
      event.api.getDisplayedRowCount() === 0
    ) {
      return;
    }
    let isAssetAvailable = false;

    event.api.forEachNode((node: any) => {
      if (node.data.id === +assetId) {
        isAssetAvailable = true;

        const hardcodedFilter = {
          'ag-Grid-AutoColumn': {
            type: 'set',
            filter: node.data.name,
          },
        };
        event.api.setFilterModel(hardcodedFilter);
        node.setSelected(true);

        if (!this.isDefaultAssetSelected) {
          this.isDefaultAssetSelected = true;
        }
      }
    });

    if (!isAssetAvailable) {
      this.toastr.showError(this.translate.instant('messages.asset.not_found'));
    }
  };

  myCellCallback = (params: any) => {
    return params.value;
  };

  get exportBtnTitle(): string {
    return this.isCSVExporting
      ? 'Exporting...'
      : this.translate.instant(
          'messages.criticality.export_criticality_dataset'
        );
  }

  get recalculateCritBtnTitle(): string {
    return this.isRecalculatingCrit
      ? 'Recalculating...'
      : this.translate.instant('messages.criticality.recalculate_criticality');
  }
}
