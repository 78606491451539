import { Component, OnInit } from '@angular/core';
import { AuthService, DataService } from '../../shared/services';
import { Router } from '@angular/router';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import { LocationModel } from '../../shared/models';
import { Observable } from 'rxjs';
import { sortBy } from 'lodash';
import { syncItems } from 'src/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {
  // Currently selected location
  selectedLocation?: any;
  showLeftGrid = true;
  showRightGrid = true;
  // List of items
  items: any = [];
  selectedItems: any = [];
  hoveredItemId: any = null;

  constructor(
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService,
    public data: DataService,
    private translate: TranslateService
  ) {}

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.location'),
          link: '/locations',
        },
      ],
      2
    );
  }

  toggleLeftGrid(): void {
    this.showLeftGrid = !this.showLeftGrid;
    const areBothGridsHidden = !this.showLeftGrid && !this.showRightGrid;
    if (areBothGridsHidden) {
      this.showRightGrid = true;
    }
  }

  toggleRightGrid(): void {
    this.showRightGrid = !this.showRightGrid;
    const areBothGridsHidden = !this.showLeftGrid && !this.showRightGrid;
    if (areBothGridsHidden) {
      this.showLeftGrid = true;
    }
  }

  /**
   *
   * @param event
   */
  async selectLocation(event: any): Promise<void> {
    this.selectedLocation = new LocationModel(event);
    this.items = await this.loadLocationItems(
      this.selectedLocation
    ).toPromise();
  }

  loadLocationItems(location: LocationModel): Observable<any> {
    return this.data.get('api/map/locations/' + location.id + '/items');
  }

  async itemsUpdated(event: any): Promise<void> {
    if (event && event.length > 0) {
      this.syncLocationItems(event);
    } else {
      this.items = await this.loadLocationItems(
        this.selectedLocation
      ).toPromise();
    }
  }

  syncLocationItems(event: any): void {
    const assetData = event.map((asset: any) => ({
      ...asset,
      id: 'asset_' + asset.id,
      type: 'asset',
      location_order: asset.locationOrder,
      name: asset.fullName,
    }));
    this.items = syncItems(assetData, this.items);
    this.items = sortBy(this.items, 'location_order');
  }

  async itemOnHover(event: any): Promise<void> {
    this.hoveredItemId = event;
  }

  onItemsSelectionChanged(ev: any): void {
    this.selectedItems = ev;
  }
}
