import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { StringValidator } from '../../../shared/services/form.utils';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as fs from 'file-saver';

@Component({
  selector: 'app-asset-match',
  templateUrl: './match.component.html'
})

export class AssetMatchComponent implements OnInit {

  assetNames: any;
  showValidationError = false;
  isSubmitted = false;
  afterPaste = false;
  actionBtn = 'cancel';
  pastedAssetNames = [];
  pastedAssetsCount = 0;
  unmatchedAssets = [];
  matchedAssetsCount = 0;
  form!: UntypedFormGroup;

  /**
   * @param bsModalRef
   * @param translate
   * @param formBuilder
   */
  constructor(
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private formBuilder: UntypedFormBuilder
  ) {
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    this.form = this.getForm({});
  }

  /**
   *
   */
  getForm(item: any): UntypedFormGroup {
    return this.formBuilder.group({
      names: new UntypedFormControl(item.names, [Validators.required, StringValidator.noWhitespace]),
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => { };

  /**
   *
   */
  submit(): void | boolean {
    this.showValidationError = true;
    if (!this.form || (this.form && !this.form.valid)) {
      return false;
    }

    this.isSubmitted = true;

    const data = this.form.value;

    this.pastedAssetNames = (data.names || '')
      .split('\n')
      .filter((name: any) => name.trim() !== '')
      .map((v: any) => v.trim().toLowerCase());

    if (this.pastedAssetNames.length) {
      this.confirmFn(this.pastedAssetNames);
    }

    this.assetNames = this.assetNames.map((assetName: string) => assetName.toLowerCase());
    this.unmatchedAssets = this.pastedAssetNames.filter((name: any) => !this.assetNames.includes(name));

    this.matchedAssetsCount = this.pastedAssetNames.length - this.unmatchedAssets.length;
    this.pastedAssetsCount = this.pastedAssetNames.length;

    this.afterPaste = true;
    this.actionBtn = 'ok';
    this.isSubmitted = false;
  }

  downloadFile(): void {
    const result = this.unmatchedAssets.join('\r\n');
    const blob = new Blob([result as any], {
      type: 'text/csv;charset=utf-8;'
    });
    fs.saveAs(blob, 'unmatched_assets.xls', { autoBom: false });
  }
}
