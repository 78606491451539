import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DataService, AuthService, ToasterService } from 'src/app/shared/services';
import { AssetConditionValueModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { doSelectOpen, onSearch } from 'src/utils';
import * as moment from 'moment';

@Component({
  selector: 'app-asset-condition-value-edit',
  templateUrl: './edit.component.html'
})
export class AssetConditionValueEditComponent implements OnInit, OnDestroy {
  // Modal parameters
  items: any; // array of items to edit
  // form instance
  form!: UntypedFormGroup;
  selectedAssetModelNodes: any[] = [];
  conditionsReference: any[] = [];
  conditionsValues: any[] = [];
  valueField: any = [];
  today = moment(new Date()).format('YYYY-MM-DD');

  // Usage mode
  mode: ('mass' | 'single' | 'new') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;
  showValidationError = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   * @param toastr
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private toastr: ToasterService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {

    await this.loadConditionsAndValues();

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.targetIds = _.map(this.items, el => el.id);

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.items = [new AssetConditionValueModel({
        site: this.auth.getSiteId(),
      })];
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    const valueField: any[] = [];

    if (this.mode !== 'new') {
      const conditionsValues = this.conditionsValues.filter((values: any) => {
        return values?.condition?.id === this.items[0]?.condition?.id;
      });
      conditionsValues.map((values: any, index: number) => {
        valueField.push({ id: index, description: values.description });
      });
      this.valueField = valueField;
    }

    const matchedValueField = this.valueField.find((value: any) => value.description === this.items[0].value);

    if (!matchedValueField && this.items[0].value) {
      this.valueField.unshift({ id: 0, description: this.items[0].value });
    }

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [AssetConditionValueModel?]): ('mass' | 'single' | 'new') {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // More than one item => Mass Edit
    } else if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  // Load Types, Budgets, Statuses used in modal edit form
  async loadConditionsAndValues(): Promise<void> {
    const id = this.selectedAssetModelNodes[0]?.data?.model?.id;

    const orderByDesc = { orderBy: { description: 'asc' } };

    const conditionPromise = this.service.by('api/fastlc/model-conditions', {
      criteria: {
        site: this.auth.getSiteId(),
        model: id
      },
      ...orderByDesc
    }).toPromise();

    const valuesPromise = this.service.by('api/fastlc/condition-values', {
      criteria: {
        site: this.auth.getSiteId()
      },
      ...orderByDesc
    }).toPromise();
    try {
      const [conditions, values] = await Promise.all([conditionPromise, valuesPromise]);
      this.conditionsReference = _.map(conditions, (el) => {
        return {
          name: el.description,
          value: el.id
        };
      });

      this.conditionsValues = values;
    } catch (error) {
      // Handle error if any of the promises reject
      console.error('Error fetching data:', error);
    }
  }

  /**
   *
   */
  getForm(item: AssetConditionValueModel): UntypedFormGroup {
    let validators: any = [];
    if (this.mode !== 'mass') {
      validators = [Validators.required];
    }

    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      site: new UntypedFormControl(this.auth.getSiteId()),

      condition: new UntypedFormControl({
        value: item.condition ? item.condition.id : null,
        disabled: this.mode !== 'new'
      }, validators),
      value: new UntypedFormControl(item.value, validators),
      date: new UntypedFormControl(item.date || this.today, validators),
      comment: new UntypedFormControl(item.comment),
    });
  }

  onChange(): void {
    this.valueField = this.conditionsValues.filter(value => value?.condition?.id === this.form.value.condition);
  }

  doValueSelectOpen(): void {
    doSelectOpen(this.form, 'value');
  }

  /**
   *
   * @param event
   */
  onValueSearch(event: any): void {
    onSearch(event, this.valueField, this.form, 'value');
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => { };

  /**
   *
   */
  save(): boolean | void {
    this.showValidationError = true;

    if (!this.form || (this.form && !this.form.valid)) {
      return false;

    } else {

      // Query pointer
      let query;

      if (this.mode === 'new') {
        const assetCondition: any = [];
        _.each(this.selectedAssetModelNodes, modelNode => {
          const assetConditionData = _.pickBy(this.form.value, el => el !== null);
          assetConditionData.assetModel = modelNode.data.id;
          assetConditionData.asset = modelNode.data.asset.id;
          assetCondition.push(assetConditionData);
        });
        query = this.service.post('api/fastlc/asset-conditions', assetCondition[0]);
      } else if (this.mode === 'single') {
        query = this.service.update('api/fastlc/asset-conditions', this.targetIds[0], this.form.value);

      } else {
        const tmpData: any = {};
        _.each(this.form.controls, (control, name) => {
          if (control.dirty === true && control.value !== -1) {
            tmpData[name] = control.value;
          }
        });
        const data = _.map(this.targetIds, id => {
          const cloned = _.cloneDeep(tmpData);
          _.merge(cloned, { id });
          return cloned;
        });

        query = this.service.saveAll('api/fastlc/asset-conditions', data);
      }

      this.isSubmitted = true;
      query?.subscribe(res => {
        this.isSubmitted = false;
        this.confirmFn(res);
        this.bsModalRef.hide();
      }, err => {
        this.toastr.showError(`${err.error.message} </br> ${this.translate.instant('navigations.already_exist')}`);
        this.isSubmitted = false;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.valueField[0]?.id === 0) {
      this.valueField.shift();
    }
  }
}
