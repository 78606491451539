<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group col-9">
          <label
            [class.font-weight-bold]="form.get('description')?.dirty"
            for="description"
          >
            {{ "headers.fastcr.processes.description" | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('description')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="description"
            id="description"
            type="text"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('description')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-3">
          <label
            [class.font-weight-bold]="form.get('buffer')?.dirty"
            for="buffer"
          >
            {{ "headers.fastcr.processes.buffer" | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            [ngClass]="{
              'is-invalid': showValidationError && form.get('buffer')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="buffer"
            id="buffer"
            type="number"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('buffer')?.errors?.required"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-9 mt-15px">
          <label
            [class.font-weight-bold]="form.get('finality')?.dirty"
            for="finality"
          >
            {{ "headers.fastcr.processes.finality" | translate }}
          </label>
          <app-field-clear-button
            [formGroupRef]="form"
            [fieldName]="'finality'"
          ></app-field-clear-button>
          <ngx-select
            [items]="finalitiesReference"
            [placeholder]="'(Select a Finality)'"
            formControlName="finality"
            id="finality"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('finality')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-3">
          <label
            [class.font-weight-bold]="form.get('throughputReduction')?.dirty"
            for="throughputReduction"
          >
            {{ "headers.fastcr.processes.throughput_reduction" | translate }} %
            <span class="text-danger">*</span>
          </label>
          <input
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('throughputReduction')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="throughputReduction"
            id="throughputReduction"
            type="number"
            max="100"
          />
          <div
            class="text-danger"
            *ngIf="
              showValidationError &&
              form.get('throughputReduction')?.errors?.required
            "
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
