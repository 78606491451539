import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ProxyService} from './proxy.service';
import * as _ from 'lodash';

@Injectable()
export class DataService {
  // Base API url
  baseUrl: string | undefined;

  /**
   * @param proxy
   */
  constructor(
    public proxy: ProxyService
  ) {
  }

  /**
   * Affect API base url.
   *
   * @param baseUrl
   */
  setBaseUrl(baseUrl: string): void {
    this.baseUrl = baseUrl;
  }

  /**
   * Return parsed URL from baseUrl or one shot url (param).
   *
   * If an id is provided, it will be place at the "_id_" placeholder position, or a the end of the url.
   *
   * @param url
   * @param id
   */
  getServiceUrl(url: string, id?: number): string {
    if ((url === undefined || url === '') && this.baseUrl !== undefined) {
      url = this.baseUrl;
    }
    if (url.indexOf('_id_') > -1 && id !== undefined) {
      url = url.replace('_id_', ('' + id));
    } else if (id !== undefined) {
      url = url + '/' + id;
    }
    return url;
  }

  /**
   * Return the observable to execute a GET request.
   *
   * Used to find objects
   *
   * @param url
   */
  list(url: string): Observable<any> {
    const localUrl = this.getServiceUrl(url);
    const params = {params: {}};

    // @ts-ignore
    return this.proxy.request(
      localUrl, {}, params, {}, 'get', true
    ).pipe(
      // tap(_ => console.log('Fetched List')),
      catchError(this.proxy.handleError('DataService::list', []))
    );
  }

  /**
   * Return the observable to execute a GET request with the "/search" path and a query param.
   *
   * @param url
   * @param term
   */
  search(url: string, term: string): Observable<any> {
    const localUrl = this.getServiceUrl(url);

    // @ts-ignore
    return this.proxy.request(
      localUrl + '/' + 'search?q=' + term, {}, {}, {}, 'get', true
    ).pipe(
      catchError(this.proxy.handleError('DataService::searchEntries', []))
    );
  }

  /**
   * Return the observable to execute a POST request with the "/by" path and a body criteria.
   *
   * Used to find objects.
   *
   * @param url
   * @param criteria
   */
  by(url: string, criteria: object): Observable<any> {
    const localUrl = this.getServiceUrl(url);

    // @ts-ignore
    return this.proxy.request(
      localUrl + '/by', {}, {}, criteria, 'post', true
    ).pipe(
      catchError(this.proxy.handleError('DataService::findByEntries', []))
    );
  }

  /**
   * Return the observable to execute a GET request with an id.
   *
   * Used to read an object.
   *
   * @param url
   * @param id
   */
  detail(url: string, id: number): Observable<any> {
    const localUrl = this.getServiceUrl(url, id);
    // @ts-ignore
    return this.proxy.request(
      localUrl, {}, {}, {}, 'get', true
    ).pipe(
      catchError(this.proxy.handleError('DataService::detail', []))
    );
  }

  /**
   * Return the observable to execute a GET request with an id.
   *
   * @param url
   * @param id
   */
  logs(url: string, id: number): Observable<any> {
    const localUrl = this.getServiceUrl(url, id);

    // @ts-ignore
    return this.proxy.request(
      localUrl, {}, {}, {}, 'get', true
    ).pipe(
      catchError(this.proxy.handleError('DataService::detail', []))
    );
  }

  /**
   * Return the observable to execute a POST request with a body.
   *
   * @param url
   * @param data
   */
  post(url: string, data: object): Observable<any> {
    const localUrl = this.getServiceUrl(url);

    // @ts-ignore
    return this.proxy.request(
      localUrl, {'Content-Type': 'application/json'}, {}, data, 'post', true
    ).pipe(
      catchError(this.proxy.handleError('DataService::post'))
    );
  }

  /**
   * Return the observable to execute a PUT request with an id and a body.
   *
   * Used to update an object.
   *
   * @param url
   * @param id
   * @param data
   */
  update(url: string, id: number, data: object): Observable<any> {
    const localUrl = this.getServiceUrl(url, id);

    // @ts-ignore
    return this.proxy.request(
      localUrl, {'Content-Type': 'application/json'}, {}, data, 'put', true
    ).pipe(
      catchError(this.proxy.handleError('DataService::update'))
    );
  }

  /**
   * Return the observable to execute a POST request with a body.
   *
   * Used to create an object.
   *
   * @param url
   * @param data
   */
  create(url: string, data: object): Observable<any> {
    const localUrl = this.getServiceUrl(url);

    // @ts-ignore
    return this.proxy.request(
      localUrl, {'Content-Type': 'application/json'}, {}, data, 'post', true
    ).pipe(
      catchError(this.proxy.handleError('DataService::create'))
    );
  }

  /**
   * Return the observable to execute a DELETE request with an id.
   *
   * Used to delete an object.
   *
   * @param url
   * @param id
   */
  delete(url: string, id: number): Observable<any> {
    const localUrl = this.getServiceUrl(url, id);

    // @ts-ignore
    return this.proxy.request(
      localUrl, {'Content-Type': 'application/json'}, {}, {}, 'delete', true
    ).pipe(
      catchError(this.proxy.handleError('DataService::delete'))
    );
  }

  /**
   * Return the observable to execute a POST request with the "/duplicate" path and an id.
   *
   * Used to clone an object.
   *
   * @param url
   * @param id
   */
  clone(url: string, id: number): Observable<any> {
    const localUrl = this.getServiceUrl(url, id);

    // @ts-ignore
    return this.proxy.request(
      localUrl + '/duplicate', {}, {}, {}, 'post', true
    ).pipe(
      catchError(this.proxy.handleError('DataService::clone'))
    );
  }

  /**
   * Return the observable to execute a POST request with the "/save-all" path and a body.
   *
   * Used to save a list of objects.
   *
   * @param url
   * @param rows
   */
  saveAll(url: string, rows: object[]): Observable<any> {
    const localUrl = this.getServiceUrl(url);

    // @ts-ignore
    return this.proxy.request(
      localUrl + '/save-all', {}, {}, rows, 'post', true
    ).pipe(
      catchError(this.proxy.handleError('DataService::saveAll'))
    );
  }

  /**
   * Return the observable to execute a POST request with the "/save-all-retain" path and a body.
   *
   * Used to save a list of objects, while retaining existing data.
   *
   * @param url
   * @param rows
   */
  saveAllRetain(url: string, rows: object[], retainFields: string[]): Observable<any> {
    const localUrl = this.getServiceUrl(url);
    const postData = {data: rows, retainFields};

    // @ts-ignore
    return this.proxy.request(
      localUrl + '/save-all-retain', {}, {}, postData, 'post', true
    ).pipe(
      catchError(this.proxy.handleError('DataService::saveAllRetain'))
    );
  }

  /**
   *
   * @param url
   * @param data
   */
  register(url: string, data: object): Observable<any> {
    const localUrl = this.getServiceUrl(url);

    // @ts-ignore
    return this.proxy.request(
      localUrl, {'Content-Type': 'application/json'}, {}, data, 'post', false
    ).pipe(
      catchError(this.proxy.handleError('DataService::register'))
    );
  }

  /**
   * Return the observable to execute a GET request.
   *
   * Used to find objects
   *
   * @param url
   * @param args
   */
  get(url: string, args: {} = {}): Observable<any> {
    const localUrl = this.getServiceUrl(url);
    const params = {params: args};

    // @ts-ignore
    return this.proxy.request(
      localUrl, {}, params, {}, 'get', true
    ).pipe(
      // tap(_ => console.log('Fetched List')),
      catchError(
        this.proxy.handleError('DataService::get', [])
      )
    );
  }

  /**
   *
   * @param url
   */
  getBlob(url: string): Observable<any> {
    const localUrl = this.getServiceUrl(url);
    // @ts-ignore
    return this.proxy.request(
      localUrl,
      {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }, {
        responseType: 'blob' as 'json',
        observe: 'response'
      },
      {},
      'get',
      true
    ).pipe(
      catchError(this.proxy.handleError('DataService::getBlob'))
    );
  }

  /**
   * Return the observable to execute a POST request with a body of formData Containing Files.
   *
   * @param url
   * @param data
   */
  postFiles(url: string, data: any): Observable<any> {
    const localUrl = this.getServiceUrl(url);
    // @ts-ignore
    return this.proxy.request(
      localUrl,
      {},
      {},
      data,
      'post',
      true
    ).pipe(
      catchError(this.proxy.handleError('DataService::postFiles'))
    );
  }

  /**
   * @param url
   * @param data
   * @param pick
   */
  download(url: string, data: object): Observable<any> {
    const localUrl = this.getServiceUrl(url);
    const params = {
      params: {},
      responseType: 'blob',
      observe: 'response',
    };

    // @ts-ignore
    return this.proxy.request(
      localUrl,
      {'Content-Type': 'application/json'},
      params, data,
      'post',
      true
    ).pipe(
      catchError(this.proxy.handleError('DataService::download'))
    );
  }

  /**
   *
   * @param body
   * @param options
   * @param filename
   */
  createAndDownloadBlobFile(body: any, options: any, filename: any): void {
    const blob = new Blob([body], options);
    // if (navigator.msSaveBlob) {
    //   // IE 10+
    //   navigator.msSaveBlob(blob, filename);
    // } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    // }
  }
}
