<ng-container *ngIf="!isLoading">
  <div class="modal-header">
    <h3 class="mb-0 font-weight-bold alert-heading">
      {{ modelName }}
    </h3>
  </div>

  <div class="d-flex flex-grow-1 p-2 ag-grid-container">
    <app-grid
      [gridOptions]="gridOptions"
      [gridUid]="'model_detail_list'"
      [rowData]="modelItems"
      [masterDetail]="true"
      [detailCellRendererParams]="detailCellRendererParams"
      class="ag-theme-alpine"
      style="width: 100%; height: 600px; max-height: 600px"
    >
    </app-grid>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<app-loader *ngIf="isLoading"></app-loader>
