import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DataService, AuthService, ToasterService } from 'src/app/shared/services';
import { ConditionsModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lifecycle-model-condition-edit',
  templateUrl: './edit.component.html',
})
export class ConditionsEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  // form instance
  form!: UntypedFormGroup;
  selectedLifecycleModelNodes: any[] = [];
  valuesReference: any[] = [];
  tags: any[] = [];

  // Usage mode
  mode: ('mass' | 'single' | 'new') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;
  showValidationError = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   * @param toastr
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private toastr: ToasterService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.targetIds = _.map(this.items, el => el.id);

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.items = [new ConditionsModel({
        site: this.auth.getSiteId(),
      })];
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    if (this.valuesReference.length > 0) {
      this.valuesReference.map(val => {
        this.tags.push(val.description);
      }
      );
    }

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [ConditionsModel?]): ('mass' | 'single' | 'new') {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // More than one item => Mass Edit
    } else if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  onTagsAdd(tags: any[]): void {
    this.tags = tags;
  }

  /**
   *
   */
  getForm(item: ConditionsModel): UntypedFormGroup {
    let validators: any = [];
    if (this.mode !== 'mass') {
      validators = [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)];
    }

    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      site: new UntypedFormControl(this.auth.getSiteId()),
      comment: new UntypedFormControl(item.comment),
      description: new UntypedFormControl(item.description, validators),
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => {
  };

  /**
   *
   */
  save(): boolean | void {
    this.showValidationError = true;

    if (!this.form || (this.form && !this.form.valid)) {
      return false;

    } else {

      // Query pointer
      let query;

      if (this.mode === 'new') {

        const modelConditions: any = [];
        _.each(this.selectedLifecycleModelNodes, modelNode => {
          const conditionData = _.pickBy(this.form.value, el => el !== null);
          conditionData.model = modelNode.data.id;
          conditionData.values = this.tags;
          modelConditions.push(conditionData);
        });

        query = this.service.saveAll('api/fastlc/model-conditions', modelConditions);
      } else if (this.mode === 'single') {
        query = this.service.update('api/fastlc/model-conditions', this.targetIds[0], { ...this.form.value, values: this.tags });

      } else {
        const tmpData: any = {};
        _.each(this.form.controls, (control, name) => {
          if (control.dirty === true && control.value !== -1) {
            tmpData[name] = control.value;
          }
        });
        const data = _.map(this.targetIds, id => {
          const cloned = _.cloneDeep(tmpData);
          _.merge(cloned, { id });
          return cloned;
        });

        query = this.service.saveAll('api/fastlc/model-conditions', data);
      }

      this.isSubmitted = true;
      query?.subscribe(res => {
        this.isSubmitted = false;
        this.confirmFn(res);
        this.bsModalRef.hide();
      }, err => {
        this.toastr.showError(`${err.error.message} </br> ${this.translate.instant('navigations.already_exist')}`);
        this.isSubmitted = false;
      });
    }
  }
}
