<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="card form">
        <div class="card-body">
          <ng-container *ngIf="mode !== 'mass'">
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('name')?.dirty"
                for="name"
              >
                {{ "headers.routing.plan.plan" | translate }}
                <span class="text-danger">*</span>
              </label>
              <input
                [ngClass]="{
                  'is-invalid': isSubmitted && form.get('name')?.invalid
                }"
                class="form-control form-control-sm"
                formControlName="name"
                id="name"
                type="text"
              />
            </div>
          </ng-container>

          <div class="form-group col-12">
            <label
              [class.font-weight-bold]="form.get('status')?.dirty"
              for="status"
            >
              {{ "headers.routing.plan.status" | translate }}
              <span class="text-danger">*</span>
            </label>
            <ngx-select
              [items]="statusReference"
              [placeholder]="'(Select a Value)'"
              formControlName="status"
              id="status"
              optionTextField="name"
              optionValueField="value"
              size="small"
            ></ngx-select>
          </div>

          <div class="form-group col-12">
            <label
              [class.font-weight-bold]="form.get('quality')?.dirty"
              for="quality"
            >
              {{ "headers.routing.plan.quality" | translate }}
            </label>
            <app-field-clear-button
              [formGroupRef]="form"
              [fieldName]="'quality'"
            ></app-field-clear-button>
            <input
              [ngClass]="{
                'is-invalid': isSubmitted && form.get('quality')?.invalid
              }"
              class="form-control form-control-sm"
              formControlName="quality"
              id="quality"
              type="text"
            />
          </div>

          <div class="form-group col-12">
            <label
              [class.font-weight-bold]="form.get('safety')?.dirty"
              for="safety"
            >
              {{ "headers.routing.plan.safety" | translate }}
            </label>
            <app-field-clear-button
              [formGroupRef]="form"
              [fieldName]="'safety'"
            ></app-field-clear-button>
            <input
              [ngClass]="{
                'is-invalid': isSubmitted && form.get('safety')?.invalid
              }"
              class="form-control form-control-sm"
              formControlName="safety"
              id="safety"
              type="text"
            />
          </div>

          <div class="form-group col-12">
            <label
              [class.font-weight-bold]="form.get('parts')?.dirty"
              for="parts"
            >
              {{ "headers.routing.plan.parts" | translate }}
            </label>
            <app-field-clear-button
              [formGroupRef]="form"
              [fieldName]="'parts'"
            ></app-field-clear-button>
            <input
              [ngClass]="{
                'is-invalid': isSubmitted && form.get('parts')?.invalid
              }"
              class="form-control form-control-sm"
              formControlName="parts"
              id="parts"
              type="text"
            />
          </div>

          <div class="form-group col-12">
            <label
              [class.font-weight-bold]="form.get('actualDuration')?.dirty"
              for="parts"
            >
              {{ "headers.routing.plan.actualDuration" | translate }}
            </label>
            <app-field-clear-button
              [formGroupRef]="form"
              [fieldName]="'actualDuration'"
            ></app-field-clear-button>
            <input
              [ngClass]="{
                'is-invalid': isSubmitted && form.get('actualDuration')?.invalid
              }"
              class="form-control form-control-sm"
              formControlName="actualDuration"
              id="actualDuration"
              type="number"
            />
          </div>
          <div class="form-group col-12">
            <label
              [class.font-weight-bold]="form.get('cmmsReference')?.dirty"
              for="cmmsReference"
            >
              {{ "headers.routing.plan.cmms_reference" | translate }}
            </label>
            <app-field-clear-button
              [formGroupRef]="form"
              [fieldName]="'cmmsReference'"
            ></app-field-clear-button>
            <input
              [ngClass]="{
                'is-invalid': isSubmitted && form.get('cmmsReference')?.invalid
              }"
              class="form-control form-control-sm"
              formControlName="cmmsReference"
              id="cmmsReference"
              type="text"
            />
          </div>
          <div class="form-group col-12">
            <label
              [class.font-weight-bold]="form.get('comments')?.dirty"
              for="comments"
            >
              {{ "headers.routing.plan.comments" | translate }}
            </label>
            <app-field-clear-button
              [formGroupRef]="form"
              [fieldName]="'comments'"
            ></app-field-clear-button>
            <input
              [ngClass]="{
                'is-invalid': isSubmitted && form.get('comments')?.invalid
              }"
              class="form-control form-control-sm"
              formControlName="comments"
              id="comments"
              type="text"
            />
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="
            (!form.pristine && !form.dirty && isSubmitted) || !form.valid
          "
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
