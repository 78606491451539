<div class="footer p-2 border-top">
  <div class="container">
    <div class="d-flex flex-row align-items-center">
      <div class="title mr-3">&copy; 2020 <a href="#">Amsyst LTD.</a> All rights reserved</div>
      <div class="title mr-3"><a href="#">Term of Service</a></div>
      <div class="title mr-3"><a href="#">Privacy Policy</a></div>
      <div class="title"><a href="#">Contact</a></div>
      <div class="title ml-auto">
        <a target="_blank" href="https://www.linkedin.com/company/amsyst/"><i class="lab la-linkedin la-2x"></i></a>
      </div>
    </div>
  </div>
</div>
