<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <h5 *ngIf="mode === 'single'">
      {{ items[0]?.name }} - {{ items[0]?.description }}
    </h5>
    <form [formGroup]="form">
      <div class="card form">
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('fastcrAssetProcess')?.dirty"
                for="fastcrAssetProcess"
              >
                {{ "headers.fastcr.risk_assessment.process" | translate }}
              </label>
              <app-field-clear-button
                [formGroupRef]="form"
                [fieldName]="'fastcrAssetProcess'"
              ></app-field-clear-button>
              <ngx-select
                [items]="processReference"
                [placeholder]="'(Select a Process)'"
                formControlName="fastcrAssetProcess"
                id="fastcrAssetProcess"
                optionTextField="name"
                optionValueField="value"
                size="small"
              ></ngx-select>
              <div
                class="text-danger"
                *ngIf="
                  showValidationError && form.get('fastcrAssetProcess')?.invalid
                "
              >
                {{ "messages.required" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card form">
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('configCrProduct')?.dirty"
                for="configCrProduct"
              >
                {{ "headers.fastcr.risk_assessment.product" | translate }}
              </label>
              <app-field-clear-button
                [formGroupRef]="form"
                [fieldName]="'configCrProduct'"
              ></app-field-clear-button>
              <ngx-select
                [items]="productsReference"
                [placeholder]="'(Select a Product)'"
                formControlName="configCrProduct"
                id="configCrProduct"
                [multiple]="true"
                optionTextField="name"
                optionValueField="value"
                size="small"
              ></ngx-select>
              <div
                class="text-danger"
                *ngIf="
                  showValidationError && form.get('configCrProduct')?.invalid
                "
              >
                {{ "messages.required" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card form">
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('fastcrAssetModel')?.dirty"
                for="fastcrAssetModel"
              >
                {{ "headers.fastcr.risk_assessment.model" | translate }}
              </label>
              <app-field-clear-button
                [formGroupRef]="form"
                [fieldName]="'fastcrAssetModel'"
              ></app-field-clear-button>
              <ngx-select
                [items]="riskModelsReference"
                [placeholder]="'(Select a Model)'"
                formControlName="fastcrAssetModel"
                id="fastcrAssetModel"
                [multiple]="true"
                optionTextField="name"
                optionValueField="value"
                size="small"
              ></ngx-select>
              <div
                class="text-danger"
                *ngIf="
                  showValidationError && form.get('fastcrAssetModel')?.invalid
                "
              >
                {{ "messages.required" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card form">
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('fastcrAssetFactors')?.dirty"
                for="fastcrAssetFactors"
              >
                {{ "headers.fastcr.risk_assessment.factor" | translate }}
              </label>
              <app-field-clear-button
                [formGroupRef]="form"
                [fieldName]="'fastcrAssetFactors'"
              ></app-field-clear-button>
              <ngx-select
                [items]="configFactorReference"
                [placeholder]="'(Select a Factor)'"
                formControlName="fastcrAssetFactors"
                id="fastcrAssetFactors"
                [multiple]="true"
                optionTextField="name"
                optionValueField="value"
                size="small"
              ></ngx-select>
              <div
                class="text-danger"
                *ngIf="
                  showValidationError && form.get('fastcrAssetFactors')?.invalid
                "
              >
                {{ "messages.required" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card form">
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('title')?.dirty"
                for="title"
              >
                {{ "headers.fastcr.risk_assessment.description" | translate }}
              </label>
              <textarea
                class="form-control form-control-sm"
                formControlName="fastcrAssetComment"
                id="fastcrAssetComment"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="
            (!form.pristine && !form.dirty && isSubmitted) ||
            !form.valid ||
            isSubmitted
          "
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
