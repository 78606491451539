import { ActionBudgetModel } from './ActionBudgetModel';
import { ActionTypeModel } from './ActionTypeModel';
import { LifecycleModelModel } from './LifecycleModelModel';

export class ActionsModel {
  id: number;
  description: string;
  fastlcModel: LifecycleModelModel;
  cost: number;
  type: ActionTypeModel;
  budget: ActionBudgetModel;
  comment: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.fastlcModel = data.fastlcModel ? data.fastlcModel : LifecycleModelModel;
    this.description = data.description ? data.description : '';
    this.cost = data.cost || data.cost === 0 ? data.cost : null;
    this.comment = data.comment ? data.comment : '';
    this.type = data.type ? data.type : ActionTypeModel;
    this.budget = data.budget ? data.budget : ActionBudgetModel;
  }
}
