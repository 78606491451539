<ng-container *ngIf="!isLoading">
  <div class="modal-header">
    <h3 class="mb-0 font-weight-bold alert-heading">
      {{ processDetails.description }}
    </h3>
  </div>
  <div class="modal-body">
    <div class="card">
      <div class="card-body">
        <div class="col-6 mb-1">
          <span class="font-size-smaller text-muted">
            {{ "headers.fastcr.processes.buffer" | translate }}
            ({{ "headers.fastcr.processes.in_hours" | translate }}):
          </span>
          <span class="font-weight-bold">{{ processDetails.buffer }}</span>
        </div>
        <div class="col-6 mb-1">
          <span class="font-size-smaller text-muted">
            {{ "headers.fastcr.processes.finality" | translate }}:
          </span>
          <span class="font-weight-bold">{{
            processDetails.finality.description
          }}</span>
        </div>
        <div class="col-6 mb-1">
          <span class="font-size-smaller text-muted">
            {{ "headers.fastcr.processes.finality" | translate }}
            {{ "headers.risk_parameters.finalities.costperhour" | translate }}:
          </span>
          <span class="font-weight-bold">{{
            processDetails.finality.costperhour
          }}</span>
        </div>

        <div class="col-6 mb-1">
          <span class="font-size-smaller text-muted">
            {{ "headers.fastcr.processes.throughput_reduction" | translate }}:
          </span>
          <span class="font-weight-bold"
            >{{ processDetails.throughputReduction * 100 }}%</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
<app-loader *ngIf="isLoading"></app-loader>
