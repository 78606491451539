import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { DataService } from './data.service';

@Injectable()
export class SharedDataService {

  private assetCustomHeaders: any = [];

  constructor(
    private translate: TranslateService,
    private data: DataService
  ) {
  }

  async setAssetCustomHeaders(account: number): Promise<any> {
    const data = await this.data.by(
      'api/config/asset/custom-headers', {
      criteria: {
        account
      }
    }).toPromise();

    this.assetCustomHeaders[account] = data || {};
    return data;
  }

  async getAssetCustomHeaders(account: number): Promise<any> {
    if (!this.assetCustomHeaders[account]) {
      await this.setAssetCustomHeaders(account);
    }

    return {
      custom1: _.find(this.assetCustomHeaders[account], header => header.customField === 'custom1')?.description || this.translate.instant('headers.asset.custom1'),
      custom2: _.find(this.assetCustomHeaders[account], header => header.customField === 'custom2')?.description || this.translate.instant('headers.asset.custom2'),
      custom3: _.find(this.assetCustomHeaders[account], header => header.customField === 'custom3')?.description || this.translate.instant('headers.asset.custom3'),
      custom4: _.find(this.assetCustomHeaders[account], header => header.customField === 'custom4')?.description || this.translate.instant('headers.asset.custom4'),
      custom5: _.find(this.assetCustomHeaders[account], header => header.customField === 'custom5')?.description || this.translate.instant('headers.asset.custom5'),
      custom6: _.find(this.assetCustomHeaders[account], header => header.customField === 'custom6')?.description || this.translate.instant('headers.asset.custom6')
    };
  }
}
