export class ConfResponsibleGroupsModel {
  id: number;
  description: string;
  type: number;
  siteId: number;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.description = data.description ? data.description : '';
    this.type = data.type ? data.type : 0;
    this.siteId = data.siteId ? data.siteId : null;
  }
}
