import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss']
})
export class TagInputComponent implements OnInit {
  @Input() items?: any = [];
  @Output() addTags = new EventEmitter<any>();

  tags: any[] = [];
  tagInput = '';

  async ngOnInit(): Promise<void> {
    this.tags = this.items;
  }

  addTag(): void {
    if (this.tagInput && !this.tags.includes(this.tagInput)) {
      this.tags.push(this.tagInput);
      this.tagInput = '';

      this.addTags.emit(this.tags);
    }
  }

  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);
    if (index !== -1) {
      this.tags.splice(index, 1);
    }
  }
}
