<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <div class="form-row">
      <div class="form-group col-12">
        <label for="description">
          {{ "messages.select_csv" | translate }}
          <span class="text-danger">*</span>
        </label>
        <input
          type="file"
          id="file"
          accept=".csv"
          (change)="handleFileInput($event)"
        />
        <div class="text-danger" *ngIf="errorMessage">
          <p *ngFor="let error of errorMessage | keyvalue">{{ error.value }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
