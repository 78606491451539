<div class="container-fluid">
  <form [formGroup]="form">
    <div class="card form">
      <div class="card-body">
        <h6 class="card-title font-weight-bold">
          {{ "messages.password.update_password" | translate }}
        </h6>
        <div class="form-row">
          <div class="col-6">
            <label [class.font-weight-bold]="form.get('password')?.dirty" for="password">
              {{ "messages.password.password" | translate }}
              <span class="text-danger">*</span>
            </label>
            <input [ngClass]="{
                'is-invalid': isSubmitted && form.get('password')?.invalid
              }" class="form-control form-control-sm" formControlName="password" id="password" type="password" />

            <ul class="list-group mt-3 small">
              <li class="list-group-item" [class.list-group-item-danger]="!requirementMinSix()"
                [class.list-group-item-success]="requirementMinSix()">
                {{ "messages.password.requirement_min_6" | translate }}
              </li>
            </ul>
          </div>

          <div class="col-6">
            <label [class.font-weight-bold]="form.get('password_confirm')?.dirty" for="password_confirm">
              {{ "messages.password.password_confirm" | translate }}
              <span class="text-danger">*</span>
            </label>
            <input class="form-control form-control-sm" formControlName="password_confirm" id="password_confirm"
              type="password" />

            <ul class="list-group mt-3 small">
              <li class="list-group-item" [class.list-group-item-danger]="!requirementSameValues()"
                [class.list-group-item-success]="requirementSameValues()">
                {{ "messages.password.requirement_same" | translate }}
              </li>
            </ul>
          </div>

          <div class="col-12 d-flex justify-content-between mt-3">
            <button (click)="save()" [disabled]="
                !form.valid &&
                isSubmitted &&
                areRequirementOk() &&
                requirementSameValues()
              " class="btn btn-sm btn-success">
              <i *ngIf="!isSubmitted" class="la la-save"></i>
              <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
              {{ "navigations.save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="card form">
    <div class="card-body">
      <h6 class="card-title font-weight-bold">
        {{ "messages.locale.choose" | translate }}
      </h6>
      <div class="form-row language">
        <div class="form-group col-12">
          <div class="locale border border-radius p-2 d-flex align-items-center"
            [class.active]="intlService.getLocale() === 'en'" (click)="switchLanguage('en')">
            <img alt="English" class="mr-2 flag" [class.active]="intlService.getLocale() === 'en'"
              src="assets/img/en.png" />
            <div class="title">{{ "messages.locale.locale_en" | translate }}</div>
          </div>
        </div>
        <div class="form-group col-12">
          <div class="locale border border-radius p-2 d-flex align-items-center"
            [class.active]="intlService.getLocale() === 'fr'" (click)="switchLanguage('fr')">
            <img alt="Français" class="mr-2 flag" [class.active]="intlService.getLocale() === 'fr'"
              src="assets/img/fr.png" />
            <div class="title">{{ "messages.locale.locale_fr" | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>