import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {merge, Observable, of, throwError} from 'rxjs';

import {AuthService} from './auth.service';

import {environment} from '../../../environments/environment';

import * as _ from 'lodash';

@Injectable()
export class ProxyService {
  /**
   * @param http
   * @param authService
   */
  constructor(
    public http: HttpClient,
    public authService: AuthService
  ) {
  }

  /**
   * Creates HTTP Headers from object.
   *
   * @param headers Headers object {k:v}
   * @param auth Do we add Bearer & token value to the query
   */
  doHeaders(headers: any = {}, auth: boolean = true): HttpHeaders {
    if (auth) {
      _.set(headers, 'Authorization', 'Bearer ' + this.authService.getToken());
    }
    return new HttpHeaders(headers);
  }

  /**
   * Creates HTTP Params from object.
   *
   * @param params Params object {k:v}
   */
  doParams(params: any = {}): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(params).forEach((key) => {
      httpParams = httpParams.append(key, params[key]);
    });
    return httpParams;
  }

  /**
   * Creates request body from object.
   *
   * @param body object {k:v}
   */
  doBody(body: any = {}): any {
    return body;
  }

  /**
   * Handle queries with headers, params, body, bearer and so.
   *
   * @param apiPath
   * @param headers
   * @param params
   * @param body
   * @param method
   * @param secured
   * @param usexmode
   */
  public request(
    apiPath: string,
    headers: any = {},
    params: any = {},
    body: any = {},
    method: string = 'get',
    secured: boolean = true,
    usexmode: boolean = true
  ): Observable<any> | null {

    // deal with expired session
    this.authService.checkAuth();

    // create params
    let lUrl = apiPath.startsWith('http://') ? apiPath : environment.url + apiPath;
    lUrl = lUrl.replace(/\/$/, '');
    const lHeaders = this.doHeaders(headers, secured);
    const lBody = this.doBody(body);

    // combien headesr & param
    const lOptions = {headers: lHeaders};
    if (params !== undefined) {
      _.each(params, (value: any, key: any) => _.set(lOptions, key, value));
    }

    // deal with query
    let res: Observable<any> | null = null;
    switch (method) {
      case 'get':
        res = this.http.get(lUrl, lOptions);
        break;
      case 'post':
        res = this.http.post(lUrl, lBody, lOptions);
        break;
      case 'put':
        res = this.http.put(lUrl, lBody, lOptions);
        break;
      case 'patch':
        res = this.http.patch(lUrl, lOptions);
        break;
      case 'delete':
        res = this.http.delete(lUrl, lOptions);
        break;
    }

    return res;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<any> {
    return (error: any): Observable<T> => {
      if (error.status && error.status === 401) {
        this.authService.logout();
      }

      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message} - ${error.status}`);

      return merge(throwError(error), of(result as T));
    };
  }
}
