
import { AssetModel } from '../AssetModel';
import { AssetModelModel } from './AssetModelModel';
import { ConditionsModel } from './ConditionsModel';

export class AssetConditionValueModel {
  id: number;
  model: AssetModelModel;
  asset: AssetModel;
  condition: ConditionsModel;
  value: string;
  date: string;
  comment: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.model = data.model ? data.model : AssetModelModel;
    this.asset = data.asset ? data.asset : AssetModel;
    this.condition = data.condition ? data.condition : ConditionsModel;
    this.value = data.value ? data.value : '';
    this.date = data.date ? data.date : '';
    this.comment = data.comment ? data.comment : '';
  }
}
