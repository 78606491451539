<form (ngSubmit)="loginCheck($event)" *ngIf="form" [formGroup]="form">
  <div class="card main">
    <div class="card-body form">
      <div class="form-group">
        <input
          class="form-control"
          formControlName="_username"
          id="_username"
          placeholder="{{ 'headers.login.username' | translate }}"
          type="text"
        />
      </div>
      <div class="form-group">
        <input
          class="form-control"
          formControlName="_password"
          id="_password"
          placeholder="{{ 'headers.login.password' | translate }}"
          type="password"
        />
      </div>
      <div class="form-group d-flex justify-content-center">
        <button
          [disabled]="!form.valid"
          class="btn btn-block btn-success btn-lg"
          type="submit"
        >
          {{ "navigations.account.login" | translate }}
          <i *ngIf="isLoading" class="la la-spin la-spinner ml-2"></i>
        </button>
      </div>
      <div class="d-flex justify-content-center">
        <button
          class="btn btn-block btn-primary btn-lg"
          routerLink="/register"
          type="button"
        >
          {{ "navigations.account.create_account" | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
