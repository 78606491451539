import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfFactorModel } from '../../../shared/models';
import { ConfirmModalComponent } from '../../../shared/modals';
import { GridComponent } from '../../../shared';
import { ConfFactorEditComponent } from './edit/edit.component';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../../shared/services/breadcrumb.service';

@Component({
  selector: 'app-conf-factors',
  templateUrl: './factors.component.html',
})
export class ConfFactorsListComponent implements OnInit {
  @ViewChild(GridComponent, { static: true }) confFactorsGrid?: GridComponent;

  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  boolReference?: { value: boolean; name: string }[];
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.parameters'),
          link: '/admin',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.factors'),
          link: '/admin/factors',
        },
      ],
      2
    );
    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.risk_parameters.factors.factors'
          ),
          field: 'description',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.risk_parameters.factors.failure_probability'
          ),
          field: 'failure_probability',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.risk_parameters.factors.mttr_percentage'
          ),
          field: 'mttr_percentage',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.risk_parameters.factors.mttr_plus_minus'
          ),
          field: 'mttr_plus_minus',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.risk_parameters.factors.no_downtime'
          ),
          field: 'no_downtime',
          filter: 'agTextColumnFilter',
          cellRenderer: (params: any): string => {
            if (params.value === true) {
              return (
                '<span class="badge badge-lg badge-success">' +
                this.translate.instant('navigations.yes') +
                '</span>'
              );
            } else {
              return (
                '<span class="badge badge-lg badge-danger">' +
                this.translate.instant('navigations.no') +
                '</span>'
              );
            }
          },
        },
      ],
    };
    await this.loadItems();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    this.boolReference = [
      { value: true, name: this.translate.instant('navigations.yes') },
      { value: false, name: this.translate.instant('navigations.no') },
    ];

    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data
      .by('api/config/factors', {
        criteria: {
          account: this.auth.getAccountId(),
        },
      })
      .toPromise();
    this.confFactorsGrid?.gridApi?.sizeColumnsToFit();
    this.confFactorsGrid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  onFilterChanged(event: any): void {}

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onRowClicked(event: any): void {
    this.showEditModal([new ConfFactorModel(event)]);
  }

  /**
   * @param items
   */
  async showEditModal(items?: ConfFactorModel[]): Promise<void> {
    this.bsModalRef = this.bsModalService.show(ConfFactorEditComponent, {
      initialState: {
        items,
        boolReference: this.boolReference,
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      this.toastr.showSuccess();
    };
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.data.post('api/config/factors/delete-all', ids).subscribe(
        (res) => {
          this.loadItems();
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  myCellCallback = (params: any) => {
    return params.value;
  };
}
