import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DataService, AuthService, ToasterService } from 'src/app/shared/services';
import { AssetModelModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { validateNumberInput } from 'src/utils';
import * as moment from 'moment';

@Component({
  selector: 'app-asset-grades-edit',
  templateUrl: './grades-edit.component.html',
  styleUrls: ['./grades-edit.component.scss']

})
export class GradesEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  selectedNodes: any[] = []; // array of items to edit
  conditionValues: any[] = []; // array of items to edit
  // form instance
  form!: UntypedFormGroup;
  gradesReference: any[] = [];
  previousAssessments: any[] = [];
  selectedLifecycleStrategyNodes: any[] = [];

  // Usage mode
  mode: ('mass' | 'single' | 'new') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;
  showValidationError = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   * @param toastr
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private toastr: ToasterService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    await this.loadGrades();

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.selectedNodes.map((ele: any) => {
      this.targetIds.push(ele.data.id);
    });

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.items = [new AssetModelModel({
        site: this.auth.getSiteId(),
      })];
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    if (this.mode === 'single') {

      const lastThreeGrades = this.items[0]?.grade?.slice(-5).reverse();

      lastThreeGrades?.map((ele: any) => {
        const gradeName = this.gradesReference.find(obj => obj.value === Number(ele.grade_id))?.name;
        const date = moment(ele.date).format('DD/MM/YYYY');

        this.previousAssessments.push({ grade: gradeName, date, grade_id: ele.grade_id });
      });
    }

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [AssetModelModel?]): ('mass' | 'single' | 'new') {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // More than one item => Mass Edit
    } else if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  // Load Types and Budgets used in modal edit form
  async loadGrades(): Promise<void> {
    const orderByDesc = { orderBy: { description: 'asc' } };

    const gradesPromise = this.service.by('api/fastlc/grades', {
      criteria: {
        account: this.auth.getAccountId()
      },
      ...orderByDesc
    }).toPromise();

    try {
      const [grades] = await Promise.all([gradesPromise]);

      this.gradesReference = _.map(grades, (el) => {
        return {
          name: el.description,
          value: el.id
        };
      });
    } catch (error) {
      // Handle error if any of the promises reject
      console.error('Error fetching data:', error);
    }
  }

  /**
   *
   */
  getForm(item: AssetModelModel): UntypedFormGroup {
    let grade = null;

    if (this.mode === 'single' && item?.grade) {
      const index = item?.grade?.length - 1;

      grade = item?.grade[index]?.grade_id;
    }

    return this.formBuilder.group({
      grade: new UntypedFormControl(Number(grade)),
    });
  }

  validateNumberInput(event: KeyboardEvent): void {
    validateNumberInput(event);
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => {
  };

  /**
   *
   */
  save(): boolean | void {
    this.showValidationError = true;

    if (
      this.previousAssessments.length > 0
      && Number(this.previousAssessments[0].grade_id) === this.form.value.grade
      && this.previousAssessments[0].date === moment().format('DD/MM/YYYY')
    ) {
      this.toastr.showError('Cannot set same grade in same day.');
      return false;
    }


    if (!this.form || (this.form && !this.form.valid)) {
      return false;

    } else {
      // Query pointer
      let query;

      const data = {
        model_ids: this.targetIds,
        grade: this.form.value.grade,
        criteria: {
          site: this.auth.getSiteId()
        }
      };

      query = this.service.post('api/fastlc/asset-models/save-grades', data);


      this.isSubmitted = true;
      query?.subscribe((res: any) => {
        this.isSubmitted = false;
        this.confirmFn(res);
        this.bsModalRef.hide();
      }, (err: any) => {
        this.toastr.showError(`${err.error.message} </br> ${this.translate.instant('navigations.already_exist')}`);
        this.isSubmitted = false;
      });
    }
  }
}
