<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <h5 *ngIf="label">
      {{ label }}
    </h5>
    <form [formGroup]="form">
      <div class="card form">
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-4">
              <label
                [class.font-weight-bold]="form.get('critical_spare')?.dirty"
              >
                {{ "headers.fastsp.spare_strategy.essential" | translate }}
              </label>
              <app-field-clear-button
                [formGroupRef]="form"
                [fieldName]="'critical_spare'"
              ></app-field-clear-button>
              <ngx-select
                [items]="boolReference"
                [placeholder]="'(Select a Value)'"
                formControlName="critical_spare"
                optionTextField="name"
                optionValueField="value"
                size="small"
              ></ngx-select>
            </div>
            <div class="form-group col-8">
              <label
                [class.font-weight-bold]="form.get('status_id')?.dirty"
                for="status_id"
              >
                {{ "headers.fastsp.spare_strategy.status" | translate }}
              </label>
              <app-field-clear-button
                [formGroupRef]="form"
                [fieldName]="'status_id'"
              ></app-field-clear-button>
              <ngx-select
                [items]="statusReference"
                [placeholder]="'(Select a Status)'"
                formControlName="status_id"
                id="status_id"
                [multiple]="false"
                optionTextField="name"
                optionValueField="value"
                size="small"
              ></ngx-select>
              <div
                class="text-danger"
                *ngIf="showValidationError && form.get('status_id')?.invalid"
              >
                {{ "messages.required" | translate }}
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-8 part_ref-select-container">
              <label
                [class.font-weight-bold]="form.get('part_ref')?.dirty"
                for="part_ref"
              >
                {{ "headers.fastsp.spare_strategy.partref" | translate }}
              </label>
              <app-field-clear-button
                [formGroupRef]="form"
                [fieldName]="'part_ref'"
              ></app-field-clear-button>
              <ngx-select
                [items]="partrefReference"
                (typed)="onPartrefSearch($event)"
                (open)="doPartrefSelectOpen()"
                [placeholder]="'(Select a Reference)'"
                formControlName="part_ref"
                id="part_ref"
                [multiple]="false"
                optionTextField="description"
                optionValueField="description"
                size="small"
              ></ngx-select>

              <div
                class="text-danger"
                *ngIf="showValidationError && form.get('part_ref')?.invalid"
              >
                {{ "messages.required" | translate }}
                0
              </div>
            </div>
            <div class="form-group col-4">
              <label
                [class.font-weight-bold]="form.get('cost')?.dirty"
                for="factor"
              >
                {{ "headers.fastsp.spare_strategy.cost" | translate }}
              </label>
              <input
                [ngClass]="{
                  'is-invalid': showValidationError && form.get('cost')?.invalid
                }"
                class="form-control form-control-sm"
                formControlName="cost"
                id="cost"
                type="number"
                (keypress)="validateNumberInput($event)"
              />
              <div
                class="text-danger"
                *ngIf="showValidationError && form.get('cost')?.errors?.min"
              >
                {{ "messages.positive" | translate }}
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('comment')?.dirty"
                for="comment"
              >
                {{ "headers.fastsp.spare_strategy.comment" | translate }}
              </label>
              <input
                [ngClass]="{
                  'is-invalid':
                    showValidationError && form.get('comment')?.invalid
                }"
                class="form-control form-control-sm"
                formControlName="comment"
                id="comment"
                type="text"
              />
              <div
                class="text-danger"
                *ngIf="showValidationError && form.get('comment')?.invalid"
              >
                {{ "messages.required" | translate }}
                0
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="
            (!form.pristine && !form.dirty && isSubmitted) ||
            !form.valid ||
            isSubmitted
          "
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
