import { SiteModel } from './SiteModel';

export class RiskModelModel {
  id: number;
  site: SiteModel;
  description: string;
  comment: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.site = data.site ? data.site : SiteModel;
    this.description = data.description ? data.description : '';
    this.comment = data.comment ? data.comment : '';
  }
}
