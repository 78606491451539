import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AuthService } from '.';
import { NO_ACCESS } from './constants';

@Injectable({
  providedIn: 'root'
})
export class AccessRightsResolver implements Resolve<any> {

  constructor(
    public auth: AuthService,
    public router: Router,
  ) { }

  resolve(route: ActivatedRouteSnapshot): any {
    if (this.auth.currentUserIsAdmin()) {
      return;
    }

    const module = route.routeConfig?.data?.module || '';
    const rights = this.auth.getAccessRights(module);
    if (rights === NO_ACCESS || module === 'admin') {
      this.router.navigate(['/404']);
    }
  }
}
