<div class="container-fluid" *ngFor="let item of modules">
  <div
    class="module__container"
    *ngIf="
      !isAdminModule(item.children[0].access_type) || auth.currentUserIsAdmin()
    "
  >
    <div class="ml-5 mt-3">
      <h3 class="module__header">
        {{ item.name | uppercase }}
      </h3>
    </div>
    <div class="row ml-4 mr-4">
      <div
        class="col-sm-6 col-md-4 col-lg-3 mb-2"
        *ngFor="let child of item.children"
      >
        <div
          [routerLink]="child.link"
          y
          class="card module {{ child.module }} d-flex justify-content-center "
          [class.disabled]="!isModuleVisible(child.access_type)"
        >
          <div class="module d-flex align-items-center flex-column">
            <div class="title p-2">{{ child.name }}</div>
            <div class="p-2 text-center">{{ child.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
