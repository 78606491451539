export class ConfFactorModel {
  id: number;
  description: string;
  failureProbability: number;
  mttrPercentage: number;
  mttrPlusMinus: number;
  noDowntime: boolean;

  account: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.description = data.description ? data.description : '';
    this.failureProbability = data.id ? data.failure_probability : 100;
    this.mttrPercentage = data.id ? data.mttr_percentage : 100;
    this.mttrPlusMinus = data.id ? data.mttr_plus_minus : 0;
    this.noDowntime = data.no_downtime ? data.no_downtime : false;

    this.account = data.account ? data.account : null;
  }
}
