export class SiteModel {
  id: number;
  name: string;

  account: string;
  accountId: number;
  site: string;
  siteId: number;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.name = data.name ? data.name : '';

    this.site = data.site ? data.site : null;
    this.siteId = data.siteId ? data.siteId : null;
    this.account = data.account ? data.account : null;
    this.accountId = data.accountId ? data.accountId : null;
  }
}
