import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {DataService, AuthService} from 'src/app/shared/services';
import {FastspStrategyModel} from 'src/app/shared/models';
import {BsModalRef} from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {doSelectOpen, onSearch, validateNumberInput} from 'src/utils';

@Component({
  selector: 'app-asset-edit',
  templateUrl: './edit.component.html'
})
export class SparePartEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  selectedNodes: any;
  statusReference: [] = [];
  partrefReference: any[] = [];
  showValidationError = false;
  boolReference: { value: boolean; name: string }[] = [];
  label: string | null = null;

  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: ('mass' | 'single') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    this.setLabel();

    // get target Ids
    this.targetIds = _.map(this.items, (el) => el.id);

    // Get default empty object
    if ('mass' === this.mode) {
      this.items = [
        new FastspStrategyModel({
          account: this.auth.getAccountId(),
          siteId: this.auth.getSiteId(),
        }),
      ];
    }

    // Push current Parent to init parentReference and have default selection
    if (['single'].indexOf(this.mode) > -1) {
      this.parentReference = _.map(this.items, (el) => {
        return {
          id: el.parentId,
          name: el.parent,
        };
      }).filter((el) => el !== null);
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    // Loading done !
    this.isLoading = false;
  }


  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [FastspStrategyModel?]): 'mass' | 'single' {
    // Empty array => New Item
    if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  setLabel(): void {
    const assetName = this.items[0]?.asset_name;
    const assetDecription = this.items[0]?.asset_description
      ? ' - ' + this.items[0]?.asset_description
      : '';
    const spareName = this.items[0]?.spare_name
      ? ' - ' + this.items[0]?.spare_name
      : '';

    if (this.mode === 'single') {
      this.label = assetName + assetDecription + spareName;
    } else if (this.mode === 'mass') {
      this.label = assetName + assetDecription;
    }

    for (let i = 1; i < this.items.length; i++) {
      if (this.items[i].asset_id !== this.items[i - 1].asset_id) {
        this.label = null;
      }
    }
  }

  /**
   *
   */
  getForm(item: any): UntypedFormGroup {
    const validators =
      this.mode === 'single' ? [Validators.required, Validators.min(0)] : null;
    return this.formBuilder.group({
      model: new UntypedFormControl(_.map(item.model, el => +el)),
      factor: new UntypedFormControl(_.map(item.factor, el => +el)),
      cost: new UntypedFormControl(item.asset_spare_cost, validators),
      status_id: new UntypedFormControl(item.status_id ? Number(item.status_id) : null),
      comment: new UntypedFormControl(item.asset_spare_comment),
      date: new UntypedFormControl(null),
      assetSpares: new UntypedFormControl(this.selectedNodes),
      part_ref: new UntypedFormControl(item.partref),
      critical_spare: new UntypedFormControl(item.critical),
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any, mode: string) => {};

  validateNumberInput(event: KeyboardEvent): void {
    validateNumberInput(event);
  }

  /**
   *
   * @param event
   */
  onTyping(event: any): void {
    if (!event) {
      return;
    }

    this.service
      .by('api/assets', {
        criteria: {site: [this.auth.getSiteId(), null]},
        fulltext: {name: event},
      })
      .subscribe((res) => {
        this.parentReference = res;
      });
  }

  /**
   *
   * @param event
   */
  onSelect(event: any): void {
    if (event[0]) {
      this.data = event[0].data;
    }
  }

  doPartrefSelectOpen(): void {
    doSelectOpen(this.form, 'part_ref');
  }

  onPartrefSearch(event: any): void {
    onSearch(event, this.partrefReference, this.form, 'part_ref');
  }

  /**
   *
   */
  save(): boolean | void {
    this.showValidationError = true;
    if (!this.form || (this.form && !this.form.valid)) {
      return false;
    }

    let data: any = {};
    if (this.mode === 'single') {
      data = this.form.value;
    } else {
      _.each(this.form.controls, (control, name) => {
        if (control.dirty === true && control.value !== -1) {
          data[name] = control.value;
        }
      });
    }

    data.assetSpares = _.map(this.selectedNodes, (el) => {
      return {
        asset_id: el.asset_id,
        spare_id: el.spare_id
      };
    });

    this.isSubmitted = true;

    if (data.part_ref) {
      const partRef = data.part_ref;

      data.part_ref = partRef.replace(/'/g, `\\'`);
    }

    this.service.post('api/fastsp/asset-spares', data).subscribe(
      (res) => {
        this.isSubmitted = false;
        this.confirmFn(res, this.mode);
        this.bsModalRef.hide();
      },
      (err) => {
        this.isSubmitted = false;
      }
    );
  }
}
