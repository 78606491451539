<div class="d-flex flex-column h-100 w-100">
  <div class="d-flex align-items-center px-2">
    <div class="mr-2">
      <button (click)="loadItems()" [ngClass]="{ 'btn-success': isLoading, 'btn-light': !isLoading }"
        class="btn btn-sm">
        <i [ngClass]="{ 'la-spin': isLoading }" class="la la-refresh"></i>
      </button>
    </div>
    <div class="mr-2">
      <button (click)="showEditModal()" [disabled]="isLoading" class="btn btn-success btn-sm">
        <i class="la la-plus"></i>
      </button>
    </div>
    <div [isDisabled]="selectedNodes.length === 0" class="btn-group mr-1" dropdown>
      <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle type="button">
        <ng-container *ngIf="selectedNodes.length > 0">{{ "navigations.with" | translate }}
          <strong>{{ selectedNodes.length }}</strong>
          {{ "navigations.selected" | translate }}
        </ng-container>
        <ng-container *ngIf="selectedNodes.length === 0">{{
          "navigations.no_selection" | translate
          }}</ng-container>
        <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu">
        <li (click)="confConsequencesGrid?.export('xlsx')" class="btn btn-sm dropdown-item">
          <i class="la la-fw la-file-excel"></i> XLSX
        </li>
        <li (click)="confConsequencesGrid?.export('csv')" class="btn btn-sm dropdown-item">
          <i class="la la-fw la-file-csv"></i> CSV
        </li>
        <li class="divider dropdown-divider"></li>
        <li role="menuitem">
          <a class="dropdown-item btn btn-sm btn-danger" (click)="showMassDeleteConfirm()">
            <i class="la la-fw la-trash"></i>
            {{ "navigations.delete" | translate }}
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="d-flex h-100 w-100 flex-grow-1 p-2 ag-grid-container">
    <app-grid (filterChanged)="onFilterChanged($event)" (rowDoubleClicked)="onRowClicked($event)"
      (selectionChanged)="onSelectionChanged($event)" [gridOptions]="gridOptions" [rowData]="items"
      class="ag-theme-alpine" style="width: 100%; height: 100%">
    </app-grid>
  </div>
</div>