import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../../shared/modals';
import { GridComponent } from '../../shared';
import * as _ from 'lodash';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';

import { RoutingPlanEditComponent } from './edit/edit.component';
import { PlanModel } from '../../shared/models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
})
export class RoutingPlanComponent implements OnInit, AfterViewInit {
  @ViewChild(GridComponent, { static: true }) grid?: GridComponent;

  showTasksGrid: any = false;
  selectedPlan: any[] = [];
  isDefaultPlanSelected = false;
  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;

  statusReference = [
    { name: this.translate.instant('headers.routing.plan.status_1'), value: 1 },
    { name: this.translate.instant('headers.routing.plan.status_2'), value: 2 },
    { name: this.translate.instant('headers.routing.plan.status_3'), value: 3 },
    { name: this.translate.instant('headers.routing.plan.status_4'), value: 4 },
  ];

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    if (this.grid) {
      this.grid.exportColumns = ['cmmsReference', 'comments'];
    }
  }

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.plans'),
          link: '/routing/plans',
        },
      ],
      2
    );
    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      onRowDataChanged: (event) => this.filterAndSelectDefaultPlan(event),
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.routing.plan.plan'),
          field: 'name',
          filter: 'agTextColumnFilter',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.status'),
          field: 'status',
          cellRenderer: (params: any): string => {
            let cssClass = '';
            if (params.data.status === 1) {
              cssClass = 'badge-light';
            } else if (params.data.status === 2) {
              cssClass = 'badge-success';
            } else if (params.data.status === 3) {
              cssClass = 'badge-warning';
            } else if (params.data.status === 4) {
              cssClass = 'badge-danger';
            }
            return (
              '<span class="badge badge-lg ' +
              cssClass +
              '">' +
              this.translate.instant(
                'headers.routing.plan.status_' + params.data.status
              ) +
              '</span>'
            );
          },
          filterParams: {
            valueFormatter: (params: any): string => {
              return this.translate.instant(
                'headers.routing.plan.status_' + params.value
              );
            },
          },
        },
        {
          headerName: this.translate.instant('headers.routing.plan.quality'),
          field: 'quality',
        },
        {
          headerName: this.translate.instant('headers.routing.plan.safety'),
          field: 'safety',
        },
        {
          headerName: this.translate.instant('headers.routing.plan.parts'),
          field: 'parts',
        },
        {
          headerName: this.translate.instant('headers.routing.plan.task_count'),
          field: 'tasks',
        },
        {
          headerName: this.translate.instant('headers.routing.plan.duration'),
          field: 'duration',
        },
        {
          headerName: this.translate.instant(
            'headers.routing.plan.actualDuration'
          ),
          field: 'actualDuration',
        },
        {
          headerName: this.translate.instant(
            'headers.routing.plan.release_date'
          ),
          field: 'release_date',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.frequency'
          ),
          field: 'frequency',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.id'
          ),
          field: 'frequencyId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.state'
          ),
          field: 'state',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.id'
          ),
          field: 'stateId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.labor'
          ),
          field: 'labour',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.id'
          ),
          field: 'labourId',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.routing.plan.cmms_reference'
          ),
          field: 'cmmsReference',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.comments'),
          field: 'comments',
          hide: true,
        },
      ],
    };
    await this.loadItems();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data.get('api/plans/from_site').toPromise();
    this.grid?.expandAll();
    this.grid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
    this.selectedPlan = this.selectedNodes.map((item) => item.data.id);
    if (this.selectedPlan.length > 0) {
      this.showTasksGrid = true;
    } else {
      this.showTasksGrid = false;
    }
  }

  onRowClicked(event: any): void {
    this.showEditModal([new PlanModel(event)]);
  }

  /**
   */
  showMassEditModal(): void {
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      const items = _.map(this.selectedNodes, (el) => el.data);
      this.showEditModal(items);
    }
  }

  /**
   * @param items
   */
  async showEditModal(items?: any): Promise<void> {
    this.bsModalRef = this.bsModalService.show(RoutingPlanEditComponent, {
      initialState: {
        items,
        statusReference: this.statusReference,
      },
      class: 'modal-lg',
    });
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      this.toastr.showSuccess();
    };
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.data.post('api/plans/delete-all', ids).subscribe(
        (res) => {
          this.loadItems();
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  myCellCallback = (params: any) => {
    return params.value;
  };

  generatePlanCsv(): void {
    const selectedRows = this.selectedNodes.map((el) => el.data.id);
    this.data
      .download('api/plans/csv', {
        ids: selectedRows,
      })
      .subscribe(
        (res) => {
          const contentDisposition = res.headers.get('content-disposition');
          let fileName = contentDisposition.split(';')[1].trim().split('=')[1];
          fileName = fileName.replace(/"/g, '');
          const options = { type: res.body.type };
          this.data.createAndDownloadBlobFile(res.body, options, fileName);
        },
        (err) => {
          this.toastr.showError();
        }
      );
  }

  generatePlanPdf(): void {
    const selectedRows = this.selectedNodes.map((el) => el.data.id);
    this.data
      .download('api/plans/pdf', {
        ids: selectedRows,
        account: this.auth.getAccountId(),
        site: this.auth.getSiteId(),
      })
      .subscribe(
        (res) => {
          if (res.status === 204) {
            this.toastr.showError('No task found for selected plan(s).');
            return;
          }
          const contentDisposition = res.headers.get('content-disposition');
          let fileName = contentDisposition.split(';')[1].trim().split('=')[1];
          fileName = fileName.replace(/"/g, '');
          const options = { type: res.body.type };
          this.data.createAndDownloadBlobFile(res.body, options, fileName);
        },
        (err) => {
          this.toastr.showError();
        }
      );
  }

  toggleTasksGrid(): void {
    this.showTasksGrid = !this.showTasksGrid;
    return;
  }

  filterAndSelectDefaultPlan = (event: any): void => {
    const planId = this.activatedRoute.snapshot.queryParamMap.get('plan_id');

    if (
      !planId ||
      this.isDefaultPlanSelected ||
      event.api.getDisplayedRowCount() === 0
    ) {
      return;
    }

    let isPlanAvailable = false;

    event.api.forEachNode((node: any) => {
      if (node.data.id === +planId) {
        isPlanAvailable = true;

        const hardcodedFilter = {
          name: {
            type: 'set',
            filter: node.data.name,
          },
        };
        event.api.setFilterModel(hardcodedFilter);
        node.setSelected(true);

        if (!this.isDefaultPlanSelected) {
          this.isDefaultPlanSelected = true;
        }
      }
    });

    if (!isPlanAvailable) {
      this.toastr.showError(this.translate.instant('messages.plan_not_found'));
    }
  };
}
