<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="card form">
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-12">
              <label [class.font-weight-bold]="form.get('user')?.dirty">
                {{
                  "headers.account_and_user_management.user.user" | translate
                }}
              </label>
              <ngx-select
                (typed)="onTyping($event)"
                [allowClear]="true"
                [items]="usersReference"
                [placeholder]="'(Search for a user)'"
                [disabled]="['single', 'mass'].includes(this.mode)"
                optionTextField="name"
                optionValueField="value"
                formControlName="user"
                size="small"
              ></ngx-select>
            </div>

            <div class="form-group col-6">
              <label
                [class.font-weight-bold]="form.get('isAdmin')?.dirty"
                for="isAdmin"
              >
                {{
                  "headers.account_and_user_management.role.isAdmin" | translate
                }}
              </label>
              <ngx-select
                (selectionChanges)="changeIsAdmin($event)"
                [items]="boolReference"
                [placeholder]="'(Select a Role)'"
                formControlName="isAdmin"
                id="isAdmin"
                optionTextField="name"
                optionValueField="value"
                size="small"
              ></ngx-select>
            </div>

            <div class="form-group col-6">
              <label
                [class.font-weight-bold]="form.get('role')?.dirty"
                for="role"
              >
                {{
                  "headers.account_and_user_management.user.role" | translate
                }}
              </label>
              <app-field-clear-button
                [formGroupRef]="form"
                [fieldName]="'role'"
              ></app-field-clear-button>
              <ngx-select
                [disabled]="isRoleDisabled"
                [items]="rolesReference"
                [placeholder]="'(Select a Role)'"
                formControlName="role"
                id="role"
                optionTextField="name"
                optionValueField="value"
                size="small"
              ></ngx-select>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="
            (!form.pristine && !form.dirty && isSubmitted) || !form.valid
          "
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
