import {
  Component,
  OnInit,
  ViewChild,
  Input,
  SimpleChanges,
  Output,
  OnChanges,
  EventEmitter,
} from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../../shared/services';
import { Router } from '@angular/router';
import { Column, GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FunctionLossModel } from '../../../shared/models';
import { ConfirmModalComponent } from '../../../shared/modals';
import { GridComponent } from '../../../shared';
import { FunctionLossEditComponent } from './function-loss-edit/edit.component';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../../shared/services/breadcrumb.service';

@Component({
  selector: 'app-function-loss',
  templateUrl: './function-loss.component.html',
})
export class FunctionLossListComponent implements OnInit, OnChanges {
  @ViewChild(GridComponent, { static: true }) functionLossGrid?: GridComponent;

  @Input() selectedRiskModelNodes: any[] = [];
  @Input() sizeColumnsToFit = true;
  @Output() changeSelectFunctionLossNodes = new EventEmitter<any>();
  @Output() functionLossDeleted = new EventEmitter<any>();
  @Output() resetModelsGrid = new EventEmitter<any>();
  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  isReferenceLoaded = false;
  functionalFailuresReference?: any;
  functionLossGridAutoGroupColumnDef?: any;
  modelDescriptions: any = {};

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    const tmpGridOptions: GridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1, // expand all groups by default
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.fastcr.risk_model.risk_model'
          ),
          field: 'fastcrModel.description',
          valueGetter: (params: any) => {
            if (params.data) {
              return params.data.fastcrModel.id;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.fastcrModel.description;
            }
          },
          cellRenderer: (params: any) => {
            return this.modelDescriptions?.[params.value];
          },
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.function_loss.mtbf'
          ),
          field: 'mtbf',
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.function_loss.mttr'
          ),
          field: 'mttr',
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.function_loss.downtime_probability'
          ),
          field: 'probabilityDowntime',
          cellRenderer: (params: any): string => {
            if (params.value !== undefined && params.value !== null) {
              return params.value + '%';
            }
            return '';
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.function_loss.comment'
          ),
          field: 'comment',
        },
        {
          headerName: this.translate.instant('headers.fastcr.risk_model.id'),
          field: 'fastcrModel.id',
          hide: true,
        },
      ],
    };
    this.functionLossGridAutoGroupColumnDef = {
      headerName: this.translate.instant(
        'headers.fastcr.function_loss.functional_failure'
      ),
      field: 'configFunctionalFailure.description',
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    this.gridOptions = tmpGridOptions;
    await this.loadItems();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedRiskModelNodes) {
      this.filterFunctionLoss(this.selectedRiskModelNodes);
    }
  }

  clearFilterAndResetModelsGrid(): void {
    this.functionLossGrid?.clearAllFilters();
    this.resetModelsGrid.emit();
  }

  resetFunctionLossGrid(): void {
    this.functionLossGrid?.uncheckAll();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }

    this.items = await this.data
      .by('api/fastcrit/function-loss', {
        criteria: {
          site: this.auth.getSiteId(),
        },
      })
      .toPromise();
    if (this.sizeColumnsToFit) {
      this.functionLossGrid?.gridApi?.sizeColumnsToFit();
      this.sizeColumnsToFit = false;
    }

    this.functionLossGrid?.uncheckAll();
    this.filterFunctionLoss(this.selectedRiskModelNodes);
    // set data array to use ID as value in valueGetter and Description as display value.
    // This is to allow grouping by ID instead of display value ("name" for eg.) thereby avoid grouping on duplicate display values
    this.items.map((val) => {
      this.modelDescriptions[val.fastcrModel.id] = val.fastcrModel.description;
    });
    if (!silent) {
      this.isLoading = false;
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
    this.changeSelectFunctionLossNodes.emit(this.selectedNodes);
  }

  onRowClicked(event: any): void {
    this.showEditModal([new FunctionLossModel(event)]);
  }

  filterFunctionLoss(riskModels: any): void {
    const instance =
      this.functionLossGrid?.gridApi?.getFilterInstance('fastcrModel.id');

    if (riskModels.length > 0) {
      setTimeout(() => {
        instance?.setModel({
          values: _.map(riskModels, (el) => el.id + ''),
        });
        this.functionLossGrid?.gridApi?.onFilterChanged();
      });
      return;
    }

    instance?.setModel(null);
    this.functionLossGrid?.gridApi?.onFilterChanged();
  }

  /**
   * @param items
   */
  async showEditModal(items?: FunctionLossModel[]): Promise<void> {
    await this.loadFunctionalFailures();

    this.bsModalRef = this.bsModalService.show(FunctionLossEditComponent, {
      initialState: {
        items,
        functionalFailuresReference: this.functionalFailuresReference,
        selectedRiskModelNodes: this.selectedRiskModelNodes,
      },
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      this.toastr.showSuccess();
    };
  }

  // Load Functional Failures used in modal edit form
  async loadFunctionalFailures(): Promise<void> {
    // Functional Failures
    const orderByDesc = { orderBy: { description: 'asc' } };
    const tpm = await this.data
      .by('api/config/functional-failures', {
        criteria: {
          account: this.auth.getAccountId(),
        },
        ...orderByDesc,
      })
      .toPromise();
    this.functionalFailuresReference = _.map(tpm, (el: any) => {
      return {
        name: el.description,
        value: el.id,
      };
    });

    // Mark ref as loaded (prevent reload)
    this.isReferenceLoaded = true;
  }

  showMassEditModal(): void {
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      const items = _.map(
        this.selectedNodes,
        (el) => new FunctionLossModel(el.data)
      );
      this.showEditModal(items);
    }
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.data.post('api/fastcrit/function-loss/delete-all', ids).subscribe(
        (res) => {
          this.loadItems();
          this.toastr.showSuccess('messages.delete');
          this.functionLossDeleted.emit(true);
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  myCellCallback = (params: any) => {
    return params.value;
  };

  exportProcessCallBack = (cellParams: any) => {
    if (
      cellParams.column.colId === 'fastcrModel.description' &&
      cellParams.value
    ) {
      return this.modelDescriptions[cellParams.value];
    }

    if (
      cellParams.value &&
      cellParams.node.field === 'fastcrModel.description'
    ) {
      return this.modelDescriptions?.[cellParams.node.key];
    }

    if (cellParams.column.colId === 'probabilityDowntime' && cellParams.value) {
      return cellParams.value + '%';
    }

    return cellParams.value;
  };
}
