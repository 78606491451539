<div class="container-fluid h-100">
  <div class="mb-2">
    <button (click)="showHeaderEditModal()" class="btn btn-sm btn-primary">
      {{ "navigations.manage_custom_field_headers" | translate }}
    </button>
  </div>
  <div class="row three-col" *ngIf="!isLoading">
    <div class="col-2 flex-column h-100">
      <app-conf-asset-custom-field1
        [customHeaders]="customHeaders"
      ></app-conf-asset-custom-field1>
    </div>
    <div class="col-2 flex-column h-100">
      <app-conf-asset-custom-field2
        [customHeaders]="customHeaders"
      ></app-conf-asset-custom-field2>
    </div>
    <div class="col-2 flex-column h-100">
      <app-conf-asset-custom-field3
        [customHeaders]="customHeaders"
      ></app-conf-asset-custom-field3>
    </div>
    <div class="col-2 flex-column h-100">
      <app-conf-asset-custom-field4
        [customHeaders]="customHeaders"
      ></app-conf-asset-custom-field4>
    </div>
    <div class="col-2 flex-column h-100">
      <app-conf-asset-custom-field5
        [customHeaders]="customHeaders"
      ></app-conf-asset-custom-field5>
    </div>
    <div class="col-2 flex-column h-100">
      <app-conf-asset-custom-field6
        [customHeaders]="customHeaders"
      ></app-conf-asset-custom-field6>
    </div>
  </div>
</div>
