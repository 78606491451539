import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {ProxyService} from './proxy.service';

import * as _ from 'lodash';

@Injectable()
export class IntlService {
  // Error
  error: any = null;

  /**
   * @param http
   * @param proxyProvider
   * @param router
   */
  constructor(
    public http: HttpClient,
    public proxyProvider: ProxyService,
    public router: Router
  ) {
  }

  /**
   * Return translated property of an object depending of current locale.
   *
   * @param object
   * @param field
   */
  getLocaleValue(object: { [x: string]: string; } | undefined, field: string): string {
    if (object === undefined) {
      return '';
    }
    const localeSuffix = (this.getLocale() === 'fr') ? '' : 'En';
    if (!_.isEmpty(object[field + localeSuffix])) {
      return object[field + localeSuffix];
    } else if (!_.isEmpty(object[field])) {
      return object[field];
    } else if (!_.isEmpty(object[field + 'En'])) {
      return object[field + 'En'];
    } else {
      return '';
    }
  }

  /**
   * Return current locale.
   */
  getLocale(): string {
    const locale: string | null = localStorage.getItem('amsyst_locale');
    if (!locale) {
      return 'en';
    } else {
      return locale;
    }
  }

  /**
   * Set current locale.
   *
   * @param lang
   */
  setLocale(lang: string): void {
    localStorage.setItem('amsyst_locale', lang);
    // this.updateLocale(lang).subscribe(data => {
    // });
  }

  /**
   * Control a a FormGroup with a "name" FormControl to have value on fr and en translation.
   *
   * @param form
   */
  namesControl(form: UntypedFormGroup): boolean {
    // @ts-ignore
    return !(form.get('name').value === '' && form.get('nameEn').value === '');
  }

  /**
   * Update loaded locale from API.
   *
   * @param lang
   */
  updateLocale(lang: string): Observable<any> {
    // @ts-ignore
    return this.proxyProvider.request(
      'users/update-locale', {}, {}, {locale: lang}, 'put', true
    ).pipe(
      catchError(this.proxyProvider.handleError('IntlService::updateLocale(' + lang + ')', []))
    );
  }

  getDateFormat(): string {
    return (this.getLocale() === 'fr' ? 'DD/MM/YYYY' : 'DD-MM-YYYY');
  }
}
