<ng-container *ngIf="!isLoading">
  <div class="modal-header">
    <h3 class="mb-0 ml-3 font-weight-bold alert-heading">
      {{ "headers.fastlc.asset_action.cost_calculator" | translate }}
    </h3>
  </div>

  <div class="d-flex flex-row h-100">
    <div class="d-flex h-100 w-50 flex-grow-1 pt-2 pl-4 ag-grid-container">
      <app-grid
        [gridOptions]="gridOptions"
        (selectionChanged)="onSelectionChanged($event)"
        [rowData]="items"
        class="ag-theme-alpine"
        style="width: 100%; height: 100%"
        [autoGroupColumnDef]="assetActionGridAutoGroupColumnDef"
        style="width: 100%; height: 300px; max-height: 300px"
      >
      </app-grid>
    </div>
    <div class="d-flex h-100 w-50">
      <app-chart
        *ngIf="!isChartLoading"
        style="width: 100%; height: 100%"
        [chartItems]="chartItems"
      ></app-chart>
      <app-loader *ngIf="isChartLoading"></app-loader>
    </div>
  </div>

  <div class="modal-footer">
    <div class="mr-2">
      <button
        (click)="downloadCsv()"
        [disabled]="isChartLoading || isLoading"
        class="btn btn-sm btn-primary"
      >
        Download CSV
      </button>
    </div>
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<app-loader *ngIf="isLoading"></app-loader>
