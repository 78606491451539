import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {DataService, AuthService} from 'src/app/shared/services';
import {ConfAssetAccessRequirementModel} from 'src/app/shared/models';
import {BsModalRef} from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {ComponentModel, AssetModel} from '../../../shared/models';

@Component({
  selector: 'app-access-edit',
  templateUrl: './edit.component.html'
})
export class AssetAccessEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  accessReference: [] = [];

  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: ('mass' | 'single' | 'new') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;
  showValidationError = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService
  ) {
    this.isLoading = true;
  }

  async ngOnInit(): Promise<void> {

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.targetIds = _.map(this.items, el => el.id);

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.items = [new ConfAssetAccessRequirementModel({
        account: this.auth.getAccountId()
      })];
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [ComponentModel?]): ('mass' | 'single' | 'new') {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // More than one item => Mass Edit
    } else if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  /**
   *
   */
  getForm(item: AssetModel): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      accesses: new UntypedFormControl(_.map(item.accesses, (el: any) => el.id)),
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => {
  };

  save(): boolean | void {
    if (!this.form || (this.form && !this.form.valid)) {
      return false;

    } else {

      // Query pointer
      let query;

      if (this.mode === 'new') {
        const tmpData = _.pickBy(this.form.value, el => el !== null);
        query = this.service.post('api/assets', tmpData);

      } else if (this.mode === 'single') {
        query = this.service.update('api/assets', this.targetIds[0], this.form.value);

      } else {
        const tmpData: any = {};
        _.each(this.form.controls, (control, name) => {
          if (control.dirty === true && control.value !== -1) {
            tmpData[name] = control.value;
          }
        });
        const data = _.map(this.targetIds, id => {
          const cloned = _.cloneDeep(tmpData);
          _.merge(cloned, {id});
          return cloned;
        });

        const retainFields = ['accesses'];
        query = this.service.saveAllRetain('api/assets', data, retainFields);
      }

      this.isSubmitted = true;
      query.subscribe(res => {
        this.isSubmitted = false;
        this.confirmFn(res);
        this.bsModalRef.hide();
      }, err => {
        console.log('err: ', err);
        this.isSubmitted = false;
      });
    }
  }
}
