import {
  Component,
  OnInit,
  ViewChild,
  Input,
  SimpleChanges,
  Output,
  OnChanges,
  EventEmitter,
} from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssetConditionValueModel } from '../../../../shared/models';
import { ConfirmModalComponent } from '../../../../shared/modals';
import { GridComponent } from '../../../../shared';
import { AssetConditionValueEditComponent } from './edit/edit.component';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../../../shared/services/breadcrumb.service';
import { syncItems } from 'src/utils';

@Component({
  selector: 'app-asset-condition-value',
  templateUrl: './asset-condition-value.component.html',
})
export class AssetConditionValueListComponent implements OnInit, OnChanges {
  @ViewChild(GridComponent, { static: true }) assetActionGrid?: GridComponent;

  @Input() selectedAssetModelNodes: any[] = [];
  @Input() sizeColumnsToFit = true;
  @Output() resetModelsGrid = new EventEmitter<any>();
  @Output() reloadAssetModelGrid = new EventEmitter<any>();
  @Output() changeSelectAssetModelNodes = new EventEmitter<any>();
  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  isReferenceLoaded = false;
  assetActionGridAutoGroupColumnDef?: any;
  assetNames: any = {};
  modelNames: any = {};
  conditionDescription: any = {};

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    const tmpGridOptions: GridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1, // expand all groups by default
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_conditions.asset'
          ),
          field: 'asset_name',
          valueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_id;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_name;
            }
          },
          cellRenderer: (params: any) => {
            return this.assetNames?.[params.value];
          },
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_conditions.lifecycle_model'
          ),
          field: 'asset_model_name',
          valueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_model_name;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_model_name;
            }
          },
          cellRenderer: (params: any) => {
            return this.modelNames?.[params.value];
          },
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_conditions.condition'
          ),
          field: 'condition_description',
          valueGetter: (params: any) => {
            if (params.data) {
              return params.data.condition_description;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.condition_description;
            }
          },
          cellRenderer: (params: any) => {
            return this.conditionDescription?.[params.value];
          },
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_conditions.date'
          ),
          field: 'date',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_conditions.comment'
          ),
          field: 'comment',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_conditions.id'
          ),
          field: 'asset_model_id',
          hide: true,
        },
      ],
    };
    this.assetActionGridAutoGroupColumnDef = {
      headerName: this.translate.instant(
        'headers.fastlc.asset_conditions.value'
      ),
      field: 'value',
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    this.gridOptions = tmpGridOptions;
    await this.loadItems();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedAssetModelNodes) {
      this.filterList(this.selectedAssetModelNodes);
    }
  }

  clearFilterAndResetModelsGrid(): void {
    this.assetActionGrid?.clearAllFilters();
    this.resetModelsGrid.emit();
  }

  resetModelActionGrid(): void {
    this.assetActionGrid?.uncheckAll();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }

    this.items = await this.data
      .by('api/fastlc/asset-conditions', {
        criteria: {
          site: this.auth.getSiteId(),
        },
      })
      .toPromise();
    if (this.sizeColumnsToFit) {
      this.assetActionGrid?.gridApi?.sizeColumnsToFit();
      this.sizeColumnsToFit = false;
    }

    this.assetActionGrid?.uncheckAll();
    this.filterList(this.selectedAssetModelNodes);

    // set data array to use ID as value in valueGetter and Description as display value.
    // This is to allow grouping by ID instead of display value ("name" for eg.) thereby avoid grouping on duplicate display values
    this.items.map((val) => {
      this.assetNames[val.asset_id] = val.asset_name;
      this.modelNames[val.asset_model_name] = val.asset_model_name;
      this.conditionDescription[val.condition_description] =
        val.condition_description;
    });

    if (!silent) {
      this.isLoading = false;
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onRowClicked(event: any): void {
    if (!event) {
      return;
    }
    this.showEditModal([event]);
  }

  filterList(assetModels: any): void {
    const instance =
      this.assetActionGrid?.gridApi?.getFilterInstance('asset_model_id');
    if (assetModels.length > 0) {
      setTimeout(() => {
        instance?.setModel({
          values: _.map(assetModels, (el) => el.id + ''),
        });
        this.assetActionGrid?.gridApi?.onFilterChanged();
      });
      return;
    }
    instance?.setModel(null);
    this.assetActionGrid?.gridApi?.onFilterChanged();
  }

  /**
   * @param items
   */
  showEditModal(items?: AssetConditionValueModel[]): void {
    this.bsModalRef = this.bsModalService.show(
      AssetConditionValueEditComponent,
      {
        initialState: {
          items,
          selectedAssetModelNodes: this.selectedAssetModelNodes,
        },
        class: 'modal-md',
      }
    );
    this.bsModalRef.content.confirmFn = (res: AssetConditionValueModel) => {
      this.items = syncItems(res, this.items);
      this.reloadAssetModelGrid.emit();

      this.filterList(this.selectedAssetModelNodes);

      this.items.map((val) => {
        this.assetNames[val.asset_id] = val.asset_name;
        this.modelNames[val.asset_model_name] = val.asset_model_name;
        this.conditionDescription[val.condition_description] =
          val.condition_description;
      });

      this.toastr.showSuccess();
    };
  }

  showMassEditModal(): void {
    if (this.selectedNodes.length === 0) {
      const items = _.map(
        this.selectedNodes,
        (el) => new AssetConditionValueModel(el.data)
      );
      this.showEditModal(items);
    }
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.isLoading = true;
      this.data.post('api/fastlc/asset-conditions/delete-all', ids).subscribe(
        (res) => {
          this.items = this.items.filter(
            (item: any) => !ids.includes(`${item.id}`)
          );
          this.isLoading = false;
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.isLoading = false;
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  myCellCallback = (params: any) => {
    return params.value;
  };

  exportProcessCallBack = (cellParams: any) => {
    if (cellParams.value && cellParams.column.colId === 'asset_name') {
      return this.assetNames?.[cellParams.value];
    }

    if (cellParams.value && cellParams.column.colId === 'asset_model_name') {
      return this.modelNames?.[cellParams.value];
    }

    if (cellParams.value && cellParams.node.field === 'asset_name') {
      return this.assetNames?.[cellParams.node.key];
    }

    if (cellParams.value && cellParams.node.field === 'asset_model_name') {
      return this.modelNames?.[cellParams.node.key];
    }

    if (
      cellParams.value &&
      cellParams.column.colId === 'condition_description'
    ) {
      return this.conditionDescription?.[cellParams.value];
    }

    if (cellParams.value && cellParams.node.field === 'condition_description') {
      return this.conditionDescription?.[cellParams.node.key];
    }

    if (cellParams.node.group) {
      return '';
    }

    return cellParams.value;
  };
}
