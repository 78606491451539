import { Component, OnInit, AfterViewInit, ViewChildren } from '@angular/core';
import { DataService, AuthService } from 'src/app/shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '../..';
import { GridOptions, IDetailCellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-model-detail',
  templateUrl: './model-detail.component.html',
})
export class ModelDetailComponent implements OnInit {
  @ViewChildren(GridComponent) grids?: GridComponent;
  // Modal parameters
  modelId = 0;
  modelName?: string;
  modelItems: any[] = [];
  isLoading = false;
  modelIndex = 0;
  detailCellRendererParams?: any;

  // Form reference Data
  gridOptions?: GridOptions;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private data: DataService
  ) {}

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    const tmpGridOptions: GridOptions = {
      getRowId: (row: any) => {
        this.modelIndex++;
        return this.modelIndex.toString();
      },
      suppressRowClickSelection: false,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: 1,
      isRowMaster: (row: any) => row?.children && row.children?.length > 0,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.fastcr.function_loss.functional_failure'
          ),
          field: 'function_failure_description',
          filter: 'agTextColumnFilter',
          cellRenderer: 'agGroupCellRenderer',
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.function_loss.mtbf'
          ),
          field: 'mtbf',
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.function_loss.mttr'
          ),
          field: 'mttr',
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.function_loss.downtime_probability'
          ),
          field: 'downtime_probability',
          cellRenderer: (params: any): string => {
            if (params.value !== undefined && params.value !== null) {
              return params.value + '%';
            }
            return '';
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.function_loss.comment'
          ),
          field: 'function_loss_comment',
        },
      ],
    };

    this.gridOptions = tmpGridOptions;

    this.detailCellRendererParams = {
      // level 2 grid options
      detailGridOptions: {
        columnDefs: [
          {
            headerName: this.translate.instant('headers.fastcr.impact.product'),
            field: 'product_description',
          },
          {
            headerName: this.translate.instant(
              'headers.fastcr.impact.category'
            ),
            field: 'category_description',
          },
          {
            headerName: this.translate.instant(
              'headers.fastcr.impact.consequence'
            ),
            field: 'consequence_description',
          },
          {
            headerName: this.translate.instant(
              'headers.fastcr.impact.probability'
            ),
            field: 'impact_probability',
            cellRenderer: (params: any): string => {
              if (params.value !== undefined && params.value !== null) {
                return params.value + '%';
              }
              return '';
            },
          },
          {
            headerName: this.translate.instant('headers.fastcr.impact.comment'),
            field: 'impact_comment',
          },
        ],
        defaultColDef: {
          flex: 1,
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.children);
      },
    } as IDetailCellRendererParams;

    await this.LoadModelItems();
  }

  async LoadModelItems(): Promise<void> {
    this.isLoading = true;
    this.modelItems = await this.data
      .get(`api/fastcrit/risk-models/details/${this.modelId}`)
      .toPromise();
    this.isLoading = false;
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => {};
}
