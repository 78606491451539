export class PlanModel {
  id: number;
  name: string;
  quality: string;
  safety: string;
  parts: string;
  status: number;
  releaseDate: string;
  actualDuration: number;
  account: string;
  accountId: number;
  frequency: string;
  frequencyId: number;
  trade: string;
  tradeId: number;
  state: string;
  stateId: number;
  cmmsReference: string;
  comments: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;

    this.name = data.name ? data.name : '';
    this.quality = data.quality ? data.quality : '';
    this.safety = data.safety ? data.safety : '';
    this.parts = data.parts ? data.parts : '';
    this.status = data.status ? data.status : '';
    this.releaseDate = data.releaseDate ? data.releaseDate : '';
    this.actualDuration = data.actualDuration ? data.actualDuration : null;
    this.account = data.account ? data.account : null;
    this.accountId = data.accountId ? data.accountId : null;
    this.frequency = data.frequency ? data.frequency : null;
    this.frequencyId = data.frequencyId ? data.frequencyId : null;
    this.state = data.state ? data.state : null;
    this.stateId = data.stateId ? data.stateId : null;
    this.trade = data.trade ? data.trade : null;
    this.tradeId = data.tradeId ? data.tradeId : null;
    this.cmmsReference = data.cmmsReference ? data.cmmsReference : null;
    this.comments = data.comments ? data.comments : null;
  }
}
