import { Component, OnInit, AfterViewInit, ViewChildren } from '@angular/core';
import { DataService, AuthService } from 'src/app/shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '../../../shared/grid/grid.component';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-model-asset-list',
  templateUrl: './model-asset-list.component.html',
})
export class ModelAssetListComponent implements OnInit, AfterViewInit {
  @ViewChildren(GridComponent) grids?: GridComponent;
  // Modal parameters
  modelId = 0;

  assetsGrid?: any;
  assets: any[] = [];
  isLoading = false;
  gridOptions?: GridOptions;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private data: DataService
  ) {}

  ngAfterViewInit(): void {
    // @ts-ignore
    this.assetsGrid = this.grids?._results[0];
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.asset.id'),
          field: 'id',
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.asset'),
          field: 'asset',
          filter: 'agTextColumnFilter',
          hide: false,
        },
        {
          headerName: this.translate.instant('headers.criticality.criticality'),
          field: 'criticality_name',
        },
        {
          headerName: this.translate.instant('headers.asset.eqtype'),
          field: 'eqtype',
        },
        {
          headerName: this.translate.instant('headers.asset.eqmake'),
          field: 'eqmake',
        },
        {
          headerName: this.translate.instant('headers.asset.eqmodel'),
          field: 'eqmodel',
        },
      ],
    };

    await this.assetsLoadItems();
  }

  async assetsLoadItems(): Promise<void> {
    this.isLoading = true;
    this.assets = await this.data
      .get(`api/fastpm/models/assets/${this.modelId}`)
      .toPromise();
    this.isLoading = false;
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => {};
}
