export class ConfEngineModel {
  id: number;
  description: string;
  target: string;
  modifier: number;

  account: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.description = data.description ? data.description : '';
    this.target = data.target ? data.target : '';
    this.modifier = data.modifier ? data.modifier : 0;

    this.account = data.account ? data.account : null;
  }
}
