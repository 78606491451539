import { Component, OnInit } from '@angular/core';
import {
  DataService,
  AuthService,
  ToasterService,
} from 'src/app/shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-model-import',
  templateUrl: './import.component.html',
})
export class FastpmModelImportComponent implements OnInit {
  fileToUpload: File | null = null;

  // UI Flag
  isLoading = false;
  errorMessage = [];
  isSubmitted = false;
  importUrl = 'api/fastpm/models/import';

  /**
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   * @param toastr
   */
  constructor(
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private toastr: ToasterService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    // Loading done !
    this.isLoading = false;
  }

  handleFileInput(e: Event): boolean {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    this.fileToUpload = (target.files as FileList)[0];
    return true;
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => {};

  /**
   *
   */
  save(): boolean | void {
    if (!this.fileToUpload) {
      this.toastr.showError(this.translate.instant('messages.file_empty'));
      return false;
    }

    this.isSubmitted = true;
    const formData: FormData = new FormData();
    formData.append(
      'importFile',
      this.fileToUpload as File,
      (this.fileToUpload as File).name
    );

    this.service.postFiles(this.importUrl, formData).subscribe(
      (res) => {
        if (res?.partial_update) {
          this.errorMessage = res.responseArr;
          this.confirmFn(res);
          this.isSubmitted = false;
          return;
        }

        this.bsModalRef.hide();
        this.confirmFn(res);
        this.toastr.showSuccess(this.translate.instant('messages.success'));
      },
      (err) => {
        this.isSubmitted = false;
        this.toastr.showError(this.translate.instant('messages.failure'));
        this.errorMessage = err.error?.responseArr;
        const inputFile = document?.getElementById('file') as HTMLInputElement;
        if (inputFile) {
          inputFile.value = '';
        }
      }
    );
  }
}
