import { RiskModelModel } from './RiskModelModel';
import { ConfFunctionalFailureModel } from './ConfFunctionalFailureModel';

export class FunctionLossModel {
  id: number;
  fastcrModel: RiskModelModel;
  configFunctionalFailure: ConfFunctionalFailureModel;
  mtbf: number;
  mttr: number;
  probabilityDowntime: number;
  comment: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.fastcrModel = data.fastcrModel ? data.fastcrModel : RiskModelModel;
    this.configFunctionalFailure = data.configFunctionalFailure ? data.configFunctionalFailure : ConfFunctionalFailureModel;
    this.mtbf = data.mtbf || data.mtbf === 0 ? data.mtbf : null;
    this.mttr = data.mttr || data.mttr === 0 ? data.mttr : null;
    this.probabilityDowntime = data.probabilityDowntime || data.probabilityDowntime === 0 ? data.probabilityDowntime : null;
    this.comment = data.comment ? data.comment : '';
  }
}
