import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { AuthService, DataService } from '../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '../../shared';

@Component({
  selector: 'app-fsl-plans',
  templateUrl: './fsl-plans.component.html',
})
export class RoutingFslPlansComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() frequency?: number;
  @Input() state?: number;
  @Input() labour?: number;

  @Output() selectItems = new EventEmitter();
  @Output() uiGridToggle = new EventEmitter();

  @ViewChildren(GridComponent) grids?: GridComponent;
  grid?: any;

  // Available items
  items: any[] = [];

  // Items (assets & tags associated to a specific location)
  selectedNodes: any[] = [];

  // UI flag
  isLoading = false;

  // Local grid options
  gridOptions?: GridOptions;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    public auth: AuthService,
    public router: Router
  ) {}

  ngAfterViewInit(): void {
    // @ts-ignore
    this.grid = this.grids?._results[0];
  }

  /**
   */
  async ngOnInit(): Promise<void> {
    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.routing.plan.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.plan'),
          field: 'name',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          hide: false,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.tasks'),
          field: 'tasks',
          hide: false,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.duration'),
          field: 'duration',
          hide: false,
        },
      ],
    };
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.frequency && this.state && this.labour) {
      await this.loadItems();
    } else {
      this.items = [];
    }
  }

  onUiGridToggle(): void {
    this.uiGridToggle.emit();
  }

  /**
   * LOADERS
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data
      .post('api/fastpm/modelTasks/fsl-plans', {
        site: this.auth.getSiteId(),
        frequency: this.frequency,
        state: this.state,
        labour: this.labour,
      })
      .toPromise();
    this.grid?.expandAll();
    this.grid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
    this.selectItems.emit(event);
  }

  myCellCallback = (params: any) => {
    return params.value;
  };
}
