import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService} from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  /**
   * @param authService
   * @param router
   */
  constructor(public authService: AuthService, public router: Router) {
  }

  /**
   * Return if route can be activated if user is logged.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const auth = this.authService.checkAuth();
    if (!auth) {
      const queryParams: any = {};
      if (state.url !== '/login' && state.url !== '/pages/home') {
        queryParams.redirect = state.url;
      }
      this.router.navigate(['/login'], {
        queryParams
      });
    }
    return auth;
  }
}

@Injectable()
export class SuperAdminGuard implements CanActivate {
  /**
   * @param authService
   * @param router
   */
  constructor(public authService: AuthService, public router: Router) {
  }

  /**
   * Return if route can be activated if user is ROLE_SUPER_ADMIN.
   */
  canActivate(): boolean {
    if (!this.authService.isSA) {
      this.router.navigate(['/']);
    }
    return this.authService.isSA;
  }
}
