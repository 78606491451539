import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DataService, AuthService, ToasterService } from 'src/app/shared/services';
import { ActionsModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { validateNumberInput } from 'src/utils';

@Component({
  selector: 'app-lifecycle-model-action-edit',
  templateUrl: './edit.component.html'
})
export class ActionsEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  // form instance
  form!: UntypedFormGroup;
  selectedLifecycleModelNodes: any[] = [];
  typesReference: [] = [];
  budgetsReference: [] = [];

  // Usage mode
  mode: ('mass' | 'single' | 'new') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;
  showValidationError = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   * @param toastr
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private toastr: ToasterService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.targetIds = _.map(this.items, el => el.id);

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.items = [new ActionsModel({
        site: this.auth.getSiteId(),
      })];
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [ActionsModel?]): ('mass' | 'single' | 'new') {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // More than one item => Mass Edit
    } else if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  /**
   *
   */
  getForm(item: ActionsModel): UntypedFormGroup {
    let validators: any = [];
    if (this.mode !== 'mass') {
      validators = [Validators.required];
    }

    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      site: new UntypedFormControl(this.auth.getSiteId()),
      cost: new UntypedFormControl(item.cost, [Validators.min(0)]),
      comment: new UntypedFormControl(item.comment),
      description: new UntypedFormControl(item.description, validators),
      type: new UntypedFormControl(item.type ? item.type.id : null),
      budget: new UntypedFormControl(item.budget ? item.budget.id : null),
    });
  }

  validateNumberInput(event: KeyboardEvent): void {
    validateNumberInput(event);
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => { };

  /**
   *
   */
  save(): boolean | void {
    this.showValidationError = true;
    if (!this.form || (this.form && !this.form.valid)) {
      return false;

    } else {

      // Query pointer
      let query;

      if (this.mode === 'new') {
        const modelActions: any = [];
        _.each(this.selectedLifecycleModelNodes, modelNode => {
          const actionData = _.pickBy(this.form.value, el => el !== null);
          actionData.model = modelNode.data.id;
          modelActions.push(actionData);
        });
        query = this.service.saveAll('api/fastlc/model-actions', modelActions);
      } else if (this.mode === 'single') {
        query = this.service.update('api/fastlc/model-actions', this.targetIds[0], this.form.value);

      } else {
        const tmpData: any = {};
        _.each(this.form.controls, (control, name) => {
          if (control.dirty === true && control.value !== -1) {
            tmpData[name] = control.value;
          }
        });
        const data = _.map(this.targetIds, id => {
          const cloned = _.cloneDeep(tmpData);
          _.merge(cloned, { id });
          return cloned;
        });

        query = this.service.saveAll('api/fastlc/model-actions', data);
      }

      this.isSubmitted = true;
      query?.subscribe(res => {
        this.isSubmitted = false;
        this.confirmFn(res);
        this.bsModalRef.hide();
      }, err => {
        this.toastr.showError(`${err.error.message} </br> ${this.translate.instant('navigations.already_exist')}`);
        this.isSubmitted = false;
      });
    }
  }
}
