import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ChartDataset, ChartOptions, ChartType} from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnChanges {
  @Input() chartItems: any = [];

  mixedChartData: ChartDataset[] = [];
  mixedChartLabels: string[] = [];
  mixedChartType: ChartType = 'bar';
  mixedChartLegend = true;
  years: any[] = [];
  baseline: any[] = [];
  actionCosts: any[] = [];
  residualRisks: any[] = [];
  benefits: any[] = [];

  mixedChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          boxWidth: 10,
        }
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,
      },
      y:
        {
          stacked: true,
        }
    },
  };

  ngOnChanges(changes: SimpleChanges): void {
    if ('chartItems' in changes) {
      const currentChartItems = changes.chartItems.currentValue;

      if (Array.isArray(currentChartItems)) {
        this.years = [];
        this.baseline = [];
        this.actionCosts = [];
        this.residualRisks = [];
        this.benefits = [];

        currentChartItems.forEach((element: any) => {
          this.years.push(element.year);
          this.baseline.push(element.baseline);
          this.residualRisks.push(element.projected_residual_risks);
          this.actionCosts.push(element.projected_actions_costs);
          this.benefits.push(element.projected_benefits);
        });

        this.updateChart();
      }
    }
  }

  updateChart(): void {
    this.mixedChartLabels = this.years;
    this.mixedChartData = [
      {
        data: this.benefits,
        label: 'PROJECTED (Benefits)',
        type: 'bar',
        stack: 'stack',
        backgroundColor: '#70AD47',
      },
      {
        data: this.actionCosts,
        label: 'PROJECTED (Proposed Actions Costs)',
        type: 'bar',
        stack: 'stack',
        backgroundColor: '#00B0F0',
      },
      {
        data: this.residualRisks,
        label: 'PROJECTED (Proposed Residual Risks)',
        type: 'bar',
        stack: 'stack',
        backgroundColor: '#ED7D31',
      },
      {
        data: this.baseline,
        label: 'BASELINE (Run to Failure)',
        type: 'line',
        backgroundColor: '#C6D4ED',
        borderColor: '#7D9DD6',
      },
    ];
  }
}
