import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { DataService, AuthService } from 'src/app/shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid-community';
import * as moment from 'moment';

@Component({
  selector: 'app-asset-criticality',
  templateUrl: './asset-criticality.component.html',
})
export class AssetCriticalityComponent implements OnInit, OnChanges {
  // Modal parameters
  @Input() assetId = 0;

  downtimeGridOptions?: GridOptions;
  criticalityDowntimeGridAutoGroupColumnDef?: any;

  riskGridOptions?: GridOptions;
  criticalityRiskGridAutoGroupColumnDef?: any;

  riskAssessmentData: any;
  riskAssessmentIsLoading = false;
  criticalityDowntimeData: any;
  criticalityRiskData: any;
  criticalityDatasetIsLoading = false;

  downtimeRowNodeKey = 0;
  riskRowNodeKey = 0;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private data: DataService
  ) {}

  async ngOnInit(): Promise<void> {
    this.downtimeGridOptions = {
      getRowId: (row: any) => {
        this.downtimeRowNodeKey++;
        return this.downtimeRowNodeKey.toString();
      },
      suppressRowClickSelection: true,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.fastcr.risk_model.model'),
          rowGroup: true,
          field: 'model_name',
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.mtbf'
          ),
          field: 'adjusted_mtbf_in_years',
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.mttr'
          ),
          field: 'adjusted_mttr',
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.dt%'
          ),
          field: 'dt%',
          cellRenderer: (params: any): string => {
            if (params.value !== undefined && params.value !== null) {
              return params.value + '%';
            }
            return '';
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.function_loss.mtbf'
          ),
          field: 'mtbf',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.function_loss.mttr'
          ),
          field: 'mttr',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.failure_probability_factor'
          ),
          field: 'failure_probability_factor',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.mttr+-factor'
          ),
          field: 'mttr_plus_minus_factor',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.mttr%factor'
          ),
          field: 'mttr_percentage_factor',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.buffer'
          ),
          field: 'buffer',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.finality$'
          ),
          field: 'finality$',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.model_name'
          ),
          field: 'model_name',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.process_name'
          ),
          field: 'process_name',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.calculation_result'
          ),
          field: 'calculation_result',
          hide: true,
        },
      ],
    };
    this.criticalityDowntimeGridAutoGroupColumnDef = {
      headerName: this.translate.instant(
        'headers.fastcr.criticality_calc_attr.functional_failure'
      ),
      field: 'functional_failure_name',
      filter: 'agTextColumnFilter',
      sortable: true,
      sort: 'asc',
    };

    this.riskGridOptions = {
      getRowId: (row: any) => {
        this.riskRowNodeKey++;
        return this.riskRowNodeKey.toString();
      },
      suppressRowClickSelection: true,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.fastcr.risk_model.model'),
          field: 'model_name',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.functional_failure'
          ),
          field: 'functional_failure_name',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastcr.impact.product'),
          field: 'product_name',
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.mtbf'
          ),
          field: 'adjusted_mtbf_in_years',
        },
        {
          headerName: this.translate.instant(
            'modules.admin.risk_parameters.children.consequence.name'
          ),
          field: 'consequence_name',
          cellRenderer: (params: any): string => {
            return params.data?.consequence_category_name
              ? params.data.consequence_category_name + ' - ' + params.value
              : params.value;
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.conseq%'
          ),
          field: 'conseq%',
          cellRenderer: (params: any): string => {
            if (params.value !== undefined && params.value !== null) {
              return params.value + '%';
            }
            return '';
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.function_loss.mtbf'
          ),
          field: 'mtbf',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.failure_probability_factor'
          ),
          field: 'failure_probability_factor',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.conseq$'
          ),
          field: 'conseq$',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.model_name'
          ),
          field: 'model_name',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastcr.criticality_calc_attr.calculation_result'
          ),
          field: 'calculation_result',
          hide: true,
        },
      ],
    };
    this.criticalityRiskGridAutoGroupColumnDef = {
      headerName: this.translate.instant(
        'headers.fastcr.criticality_calc_attr.functional_failure'
      ),
      filter: 'agTextColumnFilter',
      sortable: true,
      sort: 'asc',
    };
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.assetId) {
      await this.loadRiskAssessmentData();
      await this.loadCriticalityDataset();
      return;
    }
    this.riskAssessmentData = {};
    this.criticalityDowntimeData = [];
    this.criticalityRiskData = [];
  }

  async loadRiskAssessmentData(): Promise<void> {
    this.riskAssessmentIsLoading = true;
    const riskAssessment = await this.data
      .get(`api/fastcrit/risk-assessment/from-site-asset/${this.assetId}`)
      .toPromise();
    this.riskAssessmentData =
      riskAssessment.length > 0 ? riskAssessment[0] : {};
    if (this.riskAssessmentData?.critPushDate) {
      this.riskAssessmentData.critPushDate = moment(
        this.riskAssessmentData.critPushDate
      ).format('DD/MM/YYYY');
    }
    this.riskAssessmentIsLoading = false;
  }

  async loadCriticalityDataset(): Promise<void> {
    this.criticalityDatasetIsLoading = true;
    const criticalityDataset = await this.data
      .get(`api/fastcrit/risk-assessment/criticality-dataset/${this.assetId}`)
      .toPromise();
    this.criticalityDowntimeData = criticalityDataset.actual_downtime.filter(
      (el: any) => +el?.calculation_result
    );
    this.criticalityRiskData = criticalityDataset.actual_risk.filter(
      (el: any) => +el?.calculation_result
    );
    this.criticalityDatasetIsLoading = false;
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => {};
}
