<div class="d-flex flex-column h-100">
  <div class="d-flex align-items-center px-2">
    <div class="grid title mr-2">
      {{ "headers.routing.fsl.groups" | translate }}
    </div>
    <div class="mr-2">
      <button
        (click)="loadItems()"
        [ngClass]="{ 'btn-success': isLoading, 'btn-light': !isLoading }"
        class="btn btn-sm"
      >
        <i [ngClass]="{ 'la-spin': isLoading }" class="la la-refresh"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        [disabled]="items.length === 0 || auth.isViewer('routing')"
        (click)="grid?.export('xlsx', false)"
        class="btn btn-sm btn-primary"
      >
        <i class="la la-fw la-file-csv"></i>
        {{ "navigations.group.export" | translate }}
      </button>
    </div>
    <div class="mr-2">
      <button
        alt="clear filter"
        (click)="grid?.clearAllFilters()"
        [disabled]="!grid?.isFilterApplied"
        [ngClass]="{
          'clear-filter position-relative': grid?.isFilterApplied
        }"
        class="btn btn-light btn-sm"
      >
        <i class="las la-filter"></i>
      </button>
    </div>
    <div class="form-group col-3 mt-3">
      <ngx-select
        [items]="taskFilterOptions"
        [placeholder]="taskFilterOptions[0].name"
        (selectionChanges)="onChangeFilterType($event)"
        id="filter"
        optionTextField="name"
        optionValueField="value"
        size="small"
      ></ngx-select>
    </div>
    <div class="form-group col-4 mt-3">
      <ng-select
        [items]="respGroupPlanningGroupArr"
        [placeholder]="filterPlanningGroupAllOption"
        [multiple]="true"
        [disabled]="isLoading"
        [selectableGroup]="true"
        [selectableGroupAsModel]="false"
        [closeOnSelect]="true"
        groupBy="selectAll"
        bindLabel="description"
        bindValue="id"
        (change)="onChangePlanningGroupFilterType($event)"
        [(ngModel)]="selectedPlanningGroupIds"
        class="custom"
      >
        <ng-template
          ng-optgroup-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <input
            id="item-{{ index }}"
            type="checkbox"
            [ngModel]="item$.selected"
            class="mr-1"
          />
          {{ filterPlanningGroupAllOption }}
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <input
            id="item-{{ index }}"
            type="checkbox"
            [ngModel]="item$.selected"
            class="mr-1"
          />
          {{ item.description }}
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
    <app-grid
      [gridUid]="'routing_fsl_groups'"
      (filterChanged)="onFilterChanged($event)"
      (selectionChanged)="onSelectionChanged($event)"
      [gridOptions]="gridOptions"
      [rowData]="items"
      class="ag-theme-alpine"
      style="width: 100%; height: 100%"
    >
    </app-grid>
  </div>
</div>
