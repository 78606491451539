import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services';
import { Router } from '@angular/router';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { NO_ACCESS } from 'src/app/shared/constants';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
})
export class ModulesComponent implements OnInit {
  modules: {
    name: string;
    children: {
      name: string;
      description: string;
      link: string;
      module: string;
      access_type: string;
    }[];
  }[] = [
    {
      name: this.translate.instant('modules.core.name'),
      children: [
        {
          name: this.translate.instant('modules.core.children.assets.name'),
          description: this.translate.instant(
            'modules.core.children.assets.description'
          ),
          link: '/assets',
          module: 'core',
          access_type: 'asset',
        },
        {
          name: this.translate.instant('modules.core.children.locations.name'),
          description: this.translate.instant(
            'modules.core.children.locations.description'
          ),
          link: '/map/locations',
          module: 'map',
          access_type: 'location',
        },
        {
          name: this.translate.instant(
            'modules.core.children.responsible_groups.name'
          ),
          description: this.translate.instant(
            'modules.core.children.responsible_groups.description'
          ),
          link: '/responsible-groups',
          module: 'setting',
          access_type: 'asset',
        },
      ],
    },
    {
      name: this.translate.instant('modules.fast_criticality.name'),
      children: [
        {
          name: this.translate.instant(
            'modules.fast_criticality.children.assessment.name'
          ),
          description: this.translate.instant(
            'modules.fast_criticality.children.assessment.description'
          ),
          link: '/fastcrit/risk-assessment',
          module: 'crit',
          access_type: 'fast_criticality',
        },
        {
          name: this.translate.instant(
            'modules.fast_criticality.children.risk_models.name'
          ),
          description: this.translate.instant(
            'modules.fast_criticality.children.risk_models.description'
          ),
          link: '/fastcrit/risk-models',
          module: 'crit',
          access_type: 'fast_criticality',
        },
        {
          name: this.translate.instant(
            'modules.fast_criticality.children.processes.name'
          ),
          description: this.translate.instant(
            'modules.fast_criticality.children.processes.description'
          ),
          link: '/fastcrit/processes',
          module: 'crit',
          access_type: 'fast_criticality',
        },
      ],
    },
    {
      name: this.translate.instant('modules.fast_lifecycle.name'),
      children: [
        {
          name: this.translate.instant(
            'modules.fast_lifecycle.children.lifecycle_strategy.name'
          ),
          description: this.translate.instant(
            'modules.fast_lifecycle.children.lifecycle_strategy.description'
          ),
          link: '/fastlc/strategy',
          module: 'flc',
          access_type: 'fastlc',
        },
        {
          name: this.translate.instant(
            'modules.fast_lifecycle.children.lifecycle_models.name'
          ),
          description: this.translate.instant(
            'modules.fast_lifecycle.children.lifecycle_models.description'
          ),
          link: '/fastlc/models',
          module: 'flc',
          access_type: 'fastlc',
        },
      ],
    },
    {
      name: this.translate.instant('modules.fast_spares.name'),
      children: [
        {
          name: this.translate.instant(
            'modules.fast_spares.children.spares_strategy.name'
          ),
          description: this.translate.instant(
            'modules.fast_spares.children.spares_strategy.description'
          ),
          link: '/fastsp/strategy',
          module: 'fsp',
          access_type: 'fastsp',
        },
        {
          name: this.translate.instant(
            'modules.fast_spares.children.spares_models.name'
          ),
          description: this.translate.instant(
            'modules.fast_spares.children.spares_models.description'
          ),
          link: '/fastsp/models',
          module: 'fsp',
          access_type: 'fastsp',
        },
      ],
    },
    {
      name: this.translate.instant('modules.fast_pm.name'),
      children: [
        {
          name: this.translate.instant(
            'modules.fast_pm.children.strategy.name'
          ),
          description: this.translate.instant(
            'modules.fast_pm.children.strategy.description'
          ),
          link: '/fastpm/strategy',
          module: 'fpm',
          access_type: 'fastpm',
        },
        {
          name: this.translate.instant(
            'modules.fast_pm.children.pm_models.name'
          ),
          description: this.translate.instant(
            'modules.fast_pm.children.pm_models.description'
          ),
          link: '/fastpm/models',
          module: 'fpm',
          access_type: 'fastpm',
        },
        {
          name: this.translate.instant(
            'modules.fast_pm.children.custom_criteria.name'
          ),
          description: this.translate.instant(
            'modules.fast_pm.children.custom_criteria.description'
          ),
          link: '/fastpm/custom-criteria',
          module: 'fpm',
          access_type: 'fastpm',
        },
      ],
    },
    {
      name: this.translate.instant('modules.routing.name'),
      children: [
        {
          name: this.translate.instant('modules.routing.children.tasks.name'),
          description: this.translate.instant(
            'modules.routing.children.tasks.description'
          ),
          link: '/routing/tasks',
          module: 'routing',
          access_type: 'routing',
        },
        {
          name: this.translate.instant('modules.routing.children.plans.name'),
          description: this.translate.instant(
            'modules.routing.children.plans.description'
          ),
          link: '/routing/plans',
          module: 'routing',
          access_type: 'routing',
        },
      ],
    },
    {
      name: this.translate.instant('modules.settings.name'),
      children: [
        {
          name: this.translate.instant('navigations.breadcrumbs.parameters'),
          description: this.translate.instant(
            'modules.settings.children.parameters.description'
          ),
          link: '/admin',
          module: 'setting',
          access_type: 'admin',
        },
      ],
    },
  ];

  accessRole: any;

  constructor(
    public breadCrumbService: BreadCrumbService,
    public router: Router,
    public auth: AuthService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
      ],
      2
    );
    this.accessRole = this.auth.getAccessRole();
  }

  isModuleVisible(module: string): boolean {
    return this.auth.getAccessRights(module) !== NO_ACCESS;
  }

  isAdminModule(moduleType: string): boolean {
    return moduleType === 'admin';
  }
}
