<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('value')?.dirty"
            for="value"
          >
            {{ "headers.fastpm.custom_criteria.value" | translate }}
            <span class="text-danger mr-2">*</span>
          </label>
          <app-field-clear-button
            (setEmptyParent)="setEmpty('value')"
          ></app-field-clear-button>
          <input
            class="form-control form-control-sm"
            formControlName="value"
            id="value"
            type="text"
          />
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="
            (!form.pristine && !form.dirty && isSubmitted) ||
            (!isEmptyField && !form.valid)
          "
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
