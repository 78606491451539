import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../../shared/services/breadcrumb.service';

@Component({
  selector: 'app-conf-engines',
  templateUrl: './engines.component.html',
  styleUrls: ['./engines.component.scss'],
})
export class ConfEnginesListComponent implements OnInit {
  // Available items
  items: any[] = [];
  // UI flag
  isLoading = false;
  selectedEngine?: string;

  enginesOptionalities: {
    name: string;
    description: string;
  }[] = [
    {
      name: 'balanced',
      description:
        'Sum of all the calculated downtime, Sum of all the calculated risks, and then takes the highest of the two.',
    },
    {
      name: 'aggressive',
      description:
        'Sum of all the calculated downtime, Sum of all the calculated risks, and then sum them up.',
    },
    {
      name: 'selective',
      description:
        'Highest calculated downtime, Highest calculated risks, and then takes the highest of the two.',
    },
  ];

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.parameters'),
          link: '/admin',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.engines'),
          link: '/admin/engines',
        },
      ],
      2
    );

    this.isLoading = true;
    const currentAccount = await this.fetchCurrentAccount();
    this.selectedEngine = currentAccount[0]?.confRiskCalcEngine;
    this.isLoading = false;
  }

  /**
   */
  async fetchCurrentAccount(): Promise<any> {
    return await this.data
      .by('api/accounts', {
        criteria: {
          id: this.auth.getAccountId(),
        },
      })
      .toPromise();
  }

  updateEngine = (type: string) => {
    const oldEngine = this.selectedEngine;
    this.selectedEngine = type;

    this.data
      .update('api/accounts', this.auth.getAccountId(), {
        confRiskCalcEngine: type,
      })
      .subscribe(
        (res) => {
          this.toastr.showSuccess(this.translate.instant('messages.update'));
        },
        (err) => {
          this.selectedEngine = oldEngine;
          this.toastr.showError(this.translate.instant('messages.error'));
        }
      );
  };
}
