import { ConfFinalityModel } from './ConfFinalityModel';

export class ConfProcessModel {
  id: number;
  description: string;
  buffer: string;
  throughputReduction?: number;
  site: string;
  finality: ConfFinalityModel;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.description = data.description ? data.description : '';
    this.buffer = data.buffer ? data.buffer : 0;
    this.throughputReduction = (data.throughputReduction || data.throughputReduction === 0) ? data.throughputReduction * 100 : 100;
    this.site = data.site ? data.site : null;
    this.finality = data.finality ? data.finality : ConfFinalityModel;
  }
}
