<div class="container-fluid h-100">
  <div class="row h-100">
    <div class="col-3 flex-column h-100">
      <div class="d-flex flex-column h-100">
        <div class="d-flex align-items-center px-2">
          <div class="mr-2">
            <button
              (click)="componentsLoadItems()"
              [ngClass]="{
                'btn-success': componentsIsLoading,
                'btn-light': !componentsIsLoading
              }"
              class="btn btn-sm"
            >
              <i
                [ngClass]="{ 'la-spin': componentsIsLoading }"
                class="la la-refresh"
              ></i>
            </button>
          </div>
          <fieldset>
            <div class="mr-2" style="float: left">
              <button
                (click)="componentShowEditModal()"
                [disabled]="componentsIsLoading || auth.isViewer('fastpm')"
                class="btn btn-success btn-sm"
              >
                <i class="la la-plus"></i>
              </button>
            </div>
            <div
              [isDisabled]="componentsSelectedNodes.length === 0"
              class="btn-group mr-1"
              dropdown
            >
              <button
                class="dropdown-toggle btn btn-sm btn-primary"
                dropdownToggle
                type="button"
              >
                <ng-container *ngIf="componentsSelectedNodes.length > 0"
                  >{{ "navigations.with" | translate }}
                  <strong>{{ componentsSelectedNodes.length }}</strong>
                  {{ "navigations.selected" | translate }}
                </ng-container>
                <ng-container *ngIf="componentsSelectedNodes.length === 0">{{
                  "navigations.no_selection" | translate
                }}</ng-container>
                <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu">
                <li
                  (click)="componentClone()"
                  class="btn btn-sm dropdown-item"
                  *ngIf="!auth.isViewer('fastpm')"
                >
                  <i class="la la-fw la-clone"></i>
                  {{ "navigations.clone" | translate }}
                </li>
                <li
                  (click)="componentsGrid?.export('xlsx')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-excel"></i> XLSX
                </li>
                <li
                  (click)="componentsGrid?.export('csv')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-csv"></i> CSV
                </li>
                <li
                  class="divider dropdown-divider"
                  *ngIf="!auth.isViewer('fastpm')"
                ></li>
                <li role="menuitem" *ngIf="!auth.isViewer('fastpm')">
                  <a
                    (click)="componentShowMassDeleteConfirm()"
                    class="dropdown-item btn btn-sm btn-danger"
                  >
                    <i class="la la-fw la-trash"></i>
                    {{ "navigations.delete" | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </fieldset>
        </div>
        <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
          <app-grid
            [gridUid]="'fpm_components_list'"
            (rowDoubleClicked)="
              auth.isViewer('fastpm') ? null : componentOnRowClicked($event)
            "
            (selectionChanged)="componentOnSelectionChanged($event)"
            [gridOptions]="componentsGridOptions"
            [rowData]="components"
            class="ag-theme-alpine"
            style="width: 100%; height: 100%"
          >
          </app-grid>
        </div>
      </div>
    </div>
    <div class="col-9 flex-column h-100">
      <div class="d-flex flex-column h-100">
        <div class="d-flex align-items-center px-2">
          <div class="mr-2">
            <button
              (click)="tasksLoadItems()"
              [ngClass]="{
                'btn-success': tasksIsLoading,
                'btn-light': !tasksIsLoading
              }"
              class="btn btn-sm"
            >
              <i
                [ngClass]="{ 'la-spin': tasksIsLoading }"
                class="la la-refresh"
              ></i>
            </button>
          </div>
          <div class="mr-2">
            <button
              (click)="tasksGrid?.expandAll()"
              [disabled]="tasksIsLoading || auth.isViewer('fastpm')"
              class="btn btn-light btn-sm"
            >
              <i class="la la-expand-arrows-alt"></i>
            </button>
          </div>
          <div class="mr-2">
            <button
              (click)="tasksGrid?.collapseAll()"
              [disabled]="tasksIsLoading || auth.isViewer('fastpm')"
              class="btn btn-light btn-sm"
            >
              <i class="la la-compress-arrows-alt"></i>
            </button>
          </div>
          <div class="mr-2">
            <button
              (click)="tasksGrid?.autoSizeColumn()"
              [disabled]="tasksIsLoading || auth.isViewer('fastpm')"
              class="btn btn-light btn-sm"
            >
              <i class="la la-arrows-alt-h"></i>
            </button>
          </div>
          <fieldset>
            <div class="mr-2" style="float: left">
              <button
                (click)="taskShowEditModal()"
                [disabled]="
                  tasksIsLoading ||
                  (tasksSelectedNodes.length === 0 &&
                    componentsSelectedNodes.length === 0) ||
                  auth.isViewer('fastpm')
                "
                class="btn btn-success btn-sm"
              >
                <i class="la la-plus"></i>
              </button>
            </div>
            <div
              [isDisabled]="tasksSelectedNodes.length === 0"
              class="btn-group mr-1"
              dropdown
            >
              <button
                class="dropdown-toggle btn btn-sm btn-primary"
                dropdownToggle
                type="button"
              >
                <ng-container *ngIf="tasksSelectedNodes.length > 0"
                  >{{ "navigations.with" | translate }}
                  <strong>{{ tasksSelectedNodes.length }}</strong>
                  {{ "navigations.selected" | translate }}
                </ng-container>
                <ng-container *ngIf="tasksSelectedNodes.length === 0">{{
                  "navigations.no_selection" | translate
                }}</ng-container>
                <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu">
                <li
                  (click)="taskClone()"
                  class="btn btn-sm dropdown-item"
                  *ngIf="!auth.isViewer('fastpm')"
                >
                  <i class="la la-fw la-clone"></i>
                  {{ "navigations.clone" | translate }}
                </li>
                <li
                  (click)="taskShowMassEditModal()"
                  class="btn btn-sm dropdown-item"
                  *ngIf="!auth.isViewer('fastpm')"
                >
                  <i class="la la-fw la-pencil-alt"></i>
                  {{ "navigations.edit" | translate }}
                </li>
                <li
                  (click)="tasksGrid?.export('xlsx')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-excel"></i> XLSX
                </li>
                <li
                  (click)="tasksGrid?.export('csv')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-csv"></i> CSV
                </li>
                <li
                  class="divider dropdown-divider"
                  *ngIf="!auth.isViewer('fastpm')"
                ></li>
                <li role="menuitem" *ngIf="!auth.isViewer('fastpm')">
                  <a
                    (click)="taskShowMassDeleteConfirm()"
                    class="dropdown-item btn btn-sm btn-danger"
                  >
                    <i class="la la-fw la-trash"></i>
                    {{ "navigations.delete" | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </fieldset>
        </div>
        <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
          <app-grid
            [gridUid]="'components_task'"
            (rowDoubleClicked)="
              auth.isViewer('fastpm') ? null : tasksOnRowClicked($event)
            "
            (selectionChanged)="tasksOnSelectionChanged($event)"
            [autoGroupColumnDef]="tasksGridAutoGroupColumnDef"
            [gridOptions]="tasksGridOptions"
            [rowChangedCallBack]="taskRowChangedCallBack"
            [rowData]="tasks"
            class="ag-theme-alpine"
            style="width: 100%; height: 100%"
          >
          </app-grid>
        </div>
      </div>
    </div>
  </div>
</div>
