import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserModel } from '../../../shared/models';
import { ConfirmModalComponent } from '../../../shared/modals';
import { GridComponent } from '../../../shared/';
import * as _ from 'lodash';
import { BreadCrumbService } from '../../../shared/services/breadcrumb.service';
import { UserEditComponent } from './edit/edit.component';
import { UserAccessModel } from '../../../shared/models/UserAccessModel';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersListComponent implements OnInit {
  @ViewChild(GridComponent, { static: true }) grid?: GridComponent;

  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  //
  assetsGridAutoGroupColumnDef: any;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  // Form reference Data
  isReferenceLoaded = false;
  boolReference?: { value: boolean; name: string }[];
  rolesReference?: any;
  isOwnAccountSelected?: boolean;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.parameters'),
          link: '/admin',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.users'),
          link: '/users',
        },
      ],
      2
    );
    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.account_and_user_management.user.firstname'
          ),
          field: 'userFirstname',
          filter: 'agTextColumnFilter',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
        },
        {
          headerName: this.translate.instant(
            'headers.account_and_user_management.user.lastname'
          ),
          field: 'userLastname',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.account_and_user_management.user.email'
          ),
          field: 'userEmail',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.account_and_user_management.user.isAdmin'
          ),
          field: 'isAdmin',
          cellRenderer: (params: any): string => {
            if (params.value === true) {
              return (
                '<span class="badge badge-lg badge-success">' +
                this.translate.instant('navigations.yes') +
                '</span>'
              );
            } else {
              return (
                '<span class="badge badge-lg badge-danger">' +
                this.translate.instant('navigations.no') +
                '</span>'
              );
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.account_and_user_management.site.site'
          ),
          field: 'site',
          cellRenderer: (params: any): string => {
            if (params.data.isAdmin === true) {
              return (
                '<span>' +
                this.translate.instant('navigations.all_sites') +
                '</span>'
              );
            } else {
              return '<span>' + params.value + '</span>';
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.account_and_user_management.role.role'
          ),
          field: 'role',
          cellRenderer: (params: any): string => {
            if (params.data.isAdmin === true) {
              return (
                '<span>' +
                this.translate.instant('navigations.all_access') +
                '</span>'
              );
            } else if (params.value) {
              return '<span>' + params.value + '</span>';
            } else {
              return '';
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.account_and_user_management.user.id'
          ),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.account_and_user_management.user.account_id'
          ),
          field: 'accountId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.account_and_user_management.site.id'
          ),
          field: 'siteId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.account_and_user_management.role.id'
          ),
          field: 'roleId',
          hide: true,
        },
      ],
    };

    await this.loadItems();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data.get('api/access/site/users').toPromise();
    this.grid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
    const id = this.auth.getId();
    this.isOwnAccountSelected = _.some(
      this.selectedNodes,
      (el) => el.data.userId === id
    );
  }

  onRowClicked(event: any): void {
    if (this.auth.getId() === event.userId) {
      return;
    }
    this.showEditModal([new UserAccessModel(event)]);
  }

  /**
   * @param items
   */
  async showEditModal(items?: UserAccessModel[]): Promise<void> {
    await this.loadReferences();

    const siteUsers = this.items;
    this.bsModalRef = this.bsModalService.show(UserEditComponent, {
      initialState: {
        siteUsers,
        items,
        boolReference: this.boolReference,
        rolesReference: this.rolesReference,
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      this.toastr.showSuccess();
    };
  }

  /**
   */
  showMassEditModal(): void {
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      const items = _.map(
        this.selectedNodes,
        (el) => new UserAccessModel(el.data)
      );
      this.showEditModal(items);
    }
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.data.post('api/access/delete-all', ids).subscribe(
        (res) => {
          this.loadItems();
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  /**
   */
  showMassCloneConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.clone',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.data.post('api/access/duplicate-all', ids).subscribe(
        (res) => {
          this.loadItems();
          this.toastr.showSuccess();
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  // Load Rerefences used in modal edit form
  async loadReferences(): Promise<void> {
    // Bool
    this.boolReference = [
      { value: true, name: this.translate.instant('navigations.yes') },
      { value: false, name: this.translate.instant('navigations.no') },
    ];

    // Roles
    const tpm = await this.data
      .by('api/accounts/roles', {
        criteria: {
          account: this.auth.getAccountId(),
        },
      })
      .toPromise();
    this.rolesReference = _.map(tpm, (el: any) => {
      return {
        name: el.name,
        value: el.id,
      };
    });

    // Mark ref as loaded (prevent reload)
    this.isReferenceLoaded = true;
  }

  myCellCallback = (params: any) => {
    return params.value;
  };
}
