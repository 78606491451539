<div class="card main list">
  <h4 class="card-header">Sites</h4>
  <div class="card-body">
    <app-loader *ngIf="isLoading"></app-loader>
    <div *ngIf="!isLoading" class="d-flex flex-column">
      <a
        (click)="setSite(access)"
        *ngFor="let access of items"
        class="d-flex flex-column item mb-2 p-2 rounded"
      >
        <div class="name font-weight-bold">{{ access.name }}</div>
        <div class="subinfo">{{ access.role }}</div>
      </a>
    </div>
  </div>
  <div class="card-footer">
    <div class="d-flex justify-content-around">
      <div>
        <button
          class="btn btn-block btn-light btn-lg"
          routerLink="/accounts"
          type="button"
        >
          {{ "navigations.back" | translate }}
        </button>
      </div>
      <!--      <div>-->
      <!--        <button class="btn btn-block btn-primary btn-lg" routerLink="/sites/create" type="button">-->
      <!--          {{ 'navigations.account.create_site' | translate }}-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
  </div>
</div>
