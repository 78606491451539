export class ToolModel {
  id: number;
  name: string;
  accountId: number;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.name = data.name ? data.name : '';

    this.accountId = data.accountId ? data.accountId : null;
  }
}
