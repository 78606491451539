import { SiteModel } from '../SiteModel';

export class LifecycleModelModel {
  id: number;
  site: SiteModel;
  description: string;
  businessRules: string;
  expectedLife: number;
  typeEolDesc: string;
  comment: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.site = data.site ? data.site : SiteModel;
    this.description = data.description ? data.description : '';
    this.businessRules = data.business_rules ? data.business_rules : '';
    this.expectedLife = data.expected_life || data.expected_life === 0 ? data.expected_life : null;
    this.typeEolDesc = data.type_eol_desc ? data.type_eol_desc : '';
    this.comment = data.comment ? data.comment : '';
  }
}
