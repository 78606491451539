export class TagModel {
  id: number;
  title: string;
  positionX: number;
  positionY: number;
  location: string;
  locationId: number;

  constructor(data: any) {
    this.id = data.id ? data.id : null;
    this.title = data.title;
    this.positionX = data.positionX;
    this.positionY = data.positionY;
    this.location = data.location ? data.location : null;
    this.locationId = data.locationId ? data.locationId : null;
  }
}
