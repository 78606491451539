<div class="d-flex flex-column h-100">
  <div class="d-flex align-items-center px-2">
    <div class="mr-2">
      <i [ngClass]="{ 'la-spin': isLoading }" class="la la-refresh"></i>
    </div>
    <div class="mr-2">
      <button alt="clear filter" (click)="itemsGrid?.clearAllFilters()" [disabled]="!itemsGrid?.isFilterApplied"
        [ngClass]="{
          'clear-filter position-relative': itemsGrid?.isFilterApplied
        }" class="btn btn-light btn-sm">
        <i class="las la-filter"></i>
      </button>
    </div>
    <fieldset>
      <div [isDisabled]="selectedItems.length === 0 || isPinMode" class="btn-group mr-1" dropdown>
        <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle type="button"
          [disabled]="auth.isViewer('location')">
          <ng-container *ngIf="selectedItems.length > 0">{{ "navigations.with" | translate }}
            <strong>{{ selectedItems.length }}</strong>
            {{ "navigations.selected" | translate }}
          </ng-container>
          <ng-container *ngIf="selectedItems.length === 0">{{
            "navigations.no_selection" | translate
            }}</ng-container>
          <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu">
          <ng-container *ngIf="!auth.isViewer('location')">
            <li class="btn btn-sm dropdown-item" (click)="clearPosition()">
              <i class="la la-fw la-trash"></i>
              {{ "navigations.position.clear" | translate }}
            </li>
            <li (click)="showMassEditModal()" class="btn btn-sm dropdown-item">
              <i class="la la-fw la-pencil-alt"></i>
              {{ "navigations.edit" | translate }}
            </li>
            <li class="btn btn-sm dropdown-item" (click)="removeFromLocation()">
              {{ "navigations.location.remove_from_location" | translate }}
            </li>
            <li class="divider dropdown-divider"></li>
          </ng-container>
          <li (click)="exportHtml()" class="btn btn-sm dropdown-item">
            <i class="la la-map-marked-alt"></i>
            {{ "navigations.export" | translate }}
          </li>
          <li (click)="itemsGrid?.export('xlsx')" class="btn btn-sm dropdown-item">
            <i class="la la-fw la-file-excel"></i> XLSX
          </li>
          <li (click)="itemsGrid?.export('csv')" class="btn btn-sm dropdown-item">
            <i class="la la-fw la-file-csv"></i> CSV
          </li>
        </ul>
      </div>
    </fieldset>
    <div class="ml-auto">
      <button [disabled]="
          isLoading || selectedItems.length === 0 || selectedItems.length > 1
        " [hidden]="isPinMode" (click)="onPinModeClick()" class="btn btn-sm btn-primary">
        <i class="la la-fw la-map-pin"></i>
      </button>
      <div [hidden]="!isPinMode" class="pin-btn-box">
        <span>{{ "navigations.location.awaiting_pinnig" | translate }}</span>
        <button [disabled]="
            isLoading || selectedItems.length === 0 || selectedItems.length > 1
          " (click)="onPinModeClick()" class="ml-2 btn btn-sm">
          <i class="la la-times-circle"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
    <app-grid [gridUid]="'map_locations_items'" (rowDoubleClicked)="
        auth.isViewer('location') ? null : onRowClicked($event)
      " (rowDragEnd)="onRowDragEnd($event)" (selectionChanged)="onSelectionChanged($event)"
      [gridOptions]="itemsGridOptions" [exportShouldRowBeSkipped]="exportShouldRowBeSkipped" [rowData]="items"
      class="ag-theme-alpine" style="width: 100%; height: 100%">
    </app-grid>
  </div>
</div>