import { AssetModel } from '../AssetModel';
import { GradeModel } from './GradeModel';
import { LifecycleModelModel } from './LifecycleModelModel';

export class AssetModelModel {
  id: number;
  model: LifecycleModelModel;
  grade: any[];
  asset: AssetModel;
  catMaintCost: number;
  catDownCost: number;
  catAddRiskCost: number;
  onGoingMaintCost: number;
  onGoingDownCost: number;
  onGoingAddRiskCost: number;
  assetERV: number;
  assetInstallDate: string;
  riskScenario: string;
  catExposure: number;
  catConfidence: number;
  comment: string;
  update: boolean;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.model = data.model ? data.model : LifecycleModelModel;
    this.grade = data.grade ? data.grade : null;
    this.asset = data.asset ? data.asset : AssetModel;
    this.catMaintCost = data.catMaintCost || data.catMaintCost === 0 ? data.catMaintCost : null;
    this.catDownCost = data.catDownCost || data.catDownCost === 0 ? data.catDownCost : null;
    this.catAddRiskCost = data.catAddRiskCost || data.catAddRiskCost === 0 ? data.catAddRiskCost : null;
    this.onGoingMaintCost = data.onGoingMaintCost || data.onGoingMaintCost === 0 ? data.onGoingMaintCost : null;
    this.onGoingDownCost = data.onGoingDownCost || data.onGoingDownCost === 0 ? data.onGoingDownCost : null;
    this.onGoingAddRiskCost = data.onGoingAddRiskCost || data.onGoingAddRiskCost === 0 ? data.onGoingAddRiskCost : null;
    this.assetERV = data.assetERV || data.assetERV === 0 ? data.assetERV : null;
    this.assetInstallDate = data.assetInstallDate ? data.assetInstallDate : '';
    this.riskScenario = data.riskScenario ? data.riskScenario : '';
    this.catExposure = data.catExposure || data.catExposure === 0 ? data.catExposure : null;
    this.catConfidence = data.catConfidence || data.catConfidence === 0 ? data.catConfidence : null;
    this.comment = data.comment ? data.comment : '';
    this.update = data.update ? data.update : false;
  }
}
