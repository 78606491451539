import {
  Component,
  OnInit,
  ViewChild,
  Input,
  SimpleChanges,
  Output,
  OnChanges,
  EventEmitter,
} from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssetActionModel } from '../../../../shared/models';
import {
  ConfirmModalComponent,
  ErrorModalComponent,
} from '../../../../shared/modals';
import { GridComponent } from '../../../../shared';
import { AssetActionEditComponent } from './edit/edit.component';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../../../shared/services/breadcrumb.service';
import { syncItems } from 'src/utils';
import { CostCalculatorComponent } from '../cost-calculator/cost-calculator.component';

@Component({
  selector: 'app-asset-action',
  templateUrl: './asset-action.component.html',
})
export class AssetActionListComponent implements OnInit, OnChanges {
  @ViewChild(GridComponent, { static: true }) assetActionGrid?: GridComponent;

  @Input() selectedAssetModelNodes: any[] = [];
  @Input() sizeColumnsToFit = true;
  @Output() resetModelsGrid = new EventEmitter<any>();
  @Output() reloadAssetModelGrid = new EventEmitter<any>();
  @Output() changeSelectAssetModelNodes = new EventEmitter<any>();
  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  isReferenceLoaded = false;
  assetActionGridAutoGroupColumnDef?: any;
  assetNames: any = {};
  modelNames: any = {};

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    const tmpGridOptions: GridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1, // expand all groups by default
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      rowClassRules: {
        'disabled-font-color': (params) =>
          params?.data?.status?.closed === true,

        'text-dark': (params) => params?.data?.status?.closed === false,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.asset.asset'),
          field: 'asset_name',
          valueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_id;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_name;
            }
          },
          cellRenderer: (params: any) => {
            return this.assetNames?.[params.value];
          },
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.name'
          ),
          field: 'asset_model_name',
          valueGetter: (params: any) => {
            if (params.data) {
              if (params.data.asset_model_id) {
                return params.data.asset_model_id;
              }
              return params.data.asset_model_name;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.asset_model_name;
            }
          },
          cellRenderer: (params: any) => {
            const model = this.modelNames?.[params.value];
            if (!model) {
              return '';
            }
            if (model.includes('[Archived]')) {
              return model;
            }

            const aTag = document.createElement('a');
            aTag.href = 'javascript:void(0)';
            aTag.onclick = () => {
              this.showCostCalculatorModal(params.value);
            };
            aTag.innerHTML = model;
            return aTag;
          },
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.cost'
          ),
          field: 'cost',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.type'
          ),
          field: 'type_name',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.budget'
          ),
          field: 'budget_name',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.plan'
          ),
          field: 'plan',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.status'
          ),
          field: 'status_name',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.savings'
          ),
          field: 'savings',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.last_status_update_date'
          ),
          field: 'lastStatusUpdateDate',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.cec_reduction'
          ),
          field: 'cec_reduction',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.ogc_reduction'
          ),
          field: 'ogc_reduction',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.asset_action.comment'
          ),
          field: 'comment',
        },
        {
          headerName: this.translate.instant(
            'headers.fastlc.lifecycle_strategy.id'
          ),
          field: 'asset_model_id',
          hide: true,
        },
      ],
    };
    this.assetActionGridAutoGroupColumnDef = {
      headerName: this.translate.instant(
        'headers.fastlc.asset_action.description'
      ),
      field: 'description',
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    this.gridOptions = tmpGridOptions;
    await this.loadItems();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedAssetModelNodes) {
      this.filterList(this.selectedAssetModelNodes);
    }
  }

  clearFilterAndResetModelsGrid(): void {
    this.assetActionGrid?.clearAllFilters();
    this.resetModelsGrid.emit();
  }

  resetModelActionGrid(): void {
    this.assetActionGrid?.uncheckAll();
  }

  async showCostCalculatorModal(assetModelId: number): Promise<void> {
    this.bsModalRef = this.bsModalService.show(CostCalculatorComponent, {
      initialState: {
        assetModelId,
      },
      class: 'modal-lg',
    });
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }

    this.items = await this.data
      .by('api/fastlc/asset-actions', {
        criteria: {
          site: this.auth.getSiteId(),
        },
      })
      .toPromise();
    if (this.sizeColumnsToFit) {
      this.assetActionGrid?.gridApi?.sizeColumnsToFit();
      this.sizeColumnsToFit = false;
    }

    this.assetActionGrid?.uncheckAll();
    this.filterList(this.selectedAssetModelNodes);

    // set data array to use ID as value in valueGetter and Description as display value.
    // This is to allow grouping by ID instead of display value ("name" for eg.) thereby avoid grouping on duplicate display values
    this.items.map((val) => {
      this.assetNames[val.asset_id] = val.asset_name;
      this.modelNames[val.asset_model_name] = val.asset_model_name;
      this.modelNames[val.asset_model_id] = val.asset_model_name;
    });

    if (!silent) {
      this.isLoading = false;
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onRowClicked(event: any): void {
    if (!event) {
      return;
    }
    this.showEditModal([event]);
  }

  filterList(assetModels: any): void {
    const instance =
      this.assetActionGrid?.gridApi?.getFilterInstance('asset_model_id');
    if (assetModels.length > 0) {
      setTimeout(() => {
        instance?.setModel({
          values: _.map(assetModels, (el) => el.id + ''),
        });
        this.assetActionGrid?.gridApi?.onFilterChanged();
      });
      return;
    }
    instance?.setModel(null);
    this.assetActionGrid?.gridApi?.onFilterChanged();
  }

  /**
   * @param items
   */
  showEditModal(items?: any): void {
    this.bsModalRef = this.bsModalService.show(AssetActionEditComponent, {
      initialState: {
        items,
        selectedAssetModelNodes: this.selectedAssetModelNodes,
      },
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = (res: AssetActionModel) => {
      this.items = syncItems(res, this.items);
      this.reloadAssetModelGrid.emit();

      this.filterList(this.selectedAssetModelNodes);

      this.items.map((val) => {
        this.assetNames[val.asset_id] = val.asset_name;
        this.modelNames[val.asset_model_name] = val.asset_model_name;
        this.modelNames[val.asset_model_id] = val.asset_model_name;
      });

      this.toastr.showSuccess();
    };
  }

  showMassEditModal(): void {
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      const items = _.map(this.selectedNodes, (el) => el.data);

      const closedStatus = items.filter((item: any) => item.status.closed);

      if (closedStatus.length > 0 && this.selectedNodes.length > 1) {
        this.bsModalRef = this.bsModalService.show(ErrorModalComponent, {
          initialState: {
            title: 'naviagtions.error',
            content: 'fastlc.lifecycle_strategy.asset_actions.content',
          },
        });
        this.bsModalRef.content.cancelFn = () => {
          this.bsModalRef?.hide();
        };
        return;
      }

      this.showEditModal(items);
    }
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.isLoading = true;
      this.data.post('api/fastlc/asset-actions/delete-all', ids).subscribe(
        (res) => {
          this.items = this.items.filter(
            (item: any) => !ids.includes(`${item.id}`)
          );
          this.isLoading = false;
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.isLoading = false;
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  myCellCallback = (params: any) => {
    return params.value;
  };

  exportProcessCallBack = (cellParams: any) => {
    if (cellParams.value && cellParams.column.colId === 'asset_name') {
      return this.assetNames?.[cellParams.value];
    }
    if (cellParams.value && cellParams.column.colId === 'asset_model_name') {
      return this.modelNames?.[cellParams.value];
    }
    if (cellParams.value && cellParams.node.field === 'asset_name') {
      return this.assetNames?.[cellParams.node.key];
    }

    if (cellParams.value && cellParams.node.field === 'asset_model_name') {
      return this.modelNames?.[cellParams.node.key];
    }

    if (cellParams.node.group) {
      return '';
    }

    return cellParams.value;
  };
}
