<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('description')?.dirty"
            for="description"
          >
            {{ "headers.risk_parameters.factors.factors" | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('description')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="description"
            id="description"
            type="text"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('description')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('failureProbability')?.dirty"
            for="failureProbability"
          >
            {{
              "headers.risk_parameters.factors.failure_probability" | translate
            }}
          </label>
          <input
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('failureProbability')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="failureProbability"
            id="failureProbability"
            type="number"
            (keypress)="validateNumberInput($event)"
          />
          <div
            class="text-danger"
            *ngIf="
              showValidationError && form.get('failureProbability')?.errors?.min
            "
          >
            {{ "messages.greater_than" | translate }}
            0
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('mttrPercentage')?.dirty"
            for="mttrPercentage"
          >
            {{ "headers.risk_parameters.factors.mttr_percentage" | translate }}
          </label>
          <input
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('mttrPercentage')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="mttrPercentage"
            id="mttrPercentage"
            type="number"
            (keypress)="validateNumberInput($event)"
          />
          <div
            class="text-danger"
            *ngIf="
              showValidationError && form.get('mttrPercentage')?.errors?.min
            "
          >
            {{ "messages.greater_than" | translate }}
            0
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('mttrPlusMinus')?.dirty"
            for="mttrPlusMinus"
          >
            {{ "headers.risk_parameters.factors.mttr_plus_minus" | translate }}
          </label>
          <input
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('mttrPlusMinus')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="mttrPlusMinus"
            id="mttrPlusMinus"
            type="number"
            (keypress)="validateNumberInput($event)"
          />
          <div
            class="text-danger"
            *ngIf="
              showValidationError && form.get('mttrPlusMinus')?.errors?.min
            "
          >
            {{ "messages.positive" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label [class.font-weight-bold]="form.get('noDowntime')?.dirty">
            {{ "headers.risk_parameters.factors.no_downtime" | translate }}
          </label>
          <ngx-select
            [items]="boolReference"
            [placeholder]="'(Select a Value)'"
            formControlName="noDowntime"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
