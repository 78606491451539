<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="card form" *ngIf="mode === 'new'">
        <div class="card-body">
          <div class="form-row">
            <ng-container>
              <div class="form-group col-12">
                <label
                  [class.font-weight-bold]="form.get('model')?.dirty"
                  for="model"
                >
                  {{ "headers.fastlc.asset_model.model" | translate }}
                  <span class="text-danger">*</span>
                </label>
                <ngx-select
                  (select)="endOfLifeYear()"
                  [items]="modelsReference"
                  [placeholder]="'(Select a Lifecycle Model)'"
                  formControlName="model"
                  id="model"
                  optionTextField="name"
                  optionValueField="value"
                  size="small"
                ></ngx-select>
                <div
                  class="text-danger"
                  *ngIf="showValidationError && form.get('model')?.invalid"
                >
                  {{ "messages.required" | translate }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="card form">
        <div class="card-body">
          <h6 class="card-title">
            {{ "headers.asset.asset" | translate }}
          </h6>
          <div class="form-row">
            <div class="form-group col-6">
              <label
                [class.font-weight-bold]="form.get('assetInstallDate')?.dirty"
                for="assetInstallDate"
              >
                {{ "headers.fastlc.asset_model.install_date" | translate }}
              </label>
              <input
                [ngClass]="{
                  'is-invalid':
                    showValidationError && form.get('assetInstallDate')?.invalid
                }"
                class="form-control form-control-sm"
                (change)="endOfLifeYear()"
                formControlName="assetInstallDate"
                id="assetInstallDate"
                type="date"
              />
              <div
                class="text-danger"
                *ngIf="
                  showValidationError && form.get('assetInstallDate')?.invalid
                "
              >
                {{ "messages.required" | translate }}
              </div>
            </div>
            <div class="form-group col-6">
              <label
                [class.font-weight-bold]="form.get('assetERV')?.dirty"
                for="assetERV"
              >
                {{
                  "headers.fastlc.asset_model.equipment_replacement_value"
                    | translate
                }}
              </label>
              <input
                [ngClass]="{
                  'is-invalid':
                    showValidationError && form.get('assetERV')?.invalid
                }"
                class="form-control form-control-sm"
                formControlName="assetERV"
                id="assetERV"
                type="number"
              />
              <div
                class="text-danger"
                *ngIf="showValidationError && form.get('assetERV')?.invalid"
              >
                {{ "messages.positive" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card form">
        <div class="card-body">
          <h6 class="card-title">
            {{ "headers.fastlc.asset_model.end_life_scenario" | translate }}
          </h6>
          <div class="form-row">
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('riskScenario')?.dirty"
                for="riskScenario"
              >
                {{ "headers.fastlc.asset_model.risk_scenario" | translate }}
              </label>
              <textarea
                [ngClass]="{
                  'is-invalid':
                    showValidationError && form.get('riskScenario')?.invalid
                }"
                class="form-control form-control-sm"
                formControlName="riskScenario"
                id="riskScenario"
              >
              </textarea>
              <div
                class="text-danger"
                *ngIf="showValidationError && form.get('riskScenario')?.invalid"
              >
                {{ "messages.required" | translate }}
              </div>
            </div>
            <div class="form-group col-6">
              <label
                [class.font-weight-bold]="form.get('catExposure')?.dirty"
                for="catExposure"
              >
                {{ "headers.fastlc.asset_model.year" | translate }}
                <span class="font-weight-bold">{{ yearsLabel }}</span>
              </label>
              <ngx-select
                [items]="years"
                [placeholder]="'(Select a year)'"
                formControlName="catExposure"
                id="catExposure"
                optionTextField="name"
                optionValueField="value"
                size="small"
              ></ngx-select>
              <div
                class="text-danger"
                *ngIf="showValidationError && form.get('catExposure')?.invalid"
              >
                {{ "messages.required" | translate }}
              </div>
            </div>
            <div class="form-group col-6">
              <label
                [class.font-weight-bold]="form.get('catConfidence')?.dirty"
                for="catConfidence"
              >
                {{ "headers.fastlc.asset_model.confidence_level" | translate }}
              </label>
              <ngx-select
                [items]="levelOptions"
                [placeholder]="'(Select a confidence level)'"
                formControlName="catConfidence"
                id="catConfidence"
                optionTextField="name"
                optionValueField="value"
                size="small"
              ></ngx-select>
              <div
                class="text-danger"
                *ngIf="
                  showValidationError && form.get('catConfidence')?.invalid
                "
              >
                {{ "messages.required" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card form">
        <div class="card-body">
          <h6 class="card-title">
            {{
              "headers.fastlc.asset_model.cost_catastrophic_event" | translate
            }}
          </h6>
          <div class="form-row">
            <div class="form-group col-4">
              <label
                [class.font-weight-bold]="form.get('catDownCost')?.dirty"
                for="catDownCost"
              >
                {{ "headers.fastlc.asset_model.downtime" | translate }}
              </label>
              <input
                [ngClass]="{
                  'is-invalid':
                    showValidationError && form.get('catDownCost')?.invalid
                }"
                class="form-control form-control-sm"
                formControlName="catDownCost"
                id="catDownCost"
                type="number"
                (keypress)="validateNumberInput($event)"
              />
              <div
                class="text-danger"
                *ngIf="
                  showValidationError && form.get('catDownCost')?.errors?.min
                "
              >
                {{ "messages.positive" | translate }}
              </div>
            </div>
            <div class="form-group col-4">
              <label
                [class.font-weight-bold]="form.get('catAddRiskCost')?.dirty"
                for="catAddRiskCost"
              >
                {{ "headers.fastlc.asset_model.risks" | translate }}
              </label>
              <input
                [ngClass]="{
                  'is-invalid':
                    showValidationError && form.get('catAddRiskCost')?.invalid
                }"
                class="form-control form-control-sm"
                formControlName="catAddRiskCost"
                id="catAddRiskCost"
                type="number"
                (keypress)="validateNumberInput($event)"
              />
              <div
                class="text-danger"
                *ngIf="
                  showValidationError && form.get('catAddRiskCost')?.errors?.min
                "
              >
                {{ "messages.positive" | translate }}
              </div>
            </div>
            <div class="form-group col-4">
              <label
                [class.font-weight-bold]="form.get('catMaintCost')?.dirty"
                for="catMaintCost"
              >
                {{
                  "headers.fastlc.asset_model.replacement_repairs" | translate
                }}
              </label>
              <input
                [ngClass]="{
                  'is-invalid':
                    showValidationError && form.get('catMaintCost')?.invalid
                }"
                class="form-control form-control-sm"
                formControlName="catMaintCost"
                id="catMaintCost"
                type="number"
                (keypress)="validateNumberInput($event)"
              />
              <div
                class="text-danger"
                *ngIf="
                  showValidationError && form.get('catMaintCost')?.errors?.min
                "
              >
                {{ "messages.positive" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card form">
        <div class="card-body">
          <h6 class="card-title">
            {{ "headers.fastlc.asset_model.cost_on_going_issues" | translate }}
          </h6>
          <div class="form-row">
            <div class="form-group col-4">
              <label
                [class.font-weight-bold]="form.get('onGoingDownCost')?.dirty"
                for="onGoingDownCost"
              >
                {{ "headers.fastlc.asset_model.downtime" | translate }}
              </label>
              <input
                [ngClass]="{
                  'is-invalid':
                    showValidationError && form.get('onGoingDownCost')?.invalid
                }"
                class="form-control form-control-sm"
                formControlName="onGoingDownCost"
                id="onGoingDownCost"
                type="number"
                (keypress)="validateNumberInput($event)"
              />
              <div
                class="text-danger"
                *ngIf="
                  showValidationError &&
                  form.get('onGoingDownCost')?.errors?.min
                "
              >
                {{ "messages.positive" | translate }}
              </div>
            </div>
            <div class="form-group col-4">
              <label
                [class.font-weight-bold]="form.get('onGoingAddRiskCost')?.dirty"
                for="onGoingAddRiskCost"
              >
                {{ "headers.fastlc.asset_model.risks" | translate }}
              </label>
              <input
                [ngClass]="{
                  'is-invalid':
                    showValidationError &&
                    form.get('onGoingAddRiskCost')?.invalid
                }"
                class="form-control form-control-sm"
                formControlName="onGoingAddRiskCost"
                id="onGoingAddRiskCost"
                type="number"
                (keypress)="validateNumberInput($event)"
              />
              <div
                class="text-danger"
                *ngIf="
                  showValidationError &&
                  form.get('onGoingAddRiskCost')?.errors?.min
                "
              >
                {{ "messages.positive" | translate }}
              </div>
            </div>
            <div class="form-group col-4">
              <label
                [class.font-weight-bold]="form.get('onGoingMaintCost')?.dirty"
                for="onGoingMaintCost"
              >
                {{
                  "headers.fastlc.asset_model.operation_maintenance" | translate
                }}
              </label>
              <input
                [ngClass]="{
                  'is-invalid':
                    showValidationError && form.get('onGoingMaintCost')?.invalid
                }"
                class="form-control form-control-sm"
                formControlName="onGoingMaintCost"
                id="onGoingMaintCost"
                type="number"
                (keypress)="validateNumberInput($event)"
              />
              <div
                class="text-danger"
                *ngIf="
                  showValidationError &&
                  form.get('onGoingMaintCost')?.errors?.min
                "
              >
                {{ "messages.positive" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card form">
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-12">
              <label
                [class.font-weight-bold]="form.get('comment')?.dirty"
                for="comment"
              >
                {{ "headers.fastlc.asset_model.comment" | translate }}
              </label>
              <input
                [ngClass]="{
                  'is-invalid':
                    showValidationError && form.get('comment')?.invalid
                }"
                class="form-control form-control-sm"
                formControlName="comment"
                id="comment"
                type="text"
              />
              <div
                class="text-danger"
                *ngIf="showValidationError && form.get('comment')?.errors?.min"
              >
                {{ "messages.required" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
