import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ToasterService {
  /**
   * @param toastr
   * @param translate
   */
  constructor(
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  /**
   * Display a success toast.
   *
   * @param mess
   */
  showSuccess(mess = 'messages.save'): void {
    this.translate.get(mess).subscribe((res: string) => {
      this.toastr.success(res, '', {
        enableHtml: true,
      });
    });
  }

  /**
   * Display a copy success toast.
   */
  showCopySuccess(): void {
    this.translate.get('messages.copy').subscribe((res: string) => {
      this.toastr.success(res);
    });
  }

  /**
   * Display an error toast.
   *
   * @param mess
   */
  showError(mess = 'messages.error'): void {
    this.translate.get(mess).subscribe((res: string) => {
      this.toastr.error(res, '', {
        enableHtml: true,
      });
    });
  }
}
