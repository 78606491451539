export class ActionTypeModel {
  id: number;
  description: string;
  resetLifecycle: boolean;
  resetOnGoing: boolean;

  account: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.description = data.description ? data.description : '';
    this.resetLifecycle = data.reset_lifecycle ? data.reset_lifecycle : false;
    this.resetOnGoing = data.reset_on_going ? data.reset_on_going : false;

    this.account = data.account ? data.account : null;
  }
}
