<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <h5 *ngIf="mode === 'single'">
      {{ items[0]?.name }} - {{ items[0]?.description }}
    </h5>
    <form [formGroup]="form">
      <div *ngIf="mode !== 'mass'" class="card form">
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-12">
              <label [class.font-weight-bold]="form.get('factor')?.dirty" for="factor">
                {{ "headers.fastsp.spare_strategy.target_risk_factors" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'factor'"></app-field-clear-button>
              <ngx-select [items]="assetFactorsReference" [placeholder]="'(Select a Factor)'" formControlName="factor"
                id="factor" [multiple]="true" optionTextField="name" optionValueField="value" size="small"></ngx-select>
              <div class="text-danger" *ngIf="showValidationError && form.get('factor')?.invalid">
                {{ "messages.required" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card form">
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-12">
              <label [class.font-weight-bold]="form.get('model')?.dirty" for="model">
                {{ "headers.fastsp.spare_strategy.spares_models" | translate }}
              </label>
              <app-field-clear-button [formGroupRef]="form" [fieldName]="'model'"></app-field-clear-button>
              <ngx-select [items]="spareModelsReference" [placeholder]="'(Select a Model)'" formControlName="model"
                id="model" [multiple]="true" optionTextField="name" optionValueField="value" size="small"></ngx-select>
              <div class="text-danger" *ngIf="showValidationError && form.get('model')?.invalid">
                {{ "messages.required" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card form">
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-12">
              <label [class.font-weight-bold]="form.get('title')?.dirty" for="title">
                {{ "headers.fastsp.spare_strategy.efficiency" | translate }}
              </label>
              <input [ngClass]="{
                  'is-invalid':
                    showValidationError && form.get('efficiency')?.invalid
                }" class="form-control form-control-sm" formControlName="efficiency" id="efficiency" type="number"
                max="100" (keypress)="validateNumberInput($event)" />
              <div class="text-danger" *ngIf="
                  showValidationError && form.get('efficiency')?.errors?.min
                ">
                {{ "messages.positive" | translate }}
              </div>
              <div class="text-danger" *ngIf="
                  showValidationError && form.get('efficiency')?.errors?.max
                ">
                {{ "messages.less_than" | translate }}
                100.
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button (click)="save()" [disabled]="isSubmitted || form.invalid" class="btn btn-sm btn-success">
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>