import { Component, OnInit, AfterViewInit, ViewChildren } from '@angular/core';
import {
  DataService,
  AuthService,
  SharedDataService,
} from 'src/app/shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '../..';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-asset-summary',
  templateUrl: './asset-summary.component.html',
})
export class AssetSummaryComponent implements OnInit, AfterViewInit {
  @ViewChildren(GridComponent) grids?: GridComponent;
  // Modal parameters
  assetData: any;
  assetId = 0;

  tasksGrid?: any;
  tasks: any[] = [];
  plans: any[] = [];
  isLoading = false;
  tasksIsLoading = false;
  plansIsLoading = false;
  tasksGridOptions?: GridOptions;
  plansGridOptions?: GridOptions;
  toolingReference?: [];
  tasksGridAutoGroupColumnDef?: any;
  customHeaders: any = [];

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private data: DataService,
    private sharedData: SharedDataService
  ) {}

  ngAfterViewInit(): void {
    // @ts-ignore
    this.tasksGrid = this.grids?._results[0];
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    this.getAssetSummary(this.assetId);

    this.toolingReference = await this.data
      .by('api/config/tools', {
        criteria: {
          account: [this.auth.getAccountId(), null],
        },
      })
      .toPromise();

    this.customHeaders = await this.sharedData.getAssetCustomHeaders(
      this.auth.getAccountId()
    );

    const tmpGridOptions: GridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1, // expand all groups by default
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.model'),
          field: 'model',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.component.component'
          ),
          field: 'component',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.state'
          ),
          field: 'state',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.frequency'
          ),
          field: 'frequency',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.labor'
          ),
          field: 'trade',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.activity.activity'
          ),
          field: 'activity',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.tool.tool'
          ),
          field: 'tools',
          filterParams: {
            values: _(this.toolingReference)
              .map((el: any) => el.name)
              .unshift(null)
              .value(),
          },
          valueGetter: (params: any) => {
            if (params.data !== undefined) {
              return params.data.atools;
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.duration'
          ),
          field: 'duration',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.criteria'
          ),
          field: 'criteria',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.keyword'),
          field: 'keyword',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.proc'),
          field: 'procedure',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.comment'),
          field: 'comment',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.justification'
          ),
          field: 'justification',
        },
        {
          headerName: this.translate.instant('headers.routing.plan.plan'),
          field: 'plan_name',
          cellRenderer: (params: any) => {
            if (params.data) {
              if (!this.auth.hasAccess('routing')) {
                return params.data.plan_name;
              }
              const aTag = document.createElement('a');
              aTag.href = '/routing/plans?plan_id=' + params.data.plan_id;
              aTag.target = '_blank';
              aTag.innerHTML = params.data.plan_name;
              return aTag;
            }
          },
        },
        {
          headerName: this.translate.instant('headers.routing.task.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.id'
          ),
          field: 'stateId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.id'
          ),
          field: 'frequencyId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.id'
          ),
          field: 'tradeId',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.id'),
          field: 'modelId',
          hide: true,
        },
      ],
    };

    this.tasksGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.fastpm.pm_model.description'),
      field: 'description',
      filter: 'agTextColumnFilter',
      cellRendererParams: {
        checkbox: false,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
    };

    this.tasksGridOptions = tmpGridOptions;

    this.plansGridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.routing.plan.plan'),
          field: 'name',
          filter: 'agTextColumnFilter',
          checkboxSelection: false,
          headerCheckboxSelection: false,
          headerCheckboxSelectionFilteredOnly: false,
          cellRenderer: (params: any) => {
            if (!this.auth.hasAccess('routing')) {
              return params.data.name;
            }
            const aTag = document.createElement('a');
            aTag.href = '/routing/plans?plan_id=' + params.data.id;
            aTag.target = '_blank';
            aTag.innerHTML = params.data.name;
            return aTag;
          },
        },
        {
          headerName: this.translate.instant('headers.routing.plan.status'),
          field: 'status',
          cellRenderer: (params: any): string => {
            let cssClass = '';
            if (params.data.status === 1) {
              cssClass = 'badge-light';
            } else if (params.data.status === 2) {
              cssClass = 'badge-success';
            } else if (params.data.status === 3) {
              cssClass = 'badge-warning';
            } else if (params.data.status === 4) {
              cssClass = 'badge-danger';
            }
            return (
              '<span class="badge badge-lg ' +
              cssClass +
              '">' +
              this.translate.instant(
                'headers.routing.plan.status_' + params.data.status
              ) +
              '</span>'
            );
          },
          filterParams: {
            valueFormatter: (params: any): string => {
              return this.translate.instant(
                'headers.routing.plan.status_' + params.value
              );
            },
          },
        },
        {
          headerName: this.translate.instant('headers.routing.plan.quality'),
          field: 'quality',
        },
        {
          headerName: this.translate.instant('headers.routing.plan.safety'),
          field: 'safety',
        },
        {
          headerName: this.translate.instant('headers.routing.plan.parts'),
          field: 'parts',
        },
        {
          headerName: this.translate.instant('headers.routing.plan.task_count'),
          field: 'tasks',
        },
        {
          headerName: this.translate.instant('headers.routing.plan.duration'),
          field: 'duration',
        },
        {
          headerName: this.translate.instant(
            'headers.routing.plan.actualDuration'
          ),
          field: 'actualDuration',
        },
        {
          headerName: this.translate.instant(
            'headers.routing.plan.release_date'
          ),
          field: 'release_date',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.frequency'
          ),
          field: 'frequency',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.id'
          ),
          field: 'frequencyId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.state'
          ),
          field: 'state',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.id'
          ),
          field: 'stateId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.labor'
          ),
          field: 'labour',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.id'
          ),
          field: 'labourId',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.routing.plan.cmms_reference'
          ),
          field: 'cmmsReference',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.routing.plan.comments'),
          field: 'comments',
          hide: true,
        },
      ],
    };
    await this.tasksLoadItems();
    // await this.plansLoadItems();
  }

  async tasksLoadItems(): Promise<void> {
    this.tasksIsLoading = true;
    this.tasks = await this.data
      .get(`api/assets/tasks/${this.assetId}`)
      .toPromise();
    this.tasksIsLoading = false;
  }

  /**
   */
  async plansLoadItems(): Promise<void> {
    this.plansIsLoading = true;
    this.plans = await this.data
      .get(`api/assets/plans/${this.assetId}`)
      .toPromise();
    this.plansIsLoading = false;
  }

  /**
   */
  async onAssetChange(assetId: number): Promise<void> {
    await this.getAssetSummary(assetId);
    await this.tasksLoadItems();
    await this.plansLoadItems();
  }

  async getAssetSummary(assetId: number): Promise<void> {
    if (!assetId) {
      return;
    }
    this.isLoading = true;
    this.assetId = assetId;

    this.data.get(`api/assets/summary/${assetId}`).subscribe(
      (res) => {
        this.assetData = res;
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any) => {};
}
