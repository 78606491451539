<div
  class="app-container"
  [class.height-auto]="['/modules', '/admin'].includes(router.url)"
>
  <app-header></app-header>
  <div class="app-content">
    <router-outlet></router-outlet>
  </div>
</div>
<app-footer class="mt-auto"></app-footer>
