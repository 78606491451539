export class LocationModel {
  id: number;
  name: string;
  image: string;
  parent: string;
  parentId: number;

  accountId: number;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.name = data.name ? data.name : '';
    this.image = data.image ? data.image : '';
    this.parent = data.parent ? data.parent : '';
    this.parentId = data.parentId ? data.parentId : 0;

    this.accountId = data.accountId ? data.accountId : null;
  }
}
