import { Component, OnInit } from '@angular/core';
import { AuthService, Broadcaster } from '../../shared/services';
import { Router } from '@angular/router';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  modules: {
    name: string;
    children: {
      name: string;
      description: string;
      link: string;
      module: string;
    }[];
  }[] = [
    {
      name: this.translate.instant(
        'modules.admin.account_and_user_management.name'
      ),
      children: [
        {
          name: this.translate.instant(
            'modules.admin.account_and_user_management.children.site.name'
          ),
          description: this.translate.instant(
            'modules.admin.account_and_user_management.children.site.description'
          ),
          link: '/admin/sites',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.account_and_user_management.children.users.name'
          ),
          description: this.translate.instant(
            'modules.admin.account_and_user_management.children.users.description'
          ),
          link: '/admin/users',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.account_and_user_management.children.role.name'
          ),
          description: this.translate.instant(
            'modules.admin.account_and_user_management.children.role.description'
          ),
          link: '/admin/roles',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.account_and_user_management.children.appearance.name'
          ),
          description: this.translate.instant(
            'modules.admin.account_and_user_management.children.appearance.description'
          ),
          link: '/admin/appearance',
          module: 'core',
        },
      ],
    },
    {
      name: this.translate.instant('modules.admin.asset_parameters.name'),
      children: [
        {
          name: this.translate.instant(
            'modules.admin.asset_parameters.children.equipment_type.name'
          ),
          description: this.translate.instant(
            'modules.admin.asset_parameters.children.equipment_type.description'
          ),
          link: '/admin/equipment-types',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.asset_parameters.children.custom_fields.name'
          ),
          description: this.translate.instant(
            'modules.admin.asset_parameters.children.custom_fields.description'
          ),
          link: '/admin/asset/custom-fields',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.asset_parameters.children.access_requirements.name'
          ),
          description: this.translate.instant(
            'modules.admin.asset_parameters.children.access_requirements.description'
          ),
          link: '/admin/asset/access-requirements',
          module: 'core',
        },
      ],
    },
    {
      name: this.translate.instant('modules.admin.risk_parameters.name'),
      children: [
        {
          name: this.translate.instant(
            'modules.admin.risk_parameters.children.criticality.name'
          ),
          description: this.translate.instant(
            'modules.admin.risk_parameters.children.criticality.description'
          ),
          link: '/admin/criticalities',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.risk_parameters.children.finality.name'
          ),
          description: this.translate.instant(
            'modules.admin.risk_parameters.children.finality.description'
          ),
          link: '/admin/finalities',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.risk_parameters.children.risk_calculation_engine.name'
          ),
          description: this.translate.instant(
            'modules.admin.risk_parameters.children.risk_calculation_engine.description'
          ),
          link: '/admin/engines',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.risk_parameters.children.consequence.name'
          ),
          description: this.translate.instant(
            'modules.admin.risk_parameters.children.consequence.description'
          ),
          link: '/admin/consequences',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.risk_parameters.children.consequence_category.name'
          ),
          description: this.translate.instant(
            'modules.admin.risk_parameters.children.consequence_category.description'
          ),
          link: '/admin/categories',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.risk_parameters.children.functional_failure.name'
          ),
          description: this.translate.instant(
            'modules.admin.risk_parameters.children.functional_failure.description'
          ),
          link: '/admin/functional-failures',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.risk_parameters.children.factors.name'
          ),
          description: this.translate.instant(
            'modules.admin.risk_parameters.children.factors.description'
          ),
          link: '/admin/factors',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.risk_parameters.children.products.name'
          ),
          description: this.translate.instant(
            'modules.admin.risk_parameters.children.products.description'
          ),
          link: '/admin/products',
          module: 'core',
        },
      ],
    },
    {
      name: this.translate.instant('modules.admin.lifecycle_parameters.name'),
      children: [
        {
          name: this.translate.instant(
            'modules.admin.lifecycle_parameters.children.action_budgets.name'
          ),
          description: this.translate.instant(
            'modules.admin.lifecycle_parameters.children.action_budgets.description'
          ),
          link: '/admin/fastlc/budget-categories',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.lifecycle_parameters.children.action_types.name'
          ),
          description: this.translate.instant(
            'modules.admin.lifecycle_parameters.children.action_types.description'
          ),
          link: '/admin/fastlc/action-types',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.lifecycle_parameters.children.action_statuses.name'
          ),
          description: this.translate.instant(
            'modules.admin.lifecycle_parameters.children.action_statuses.description'
          ),
          link: '/admin/fastlc/action-statuses',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.lifecycle_parameters.children.grades.name'
          ),
          description: this.translate.instant(
            'modules.admin.lifecycle_parameters.children.grades.description'
          ),
          link: '/admin/fastlc/grades',
          module: 'core',
        },
      ],
    },
    {
      name: this.translate.instant('modules.admin.spare_parameters.name'),
      children: [
        {
          name: this.translate.instant(
            'modules.admin.spare_parameters.children.spares_type.name'
          ),
          description: this.translate.instant(
            'modules.admin.spare_parameters.children.spares_type.description'
          ),
          link: '/admin/spare-statuses',
          module: 'core',
        },
      ],
    },

    {
      name: this.translate.instant('modules.admin.tasks_parameters.name'),
      children: [
        {
          name: this.translate.instant(
            'modules.admin.tasks_parameters.children.frequency.name'
          ),
          description: this.translate.instant(
            'modules.admin.tasks_parameters.children.frequency.description'
          ),
          link: '/admin/frequencies',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.tasks_parameters.children.state.name'
          ),
          description: this.translate.instant(
            'modules.admin.tasks_parameters.children.state.description'
          ),
          link: '/admin/states',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.tasks_parameters.children.labor.name'
          ),
          description: this.translate.instant(
            'modules.admin.tasks_parameters.children.labor.description'
          ),
          link: '/admin/trades',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.tasks_parameters.children.tool.name'
          ),
          description: this.translate.instant(
            'modules.admin.tasks_parameters.children.tool.description'
          ),
          link: '/admin/tools',
          module: 'core',
        },
        {
          name: this.translate.instant(
            'modules.admin.tasks_parameters.children.activity.name'
          ),
          description: this.translate.instant(
            'modules.admin.tasks_parameters.children.activity.description'
          ),
          link: '/admin/activities',
          module: 'core',
        },
        {
          name: this.translate.instant('modules.fast_pm.children.library.name'),
          description: this.translate.instant(
            'modules.fast_pm.children.library.description'
          ),
          link: '/admin/components',
          module: 'core',
        },
      ],
    },
  ];

  constructor(
    public router: Router,
    public auth: AuthService,
    public breadCrumbService: BreadCrumbService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.parameters'),
          link: '/admin',
        },
      ],
      3
    );
  }
}
