import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RiskModelModel } from '../../shared/models';
import { ConfirmModalComponent } from '../../shared/modals';
import { GridComponent } from '../../shared';
import { SpareModelEditComponent } from './spare-model-edit/edit.component';
import { SpareListComponent } from './spares.component';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import { SpareModelAddComponent } from './spare-model-add/add.component';
import { SpareModelAssetListComponent } from './spare-model-asset-list/spare-model-asset-list.component';

@Component({
  selector: 'app-spare-models',
  templateUrl: './spare-models.component.html',
})
export class SpareModelsListComponent implements OnInit {
  @ViewChild(GridComponent, { static: true }) shareModelsGrid?: GridComponent;
  @ViewChild(SpareListComponent) sparesListComponent?: SpareListComponent;

  // Available items
  items: any[] = [];
  selectedSpareModelNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  isReferenceLoaded = false;
  sitesReferences?: [];
  sizeColumnsToFit = true;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.spares_models'),
          link: '/fastsp/models',
        },
      ],
      2
    );
    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_model.model'
          ),
          field: 'name',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          filter: 'agTextColumnFilter',
          filterParams: { newRowsAction: 'keep' },
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_model.comment'
          ),
          field: 'comment',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_model.asset_count'
          ),
          field: 'assetCount',
          hide: false,
          cellRenderer: (params: any): any => {
            const aTag = document.createElement('a');
            aTag.href = 'javascript:void(0)';
            aTag.onclick = () => {
              this.showAssetListModal(params.data.id);
            };
            aTag.innerHTML = params.value;
            return aTag;
          },
        },
      ],
    };
    await this.loadItems();
  }

  async showAssetListModal(modelId: number): Promise<void> {
    this.bsModalRef = this.bsModalService.show(SpareModelAssetListComponent, {
      initialState: {
        modelId,
      },
      class: 'modal-xl',
    });
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data.get('api/fastsp/models/from_site').toPromise();
    if (this.sizeColumnsToFit) {
      this.shareModelsGrid?.gridApi?.sizeColumnsToFit();
      this.sizeColumnsToFit = false;
    }
    this.shareModelsGrid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  onFilterChanged(event: any): void {}

  onSelectionChanged(event: any): void {
    this.selectedSpareModelNodes = event;
  }

  onRowClicked(event: any): void {
    this.showEditModal([event]);
  }

  resetModelsGrid(): void {
    this.shareModelsGrid?.uncheckAll();
    this.sparesListComponent?.resetSparesGrid();
  }

  async showAddShareModelModal(): Promise<void> {
    const orderByName = { orderBy: { name: 'asc' } };
    const criteria = {
      criteria: {
        account: [this.auth.getAccountId(), null],
      },
      ...orderByName,
    };
    this.sitesReferences = await this.data
      .by('api/sites', criteria)
      .toPromise();
    this.isReferenceLoaded = true;

    this.bsModalRef = this.bsModalService.show(SpareModelAddComponent, {
      initialState: {
        sitesReferences: this.sitesReferences,
      },
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      this.sparesListComponent?.loadItems();
      this.toastr.showSuccess();
    };
  }

  duplicateModels(): void {
    this.isLoading = true;
    const ids = _.map(this.selectedSpareModelNodes, (el) => el.id);

    this.data.post('api/fastsp/models/duplicate-all', ids).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.loadItems();
        this.sparesListComponent?.loadItems();
        this.toastr.showSuccess();
      },
      (err: any) => {
        console.log('err: ', err);
        this.isLoading = false;
      }
    );
  }

  /**
   * @param items
   */
  async showEditModal(items?: RiskModelModel[]): Promise<void> {
    this.bsModalRef = this.bsModalService.show(SpareModelEditComponent, {
      initialState: {
        items,
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      this.toastr.showSuccess();
    };
  }

  onFunctionLossDelete(event: boolean): any {
    // this.impactsListComponent?.loadItems();
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedSpareModelNodes, (el) => el.id);
      this.isLoading = true;
      this.data.post('api/fastsp/models/delete-all', ids).subscribe(
        (res) => {
          this.loadItems();
          this.toastr.showSuccess('messages.delete');
          this.sparesListComponent?.loadItems();
        },
        (err) => {
          this.isLoading = false;
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  myCellCallback = (params: any) => {
    return params.value;
  };
}
