<div class="card main list">
  <h4 class="card-header">Accounts</h4>
  <div class="card-body">
    <a
      (click)="setAccount(account)"
      *ngFor="let account of items"
      class="d-flex flex-column item mb-2 p-2 rounded"
    >
      <div class="name font-weight-bold">{{ account.name }}</div>
    </a>
    <div class="d-flex flex-column"></div>
  </div>
  <div class="card-footer">
    <div class="d-flex justify-content-around">
      <div>
        <button
          class="btn btn-block btn-light btn-lg"
          routerLink="/login"
          type="button"
        >
          {{ "navigations.back" | translate }}
        </button>
      </div>
      <!--      <div>-->
      <!--        <button class="btn btn-block btn-primary btn-lg" routerLink="/accounts/create" type="button">-->
      <!--          {{ 'navigations.account.create_account' | translate }}-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
  </div>
</div>
