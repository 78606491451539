export class ConfCriticalityLevelModel {
  id: number;
  description: string;
  threshold: number;

  account: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.description = data.description ? data.description : '';
    this.threshold = data.threshold ? data.threshold : 0;

    this.account = data.account ? data.account : null;
  }
}
