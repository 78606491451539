import {Component, Input, Output, EventEmitter} from '@angular/core';
import {FileUploadControl} from '@iplab/ngx-file-upload';
import {DataService} from '../services/data.service';
import {ToasterService} from '../services/toaster.service';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUploadComponent {

  @Output() filesUploaded = new EventEmitter<any>();

  entityType = 'location';
  imageServiceUrl = 'api/images';

  files = [];

  public fileUploadControl = new FileUploadControl().setListVisibility(false);

  constructor(
    protected service: DataService,
    public auth: AuthService,
    private toastr: ToasterService
  ) {
    this.fileUploadControl.valueChanges.subscribe((data: any) => this.statusChanged(data));
  }

  /**
   * @param files
   */
  statusChanged(files: []): void {

    if (!files.length) {
      return;
    }
    this.fileUploadControl.disable(true);
    const formData: FormData = new FormData();

    formData.append('entityType', this.entityType);
    formData.append('account', '' + this.auth.getAccountId());
    files.forEach(file => {
      formData.append('images[]', file);
    });

    this.service.postFiles(this.imageServiceUrl, formData)
      .subscribe((resFiles) => {
          setTimeout(() => {
            this.fileUploadControl.clear();
            this.fileUploadControl.disable(false);
          }, 500);
          if (resFiles && resFiles.length > 0) {
            this.filesUploaded.emit(resFiles);
          }
        },
        (err) => {
          setTimeout(() => {
            this.fileUploadControl.clear();
            this.fileUploadControl.disable(false);
          }, 500);
          this.toastr.showError();
        }
      );
  }
}
