export class ConfCategoryModel {
  id: number;
  description: string;

  account: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.description = data.description ? data.description : '';

    this.account = data.account ? data.account : null;
  }
}
