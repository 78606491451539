import { Component } from '@angular/core';
import { IntlService } from '../../../shared/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  constructor(
    public intlService: IntlService,
    public translateService: TranslateService
  ) {}

  switchLanguage(language: string): void {
    this.intlService.setLocale(language);
    this.translateService.use(language);
  }
}
