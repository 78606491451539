import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent implements OnInit {
  // Available items
  items: any[] = [];
  originalTree: any[] = [];
  tree: any[] = [];
  search = new UntypedFormControl();

  constructor(public auth: AuthService, public bsModalRef: BsModalRef) {
    this.search.valueChanges.subscribe((q) => {
      if (q) {
        const tmp = _.filter(
          this.items,
          (el) => el.name.indexOf(q) > -1 || el.description.indexOf(q) > -1
        );
        this.tree = this.toTree(tmp);
      } else {
        this.tree = this.originalTree;
      }
    });
  }

  selectItem(event: Event, item: any): void {
    event.stopPropagation();
    this.bsModalRef.hide();
    this.selectCallBack(item);
  }

  /**
   * Selection callback
   */
  selectCallBack = (item: any) => {};

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    this.originalTree = this.toTree(this.items);
    this.tree = this.originalTree;
  }

  /**
   *
   */
  resetTree(): void {
    this.tree = this.originalTree;
  }

  /**
   *
   * @param list
   */
  toTree(list: any[]): any[] {
    const map: any[] = [];
    let node: any;
    const roots: any[] = [];

    _.sortBy(list, 'parentId');

    for (let i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentId !== '0' && list[map[node.parentId]]) {
        list[map[node.parentId]].children.push(node);
      } else {
        roots.push(node);
      }
    }

    this.setDepth(roots, 0);

    return roots;
  }

  setDepth(items: any[], depth = 0): void {
    _.each(items, (item) => {
      item.depth = depth;
      item.display = depth === 0;
      item.isOpen = depth <= 0;
      if (item.children.length > 0) {
        this.setDepth(item.children, depth + 1);
      }
    });
  }

  openChange(event: any, item: any): void {
    item.isOpen = event;
  }
}
