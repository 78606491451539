<div class="container-fluid h-100">
  <div class="row h-100">
    <div [hidden]="!showLeftGrid" class="flex-column h-100 col-4">
      <app-locations-list
        (selectLocation)="selectLocation($event)"
      ></app-locations-list>
    </div>

    <div
      class="flex-column h-100"
      [ngClass]="showLeftGrid && showRightGrid ? 'col-4' : 'col-8'"
    >
      <div *ngIf="selectedLocation" class="row h-100">
        <div class="col-12">
          <app-locations-map
            (itemsUpdated)="itemsUpdated($event)"
            [items]="items"
            [showLeftGrid]="showLeftGrid"
            [showRightGrid]="showRightGrid"
            [location]="selectedLocation"
            (leftArrowClicked)="toggleLeftGrid()"
            (rightArrowClicked)="toggleRightGrid()"
            [hoveredItemId]="hoveredItemId"
            [selectedItems]="selectedItems"
          ></app-locations-map>
        </div>
      </div>
      <div *ngIf="!selectedLocation" class="row h-100">
        <div
          class="col-12 h-100 d-flex align-items-center justify-content-center"
        >
          {{ "messages.location.select" | translate }}
        </div>
      </div>
    </div>
    <div [hidden]="!showRightGrid" class="flex-column h-100 col-4">
      <div *ngIf="selectedLocation" class="h-100">
        <app-locations-items
          (itemsUpdated)="itemsUpdated($event)"
          (itemOnHover)="itemOnHover($event)"
          [location]="selectedLocation"
          (itemsSelectionChanged)="onItemsSelectionChanged($event)"
          [items]="items"
        ></app-locations-items>
      </div>
    </div>
  </div>
</div>
