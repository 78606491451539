import { Component, OnInit } from '@angular/core';
import {
  AuthService,
  DataService,
  IntlService,
  ToasterService,
} from '../../shared';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  // form instance
  form!: UntypedFormGroup;

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;

  //
  bsModalRef?: BsModalRef;

  /**
   * @param breadCrumbService
   * @param formBuilder
   * @param service
   * @param auth
   * @param translate
   * @param intlService
   * @param toastr
   */
  constructor(
    public breadCrumbService: BreadCrumbService,
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public translate: TranslateService,
    public intlService: IntlService,
    private toastr: ToasterService
  ) {}

  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.auth.getUserName(),
          link: '/my-account',
        },
      ],
      2
    );

    this.isLoading = true;

    // Build for
    this.form = this.getForm();

    // Loading done !
    this.isLoading = false;
  }

  /**
   *
   */
  getForm(): UntypedFormGroup {
    return this.formBuilder.group({
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      password_confirm: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  /**
   *
   */
  save(): boolean | void {
    if (!this.form || (this.form && !this.form.valid)) {
      return false;
    } else {
      // Query pointer
      let query;

      query = this.service.post('api/users/pwd', this.form.value);

      this.isSubmitted = true;
      query.subscribe(
        (res) => {
          this.isSubmitted = false;
          this.bsModalRef?.hide();
          this.toastr.showSuccess(
            this.translate.instant('my-account.password_changed_successfully')
          );
        },
        (err) => {
          if (err.error.message === 'requirement_same') {
            this.toastr.showError(
              this.translate.instant('my-account.requirement_same')
            );
          }
          this.isSubmitted = false;
        }
      );
    }
  }

  /**
   *
   */
  areRequirementOk(): boolean {
    return this.requirementSameValues() && this.form.valid;
  }

  requirementSameValues(): boolean {
    return (
      this.form.controls.password.value !== '' &&
      this.form.controls.password.value ===
        this.form.controls.password_confirm.value
    );
  }

  requirementMinSix(): boolean {
    return this.form.controls.password.value.length >= 6;
  }

  switchLanguage(language: string): void {
    this.intlService.setLocale(language);
    this.translate.use(language);
  }
}
