import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfConsequenceModel } from '../../../shared/models';
import { ConfirmModalComponent } from '../../../shared/modals';
import { GridComponent } from '../../../shared';
import { ConfConsequenceEditComponent } from './edit/edit.component';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../../shared/services/breadcrumb.service';

@Component({
  selector: 'app-conf-consequences',
  templateUrl: './consequences.component.html',
})
export class ConfConsequencesListComponent implements OnInit {
  @ViewChild(GridComponent, { static: true })
  confConsequencesGrid?: GridComponent;

  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  categoriesReference?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  isReferenceLoaded = false;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.parameters'),
          link: '/admin',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.consequences'),
          link: '/admin/consequences',
        },
      ],
      2
    );
    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.risk_parameters.consequences.consequences'
          ),
          field: 'description',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.risk_parameters.consequences.cost'
          ),
          field: 'cost',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.risk_parameters.consequences_categories.categories'
          ),
          field: 'category.description',
          filter: 'agTextColumnFilter',
        },
      ],
    };
    await this.loadItems();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data
      .by('api/config/consequences', {
        criteria: {
          account: this.auth.getAccountId(),
        },
      })
      .toPromise();
    this.confConsequencesGrid?.gridApi?.sizeColumnsToFit();
    this.confConsequencesGrid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  onFilterChanged(event: any): void {}

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onRowClicked(event: any): void {
    this.showEditModal([new ConfConsequenceModel(event)]);
  }

  /**
   * @param items
   */
  async showEditModal(items?: ConfConsequenceModel[]): Promise<void> {
    await this.loadCategories();

    this.bsModalRef = this.bsModalService.show(ConfConsequenceEditComponent, {
      initialState: {
        items,
        categoriesReference: this.categoriesReference,
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      this.toastr.showSuccess();
    };
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.data.post('api/config/consequences/delete-all', ids).subscribe(
        (res) => {
          this.loadItems();
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  // Load Categories used in modal edit form
  async loadCategories(): Promise<void> {
    // Categories
    const orderByDesc = { orderBy: { description: 'asc' } };
    const tpm = await this.data
      .by('api/config/categories', {
        criteria: {
          account: this.auth.getAccountId(),
        },
        ...orderByDesc,
      })
      .toPromise();
    this.categoriesReference = _.map(tpm, (el: any) => {
      return {
        name: el.description,
        value: el.id,
      };
    });

    // Mark ref as loaded (prevent reload)
    this.isReferenceLoaded = true;
  }

  myCellCallback = (params: any) => {
    return params.value;
  };
}
