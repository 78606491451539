<div class="container-fluid h-100">
  <div class="row h-50">
    <div class="flex-column h-100 col-6">
      <app-fsl-groups
        (selectItems)="groupsSelectionChanged($event)"
      ></app-fsl-groups>
    </div>
    <div class="flex-column h-100 col-6">
      <app-fsl-locations
        *ngIf="!uiGridState"
        (selectItems)="locationsSelectionChanged($event)"
        (uiGridToggle)="uiGridToggle()"
        [frequency]="currentFrequency"
        [state]="currentState"
        [labour]="currentLabour"
      ></app-fsl-locations>
      <app-fsl-plans
        *ngIf="uiGridState"
        (selectItems)="plansSelectionChanged($event)"
        (uiGridToggle)="uiGridToggle()"
        [frequency]="currentFrequency"
        [state]="currentState"
        [labour]="currentLabour"
      ></app-fsl-plans>
    </div>
  </div>
  <div class="row h-50">
    <div class="flex-column h-100 col-12">
      <app-fsl-tasks
        [frequency]="currentFrequency"
        [state]="currentState"
        [labour]="currentLabour"
        [loadFrom]="loadFrom"
        [currentSelection]="currentSelection"
      ></app-fsl-tasks>
    </div>
  </div>
</div>
