<div class="container-fluid h-100">
  <div class="row h-100">
    <div class="col-3 flex-column h-100">
      <div class="d-flex flex-column h-100">
        <div class="d-flex align-items-center px-2">
          <div class="mr-2">
            <button
              (click)="loadItems()"
              [ngClass]="{ 'btn-success': isLoading, 'btn-light': !isLoading }"
              class="btn btn-sm"
            >
              <i [ngClass]="{ 'la-spin': isLoading }" class="la la-refresh"></i>
            </button>
          </div>
          <fieldset>
            <div class="mr-2" style="float: left">
              <button
                (click)="showAddRiskModelModal()"
                [disabled]="isLoading || auth.isViewer('fast_criticality')"
                class="btn btn-success btn-sm"
              >
                <i class="la la-plus"></i>
              </button>
            </div>
            <div
              [isDisabled]="selectedRiskModelNodes.length === 0 || isLoading"
              class="btn-group mr-1"
              dropdown
            >
              <button
                class="dropdown-toggle btn btn-sm btn-primary"
                dropdownToggle
                type="button"
              >
                <ng-container *ngIf="selectedRiskModelNodes.length > 0"
                  >{{ "navigations.with" | translate }}
                  <strong>{{ selectedRiskModelNodes.length }}</strong>
                  {{ "navigations.selected" | translate }}
                </ng-container>
                <ng-container *ngIf="selectedRiskModelNodes.length === 0">{{
                  "navigations.no_selection" | translate
                }}</ng-container>
                <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu">
                <li
                  (click)="duplicateModels()"
                  class="btn btn-sm dropdown-item"
                  *ngIf="!auth.isViewer('fast_criticality')"
                >
                  <i class="la la-fw la-clone"></i>
                  {{ "navigations.clone" | translate }}
                </li>
                <li
                  (click)="riskModelsGrid?.export('xlsx')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-excel"></i> XLSX
                </li>
                <li
                  (click)="riskModelsGrid?.export('csv')"
                  class="btn btn-sm dropdown-item"
                >
                  <i class="la la-fw la-file-csv"></i> CSV
                </li>
                <li
                  class="divider dropdown-divider"
                  *ngIf="!auth.isViewer('fast_criticality')"
                ></li>
                <li role="menuitem" *ngIf="!auth.isViewer('fast_criticality')">
                  <a
                    class="dropdown-item btn btn-sm btn-danger"
                    (click)="showMassDeleteConfirm()"
                  >
                    <i class="la la-fw la-trash"></i>
                    {{ "navigations.delete" | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </fieldset>
        </div>
        <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
          <app-grid
            (filterChanged)="onFilterChanged($event)"
            (rowDoubleClicked)="
              auth.isViewer('fast_criticality') ? null : onRowClicked($event)
            "
            (selectionChanged)="onSelectionChanged($event)"
            [gridOptions]="gridOptions"
            [rowData]="items"
            class="ag-theme-alpine"
            style="width: 100%; height: 100%"
          >
          </app-grid>
        </div>
      </div>
    </div>
    <app-function-loss
      class="col-4 flex-column h-100"
      [sizeColumnsToFit]="sizeColumnsToFit"
      (changeSelectFunctionLossNodes)="updateSelectedFunctionLossNodes($event)"
      (resetModelsGrid)="resetModelsGrid()"
      [selectedRiskModelNodes]="selectedRiskModelNodes"
      (functionLossDeleted)="onFunctionLossDelete($event)"
    ></app-function-loss>
    <app-impacts
      class="col-5 flex-column h-100"
      (resetModelsGrid)="resetModelsGrid()"
      [selectedFunctionLossNodes]="selectedFunctionLossNodes"
    ></app-impacts>
  </div>
</div>
