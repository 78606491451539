import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DataService, AuthService, ToasterService } from 'src/app/shared/services';
import { RiskAssessmentModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-asset-edit',
  templateUrl: './edit.component.html'
})
export class RiskAssessmentEditComponent implements OnInit {
  // Modal parameters
  items: any; // array of items to edit
  processReference: [] = [];
  productsReference: [] = [];
  riskModelsReference: [] = [];
  configFactorReference: [] = [];
  assets: any[] = [];
  showValidationError = false;

  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: ('mass' | 'single') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param toastr
   * @param translate
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    private toastr: ToasterService,
    public translate: TranslateService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {

    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    // get target Ids
    this.targetIds = _.map(this.items, el => el.id);

    // Get default empty object
    if ('mass' === this.mode) {
      this.items = [new RiskAssessmentModel({
        account: this.auth.getAccountId(),
        siteId: this.auth.getSiteId()
      })];
    }

    // Push current Parent to init parentReference and have default selection
    if (['single'].indexOf(this.mode) > -1) {
      this.parentReference = _.map(this.items, el => {
        return {
          id: el.parentId, name: el.parent
        };
      }).filter(el => el !== null);
    }

    // Build for
    this.form = this.getForm(this.items[0]);

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [RiskAssessmentModel?]): ('mass' | 'single') {
    // Empty array => New Item
    if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  /**
   *
   */
  getForm(item: RiskAssessmentModel): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      asset: new UntypedFormControl(this.assets),
      fastcrAssetProcess: new UntypedFormControl(+item.fastcrAssetProcess),
      configCrProduct: new UntypedFormControl(_.map(item.configCrProduct, el => +el)),
      fastcrAssetModel: new UntypedFormControl(_.map(item.fastcrAssetModel, el => +el)),
      fastcrAssetFactors: new UntypedFormControl(_.map(item.fastcrAssetFactors, el => +el)),
      fastcrAssetComment: new UntypedFormControl(item.fastcrAssetComment),
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any, mode: string) => {
  };

  /**
   *
   * @param event
   */
  onTyping(event: any): void {
    if (!event) {
      return;
    }

    this.service.by('api/assets', {
      criteria: { site: [this.auth.getSiteId(), null] },
      fulltext: { name: event },
    }).subscribe((res) => {
      this.parentReference = res;
      // // @ts-ignore
      // this.parentReference.unshift({id: -1, name: '(reset value)'});
    });
  }

  /**
   *
   * @param event
   */
  onSelect(event: any): void {
    if (event[0]) {
      this.data = event[0].data;
    }
  }

  /**
   *
   */
  save(): boolean | void {
    this.showValidationError = true;
    if (!this.form || (this.form && !this.form.valid)) {
      return false;
    }

    let data: any = {};
    if (this.mode === 'single') {
      data = this.form.value;
    } else {
      _.each(this.form.controls, (control, name) => {
        if (control.dirty === true && control.value !== -1) {
          data[name] = control.value;
        }
      });

      _.merge(data, { asset: this.assets });
    }

    const validation = this.validateRiskAssessmentForm(data);
    if (validation.error) {
      if (validation.message) {
        this.toastr.showError(validation.message);
      } else {
        this.bsModalRef.hide();
      }
      return;
    }

    this.isSubmitted = true;

    this.service.post('api/fastcrit/risk-assessment', data).subscribe(res => {
      this.isSubmitted = false;
      this.confirmFn(res, this.mode);
      this.bsModalRef.hide();
    }, err => {
      this.isSubmitted = false;
    });
  }

  validateRiskAssessmentForm(data: any): any {
    const obj: { error: boolean, message: any } = { error: false, message: null };

    const msg = `${this.translate.instant('messages.criticality.at_least_one_field_required')} ${this.translate.instant('headers.fastcr.risk_assessment.process')}/${this.translate.instant('headers.fastcr.risk_assessment.product')}/${this.translate.instant('headers.fastcr.risk_assessment.model')}/${this.translate.instant('headers.fastcr.risk_assessment.factor')}`;

    if (this.form.value.asset.length > 1 &&
      !data.hasOwnProperty('fastcrAssetProcess') &&
      !data.hasOwnProperty('configCrProduct') &&
      !data.hasOwnProperty('fastcrAssetModel') &&
      !data.hasOwnProperty('fastcrAssetFactors') &&
      !data.hasOwnProperty('fastcrAssetComment')
    ) {
      obj.error = true;
    } else if (data.asset.length === 1) {
      const isRequiredFieldDirty = (this.form.get('fastcrAssetProcess')?.dirty ||
        this.form.get('configCrProduct')?.dirty ||
        this.form.get('fastcrAssetModel')?.dirty ||
        this.form.get('fastcrAssetFactors')?.dirty);

      if (!isRequiredFieldDirty && !data.fastcrAssetProcess &&
        (!data.configCrProduct || data.configCrProduct.length < 1) &&
        (!data.fastcrAssetModel || data.fastcrAssetModel.length < 1) &&
        (!data.fastcrAssetFactors || data.fastcrAssetFactors.length < 1)
      ) {
        obj.error = true;
        obj.message = msg;
      } else if (!this.form.dirty) {
        obj.error = true;
        obj.message = this.translate.instant('messages.criticality.edit_any_field');
      }
    }
    return obj;
  }
}
