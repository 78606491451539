import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GridComponent } from '../../../shared';
import { AppearanceEditComponent } from './edit/edit.component';
import { environment } from '../../../../environments/environment';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../../shared/services/breadcrumb.service';
import { AccountModel } from 'src/app/shared/models';
import { syncItems } from 'src/utils';

@Component({
  selector: 'app-appearance',
  templateUrl: './appearance.component.html',
})
export class AppearanceComponent implements OnInit {
  @ViewChild(GridComponent, { static: true }) appearanceGrid?: GridComponent;

  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.parameters'),
          link: '/admin',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.appearance'),
          link: '/admin/appearance',
        },
      ],
      2
    );
    this.gridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.account_and_user_management.appearance.account'
          ),
          field: 'name',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.account_and_user_management.appearance.color'
          ),
          field: 'color',
          cellRenderer: (params: any): any => {
            return (
              '<span class="account-color-span" style="background-color: ' +
              params.value +
              '"></span>'
            );
          },
        },
      ],
    };
    await this.loadItems();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data
      .by('api/accounts', {
        criteria: {
          id: this.auth.getAccountId(),
        },
      })
      .toPromise();
    this.appearanceGrid?.gridApi?.sizeColumnsToFit();
    this.appearanceGrid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  onFilterChanged(event: any): void {}

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onRowClicked(event: any): void {
    this.showEditModal([event]);
  }

  getImageUrl(items: any): string {
    const item = items[0];
    if (item.logo !== '') {
      return (
        environment.url +
        'api/accounts/' +
        item.id +
        '/img?token=' +
        this.auth.getToken() +
        '&r=' +
        Math.random()
      );
    } else {
      return '';
    }
  }

  /**
   * @param items
   */
  async showEditModal(items?: any[]): Promise<void> {
    const imageUrl = this.getImageUrl(items);
    this.bsModalRef = this.bsModalService.show(AppearanceEditComponent, {
      initialState: {
        items,
        imageUrl,
      },
    });
    this.bsModalRef.content.confirmFn = (res: [AccountModel]) => {
      this.items = syncItems([res], this.items);
      this.toastr.showSuccess();
    };
  }
}
