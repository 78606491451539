import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  DataService,
  SharedDataService,
  ToasterService,
} from '../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent, GridComponent } from '../../shared';
import * as _ from 'lodash';
import { BreadCrumbService } from '../../shared/services/breadcrumb.service';
import { AssetSummaryComponent } from '../../shared/components/asset-summary/asset-summary.component';
import { CustomCriteriaEditComponent } from './edit/edit.component';

@Component({
  selector: 'app-custom-criteria',
  templateUrl: './custom-criteria.component.html',
})
export class CustomCriteriaComponent implements OnInit {
  @ViewChild(GridComponent, { static: true })
  customCriteriaGrid?: GridComponent;

  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  toolingReference?: [];
  tasksGridAutoGroupColumnDef?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  customHeaders: any;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private sharedData: SharedDataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant(
            'navigations.breadcrumbs.custom_criteria'
          ),
          link: '/fastcrit/custom-criteria',
        },
      ],
      2
    );

    this.loadTools();

    this.customHeaders = await this.sharedData.getAssetCustomHeaders(
      this.auth.getAccountId()
    );

    this.gridOptions = {
      getRowId: (row: any) => row.data.key,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1, // expand all groups by default
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.asset.asset'),
          field: 'asset_id',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
          valueGetter: (params: any): any => {
            if (params.data) {
              return params.data.asset + `-${params.data.asset_id}`;
            }
          },
          cellRenderer: (params: any): any => {
            if (params.value) {
              const aTag = document.createElement('a');
              aTag.href = 'javascript:void(0)';
              const parts = params.value.split('-');
              const result = parts[parts.length - 1];
              aTag.onclick = () => {
                this.showSummaryModal(result);
              };
              aTag.innerHTML = params.value.substring(
                0,
                params.value.lastIndexOf('-')
              );
              return aTag;
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.component.component'
          ),
          field: 'task_component',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.activity.activity'
          ),
          field: 'activity_name',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.activity.parameter'
          ),
          field: 'activity_parameter',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.custom_criteria.value'
          ),
          field: 'custom_criteria_value',
        },
        {
          headerName:
            this.translate.instant('headers.asset.asset') +
            ' ' +
            this.translate.instant('headers.asset.parent'),
          field: 'parent_asset',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.location.location'),
          field: 'location',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqtype'),
          field: 'eqtype',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.specifications'),
          field: 'specifications',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmake'),
          field: 'eqmake',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.eqmodel'),
          field: 'eqmodel',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom1,
          field: 'custom1',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom2,
          field: 'custom2',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom3,
          field: 'custom3',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom4,
          field: 'custom4',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom5,
          field: 'custom5',
          hide: true,
        },
        {
          headerName: this.customHeaders.custom6,
          field: 'custom6',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.model'),
          field: 'model',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.state'
          ),
          field: 'state',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.frequency'
          ),
          field: 'frequency',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.labor'
          ),
          field: 'labor',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.tool.tool'
          ),
          field: 'tools',
          hide: true,
          filterParams: {
            values: _(this.toolingReference)
              .map((el: any) => el.name)
              .unshift(null)
              .value(),
          },
          valueGetter: (params: any) => {
            if (params.data !== undefined) {
              return params.data.atools;
            }
          },
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.duration'
          ),
          field: 'duration',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.criteria'
          ),
          field: 'criteria',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.keyword'),
          field: 'keyword',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.proc'),
          field: 'procedure',
          hide: true,
        },
      ],
    };

    this.tasksGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.fastpm.pm_model.task'),
      field: 'task_description',
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };
    await this.loadItems();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }
    this.items = await this.data
      .get('api/fastpm/custom-criteria/from-account')
      .toPromise();
    this.customCriteriaGrid?.gridApi?.sizeColumnsToFit();
    this.customCriteriaGrid?.uncheckAll();
    if (!silent) {
      this.isLoading = false;
    }
  }

  async loadTools(): Promise<void> {
    const orderByName = { orderBy: { name: 'asc' } };
    this.toolingReference = await this.data
      .by('api/config/tools', {
        criteria: {
          account: [this.auth.getAccountId(), null],
        },
        ...orderByName,
      })
      .toPromise();
  }

  async showSummaryModal(assetId: number): Promise<void> {
    this.bsModalRef = this.bsModalService.show(AssetSummaryComponent, {
      initialState: {
        assetId,
      },
      class: 'modal-lg',
    });
  }

  onFilterChanged(event: any): void {}

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onRowClicked(event: any): void {
    this.showEditModal([event]);
  }

  /**
   * @param items
   */
  async showEditModal(items?: any): Promise<void> {
    this.bsModalRef = this.bsModalService.show(CustomCriteriaEditComponent, {
      initialState: {
        items,
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      this.toastr.showSuccess();
    };
  }

  /**
   */
  showMassEditModal(): void {
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      const items = _.map(this.selectedNodes, (el) => el.data);
      this.showEditModal(items);
    }
  }

  /**
   */
  showMassClearConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const data = _.map(this.selectedNodes, (el) => ({
        id: el.data.custom_criteria_id,
        value: null,
      }));
      this.data.saveAll('api/fastpm/custom-criteria', data).subscribe(
        (res) => {
          this.loadItems();
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }
}
