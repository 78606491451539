import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DataService, AuthService, SharedDataService } from 'src/app/shared/services';
import { AssetModel } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { RESP_GROUP_UNIT, RESP_GROUP_PLANNING, RESP_GROUP_COSTCENTER, RESP_GROUP_WORKCENTER } from '../../../shared/constants';
import { doSelectOpen, onSearch } from 'src/utils';
@Component({
  selector: 'app-asset-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class AssetEditComponent implements OnInit, OnDestroy {
  // Modal parameters
  items: any; // array of items to edit
  boolReference: { value: boolean, name: string }[] = [];
  selectedAsset: any;
  criticalityReference: [] = [];
  locationReference: [] = [];
  accessReference: [] = [];
  customHeaders: any = [];
  eqtypeReference: any = [];
  customField1: any = [];
  customField2: any = [];
  customField3: any = [];
  customField4: any = [];
  customField5: any = [];
  customField6: any = [];
  respGroups: any = [];

  respGroupUnitArr = [];
  respGroupPlanningGroupArr = [];
  respGroupCostCenterArr = [];
  respGroupWorkCenterArr = [];

  // form instance
  form!: UntypedFormGroup;

  // Usage mode
  mode: ('mass' | 'single' | 'new') = 'single';

  // Target Id's
  targetIds: number[] = [];

  // UI Flag
  isLoading = false;
  data = null;
  isSubmitted = false;

  // Form reference Data
  parentReference: any;

  /**
   * @param formBuilder
   * @param service
   * @param auth
   * @param bsModalRef
   * @param translate
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    protected service: DataService,
    protected sharedData: SharedDataService,
    public auth: AuthService,
    public bsModalRef: BsModalRef,
    public translate: TranslateService
  ) {
    this.isLoading = true;
  }

  /**
   *
   */
  async ngOnInit(): Promise<void> {
    // Sniff mode
    this.mode = this.sniffEditMode(this.items);

    this.respGroupUnitArr = this.respGroups.filter((item: any) => item.type === RESP_GROUP_UNIT);
    this.respGroupPlanningGroupArr = this.respGroups.filter((item: any) => item.type === RESP_GROUP_PLANNING);
    this.respGroupCostCenterArr = this.respGroups.filter((item: any) => item.type === RESP_GROUP_COSTCENTER);
    this.respGroupWorkCenterArr = this.respGroups.filter((item: any) => item.type === RESP_GROUP_WORKCENTER);

    // get target Ids
    this.targetIds = _.map(this.items, el => el.id);

    this.customHeaders = await this.sharedData.getAssetCustomHeaders(this.auth.getAccountId());

    // Get default empty object
    if (['mass', 'new'].indexOf(this.mode) > -1) {
      this.items = [new AssetModel({
        account: this.auth.getAccountId(),
        siteId: this.auth.getSiteId(),
        parentId: this.selectedAsset?.id || null,
        parent: this.selectedAsset?.data?.name || null,
      })];

      this.parentReference = [{
        id: this.selectedAsset?.id,
        name: this.selectedAsset?.data.name
      }];
    }

    // Push current Parent to init parentReference and have default selection
    if (['single'].indexOf(this.mode) > -1) {
      this.parentReference = _.map(this.items, el => {
        return {
          id: el.parentId, name: el.parent
        };
      }).filter(el => el !== null);
    }
    // Build for
    this.form = this.getForm(this.items[0]);

    // set eqtype option in select list if not exist
    const matchedEqType = this.eqtypeReference.find((eq: any) => eq.description === this.items[0].type);
    if (!matchedEqType && this.items[0].type) {
      this.eqtypeReference.unshift({ id: 0, description: this.items[0].type });
    }

    const matchedCustomField1 = this.customField1.find((eq: any) => eq.description === this.items[0].custom1);
    if (!matchedCustomField1 && this.items[0].custom1) {
      this.customField1.unshift({ id: 0, description: this.items[0].custom1 });
    }

    const matchedCustomField2 = this.customField2.find((eq: any) => eq.description === this.items[0].custom2);
    if (!matchedCustomField2 && this.items[0].custom2) {
      this.customField2.unshift({ id: 0, description: this.items[0].custom2 });
    }

    const matchedCustomField3 = this.customField3.find((eq: any) => eq.description === this.items[0].custom3);
    if (!matchedCustomField3 && this.items[0].custom3) {
      this.customField3.unshift({ id: 0, description: this.items[0].custom3 });
    }

    const matchedCustomField4 = this.customField4.find((eq: any) => eq.description === this.items[0].custom4);
    if (!matchedCustomField4 && this.items[0].custom4) {
      this.customField4.unshift({ id: 0, description: this.items[0].custom4 });
    }

    const matchedCustomField5 = this.customField5.find((eq: any) => eq.description === this.items[0].custom5);
    if (!matchedCustomField5 && this.items[0].custom5) {
      this.customField5.unshift({ id: 0, description: this.items[0].custom5 });
    }

    const matchedCustomField6 = this.customField6.find((eq: any) => eq.description === this.items[0].custom6);
    if (!matchedCustomField6 && this.items[0].custom6) {
      this.customField6.unshift({ id: 0, description: this.items[0].custom6 });
    }

    // Loading done !
    this.isLoading = false;
  }

  /**
   * Sniff usage mode from items configuration
   */
  sniffEditMode(items: [AssetModel?]): ('mass' | 'single' | 'new') {
    // Empty array => New Item
    if (items === undefined || items.length === 0) {
      return 'new';

      // More than one item => Mass Edit
    } else if (items.length > 1) {
      return 'mass';

      // Else => Single Edit
    } else {
      return 'single';
    }
  }

  /**
   *
   */
  getForm(item: AssetModel): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(item.id),
      name: new UntypedFormControl(item.name),
      description: new UntypedFormControl(item.description),
      criticality: new UntypedFormControl(item.criticalityId),
      location: new UntypedFormControl(item.locationId),
      accesses: new UntypedFormControl(_.map(item.accesses, (el: any) => el.id)),
      type: new UntypedFormControl(item.type),
      active: new UntypedFormControl(item.active),
      system: new UntypedFormControl(item.system),
      parent: new UntypedFormControl(item.parentId),
      ranking: new UntypedFormControl(item.ranking),
      drawing: new UntypedFormControl(item.drawing),
      specifications: new UntypedFormControl(item.specifications),
      eqmake: new UntypedFormControl(item.eqmake),
      eqmodel: new UntypedFormControl(item.eqmodel),
      eqserial: new UntypedFormControl(item.eqserial),
      eqsupplier: new UntypedFormControl(item.eqsupplier),
      custom1: new UntypedFormControl(item.custom1),
      custom2: new UntypedFormControl(item.custom2),
      custom3: new UntypedFormControl(item.custom3),
      custom4: new UntypedFormControl(item.custom4),
      custom5: new UntypedFormControl(item.custom5),
      custom6: new UntypedFormControl(item.custom6),
      site: new UntypedFormControl(item.siteId),
      unit: new UntypedFormControl(item.unit),
      planningGroup: new UntypedFormControl(item.planningGroup),
      costCenter: new UntypedFormControl(item.costCenter),
      workCenter: new UntypedFormControl(item.workCenter)
    });
  }

  /**
   * Confirm callback with updated res
   */
  confirmFn = (res: any, mode: string) => {
  }

  /**
   *
   * @param event
   */
  onTyping(event: any): void {
    if (!event) {
      return;
    }

    this.service.by('api/assets', {
      criteria: { site: [this.auth.getSiteId(), null] },
      fulltext: { name: event },
    }).subscribe((res) => {
      this.parentReference = res;
      // // @ts-ignore
      // this.parentReference.unshift({id: -1, name: '(reset value)'});
    });
  }

  /**
   *
   * @param event
   */
  onEqTypeSearch(event: any): void {
    onSearch(event, this.eqtypeReference, this.form, 'type');
  }

  /**
   *
   * @param event
   */
  onCustom1Search(event: any): void {
    onSearch(event, this.customField1, this.form, 'custom1');
  }

  /**
   *
   * @param event
   */
  onCustom2Search(event: any): void {
    onSearch(event, this.customField2, this.form, 'custom2');
  }

  /**
   *
   * @param event
   */
  onCustom3Search(event: any): void {
    onSearch(event, this.customField3, this.form, 'custom3');
  }

  /**
   *
   * @param event
   */
  onCustom4Search(event: any): void {
    onSearch(event, this.customField4, this.form, 'custom4');
  }

  /**
   *
   * @param event
   */
  onCustom5Search(event: any): void {
    onSearch(event, this.customField5, this.form, 'custom5');
  }

  /**
   *
   * @param event
   */
  onCustom6Search(event: any): void {
    onSearch(event, this.customField6, this.form, 'custom6');
  }

  /**
   *
   * @param event
   */
  onSelect(event: any): void {
    if (event[0]) {
      this.data = event[0].data;
    }
  }

  /**
   *
   */
  doEqtypeSelectOpen(): void {
    doSelectOpen(this.form, 'type');
  }

  /**
   *
   */
  doCustom1SelectOpen(): void {
    doSelectOpen(this.form, 'custom1');
  }

  /**
   *
   */
  doCustom2SelectOpen(): void {
    doSelectOpen(this.form, 'custom2');
  }


  /**
   *
   */
  doCustom3SelectOpen(): void {
    doSelectOpen(this.form, 'custom3');
  }

  /**
   *
   */
  doCustom4SelectOpen(): void {
    doSelectOpen(this.form, 'custom4');
  }

  /**
   *
   */
  doCustom5SelectOpen(): void {
    doSelectOpen(this.form, 'custom5');
  }

  /**
   *
   */
  doCustom6SelectOpen(): void {
    doSelectOpen(this.form, 'custom6');
  }

  /**
   *
   */
  save(): boolean | void {
    if (!this.form || (this.form && !this.form.valid)) {
      return false;

    } else {

      // Query pointer
      let query;

      if (this.mode === 'new') {
        const tmpData = _.pickBy(this.form.value, el => el !== null);
        query = this.service.post('api/assets', tmpData);

      } else if (this.mode === 'single') {
        query = this.service.update('api/assets', this.targetIds[0], this.form.value);

      } else {
        const tmpData: any = {};
        _.each(this.form.controls, (control, name) => {
          if (control.dirty === true && control.value !== -1) {
            tmpData[name] = control.value;
          }
        });
        const data = _.map(this.targetIds, id => {
          const cloned = _.cloneDeep(tmpData);
          _.merge(cloned, { id });
          return cloned;
        });

        const retainFields = ['accesses'];
        query = this.service.saveAllRetain('api/assets', data, retainFields);
      }

      this.isSubmitted = true;
      query.subscribe(res => {
        this.isSubmitted = false;
        this.confirmFn(res, this.mode);
        this.bsModalRef.hide();
      }, err => {
        console.log('err: ', err);
        this.isSubmitted = false;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.eqtypeReference[0]?.id === 0) {
      this.eqtypeReference.shift();
    }
    if (this.customField1[0]?.id === 0) {
      this.customField1.shift();
    }
    if (this.customField2[0]?.id === 0) {
      this.customField2.shift();
    }
    if (this.customField3[0]?.id === 0) {
      this.customField3.shift();
    }
    if (this.customField4[0]?.id === 0) {
      this.customField4.shift();
    }
    if (this.customField5[0]?.id === 0) {
      this.customField5.shift();
    }
    if (this.customField6[0]?.id === 0) {
      this.customField6.shift();
    }
  }
}
