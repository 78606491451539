import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BreadCrumbService } from '../../../shared/services/breadcrumb.service';
import { GridOptions } from 'ag-grid-community';
import { AuthService, DataService } from '../../../shared/services';
import * as _ from 'lodash';
import { AssetSummaryComponent } from '../../../shared/components/asset-summary/asset-summary.component';

@Component({
  selector: 'app-plans-tasks',
  templateUrl: './plan-tasks.component.html',
})
export class PlanTasksComponent implements OnInit, OnChanges {
  // Modal ref pointer

  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;

  tasksGridAutoGroupColumnDef?: any;

  items: any[] = [];

  isLoading = false;

  @Input() selectedPlan: any[] = [];
  isReferenceLoaded = false;
  boolReference?: { value: boolean; name: string }[];
  criticalityReference?: [];
  locationReference?: [];

  constructor(
    private translate: TranslateService,
    public breadCrumbService: BreadCrumbService,
    private data: DataService,
    public auth: AuthService,
    private bsModalService: BsModalService
  ) {}

  async ngOnInit(): Promise<void> {
    this.breadCrumbService.set(
      [
        {
          name: this.translate.instant('navigations.breadcrumbs.modules'),
          link: '/modules',
        },
        {
          name: this.translate.instant('navigations.breadcrumbs.plans'),
          link: '/routing/plans',
        },
      ],
      2
    );

    this.gridOptions = {
      getRowId: (row: any) => row.data.routing_plan_task_id,
      suppressRowClickSelection: true,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant('headers.routing.plan.plan'),
          field: 'plan_name',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.location.location'),
          field: 'location_name',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.asset.asset'),
          field: 'asset_id',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
          cellRenderer: (params: any): any => {
            const paramAsset = this.items.find(
              (asset) => asset.asset_id === params.value
            );
            if (!paramAsset) {
              return '';
            }
            const aTag = document.createElement('a');
            aTag.href = 'javascript:void(0)';
            aTag.onclick = () => {
              this.showSummaryModal(params.value);
            };
            aTag.innerHTML = paramAsset?.asset_name;
            return aTag;
          },
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.component.component'
          ),
          field: 'component',
          rowGroup: true,
          filter: 'agTextColumnFilter',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.description'
          ),
          field: 'description',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.frequency'
          ),
          field: 'frequency',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.labor'
          ),
          field: 'trade',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.activity.activity'
          ),
          field: 'activity',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.duration'
          ),
          field: 'duration',
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.state'
          ),
          field: 'state',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.criteria'
          ),
          field: 'criteria',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.keyword'),
          field: 'keyword',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.proc'),
          field: 'procedure',
        },
        {
          headerName: this.translate.instant('headers.fastpm.pm_model.comment'),
          field: 'comment',
        },
        {
          headerName: this.translate.instant(
            'headers.fastpm.pm_model.justification'
          ),
          field: 'justification',
        },
        {
          headerName: this.translate.instant('headers.routing.task.id'),
          field: 'id',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.state.id'
          ),
          field: 'stateId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.frequency.id'
          ),
          field: 'frequencyId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.labor.id'
          ),
          field: 'tradeId',
          hide: true,
        },
        {
          headerName: this.translate.instant(
            'headers.tasks_parameters.component.id'
          ),
          field: 'componentId',
          hide: true,
        },
      ],
    };
    this.tasksGridAutoGroupColumnDef = {
      headerName: this.translate.instant('headers.fastpm.pm_model.group'),
      field: 'component',
      filter: 'agTextColumnFilter',
      sortable: true,
      sort: 'asc',
    };
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedPlan) {
      await this.loadItems();
      return;
    }
    this.items = [];
  }

  async loadItems(): Promise<void> {
    this.isLoading = true;
    this.items = await this.data
      .post('api/plans/tasks', { plan_ids: this.selectedPlan })
      .toPromise();
    this.isLoading = false;
  }

  async showSummaryModal(assetId: number): Promise<void> {
    await this.loadReferences();

    this.bsModalRef = this.bsModalService.show(AssetSummaryComponent, {
      initialState: {
        assetId,
      },
      class: 'modal-lg',
    });
  }

  async loadReferences(): Promise<void> {
    // Bool
    this.boolReference = [
      { value: true, name: this.translate.instant('navigations.yes') },
      { value: false, name: this.translate.instant('navigations.no') },
    ];

    const orderByName = { orderBy: { name: 'asc' } };
    const orderByThreshold = { orderBy: { threshold: 'asc' } };
    // Criticality
    this.criticalityReference = await this.data
      .by('api/config/criticalities', {
        criteria: {
          account: [this.auth.getAccountId()],
        },
        ...orderByThreshold,
      })
      .toPromise();

    // Location
    this.locationReference = await this.data
      .by('api/map/locations', {
        criteria: {
          site: [this.auth.getSiteId()],
        },
        ...orderByName,
      })
      .toPromise();

    // Mark ref as loaded (prevent reload)
    this.isReferenceLoaded = true;
  }
}
