export class ConfFinalityModel {
  id: number;
  description: string;
  costperhour: number;

  account: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.description = data.description ? data.description : '';
    this.costperhour = data.costperhour ? data.costperhour : 0;

    this.account = data.account ? data.account : null;
  }
}
