<ng-container *ngIf="!isLoading">
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="form-group description-select-container col-12">
          <label
            [class.font-weight-bold]="form.get('description')?.dirty"
            for="description"
          >
            {{ "headers.fastlc.asset_action.description" | translate }}
            <span *ngIf="mode !== 'mass'" class="text-danger">*</span>
          </label>
          <ngx-select
            *ngIf="mode === 'new'"
            (select)="onChange()"
            (typed)="onActionSearch($event)"
            [items]="actionsReference"
            [placeholder]="'(Select description)'"
            optionTextField="description"
            optionValueField="description"
            size="small"
            formControlName="description"
            (open)="doActionSelectOpen()"
          ></ngx-select>
          <input
            *ngIf="mode !== 'new'"
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('description')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="description"
            id="description"
            type="text"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('description')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label [class.font-weight-bold]="form.get('cost')?.dirty" for="cost">
            {{ "headers.fastlc.asset_action.cost" | translate }}
          </label>
          <input
            [ngClass]="{
              'is-invalid': showValidationError && form.get('cost')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="cost"
            id="cost"
            type="number"
            (keypress)="validateNumberInput($event)"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('cost')?.errors?.min"
          >
            {{ "messages.positive" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label [class.font-weight-bold]="form.get('type')?.dirty" for="type">
            {{ "headers.fastlc.asset_action.type" | translate }}
            <span *ngIf="mode !== 'mass'" class="text-danger">*</span>
          </label>
          <ngx-select
            [items]="typesReference"
            [placeholder]="'(Select a Type)'"
            formControlName="type"
            id="type"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('type')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('budget')?.dirty"
            for="budget"
          >
            {{ "headers.fastlc.asset_action.budget" | translate }}
            <span *ngIf="mode !== 'mass'" class="text-danger">*</span>
          </label>
          <ngx-select
            [items]="budgetsReference"
            [placeholder]="'(Select a Budget)'"
            formControlName="budget"
            id="budget"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('budget')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label [class.font-weight-bold]="form.get('plan')?.dirty" for="plan">
            {{ "headers.fastlc.asset_action.plan" | translate }}
            <span *ngIf="mode !== 'mass'" class="text-danger">*</span>
          </label>
          <ngx-select
            [items]="years"
            [placeholder]="'(Select a year)'"
            formControlName="plan"
            id="plan"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('plan')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('status')?.dirty"
            for="status"
          >
            {{ "headers.fastlc.asset_action.status" | translate }}
            <span *ngIf="mode !== 'mass'" class="text-danger">*</span>
          </label>
          <ngx-select
            [items]="statusesReference"
            [placeholder]="'(Select a Status)'"
            formControlName="status"
            id="status"
            optionTextField="name"
            optionValueField="value"
            size="small"
          ></ngx-select>
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('status')?.invalid"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('savings')?.dirty"
            for="savings"
          >
            {{ "headers.fastlc.asset_action.savings" | translate }}
          </label>
          <input
            [ngClass]="{
              'is-invalid': showValidationError && form.get('savings')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="savings"
            id="savings"
            type="number"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('savings')?.invalid"
          >
            {{ "messages.positive" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('cec_reduction')?.dirty"
            for="cec_reduction"
          >
            {{ "headers.fastlc.asset_action.cec_reduction_ff" | translate }}
          </label>
          <input
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('cec_reduction')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="cec_reduction"
            id="cec_reduction"
            type="number"
            (keypress)="validateNumberInput($event)"
          />
          <div
            class="text-danger"
            *ngIf="
              showValidationError && form.get('cec_reduction')?.errors?.min
            "
          >
            {{ "messages.positive" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('ogc_reduction')?.dirty"
            for="ogc_reduction"
          >
            {{ "headers.fastlc.asset_action.ogc_reduction_ff" | translate }}
          </label>
          <input
            [ngClass]="{
              'is-invalid':
                showValidationError && form.get('ogc_reduction')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="ogc_reduction"
            id="ogc_reduction"
            type="number"
            (keypress)="validateNumberInput($event)"
          />
          <div
            class="text-danger"
            *ngIf="
              showValidationError && form.get('ogc_reduction')?.errors?.min
            "
          >
            {{ "messages.positive" | translate }}
          </div>
        </div>
        <div class="form-group col-12">
          <label
            [class.font-weight-bold]="form.get('comment')?.dirty"
            for="comment"
          >
            {{ "headers.fastlc.asset_action.comment" | translate }}
          </label>
          <input
            [ngClass]="{
              'is-invalid': showValidationError && form.get('comment')?.invalid
            }"
            class="form-control form-control-sm"
            formControlName="comment"
            id="comment"
            type="text"
          />
          <div
            class="text-danger"
            *ngIf="showValidationError && form.get('comment')?.errors?.min"
          >
            {{ "messages.required" | translate }}
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="form-group">
      <div class="col-12 d-flex justify-content-between">
        <button (click)="bsModalRef.hide()" class="btn btn-sm btn-light mr-2">
          <i class="la la-ban"></i>
          {{ "navigations.cancel" | translate }}
        </button>
        <button
          (click)="save()"
          [disabled]="isSubmitted"
          class="btn btn-sm btn-success"
        >
          <i *ngIf="!isSubmitted" class="la la-save"></i>
          <i *ngIf="isSubmitted" class="la la-spinner la-spin"></i>
          {{ "navigations.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
