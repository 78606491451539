import { trimStart } from 'lodash';

export const doSelectOpen = (form: any, value: string): void => {
  setTimeout(() => {
    const container = document.querySelector('.' + value + '-select-container');
    const searchInput = (container?.querySelector('.ngx-select__search') as HTMLInputElement);
    searchInput.value = form.get(value)?.value;
  });
};

export const onSearch = (event: any, field: any, form: any, value: any): void => {
  const fieldLength = field.length;

  // if the last element is the dynamic one (not available in original list), then remove it and add updated input value again
  if (fieldLength && field[0].id === 0) {
    field.shift();
  }

  form.get(value)?.setValue(event || null);

  if (!event) {
    return;
  }

  form.get(value)?.markAsDirty();
  field.unshift({ id: 0, description: event });
};

export const validateNumberInput = (event: KeyboardEvent): void => {
  const charKey = event.key;

  if (['.', 'e', 'E'].includes(charKey)) {
    event.preventDefault();
  }
};

export const syncItems = (res: any, items: any[]): any[] => {

  if (!Array.isArray(res)) {
    res = [res];
  }

  // if a new single item was added, just push it to items
  if (res.length === 1 && !items.find((row: any) => row.id === res[0].id)) {
    return [...items, res[0]];
  }

  res.map((resItem: any) => {
    if (!items.find((row: any) => row.id === resItem.id)) {
      items = [...items, resItem];
    } else {
      items = items.map((item: any) => {
        const updatedItem = res.find((row: any) => row.id === item.id);
        if (updatedItem) {
          delete updatedItem.hierarchy;
          return { ...item, ...updatedItem };
        }
        return item;
      });
    }
  });

  return items;
};

export const copyToClipboard = (selectedNodes: any) => {
  let text = '';

  selectedNodes.map((item: any) =>
    text = text + '\n' + item.data.name
  );

  navigator.clipboard.writeText(trimStart(text));
};
