<ag-grid-angular
  (filterChanged)="onFilterChange()"
  (sortChanged)="onSortChange()"
  (columnResized)="onColumnResize()"
  (columnMoved)="onColumnMoved()"
  (gridReady)="onGridReady($event)"
  (rowClicked)="onRowClick($event)"
  (rowDoubleClicked)="onRowDoubleClick($event)"
  (selectionChanged)="onSelectionChange($event)"
  (rowDataChanged)="onRowDataChanged()"
  (rowDragMove)="onRowDragMove($event)"
  (rowDragEnd)="onRowDragEnd($event)"
  (columnVisible)="onColumnVisible($event)"
  (firstDataRendered)="onFirstDataRendered()"
  *ngIf="gridOptions !== undefined"
  [gridOptions]="gridOptions"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [frameworkComponents]="frameworkComponents"
  [groupSelectsChildren]="groupSelectsChildren"
  [groupSelectsFiltered]="true"
  [masterDetail]="masterDetail"
  [detailCellRendererParams]="detailCellRendererParams"
  [rowData]="rowData"
  class="ag-theme-alpine"
  style="width: 100%; height: 100%"
>
</ag-grid-angular>
