<div class="d-flex flex-column h-100">
  <div class="d-flex align-items-center px-2">
    <div class="mr-2">
      <button (click)="onUiGridToggle()" class="btn btn-sm btn-light mr-1">
        <i class="la la-exchange-alt la-lg"></i>
      </button>
    </div>
    <div class="grid title mr-2">
      {{ "headers.routing.fsl.plans" | translate }}
    </div>
    <div class="mr-2">
      <button
        (click)="loadItems()"
        [ngClass]="{ 'btn-success': isLoading, 'btn-light': !isLoading }"
        class="btn btn-sm"
      >
        <i [ngClass]="{ 'la-spin': isLoading }" class="la la-refresh"></i>
      </button>
    </div>
    <div class="mr-2">
      <button
        alt="clear filter"
        (click)="grid?.clearAllFilters()"
        [disabled]="!grid?.isFilterApplied"
        [ngClass]="{
          'clear-filter position-relative': grid?.isFilterApplied
        }"
        class="btn btn-light btn-sm"
      >
        <i class="las la-filter"></i>
      </button>
    </div>
  </div>
  <div class="d-flex h-100 w-100 flex-grow-1 pt-2 ag-grid-container">
    <app-grid
      [gridUid]="'routing_fsl_plans'"
      (selectionChanged)="onSelectionChanged($event)"
      [gridOptions]="gridOptions"
      [rowData]="items"
      class="ag-theme-alpine"
      style="width: 100%; height: 100%"
    >
    </app-grid>
  </div>
</div>
