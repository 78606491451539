import { ConfProductModel } from './ConfProductModel';
import { ConfConsequenceModel } from './ConfConsequenceModel';
import { FunctionLossModel } from './FunctionLossModel';

export class ImpactModel {
  id: number;
  configProduct: ConfProductModel;
  configConsequence: ConfConsequenceModel;
  fastcrFunctionLoss: FunctionLossModel;
  probabilityConsequence: string;
  comment: string;

  constructor(data: any) {
    this.id = data.id ? data.id : 0;
    this.configProduct = data.configProduct ? data.configProduct : ConfProductModel;
    this.configConsequence = data.configConsequence ? data.configConsequence : ConfConsequenceModel;
    this.fastcrFunctionLoss = data.fastcrFunctionLoss ? data.fastcrFunctionLoss : FunctionLossModel;
    this.probabilityConsequence = data.probabilityConsequence ? data.probabilityConsequence : '';
    this.comment = data.comment ? data.comment : '';
  }
}
