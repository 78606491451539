import {
  Component,
  OnInit,
  ViewChild,
  Input,
  SimpleChanges,
  Output,
  OnChanges,
  EventEmitter,
} from '@angular/core';
import {
  AuthService,
  DataService,
  ToasterService,
} from '../../shared/services';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FastspSpareModel } from '../../shared/models';
import { ConfirmModalComponent } from '../../shared/modals';
import { GridComponent } from '../../shared';
import { SpareEditComponent } from './spare-edit/edit.component';

import * as _ from 'lodash';

import { BreadCrumbService } from '../../shared/services/breadcrumb.service';

@Component({
  selector: 'app-spares',
  templateUrl: './spares.component.html',
})
export class SpareListComponent implements OnInit, OnChanges {
  @ViewChild(GridComponent, { static: true }) sparesGrid?: GridComponent;

  @Input() selectedSpareModelNodes: any[] = [];
  @Input() sizeColumnsToFit = true;
  @Output() resetModelsGrid = new EventEmitter<any>();
  // Available items
  items: any[] = [];
  selectedNodes: any[] = [];
  parentFiltered?: any;
  // UI flag
  isLoading = false;
  // Local grid options
  gridOptions?: GridOptions;
  // Modal ref pointer
  bsModalRef?: BsModalRef;
  isReferenceLoaded = false;
  sparesGridAutoGroupColumnDef?: any;
  modelData: any = {};

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private bsModalService: BsModalService,
    private toastr: ToasterService,
    public auth: AuthService,
    public router: Router,
    public breadCrumbService: BreadCrumbService
  ) {}

  /**
   */
  async ngOnInit(): Promise<void> {
    const tmpGridOptions: GridOptions = {
      getRowId: (row: any) => row.data.id,
      suppressRowClickSelection: false,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowHeight: 30,
      headerHeight: 30,
      animateRows: true,
      floatingFiltersHeight: 30,
      enableCellTextSelection: false,
      suppressContextMenu: true,
      groupDefaultExpanded: -1, // expand all groups by default
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_model.model'
          ),
          field: 'model_name',
          valueGetter: (params: any) => {
            if (params.data) {
              return params.data.model_id;
            }
          },
          filterValueGetter: (params: any) => {
            if (params.data) {
              return params.data.model_name;
            }
          },
          cellRenderer: (params: any) => {
            return this.modelData?.[params.value];
          },
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.translate.instant('headers.fastsp.spare-list.cost'),
          field: 'cost',
        },
        {
          headerName: this.translate.instant(
            'headers.fastsp.spare_model.model_id'
          ),
          field: 'model_id',
          hide: true,
        },
      ],
    };
    this.sparesGridAutoGroupColumnDef = {
      headerName: this.translate.instant(
        'headers.fastsp.spare-list.description'
      ),
      field: 'description',
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      sort: 'asc',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    };

    this.gridOptions = tmpGridOptions;
    await this.loadItems();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedSpareModelNodes) {
      this.filterList(this.selectedSpareModelNodes);
    }
  }

  clearFilterAndResetModelsGrid(): void {
    this.sparesGrid?.clearAllFilters();
    this.resetModelsGrid.emit();
  }

  resetSparesGrid(): void {
    this.sparesGrid?.uncheckAll();
  }

  /**
   */
  async loadItems(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
    }

    this.items = await this.data
      .get('api/fastsp/spares/site-all', {})
      .toPromise();
    if (this.sizeColumnsToFit) {
      this.sparesGrid?.gridApi?.sizeColumnsToFit();
      this.sizeColumnsToFit = false;
    }

    this.sparesGrid?.uncheckAll();
    this.filterList(this.selectedSpareModelNodes);

    // set data array to use ID as value in valueGetter and Description as display value.
    // This is to allow grouping by ID instead of display value ("name" for eg.) thereby avoid grouping on duplicate display values
    this.items.map((val) => {
      this.modelData[val.model_id] = val.model_name;
    });

    if (!silent) {
      this.isLoading = false;
    }
  }

  onSelectionChanged(event: any): void {
    this.selectedNodes = event;
  }

  onRowClicked(event: any): void {
    this.showEditModal([event]);
  }

  filterList(spareModels: any): void {
    const instance = this.sparesGrid?.gridApi?.getFilterInstance('model_id');
    if (spareModels.length > 0) {
      setTimeout(() => {
        instance?.setModel({
          values: _.map(spareModels, (el) => el.id + ''),
        });
        this.sparesGrid?.gridApi?.onFilterChanged();
      });
      return;
    }
    instance?.setModel(null);
    this.sparesGrid?.gridApi?.onFilterChanged();
  }

  /**
   * @param items
   */
  showEditModal(items?: FastspSpareModel[]): void {
    this.bsModalRef = this.bsModalService.show(SpareEditComponent, {
      initialState: {
        items,
        selectedSpareModelNodes: this.selectedSpareModelNodes,
      },
      class: 'modal-md',
    });
    this.bsModalRef.content.confirmFn = () => {
      this.loadItems();
      this.toastr.showSuccess();
    };
  }

  showMassEditModal(): void {
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      const items = _.map(
        this.selectedNodes,
        (el) => new FastspSpareModel(el.data)
      );
      this.showEditModal(items);
    }
  }

  /**
   */
  showMassDeleteConfirm(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'navigations.delete',
        content: '',
      },
    });
    this.bsModalRef.content.confirmFn = () => {
      const ids = _.map(this.selectedNodes, (el) => el.id);
      this.isLoading = true;
      this.data.post('api/fastsp/spares/delete-all', ids).subscribe(
        (res) => {
          this.loadItems();
          this.toastr.showSuccess('messages.delete');
        },
        (err) => {
          this.isLoading = false;
          this.toastr.showError();
        }
      );
      this.bsModalRef?.hide();
    };
    this.bsModalRef.content.cancelFn = () => {
      this.bsModalRef?.hide();
    };
  }

  myCellCallback = (params: any) => {
    return params.value;
  };

  exportProcessCallBack = (cellParams: any) => {
    if (!cellParams.value) {
      return '';
    }

    // for group row
    if (cellParams.node.field === 'model_name') {
      return this.modelData[cellParams.node.key];
    }

    // for normal row
    if (cellParams.column.colId === 'model_name') {
      return cellParams.node.data.model_name;
    }

    return cellParams.value;
  };
}
